import { loadingSlice } from './loadingSlice';

const { actions } = loadingSlice;

export const showLoading =
  (url: string, noLoading: boolean) => (dispatch: any) => {
    dispatch(actions.startCall({ url, noLoading }));
  };

export const hiddeLoading = (url: string) => (dispatch: any) => {
  dispatch(actions.endCall({ url }));
};
