import { Fragment, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { FranchiseModel } from '../../models/FranchiseModel';
import {
  initialFilterFranchises,
  queryParamsFranchiseConfig,
} from '../../utils/utils';
import franchisesServices from '../../services/FranchiseServices';
import { FranchisesFilter } from './FranchisesFilter';
import { useDebouncedCallback } from 'use-debounce/lib';
import { FranchiseCreate } from './FranchiseCreate';
import { RootState } from '../../../../redux';
import { shallowEqual, useSelector } from 'react-redux';
import { FranchiseEdit } from './FranchiseEdit';
import { useParams } from 'react-router';
import ListComponent from '../../../../components/ListComponent';
import { useQueryParamsReplace } from '../../../../utils/useQueryParamsReplace';
import { GroupModel } from '../../../users/models/GroupModel';
interface Params {
  accountId: string;
  clientId: string;
  franchiseId: string;
}
export function FranchisesList() {
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );

  const { accountId, clientId } = useParams<Params>();
  // Pagination, Filter and Query Params
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<FranchiseModel[]>([]);
  const [filter, setFilter] = useState('');

  const [queryParams, setQueryReplace] = useQueryParamsReplace(
    queryParamsFranchiseConfig
  );
  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<FranchiseModel>[] = useMemo(
    () => [
      {
        name: 'Franchise Name',
        selector: (row) => row.name,
        sortable: true,
        sortField: 'name',
      },
      {
        name: 'Franchise Number',
        selector: (row) => row.number,
        sortable: true,
        sortField: 'number',
        center: true,
        wrap: true,
      },
      {
        name: 'Corporate Franchise',
        selector: (row) => row.isCorporate,
        cell: (row) => {
          const badgeText = row.isCorporate ? 'Yes' : 'No';
          const color = row.isCorporate ? 'badge-success' : 'badge-light';
          return <span className={`badge ${color}`}>{badgeText}</span>;
        },
        sortable: true,
        sortField: 'isCorporate',
        center: true,
      },
      {
        name: 'Active',
        cell: (row) => {
          const badgeText = row.isActive ? 'Active' : 'Inactive';
          const color = row.isActive ? 'badge-success' : 'badge-light';
          return <span className={`badge ${color}`}>{badgeText}</span>;
        },
        sortable: false,
      },
      {
        name: 'Actions',
        button: true,
        cell: (row) =>
          row.id && (
            <>
              <Fragment>
                {group.franchisePermission?.view && (
                  <button
                    onClick={() => handleView(row)}
                    className='btn btn-icon btn-bg-light btn-active-success btn-sm me-1'
                    data-bs-toggle='tooltip'
                    title='View'
                  >
                    <i className='las la-eye fs-2'></i>
                  </button>
                )}
                {group.franchisePermission?.view &&
                  group.franchisePermission?.update && (
                    <button
                      onClick={() => handleShowUpdateModal(row.id)}
                      className='btn btn-icon btn-bg-light btn-active-primary btn-sm me-1'
                      data-bs-toggle='tooltip'
                      title='Edit'
                    >
                      <i className='las la-edit fs-2'></i>
                    </button>
                  )}
              </Fragment>
            </>
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleView = (franchise: FranchiseModel) => {
    history.push(
      `/accounts/${accountId}/clients/${clientId}/franchises/${franchise.id}/locations`
    );
  };
  //Initialization methods
  useEffect(() => {
    if (history.location.search === '' && accountId && clientId) {
      setQueryReplace({
        ...initialFilterFranchises,
        accountId: accountId,
        clientId: clientId,
      });
    }
    if (queryParams.filter) setFilter(queryParams.filter);
    // Call to list franchises if and only if there are account and customer ids.
    if (queryParams.clientId && queryParams.accountId) {
      findFranchises(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findFranchises = function (queryParams: any) {
    if (group.franchisePermission?.list) {
      franchisesServices.findFranchises(queryParams).then((response) => {
        setEntities(response.data.items);
        setTotalCount(response.data.totalCount);
      });
    }
  };

  const debounced = useDebouncedCallback((params: any) => {
    const newQueryParams = { ...queryParams };
    if (params.key === 'filter') {
      let filter = params.value;
      newQueryParams.filter = filter;
      newQueryParams.pageNumber = 1;
      setQueryReplace(newQueryParams);
    }
  }, 800);
  const onFilterChange = (e: any, key: string) => {
    if (key === 'filter') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };

  const refetch = () => {
    findFranchises(queryParams);
  };

  const [selectedId, setSelectedId] = useState<number>(0);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setSelectedId(0);
  };
  const handleShowUpdateModal = (id: number) => {
    setShowUpdateModal(true);
    setSelectedId(id);
  };

  return (
    <>
      <div
        style={{
          borderTopRightRadius: '0px',
          borderTopLeftRadius: '0px',
        }}
        className='card'
      >
        {(group.franchisePermission?.list ||
          group.franchisePermission?.create) && (
          <div className='card-header py-6'>
            <div className='card-title'>
              {group.franchisePermission?.list && (
                <div className='d-flex align-items-center position-relative my-1'>
                  <i className='las la-search position-absolute ms-6 fs-2'></i>
                  <input
                    autoComplete='none'
                    type='search'
                    className='form-control form-control-solid w-350px ps-15'
                    value={filter}
                    placeholder='Search by Name or Number'
                    onChange={(e) => onFilterChange(e, 'filter')}
                  />
                </div>
              )}
            </div>
            <div className='card-toolbar'>
              {group.franchisePermission?.list && (
                <FranchisesFilter
                  queryParams={queryParams}
                  setQueryParams={setQueryReplace}
                />
              )}
              {group.franchisePermission?.create && (
                <FranchiseCreate refetch={refetch} />
              )}
            </div>
          </div>
        )}

        <div className='card-body px-9 py-3'>
          <ListComponent
            table={{
              columns,
              data: entities,
              paginationTotalRows: totalCount,
              pointerOnHover: true,
              highlightOnHover: true,
              onRowClicked: handleView,
            }}
            queryParams={queryParams}
            setQueryParams={setQueryReplace}
            notPermissions={!group.franchisePermission?.list}
          />
        </div>
      </div>
      {showUpdateModal && (
        <FranchiseEdit
          refetch={refetch}
          id={selectedId}
          show={showUpdateModal}
          onHide={handleCloseUpdateModal}
        />
      )}
    </>
  );
}
