import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
//const FILE_SIZE = 2000000;
const SUPPORTED_FORMATS = ['text/plain'];
const FileSchema = Yup.mixed()
  /*.test('fileSize', 'Max Size: 2MB.', (value) => {
    if (!value) {
      return true;
    }
    return value.size <= FILE_SIZE;
  })*/
  .test('fileFormat', 'Invalid file type', (value) => {
    if (!value) {
      return true;
    }
    return SUPPORTED_FORMATS.includes(value.type);
  })
  .required('Required file');
export const MFDataImportSchema = (intl: IntlShape) =>
  Yup.object().shape({
    productId: Yup.number()
      .positive(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .min(1, intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .integer(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    file: FileSchema,
  });
