import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useParams, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core';
import { EntityDetails } from '../../../../components/EntityDetails';
import { IsActiveComponent } from '../../../../components/IsActiveComponent';
import { EntityModel } from '../../../../models/EntityModel';
import { RootState } from '../../../../redux';
import {
  removeEntityClient,
  setEntityClient,
} from '../../../../redux/entity/entitiesActions';
import { initEntityClient } from '../../../../utils/entityUtils';
import { GroupModel } from '../../../users/models/GroupModel';
import { FranchisesPage } from '../../FranchisesPage';
import { CustomPropertyCreate } from '../../models/CustomPropertyCreate';
import clientsServices from '../../services/ClientsServices';
import { ClientEdit } from './ClientEdit';

interface Params {
  accountId: string;
  clientId: string;
}
export function ClientsView() {
  const { clientId } = useParams<Params>();
  const [client] = useSelector<RootState, EntityModel[]>(
    ({ entities }: any) => [entities.client],
    shallowEqual
  );
  const [customAttributes, setCustomAttributes] = useState<
    CustomPropertyCreate[] | null
  >(null);
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  useEffect(() => {
    if (
      (!client || !customAttributes) &&
      (pathname.endsWith('franchises') || pathname.endsWith('attributes'))
    ) {
      refetch(Number(clientId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetch = (clientId: number) => {
    clientsServices.getClient(clientId).then((response) => {
      const entity: EntityModel = initEntityClient(response.data);
      dispatch(setEntityClient(entity));
      setCustomAttributes(response.data.customProperties);
    });
  };

  //Al salir del componente limpiamos el estado
  useEffect(() => {
    return () => {
      dispatch(removeEntityClient());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumbs: Array<PageLink> = [
    {
      title: 'Accounts',
      path: '/accounts',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Clients',
      path: `/accounts/${client ? client.parentId : 0}/clients`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  const history = useHistory();
  const goBack = () => {
    history.goBack();
    setTimeout(() => {
      dispatch(removeEntityClient());
    }, 200);
  };
  //Activate/Desactivate
  const setActivate = (clientId: number) => {
    clientsServices.activateClient(clientId).then(() => {
      toast.success('Successful activation');
      refetch(clientId);
    });
  };

  const setDeactivate = (clientId: number, reason?: string) => {
    clientsServices.desactivateClient(clientId, reason).then(() => {
      toast.success('Successful deactivation');
      refetch(clientId);
    });
  };
  return (
    <>
      <Route
        path={[
          '/accounts/:accountId/clients/:clientId/franchises',
          '/accounts/:accountId/clients/:clientId/attributes',
        ]}
        exact
      >
        {client && (
          <EntityDetails
            entity={client}
            goBack={goBack}
            desactivateComponent={IsActiveComponent}
            setActivate={setActivate}
            setDeactivate={setDeactivate}
            permissions={group.clientPermission}
            editComponent={ClientEdit}
            refetch={refetch}
          />
        )}
      </Route>
      <Route path='/accounts/:accountId/clients/:clientId/franchises'>
        <FranchisesPage />
      </Route>
      <Route path='/accounts/:accountId/clients/:clientId/attributes'>
        <PageTitle breadcrumbs={breadcrumbs}>Client Detail </PageTitle>
        <div
          style={{
            borderTopRightRadius: '0px',
            borderTopLeftRadius: '0px',
          }}
          className='card'
        >
          <div className='card-body px-9 py-3'>
            {customAttributes && customAttributes.length > 0 && (
              <div className='table-responsive mt-10'>
                <table className='table table-row-dashed table-row-gray-300 align-middle g-4'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className=''>Name</th>
                      <th className=''>Value</th>
                    </tr>
                  </thead>
                  <tbody className='border-0'>
                    {customAttributes &&
                      customAttributes.map(
                        (x: CustomPropertyCreate, index: number) => {
                          return (
                            <tr key={`userGroupRelations.${index}`}>
                              <td className='px-8'>{x.name}</td>
                              <td className='px-8'>{x.value}</td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            )}
            {(!customAttributes || customAttributes.length === 0) && (
              <p className='text-center py-10'>
                There are no records to display
              </p>
            )}
          </div>
        </div>
      </Route>
    </>
  );
}
