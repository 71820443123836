import { AxiosInstance } from 'axios';
import MockAdapter from 'axios-mock-adapter';
// import { mockReports } from '../modules/reports/__mocks__/mockReports';
// import { mockWarehouses } from '../modules/warehouses/__mocks__/mockWarehouses';
//import { mockFulfillment } from '../modules/fulfillment/__mocks__/mockFulfillment';
//import { mockOrders } from '../modules/orders/__mocks__/mockOrders';
// import { mockInventory } from '../modules/entities/__mock__/mockInventory';
// import { mockProducts } from '../modules/products/__mocks__/mockProducts';
//import { mockAuth } from '../../app/modules/auth'
//simport { mockGroups } from '../modules/users/__mocks__/mockGroups';
//import {mockUsers} from '../../app/modules/users/__mocks__/mockUsers'
//import { mockAccounts } from '../modules/entities/__mock__/mockAccounts'
//import { mockClients } from '../modules/entities/__mock__/mockClients'
//import { mockFranchises } from '../modules/entities/__mock__/mockFranchises'
//import { mockMFData } from '../modules/mfData/__mocks__/mockMFData';
export default function mockAxios(axios: AxiosInstance) {
  const mock = new MockAdapter(axios, {
    delayResponse: 300,
    onNoMatch: 'passthrough',
  });
  //mockAuth(mock);
  //mockUsers(mock);
  //mockAccounts(mock);
  //mockClients(mock);
  //mockFranchises(mock);
  // mockGroups(mock);

  // mockProducts(mock);
  //mockOrders(mock);
  // mockInventory(mock);
  //mockFulfillment(mock);
  // mockReports(mock);
  // mockWarehouses(mock);
  return mock;
}
