import { useFormikContext, ErrorMessage } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { AvailabilityModel } from '../../../models/AvailabilityModel';
import { kitHelpers } from '../helpers/kit';
import { CreateKitModel } from '../models/CreateKitModel';
import { KitCascadeEntitySelection } from './KitCascadeEntitySelection';

interface Props {
  kit: CreateKitModel;
}

export function KitAvailabilityStep4({ kit }: Props) {
  const [list, setList] = useState<AvailabilityModel[]>(kit.availabilities);
  const { setFieldValue } = useFormikContext();
  const handleAddItem = () => {
    if (!kit.cascadeselects?.accountId) {
      return toast.warning('You must select an account');
    }
    if (kit.cascadeselects) {
      setList([...list, kit.cascadeselects]);
    }
  };
  const handleRemoveItem = (idx: number) => {
    const items = list;
    if (items.length > 0) {
      setList(items.filter((item, index) => index !== idx));
    }
  };
  useEffect(() => {
    setFieldValue('availabilities', list);
  }, [list, setFieldValue]);

  const productIds = useMemo(() => {
    return kitHelpers.getProductIds(kit.kitProducts);
  }, [kit.kitProducts]);

  return (
    <div className='container-fluid'>
      <div className='d-flex justify-content-around'>
        {!!kit.id && <KitCascadeEntitySelection ids={productIds} />}

        <div className='text-end'>
          <button
            onClick={handleAddItem}
            type='button'
            className='btn btn-primary mt-7 p-3'
          >
            <i className='las la-plus fs-2'></i>Add
          </button>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-12'>
          <ErrorMessage name='availabilities'>
            {(msg) => <div className='text-danger'>{msg}</div>}
          </ErrorMessage>
        </div>
      </div>

      <div className='table-responsive mt-10'>
        <table className='table table-row-dashed table-row-gray-300 align-middle g-4'>
          <thead>
            <tr className='fw-bolder text-muted'>
              <th className='min-w-150px'>Account</th>
              <th className='min-w-150px'>Client</th>
              <th className='min-w-150px'>Franchise</th>
              <th className='min-w-150px'>Locations</th>
            </tr>
          </thead>
          <tbody className='border-0'>
            {list.map((x, index) => {
              return (
                <tr key={`availabilities.${index}`}>
                  <td>{x.accountId ? x.accountName : 'All'}</td>
                  <td>{x.clientId ? x.clientName : 'All'}</td>
                  <td>{x.franchiseId ? x.franchiseName : 'All'}</td>
                  <td>{x.locationId ? x.locationName : 'All'}</td>
                  <td>
                    <button
                      onClick={() => {
                        handleRemoveItem(index);
                      }}
                      type='button'
                      id='buton-delete'
                      className='btn btn-active-danger p-3'
                    >
                      <i className='las la-times fs-2'></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
