import { OrderviewDetailShippingUnitModel } from '../../orders/models/OrderViewDetailShippingUnitModel';

interface Props {
  shippingUnits: OrderviewDetailShippingUnitModel[];
  shippingUnitSize?: number;
  handleCheckShippingUnit: (
    shiippingUnit: OrderviewDetailShippingUnitModel
  ) => void;
  status: string;
}
export function OrderDetailShippingUnit({
  shippingUnits,
  shippingUnitSize,
  handleCheckShippingUnit,
  status,
}: Props) {
  return (
    <table className='col-12'>
      {shippingUnits &&
        shippingUnits.map((x, index) => {
          return (
            <tbody key={x.shippingUnitNumber}>
              <tr>
                <td className='fw-bolder w-50'>
                  {status === 'Picking' ? (
                    <label className='form-check form-check-custom form-check-solid '>
                      <input
                        className='form-check-input me-2'
                        type='checkbox'
                        name={`${x.shippingUnitNumber}.check`}
                        id={`item-card-${x.shippingUnitNumber}-check-input`}
                        onChange={(e) => {
                          x.checked = e.target.checked;
                          handleCheckShippingUnit(x);
                        }}
                      />
                      <span>S.U. # {x.shippingUnitNumber}</span>
                    </label>
                  ) : (
                    <span>S.U. # {x.shippingUnitNumber}</span>
                  )}
                </td>
                <td
                  className='fs-2 fw-bolder w-50px text-right'
                  style={{ textAlign: 'right' }}
                >
                  <span>{x.quantity}</span>
                </td>
                <td>
                  {x.quantity === shippingUnitSize ? (
                    <span
                      className={`badge mx-3 align-right w-80px`}
                      style={{ backgroundColor: '#6610f2' }}
                    >
                      FULL
                    </span>
                  ) : (
                    <span
                      className={`badge mx-3 align-right w-80px`}
                      style={{ backgroundColor: '#52C06A' }}
                    >
                      INDIVIDUAL
                    </span>
                  )}
                </td>
              </tr>
              {x.quantity !== shippingUnitSize && (
                <>
                  <tr>
                    <td style={{ paddingLeft: '10px' }}>From:</td>
                    <td colSpan={3}>{x.startingCardNumber}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: '10px' }}>To:</td>
                    <td colSpan={3}>{x.endingCardNumber}</td>
                  </tr>
                </>
              )}
            </tbody>
          );
        })}
    </table>
  );
}
