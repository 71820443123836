/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { LoginUserModel } from '../../../../app/modules/auth/models/LoginUserModel';
import { RootState } from '../../../../app/redux';
import { toAbsoluteUrl, toAbsoluteUrlAvatar } from '../../../helpers';
import { SelectGroup } from '../../../../app/components/SelectGroup';
import { UserGroupModel } from '../../../../app/modules/auth/models/UserGroupModel';
import { useAuth } from 'oidc-react';
import {
  getGroupsByToken,
  postUserLoggedOut,
} from '../../../../app/modules/auth/services/AuthServices';
import { actions } from '../../../../app/modules/auth';

const HeaderUserMenu: FC = () => {
  const user: LoginUserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as LoginUserModel;
  const groups = useSelector<RootState, Array<UserGroupModel>>(
    ({ auth }: any) => auth.user.groups,
    shallowEqual
  );

  const authCap = useAuth();

  const logout = () => {
    postUserLoggedOut().then(authCap.signOutRedirect);
  };
  const changeEntityGroup = () => {
    setShowSelectGroupeModal(true);
  };
  const [showSelectGroupeModal, setShowSelectGroupeModal] = useState(false);
  const handleCloseModal = () => {
    setShowSelectGroupeModal(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getGroupsByToken().then(({ data: groups }) => {
      const newUser = { ...user, ...{ groups } };
      dispatch(actions.fulfillUser(newUser));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SelectGroup
        show={showSelectGroupeModal}
        onHide={handleCloseModal}
      ></SelectGroup>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-350px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
              <img
                alt='Logo'
                src={
                  user.gravatarHash && user.gravatarHash !== ''
                    ? toAbsoluteUrlAvatar(user.gravatarHash)
                    : toAbsoluteUrl('/media/svg/avatars/001-boy.svg')
                }
              />
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {user.name}
              </div>
              <a
                href='#'
                className='fw-bold text-muted text-hover-primary fs-7'
                style={{ maxWidth: '200px', overflowWrap: 'break-word' }}
              >
                {user.email}
              </a>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>
        {groups &&
          (groups.length > 1 ||
            (groups.length === 1 &&
              groups[0].userGroupRelations.length > 1)) && (
            <div className='menu-item px-5'>
              <a onClick={changeEntityGroup} className='menu-link px-5'>
                Change User Group
              </a>
            </div>
          )}

        <div className='menu-item px-5'>
          <a onClick={logout} className='menu-link px-5'>
            Sign Out
          </a>
        </div>
      </div>
    </>
  );
};

export { HeaderUserMenu };
