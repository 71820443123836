import { toAbsoluteUrl, toAbsoluteUrlImage } from '../../_metronic/helpers';
import { EntityModel } from '../models/EntityModel';
import { AccountModel } from '../modules/entities/models/AccountModel';
import { ClientModel } from '../modules/entities/models/ClientModel';
import { FranchiseModel } from '../modules/entities/models/FranchiseModel';
import { LocationModel } from '../modules/entities/models/LocationModel';

// Initial values for franchises
export const initEntityAccount = (account: AccountModel) => {
  const entity: EntityModel = {
    id: account.id,
    type: 'ACCOUNT',
    name: account.accountName,
    isActive: account.isActive,
    hasActive: true,
    deactivatedReason: account.deactivatedReason,
    details: {
      key: account.accountKey,
      number: account.accountNumber,
      contact: account.contactName,
      address:
        account.addressLine1 +
        ', ' +
        account.postalCode +
        ', ' +
        account.city +
        ', ' +
        account.state,
    },
    imagePath:
      account.branding?.accountLogo && account.branding?.accountLogo.path
        ? toAbsoluteUrlImage(account.branding.accountLogo.path)
        : toAbsoluteUrl('/media/logos/valid-accounts.jpeg'),
    menu: [
      {
        title: 'Clients',
        path: `/accounts/${account.id}/clients`,
        isActive: true,
      },
    ],
    editPath: '/home',
  };
  return entity;
};

export const initEntityClient = (client: ClientModel) => {
  const entity: EntityModel = {
    parentId: client.accountId,
    id: client.id,
    type: 'CLIENT',
    name: client.name,
    isActive: client.isActive,
    deactivatedReason: client.deactivatedReason,
    hasActive: true,
    details: {
      account: client.account ? client.account.accountName : client.accountName,
      'Order Note 1': client.orderNote1 ? client.orderNote1 : '-',

      key: client.key,
      'Order Note 2': client.orderNote2 ? client.orderNote2 : '-',

      'Prefered Shipper': client.preferedShipper,
      'Order Note 3': client.orderNote3 ? client.orderNote3 : '-',

      'Default Shipping Method - Web': client.shippingMethod,

      'Auto Replenish': client.autoReplenish ? 'Yes' : 'No',
      'Default Shipping Method - Auto': client.shippingMethodAutoReplenishment,
      'Can Active Cards': client.canActiveCards ? 'Yes' : 'No',

      'Packing List Message': client.packingListMessage,
      'Allow Third Party Shipping': client.allowThirdPartyShipping
        ? 'Yes'
        : 'No',
    },
    menu: [
      {
        title: 'Franchises',
        path: `/accounts/${client.accountId}/clients/${client.id}/franchises`,
        isActive: true,
      },
      {
        title: 'Custom Attributes',
        path: `/accounts/${client.accountId}/clients/${client.id}/attributes`,
        isActive: true,
      },
    ],
    editPath: '/home',
  };
  return entity;
};

export const initEntityFranchise = (franchise: FranchiseModel) => {
  const entity: EntityModel = {
    parentId: franchise.clientId,

    id: franchise.id,
    type: 'FRANCHISE',
    name: franchise.name,
    isActive: franchise.isActive,
    hasActive: true,
    deactivatedReason: franchise.deactivatedReason,
    details: {
      clientId: franchise.clientId.toString(),
      accountId: franchise.accountId.toString(),
      client: franchise.clientName,
      account: franchise.accountName,
      number: franchise.number.toString(),
      corporate: franchise.isCorporate ? 'Yes' : 'No',
    },
    menu: [
      {
        title: 'Locations',
        path: `/accounts/${franchise.accountId}/clients/${franchise.clientId}/franchises/${franchise.id}/locations`,
        isActive: true,
      },
    ],
    editPath: '/home',
  };
  return entity;
};

export const initEntityLocation = (location: LocationModel) => {
  const entity: EntityModel = {
    parentId: location.franchiseId,
    id: location.id,
    type: 'LOCATION',
    name: location.name,
    isActive: location.isActive,
    deactivatedReason: location.deactivatedReason,
    hasActive: true,
    details: {
      account: location.accountName,
      client: location.clientName,
      franchise: location.franchiseName,
      accountId: location.accountId.toString(),
      clientId: location.clientId.toString(),
      franchiseId: location.franchiseId.toString(),
      locationNumber: location.number.toString(),
      autoreplenishment:
        location.autoReplenish !== null
          ? location.autoReplenish === true
            ? 'Yes'
            : 'No'
          : '-',
      deliveryDaysUps: location.deliverDaysUps
        ? location.deliverDaysUps.toString()
        : '-',
      deliveryDaysFedex: location.deliverDaysFedex
        ? location.deliverDaysFedex.toString()
        : '-',
      locationType: location.locationType ? location.locationType : '-',
      addressLine1:
        location.addresses[0]?.addressLine1 +
        ' ' +
        (location.addresses[0]?.addressLine2
          ? location.addresses[0]?.addressLine2
          : ''),
      addressLine2: location.addresses[0]?.addressLine3
        ? location.addresses[0].addressLine3
        : '',
      city: location.addresses[0]?.city ? location.addresses[0].city : '-',
      state: location.addresses[0]?.state ? location.addresses[0].state : '-',
      country: location.addresses[0]?.country
        ? location.addresses[0].country
        : '-',
      email: location.emailAddress ? location.emailAddress : '-',
      phone: location.addresses[0]?.phone ? location.addresses[0].phone : '-',
    },
    menu: [
      {
        title: 'Inventory',
        path: `/accounts/${location.accountId}/clients/${location.clientId}/franchises/${location.franchiseId}/locations/${location.id}`,
        isActive: true,
      },
    ],
    editPath: '/home',
  };
  return entity;
};
