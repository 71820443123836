import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import * as auth from '../../app/modules/auth/index';
import { loadingSlice } from './loading/loadingSlice';
import { entitiesSlice } from './entity/entitiesSlice';
import { accessDeniedSlice } from './accessDenied/accessDeniedSlice';
import { unautorizedSlice } from './unautorized/unautorizedSlice';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  loading: loadingSlice.reducer,
  entities: entitiesSlice.reducer,
  accessDenied: accessDeniedSlice.reducer,
  unautorized: unautorizedSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([auth.saga()]);
}
