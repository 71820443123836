import { IntlShape } from 'react-intl';
import { CreateProductSchemaStep1 } from './createProductSchemasStep1';
import { CreateProductSchemaStep2 } from './createProductSchemasStep2';
import { CreateProductSchemaStep3 } from './createProductSchemasStep3';
import { CreateProductSchemaStep4 } from './createProductSchemasStep4';

export const createProductSchemas = (intl: IntlShape) => [
  CreateProductSchemaStep1(intl),
  CreateProductSchemaStep2(intl),
  CreateProductSchemaStep3(intl),
  CreateProductSchemaStep4,
];
