import { CustomBadge } from '../../../components/CustomBadge';
import { KitModel } from '../models/KitModel';

interface KitViewInventoryProps {
  kit: KitModel;
}

export function KitViewInventory({ kit }: KitViewInventoryProps) {
  return (
    <div className='p-5'>
      <div className='row py-1 mt-5'>
        <div className='fw-bold fs-5 text-gray-400 text-capitalize col-3'>
          Web Orderable
        </div>
        <div className=' fs-5 col-9'>
          <CustomBadge
            status={!!kit.webOrderable}
            activeText='Yes'
            inactiveText='No'
          />
        </div>
      </div>

      <div className='row py-1 mt-5'>
        <div className='fw-bold fs-5 text-gray-400 text-capitalize col-3'>
          Max Order Quantity
        </div>
        <div className=' fs-5 col-9'> {kit.maxOrderQuantity || '-'}</div>
      </div>

      <div className='row py-1 mt-3 mt-5'>
        <div className='fw-bold fs-5 text-gray-400 text-capitalize col-3'>
          Shipping Unit Size
        </div>
        <div className='fs-5 col-9 mb-10'> {kit.shippingUnitSize || '-'}</div>
      </div>
    </div>
  );
}
