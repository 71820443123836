import { Route, Switch } from 'react-router';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { GroupsList } from './components/groups/GroupsList';

export function GroupsPage() {
  const breadcrumbs: Array<PageLink> = [];

  return (
    <>
      <Switch>
        <Route exact path='/groups'>
          <PageTitle breadcrumbs={breadcrumbs}>Groups List</PageTitle>
          <GroupsList />
        </Route>
      </Switch>
    </>
  );
}
