import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  account: null,
  client: null,
  franchise: null,
  location: null,
  group: null,
  product: null,
};

export const entitiesSlice = createSlice({
  name: 'entities',
  initialState: initialState,
  reducers: {
    setAccount: (state, action) => {
      state.account = action.payload.account;
    },
    removeAccount: (state, action) => {
      state.account = null;
    },
    setClient: (state, action) => {
      state.client = action.payload.client;
    },
    removeClient: (state, action) => {
      state.client = null;
    },
    setFranchise: (state, action) => {
      state.franchise = action.payload.franchise;
    },
    removeFranchise: (state, action) => {
      state.franchise = null;
    },
    setGroup: (state, action) => {
      state.group = action.payload.group;
    },
    setProduct: (state, action) => {
      state.product = action.payload.product;
    },
    setLocation: (state, action) => {
      state.location = action.payload.location;
    },
    removeLocation: (state, action) => {
      state.location = null;
    },
  },
});
