import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { EntityModel } from '../../../../models/EntityModel';
import { RootState } from '../../../../redux';
import {
  removeEntityLocation,
  setEntityLocation,
} from '../../../../redux/entity/entitiesActions';
import { initEntityLocation } from '../../../../utils/entityUtils';
import { toast } from 'react-toastify';
import { IsActiveComponent } from '../../../../components/IsActiveComponent';
import { GroupModel } from '../../../users/models/GroupModel';
import locationsServices from '../../services/LocationsServices';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { useIntl } from 'react-intl';
import { NavTabs } from '../../../../components/NavTabs';
import { Route, Switch } from 'react-router-dom';
import { LocationInventoryList } from './LocationInventoryList';
import { LocationEdit } from './LocationEdit';
interface Params {
  accountId: string;
  clientId: string;
  franchiseId: string;
  locationId: string;
}
export function LocationsView() {
  const { locationId } = useParams<Params>();
  const [location] = useSelector<RootState, EntityModel[]>(
    ({ entities }: any) => [entities.location],
    shallowEqual
  );

  const intl = useIntl();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
  };
  const handleShowUpdateModal = () => {
    setShowUpdateModal(true);
  };
  const [showDesactivateModal, setShowDesactivateModal] = useState(false);
  const handleCloseDesactivateModal = () => {
    setShowDesactivateModal(false);
  };
  const handleShowDesactivateModal = () => {
    setShowDesactivateModal(true);
  };
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!location) {
      refetch(Number(locationId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Al salir del componente limpiamos el estado
  useEffect(() => {
    return () => {
      dispatch(removeEntityLocation());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const goBack = () => {
    history.goBack();
    setTimeout(() => {
      dispatch(removeEntityLocation());
    }, 200);
  };

  const setActivate = (locationId: number) => {
    locationsServices.activateLocation(locationId).then(() => {
      toast.success('Successful activation');
      refetch(locationId);
    });
  };

  const setDeactivate = (locationId: number, reason?: string) => {
    locationsServices.desactivateLocation(locationId, reason).then(() => {
      toast.success('Successful deactivation');
      refetch(locationId);
    });
  };
  const refetch = (locationId: number) => {
    locationsServices.getLocation(locationId).then((response) => {
      const entity: EntityModel = initEntityLocation(response.data);
      dispatch(setEntityLocation(entity));
    });
  };
  const DesactivateComponentNode = () =>
    IsActiveComponent({
      id: location.id,
      isActive: location.isActive === true ? true : false,
      onHide: handleCloseDesactivateModal,
      show: showDesactivateModal,
      setDeactivate,
      setActivate,
    });
  return (
    <>
      <Switch>
        <Route
          path='/accounts/:accountId/clients/:clientId/franchises/:franchiseId/locations/:locationId'
          exact
        >
          {location && (
            <>
              {showUpdateModal && location && (
                <LocationEdit
                  refetch={refetch}
                  id={location.id}
                  show={showUpdateModal}
                  onHide={handleCloseUpdateModal}
                />
              )}
              <DesactivateComponentNode />
              <div
                style={{
                  borderBottomRightRadius: '0px',
                  borderBottomLeftRadius: '0px',
                }}
                className='card mb-0 mb-xl-0'
              >
                {' '}
                <div className='card-body pt-9 pb-0'>
                  <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    {!!location.imagePath && (
                      <div className='me-7 mb-4'>
                        <div className='symbol symbol-100px symbol-lg-160px position-relative'>
                          <img
                            src={toAbsoluteUrl(location.imagePath)}
                            alt='Metronic'
                          />
                        </div>
                      </div>
                    )}

                    <div className='flex-grow-1'>
                      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                        <div className='d-flex flex-column align-middle'>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='text-gray-800 text-hover-primary fs-1 fw-bolder me-1'>
                              {location.name}
                            </div>
                            {!!location.hasActive && (
                              <div className='ms-10'>
                                <span
                                  className={`badge ${
                                    location.isActive
                                      ? 'bg-success'
                                      : 'bg-secondary'
                                  } `}
                                >
                                  {location.isActive ? 'Active' : 'Inactive'}
                                </span>
                                {location.deactivatedReason && (
                                  <span className='text-muted ms-4'>
                                    {location.isActive
                                      ? ''
                                      : location.deactivatedReason}
                                  </span>
                                )}
                              </div>
                            )}
                            {group.locationPermission?.update && (
                              <div className='form-check form-switch form-check-custom  mx-5'>
                                <input
                                  className={`form-check-input ${
                                    location.isActive
                                      ? 'bg-primary text-white'
                                      : ''
                                  } `}
                                  checked={location.isActive}
                                  type='checkbox'
                                  onChange={() => handleShowDesactivateModal()}
                                />
                                <label
                                  className={`form-check-label ${
                                    location.isActive
                                      ? 'text-muted'
                                      : 'text-muted'
                                  } `}
                                >
                                  {!location.isActive
                                    ? 'Activate'
                                    : 'Deactivate'}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className='d-flex flex-column'>
                            <div>
                              <table className='table-responsive fw-bolder fs-6'>
                                <tbody className='fw-bolder fs-6'>
                                  <tr className='my-1'>
                                    <td className='text-muted'>Account: </td>
                                    <td className='px-3'>
                                      {location.details.account}
                                    </td>
                                  </tr>
                                  <tr className='my-1'>
                                    <td className='text-muted'>Client:</td>
                                    <td className='px-3'>
                                      {location.details.client}
                                    </td>
                                  </tr>
                                  <tr className='my-1'>
                                    <td className='text-muted'>Franchise: </td>
                                    <td className='px-3'>
                                      {location.details.franchise}
                                    </td>
                                  </tr>
                                  <tr className='my-1'>
                                    <td className='text-muted'>
                                      Location Number:{' '}
                                    </td>
                                    <td className='px-3'>
                                      {location.details.locationNumber}
                                    </td>
                                  </tr>
                                  <tr className='my-1'>
                                    <td className='text-muted'>
                                      Autoreplenishment:
                                    </td>
                                    <td className='px-3'>
                                      {location.details.autoreplenishment}
                                    </td>
                                  </tr>
                                  <tr className='my-1'>
                                    <td className='text-muted'>
                                      Delivery Days Ups:
                                    </td>
                                    <td className='px-3'>
                                      {location.details.deliveryDaysUps}
                                    </td>
                                  </tr>
                                  <tr className='my-1'>
                                    <td className='text-muted'>
                                      Delivery Days Fedex:{' '}
                                    </td>
                                    <td className='px-3'>
                                      {location.details.deliveryDaysFedex}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          {/* {group.locationPermission?.update && (
                            <div className='form-check form-switch form-check-custom form-check-solid mt-2'>
                              <input
                                className={`form-check-input ${
                                  location.isActive
                                    ? 'bg-primary text-white'
                                    : ''
                                } `}
                                checked={location.isActive}
                                type='checkbox'
                                onChange={() => handleShowUpdateModal()}
                              />
                              <label
                                className={`form-check-label ${
                                  location.isActive
                                    ? 'text-muted'
                                    : 'text-muted'
                                } `}
                              >
                                {!location.isActive ? 'Activate' : 'Deactivate'}
                              </label>
                            </div>
                          )} */}
                        </div>
                        <div className='d-flex flex-column align-middle'>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='text-white text-hover-primary fs-1 fw-bolder me-1'>
                              {'title'}
                            </div>
                          </div>

                          <div className='d-flex flex-column'>
                            <table className='table-responsive'>
                              <tbody className='fw-bolder fs-6'>
                                <tr className='my-1'>
                                  <td className='text-muted'>Location Type:</td>
                                  <td className='px-3'>
                                    {location.details.locationType}
                                  </td>
                                </tr>
                                <tr className='my-1'>
                                  <td className='text-muted'>Address:</td>
                                  <td className='px-3'>
                                    {location.details.addressLine1}
                                  </td>
                                </tr>

                                <tr className='my-1'>
                                  <td className='text-muted'>City:</td>
                                  <td className='px-3'>
                                    {location.details.city}
                                  </td>
                                </tr>
                                <tr className='my-1'>
                                  <td className='text-muted'>State:</td>
                                  <td className='px-3'>
                                    {location.details.state}
                                  </td>
                                </tr>
                                <tr className='my-1'>
                                  <td className='text-muted'>Country:</td>
                                  <td className='px-3'>
                                    {location.details.country}
                                  </td>
                                </tr>
                                <tr className='my-1'>
                                  <td className='text-muted'>Email:</td>
                                  <td className='px-3'>
                                    {location.details.email}
                                  </td>
                                </tr>
                                <tr className='my-1'>
                                  <td className='text-muted'>Phone:</td>
                                  <td className='px-3'>
                                    {location.details.phone}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className='text-end'>
                          {goBack && (
                            <button
                              onClick={goBack}
                              className='btn btn-light btn-active-primary align-self-center'
                            >
                              <i className='fas fa-arrow-left'></i>
                              {intl.formatMessage({ id: 'ACTION.BACK' })}
                            </button>
                          )}
                          {group.locationPermission?.update && (
                            <button
                              onClick={() => handleShowUpdateModal()}
                              className='m-2 btn  btn-light btn-primary align-self-center'
                            >
                              <i className='las la-edit fs-2'></i>
                              Edit Location
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex overflow-auto h-55px'>
                    <NavTabs submenu={location.menu} />
                  </div>
                </div>
              </div>
              <LocationInventoryList />
            </>
          )}
        </Route>
        <Route path='/accounts/:accountId/clients/:clientId/franchises/:franchiseId/locations/:locationId'>
          <LocationInventoryList />
        </Route>
      </Switch>
    </>
  );
}
