import { Modal } from 'react-bootstrap-v5';
import { OrderViewModel } from '../../orders/models/OrderViewModel';

interface Props {
  process: (params: any) => void;
  show: boolean;
  order: OrderViewModel;
  onHide: () => void;
}

export function ProcessOrderModal({ process, show, order, onHide }: Props) {
  return (
    <Modal show={show} onHide={onHide} backdrop='static' keyboard={false}>
      <Modal.Body className='mt-1 px-8 py-8 position-relative z-index-1'>
        <div className='d-flex align-items-center'>
          <i className={`las la-exclamation-circle fs-5x text-warning`}></i>
          <div className='flex-grow-3 ms-4'>
            <div className='fw-bold text-gray-700 fs-6'>
              Are you sure you want to change the status of the order{' '}
              <span className='fw-bolder'>#{order.id}</span> from{' '}
              <span className='fw-bolder'>{order.status}</span> to{' '}
              <span className='fw-bolder'>Picking</span>?
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='border-0'>
        <div className='text-end'>
          <button
            type='button'
            id='kt_modal_new_card_cancel'
            className='btn btn-danger me-3'
            onClick={() => onHide()}
          >
            No
          </button>
          <button
            type='submit'
            id='kt_modal_new_card_submit'
            className='btn btn-primary'
            onClick={() => process(order.id)}
          >
            Yes
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
