import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const userSchemaValidation = (intl: IntlShape) =>
  Yup.object().shape({
    groups: Yup.array()
      .min(1, 'You must select at least one group')
      .required(
        intl.formatMessage({
          id: 'VALIDATION.REQUIRED_FIELD',
        })
      ),
  });
