import { useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { RootState } from '../../../redux';
import { shallowEqual, useSelector } from 'react-redux';
import { useQueryParamsReplace } from '../../../utils/useQueryParamsReplace';
import ListComponent from '../../../components/ListComponent';
import { GroupModel } from '../../users/models/GroupModel';
import {
  initialFilterStockAdjustment,
  queryParamsConfigStockAdjustment,
} from '../utils/utils';
import warehouseService from '../services/WarehousesServices';
import { WarehouseModel } from '../models/WarehouseModel';
import { useDebouncedCallback } from 'use-debounce/lib';
import { StockAdjustmentModel } from '../models/StockAdjustmentModel';
import moment from 'moment';
import { WarehouseAdjustStock } from './WarehouseAdjustStock';

export function StockAdjustmentList({
  warehouse,
}: {
  warehouse: WarehouseModel;
}) {
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );

  // Pagination, Filter and Query Params
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<StockAdjustmentModel[]>([]);

  const [queryParams, setQueryReplace] = useQueryParamsReplace(
    queryParamsConfigStockAdjustment
  );
  const [filter, setFilter] = useState('');
  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<StockAdjustmentModel>[] = useMemo(
    () => [
      {
        name: 'Product Code',
        selector: (row) => row.productCode,
        sortable: true,
        sortField: 'productCode',
        width: '120px',
        wrap: true,
      },
      {
        name: 'Description',
        selector: (row) => row.productDescription || '-',
        sortable: true,
        sortField: 'productDescription',
        wrap: true,
      },
      {
        name: 'User',
        selector: (row) => row.user || '-',
        sortable: true,
        sortField: 'user',
        width: '140px',
        wrap: true,
      },
      {
        name: 'Amount',
        selector: (row) => row.adjustmentAmount || '-',
        sortField: 'adjustmentAmount',
        sortable: true,
        width: '130px',
      },
      {
        name: 'Current',
        selector: (row) =>
          row.currentLevel !== undefined ? row.currentLevel : '-',
        sortable: true,
        sortField: 'current',
        width: '130px',
      },
      {
        name: 'Date',
        selector: (row) => moment(row.date).format('MM-DD-YYYY hh:mm A') || '-',
        sortable: true,
        sortField: 'date',
        width: '170px',
      },
      {
        name: 'Reason',
        selector: (row) => row.reason || '-',
        sortable: true,
        sortField: 'reason',
        wrap: true,
      },
    ],

    []
  );

  //Initialization methods
  useEffect(() => {
    if (history.location.search === '') {
      setQueryReplace({
        ...initialFilterStockAdjustment,
      });
    }

    if (queryParams.pageNumber) {
      findStockAdjustments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findStockAdjustments = function () {
    if (warehouse.id) {
      warehouseService
        .findStockAdjustments(warehouse.id, {
          ...queryParams,
          warehouseId: warehouse.id,
        })
        .then((response) => {
          setEntities(response.data.items);
          setTotalCount(response.data.totalCount);
        });
    }
  };
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleShowCreateModal = () => {
    setShowCreateModal(true);
  };
  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };
  const refetch = () => {
    findStockAdjustments();
  };
  const onFilterChange = (e: any, key: string) => {
    if (key === 'filter') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };
  const debounced = useDebouncedCallback((params: any) => {
    const newQueryParams = { ...queryParams };
    if (params.key === 'filter') {
      let filter = params.value;
      newQueryParams.filter = filter;
    }
    newQueryParams.pageNumber = 1;
    setQueryReplace(newQueryParams);
  }, 800);
  return (
    <>
      {group.warehouseInventoryPermission?.update && (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header py-6'>
            <div className='card-title'>
              {group.warehouseInventoryPermission?.update && (
                <div className='d-flex align-items-center position-relative my-1'>
                  <i className='las la-search position-absolute ms-6 fs-2'></i>
                  <input
                    autoComplete='none'
                    type='search'
                    className='form-control form-control-solid w-350px ps-15'
                    value={filter}
                    placeholder='Search by Product Code or Description'
                    onChange={(e) => onFilterChange(e, 'filter')}
                  />
                </div>
              )}
            </div>
            <div className='card-toolbar'>
              {group.warehouseInventoryPermission?.update &&
                warehouse.isActive && (
                  <>
                    <button
                      onClick={() => handleShowCreateModal()}
                      type='button'
                      className='btn btn-primary align-self-center'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      <i className='las la-edit fs-2'></i>
                      Adjust Stock
                    </button>

                    {showCreateModal && (
                      <WarehouseAdjustStock
                        refetch={refetch}
                        show={showCreateModal}
                        onHide={handleCloseCreateModal}
                        warehouse={warehouse}
                      />
                    )}
                  </>
                )}
            </div>
          </div>

          <div className='card-body px-9 py-3'>
            <ListComponent
              table={{
                columns,
                data: entities,
                paginationTotalRows: totalCount,
                pointerOnHover: true,
                highlightOnHover: true,
              }}
              queryParams={queryParams}
              setQueryParams={setQueryReplace}
            />
          </div>
        </div>
      )}
    </>
  );
}
