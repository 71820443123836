import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const warehouseInventorySchemaValidation = (intl: IntlShape) =>
  Yup.object().shape({
    productId: Yup.number()
      .positive(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .min(
        1,
        intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }, { min: 1 })
      )
      .integer(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    quantity: Yup.number()
      .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
      .min(1, intl.formatMessage({ id: 'VALIDATION.MIN.NUMBER' }, { min: 1 }))
      .integer(intl.formatMessage({ id: 'VALIDATION.INTEGER_FIELD' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    startingCardNumber: Yup.string().when(['productType'], {
      is: (productType: string) => productType === 'Card',
      then: Yup.string()
        .ensure()
        .trim()
        .max(
          50,
          intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 50 })
        )
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    }),
    runPrefix: Yup.string().when(['productType'], {
      is: (productType: string) => productType === 'Card',
      then: Yup.string()
        .ensure()
        .trim()
        .max(
          5,
          intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 5 })
        )
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    }),
    startingRunNumber: Yup.number().when(['productType'], {
      is: (productType: string) => productType === 'Card',
      then: Yup.number()
        .positive(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
        .min(
          1,
          intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }, { min: 1 })
        )
        .integer(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    }),
  });
