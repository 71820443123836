import { Fragment, useState } from 'react';
import { BasicTabModel, BasicTabs } from '../../../../components/BasicTabs';
import { UserGroupRelationModel } from '../../../auth/models/UserGroupRelationModel';
import { PermissionItem, PermissionItemModel } from './PermissionItem';
import { SellectAllCheck } from './SellectAll';
import { UserGroupNRelacions } from './UserGroupNRelacions';

const tabs: BasicTabModel[] = [
  {
    title: 'Group Permissions',
    id: 'permissions',
  },
  {
    title: 'Group Relations',
    id: 'relations',
  },
];

interface Props {
  permissions: PermissionItemModel[];
  userGroupRelations?: UserGroupRelationModel[];
}
export function GroupForm({ permissions }: Props) {
  const [activeTab, setActiveTab] = useState<string>('permissions');

  return (
    <Fragment>
      <BasicTabs
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        tabs={tabs}
      />
      {activeTab === 'permissions' && (
        <div className='row pt-4'>
          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed fs-6 gy-5'>
              <tbody className='text-gray-600 fw-bold'>
                <SellectAllCheck
                  permissionKeys={permissions.map((p) => p.key)}
                />
                {permissions.map((p: PermissionItemModel) => {
                  return (
                    <tr key={`permision-row-${p.key}`}>
                      <PermissionItem permission={p} />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {activeTab === 'relations' && (
        <div className='row pt-4'>
          <label className='fs-5 fw-bolder form-label'></label>
          <UserGroupNRelacions />
        </div>
      )}
    </Fragment>
  );
}
