import { Fragment, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { AuditTrailModel } from '../models/AuditTrailModel';
import { auditServices } from '../services/AuditServices';
import ListComponent from '../../../components/ListComponent';
import { initialFilter, queryParamsConfig } from '../utils/utils';
import moment from 'moment';
import { AuditTrailsFilter } from './AuditTrailsFilter';
import { useQueryParamsReplace } from '../../../utils/useQueryParamsReplace';
import { AuditTrailDetail } from './AuditTrailDetail';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';

export function AuditList() {
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  // Pagination, Filter and Query Params
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<AuditTrailModel[]>([]);

  const [queryParams, setQueryParams] =
    useQueryParamsReplace(queryParamsConfig);
  const [filter, setFilter] = useState('');

  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<AuditTrailModel>[] = useMemo(
    () => [
      {
        name: 'Time Stamp',
        selector: (row) =>
          moment.utc(row.timeStamp).local().format('MM-DD-YY HH:mm'),
        sortable: true,
        sortField: 'timeStamp',
      },
      {
        name: 'Log Type',
        selector: (row) => row.logType,
        sortable: true,
        sortField: 'logType',
        minWidth: '140px',
        wrap: true,
      },
      {
        name: 'Event Type',
        selector: (row) => row.eventType,
        sortable: true,
        sortField: 'eventType',
        minWidth: '250px',
        wrap: true,
      },
      {
        name: 'User email',
        selector: (row) => row.userEmail,
        sortable: true,
        sortField: 'userEmail',
        wrap: true,
        minWidth: '250px',
      },
      {
        name: 'Account',
        selector: (row) => row.account,
        sortable: true,
        sortField: 'account',
      },
      {
        name: 'Client',
        selector: (row) => row.client,
        sortable: true,
        sortField: 'client',
      },
      {
        name: 'Franchise',
        selector: (row) => row.franchise,
        sortable: true,
        sortField: 'franchise',
      },
      {
        name: 'Location',
        selector: (row) => row.location,
        sortable: true,
        sortField: 'location',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //Initialization methods
  useEffect(() => {
    if (history.location.search === '') {
      const newQueryParams = {
        ...initialFilter,
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };
      setQueryParams(newQueryParams);
    }
    if (queryParams.userEmail) setFilter(queryParams.userEmail);
    if (queryParams.pageNumber) findAuditTrails(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findAuditTrails = function (queryParams: any) {
    auditServices.findAuditTrails(queryParams).then((response) => {
      setEntities(response.data.items);
      setTotalCount(response.data.totalCount);
    });
  };

  const onFilterChange = (e: any, key: string) => {
    if (key === 'userEmail') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };
  const debounced = useDebouncedCallback((params: any) => {
    const newQueryParams = { ...queryParams };
    if (params.key === 'userEmail') {
      let userEmail = params.value;
      newQueryParams.userEmail = userEmail;
    }
    newQueryParams.pageNumber = 1;
    setQueryParams(newQueryParams);
  }, 800);

  //Modal View
  const [selectedAuditTrail, setSelectedAuditTrail] =
    useState<AuditTrailModel>();

  const [showDetailModal, setShowDetailModal] = useState(false);
  const handleCloseDetailsModal = () => {
    setShowDetailModal(false);
  };
  const handleShowDetailsModal = (auditTrail: AuditTrailModel) => {
    setShowDetailModal(true);
    setSelectedAuditTrail(auditTrail);
  };
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header py-6'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <i className='las la-search position-absolute ms-6 fs-2'></i>
              <input
                autoComplete='none'
                type='search'
                className='form-control form-control-solid w-250px ps-15'
                value={filter}
                placeholder='Search by email'
                onChange={(e) => onFilterChange(e, 'userEmail')}
              />
            </div>
          </div>
          <div className='card-toolbar'>
            <AuditTrailsFilter
              queryParams={queryParams}
              setQueryParams={setQueryParams}
            />
          </div>
        </div>

        <div className='card-body px-9 py-3'>
          <ListComponent
            table={{
              columns,
              data: entities,
              paginationTotalRows: totalCount,
              pointerOnHover: true,
              highlightOnHover: true,
              onRowClicked: handleShowDetailsModal,
            }}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
        </div>
      </div>
      {showDetailModal && (
        <AuditTrailDetail
          id={selectedAuditTrail ? selectedAuditTrail.id : 0}
          show={showDetailModal}
          onHide={handleCloseDetailsModal}
        />
      )}
    </>
  );
}
