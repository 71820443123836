import React from 'react';
import { useIntl } from 'react-intl';

interface CustomFilterProps {
  resetFilters: () => void;
  applyFilters: () => void;
  children: React.ReactElement;
  scroll?: boolean;
  size?: string;
}
export function CustomFilter(props: CustomFilterProps) {
  const { children, applyFilters, resetFilters, scroll, size } = props;
  // I18n
  const intl = useIntl();

  return (
    <div
      className='d-flex justify-content-end py-4'
      data-kt-customer-table-toolbar='base'
    >
      <button
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='left'
      >
        <i className='las la-filter fs-2'></i>
        {intl.formatMessage({ id: 'COMMON.FILTERS' })}
      </button>

      <div
        className={`menu menu-sub menu-sub-dropdown ${
          size === 'lg' ? 'w-500px w-md-600px' : 'w-250px w-md-300px'
        } `}
        data-kt-menu='true'
      >
        <form className='form form-label-right'>
          <div className='px-7 py-4'>
            <div id='kt_example_header' className='fs-5 text-dark fw-bolder'>
              Filter Options
            </div>
          </div>

          <div className='separator border-gray-200'></div>
          {scroll && (
            <div
              className='scroll'
              data-kt-scroll='true'
              id='kt_example_content'
              data-kt-scroll-offset='100px'
              data-kt-scroll-wrappers='#kt_example_content'
              data-kt-scroll-dependencies='#kt_example_header, #kt_example_footer, #kt_header'
              data-kt-scroll-height="{default: '150px', lg: 'auto'}"
            >
              <div className='px-7 py-5'>{children}</div>
            </div>
          )}
          {!scroll && <div className='px-7 py-4'>{children}</div>}
          <div
            id='kt_example_footer'
            className='px-7 py-4 d-flex justify-content-end'
          >
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={resetFilters}
            >
              {intl.formatMessage({ id: 'ACTION.RESET' })}
            </button>

            <button
              type='submit'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={(e) => {
                e.preventDefault();
                applyFilters();
              }}
            >
              {intl.formatMessage({ id: 'ACTION.APPLY' })}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
