import { Fragment } from 'react';
import { CustomInput } from '../../../../components/CustomInput';
import { CustomSelect } from '../../../../components/CustomSelect';

export const locationTypeOptions = [
  { value: 'Store', label: 'Store' },
  { value: 'ThirdParty', label: 'ThirdParty' },
  { value: 'Corporate', label: 'Corporate' },
];
export function LocationForm() {
  return (
    <Fragment>
      <div className='row pt-4'>
        <div className='col-4'>
          <CustomInput
            isRequired={true}
            id='number'
            name='number'
            type='text'
            label='Number'
          />
        </div>
        <div className='col-4'>
          <CustomInput
            isRequired={true}
            id='name'
            type='text'
            name='name'
            label='Name'
          />
        </div>
        <div className='col-4'>
          <CustomSelect
            isRequired={true}
            label='Location Type'
            placeholder='Select Location Type'
            id='locationType'
            name='locationType'
            options={locationTypeOptions}
          />
        </div>
      </div>
      <div className='row pt-4'>
        <div className='col-4'>
          <CustomInput
            isRequired={true}
            id='emailAddress'
            name='emailAddress'
            type='email'
            label='Email'
          />
        </div>
        <div className='col-4'>
          <CustomInput
            id='deliverDaysUps'
            type='number'
            name='deliverDaysUps'
            label='Deliver Days Ups'
          />
        </div>
        <div className='col-4'>
          <CustomInput
            id='deliverDaysFedex'
            type='number'
            name='deliverDaysFedex'
            label='Deliver Days Fedex'
          />
        </div>
      </div>
    </Fragment>
  );
}
