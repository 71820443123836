import axios from 'axios';
import { PagedResult } from '../../../models/pagedResult';
import {
  AccountCreateModel,
  BrandingDataModel,
} from '../models/AccountCreateModel';
import { AccountEditModel } from '../models/AccountEditModel';
import { AccountModel } from '../models/AccountModel';

export const ACCOUNTS_URL = `/api/v1/accounts`;

const accountsServices = {
  getAll: function () {
    return axios.get<AccountModel[]>(`${ACCOUNTS_URL}/all`);
  },
  findAccounts: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(ACCOUNTS_URL, { params: params });
  },
  getAccount: (id: number) => {
    return axios.get<AccountModel>(`${ACCOUNTS_URL}/${id}`);
  },
  getAccountBranding: (id: number) => {
    return axios.get<BrandingDataModel>(`${ACCOUNTS_URL}/${id}/branding`);
  },
  deleteAccount: (id: number) => {
    return axios.delete(`${ACCOUNTS_URL}/${id}`);
  },
  createAccount: (account: AccountCreateModel) => {
    return axios.post(ACCOUNTS_URL, account);
  },
  updateAccount: (id: number, account: AccountEditModel) => {
    return axios.put(`${ACCOUNTS_URL}/${id}`, account);
  },
  desactivateAccount: (id: number, reason?: string) => {
    const body = {
      id,
      reason,
    };
    return axios.put(`${ACCOUNTS_URL}/deactivate/${id}`, body);
  },
  activateAccount: (id: number) => {
    return axios.put(`${ACCOUNTS_URL}/activate/${id}`);
  },
  accountImages: (accountId: number, accountImages: any) => {
    return axios.post<AccountCreateModel>(
      `${ACCOUNTS_URL}/${accountId}/image`,
      accountImages
    );
  },
};

export default accountsServices;
