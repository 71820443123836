import { Formik, Form, FormikHelpers } from 'formik';
import { UserCreateModel } from '../../models/UserCreateModel';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { userSchemaValidation } from '../../utils/userShemaValidations';
import { createUserinitialValues } from '../../utils/utils';
import { toast } from 'react-toastify';
import { CustomInput } from '../../../../components/CustomInput';
import { ActionsComponent } from '../../../../components/ActionsComponent';
import { handle400Error } from '../../../../utils/handle400Error';
import { usersServices } from '../../services/UsersServices';

export function UserCreate() {
  // i18n
  const intl = useIntl();
  // History
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  // Error
  // Save Function
  const saveUser = (
    values: UserCreateModel,
    actions: FormikHelpers<UserCreateModel>
  ) => {
    usersServices
      .createUser(values)
      .then(() => {
        toast.success(intl.formatMessage({ id: 'USER.CREATED.SUCCESS' }));
        goBack();
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          handle400Error(error.response.data.errors, actions.setErrors);
        }
      });
  };
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              {intl.formatMessage({ id: 'ACTION.CREATE' })}
            </h3>
          </div>

          <button
            onClick={goBack}
            className='btn  btn-light btn-active-primary align-self-center'
          >
            <i className='fas fa-arrow-left'></i>
            {intl.formatMessage({ id: 'ACTION.BACK' })}
          </button>
        </div>

        <div className='card-body p-9'>
          <Formik
            initialValues={createUserinitialValues}
            validationSchema={userSchemaValidation(intl)}
            onSubmit={(values, actions) => {
              saveUser(values, actions);
              actions.setSubmitting(false);
            }}
          >
            <Form>
              <div className='row'>
                <div className='col-6'>
                  <CustomInput
                    isRequired={true}
                    id='name'
                    name='name'
                    type='text'
                    label={intl.formatMessage({ id: 'USER.NAME' })}
                  />
                </div>
                <div className='col-6'>
                  <CustomInput
                    isRequired={true}
                    id='email'
                    name='email'
                    label={intl.formatMessage({ id: 'USER.EMAIL' })}
                  />
                </div>
              </div>
              <div className='row py-10'>
                
                <div className='col-6'>
                  <CustomInput
                    id='capUserName'
                    type='text'
                    name='capUserName'
                    isRequired={true}
                    label={intl.formatMessage({ id: 'USER.CAP_USERNAME' })}
                  />
                </div>
              </div>
              <ActionsComponent
                labelSubmit={intl.formatMessage({ id: 'ACTION.SUBMIT' })}
              />
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
