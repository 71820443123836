import { Action } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { BrandingModel } from '../../../models/BrandingModel';
import { GroupModel } from '../../users/models/GroupModel';
import { LoginUserModel } from '../models/LoginUserModel';
import { UserGroupModel } from '../models/UserGroupModel';
import { UserGroupRelationModel } from '../models/UserGroupRelationModel';
import {
  getGroupsByToken,
  getUserByToken,
  postUserLoggedIn,
} from '../services/AuthServices';

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  SetGroup: '[Set Group] Action',
  SetUGR: '[Set User Group Relation] Action',
  SetBranding: '[Set Branding] Action',
  RemoveBranding: '[Remove Branding] Action',
};

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  capId: undefined,
  group: undefined,
  branding: undefined,
};

export interface IAuthState {
  user?: LoginUserModel;
  accessToken?: string;
  capId?: string;
  group?: UserGroupModel;
  userGroupRelation?: UserGroupRelationModel;
  branding?: BrandingModel;
}

export const reducer = persistReducer(
  {
    storage,
    key: 'v100-demo1-auth',
    whitelist: [
      'user',
      'accessToken',
      'capId',
      'group',
      'userGroupRelation',
      'branding',
    ],
  },
  (
    state: IAuthState = initialAuthState,
    action: ActionWithPayload<IAuthState>
  ) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken;
        const capId = action.payload?.capId;
        actions.capId = capId ? capId : '';
        return { accessToken, capId, user: undefined };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserRequested: {
        return { ...state, user: undefined };
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user;
        return { ...state, user };
      }

      case actionTypes.SetGroup: {
        const group = action.payload?.group;
        const userGroupRelation = action.payload?.userGroupRelation;
        return { ...state, ...{ group, userGroupRelation } };
      }

      case actionTypes.SetBranding: {
        const branding = action.payload?.branding;
        return { ...state, branding };
      }
      case actionTypes.RemoveBranding: {
        const branding = action.payload?.branding;
        return { ...state, branding };
      }

      // case actionTypes.SetUGR: {
      //   const ugr = action.payload?.ugr;
      //   return { ...state, ugr };
      // }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (accessToken: string, capId: string) => ({
    type: actionTypes.Login,
    payload: { accessToken, capId },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (capId: string) => ({
    type: actionTypes.UserRequested,
    payload: { capId },
  }),
  fulfillUser: (user: LoginUserModel) => ({
    type: actionTypes.UserLoaded,
    payload: { user },
  }),
  setUser: (user: LoginUserModel) => ({
    type: actionTypes.SetUser,
    payload: { user },
  }),
  setGroup: (groupData: { ugr: UserGroupRelationModel; g: GroupModel }) => ({
    type: actionTypes.SetGroup,
    payload: { group: groupData.g, userGroupRelation: groupData.ugr },
  }),
  setBranding: (branding: BrandingModel) => ({
    type: actionTypes.SetBranding,
    payload: { branding },
  }),
  removeBranding: () => ({
    type: actionTypes.RemoveBranding,
  }),
  // setUGR: (ugr: { urg: UserGroupRelationModel; g: GroupModel }) => ({
  //   type: actionTypes.SetUGR,
  //   payload: { ugr },
  // }),
  capId: '',
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    /**TODO: Cuando en backend implementen el cambio, no será más necesario pasar el capId para obtener el usuario*/
    const { data: user } = yield getUserByToken();
    const { data: groups } = yield getGroupsByToken();
    yield postUserLoggedIn();
    user.groups = groups;
    yield put(actions.fulfillUser(user));
  });
}
