import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Formik, FormikHelpers, Form } from 'formik';
import { importLocationsInitialValues } from '../../utils/utils';
import { shallowEqual, useSelector } from 'react-redux';
import { importLocationsSchemaValidations } from '../../utils/importLocationsSchemeValidations';
import { RootState } from '../../../../redux';
import { ImportLocationsModel } from '../../models/ImportLocationsModel';
import locationServices from '../../services/LocationsServices';
import { Modal } from 'react-bootstrap-v5';
import { ActionsComponent } from '../../../../components/ActionsComponent';
import { CustomSelect } from '../../../../components/CustomSelect';
import CustomFileInput from '../../../../components/CustomFileInput';
import clientsServices from '../../services/ClientsServices';
import { UserGroupRelationModel } from '../../../auth/models/UserGroupRelationModel';
import { ClientModel } from '../../models/ClientModel';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { handle400Error } from '../../../../utils/handle400Error';

interface Props {
  refetch: () => void;
  show: boolean;
  onHide: () => void;
}

interface ClientOption {
  value: number;
  label: string;
  client: ClientModel;
}

export function ImportLocationsModal({ refetch, show, onHide }: Props) {
  const intl = useIntl();
  const [locationsResult, setLocationResult] = useState<any>(null);
  // Init Values & State
  const [importLocations] = useState<ImportLocationsModel>(
    importLocationsInitialValues
  );
  // Obtain redux state values
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );

  const [clients, setClients] = useState<ClientOption[]>([]);
  const clientList = useCallback(() => {
    // TODO: Replace for the correct function
    clientsServices.getAll().then((values) => {
      const clientsData = values.data;
      const items: ClientOption[] =
        clientsData.length > 0
          ? clientsData.map((x) => {
              return { value: x.id, label: x.name, client: x };
            })
          : [];
      setClients(items);
    });
  }, []);
  useEffect(() => {
    clientList();
    return () => {
      setClients([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userGroupRelations.clientId) {
    importLocations.clientId = userGroupRelations.clientId;
  }

  const importLocationsFile = (
    values: ImportLocationsModel,
    actions: FormikHelpers<ImportLocationsModel>
  ) => {
    locationServices
      .importLocationsFile(values)
      .then((response: any) => {
        // toast.success(`${response.data} Locations imported succefully`);
        setLocationResult(response.data);
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          handle400Error(error.response.data.errors, actions.setErrors);
        }
      });
  };

  const closeModal = () => {
    setLocationResult(null);
    refetch();
    onHide();
  };

  return (
    <>
      {locationsResult && (
        <Modal
          show={show && locationsResult}
          onHide={onHide}
          backdrop='static'
          keyboard={false}
          dialogClassName='modal-90w'
          aria-labelledby='example-custom-modal-styling-title'
        >
          <Modal.Header className='px-9 pt-7 card-rounded'>
            <Modal.Title className='m-0 fw-bolder fs-3'>
              Locations imported succefully
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='mt-1 px-10 py-3 position-relative z-index-1'>
            <table
              className='table align-middle table-row-dashed fs-6 fw-bold gy-4 dataTable no-footer'
              id='kt_subscription_products_table'
            >
              <tbody className='text-gray-600'>
                <tr className='odd'>
                  <td>Total Corrects</td>
                  <td>{locationsResult.totalCorrects}</td>
                </tr>
                <tr className='even'>
                  <td>Total Duplicates</td>
                  <td>{locationsResult.totalDuplicates}</td>
                </tr>
                {locationsResult?.rowsWithDuplicate.length > 0 && (
                  <>
                    <tr style={{ borderBottom: 0 }}>
                      <td colSpan={2} className='pb-2'>
                        Rows Duplicates
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className='pt-2 px-2 bg-light'>
                        <div style={{ maxHeight: 135, overflow: 'auto' }}>
                          {locationsResult.rowsWithDuplicate.map(
                            (row: number, index: number) => {
                              const color =
                                index % 2 === 0
                                  ? 'badge-dark'
                                  : 'badge-primary';
                              return (
                                <span
                                  className={`badge ${color} mx-1 my-1 w-70px`}
                                >
                                  Row {row}
                                </span>
                              );
                            }
                          )}
                        </div>
                      </td>
                    </tr>
                  </>
                )}
                <tr className='odd'>
                  <td>Total Invalids</td>
                  <td>{locationsResult.totalInvalids}</td>
                </tr>

                {locationsResult?.rowsWithInvalidData.length > 0 && (
                  <>
                    <tr style={{ borderBottom: 0 }}>
                      <td colSpan={2} className='pb-2'>
                        Rows Invalids
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className='pt-2 px-2 bg-light'>
                        <div style={{ maxHeight: 135, overflow: 'auto' }}>
                          {locationsResult.rowsWithInvalidData.map(
                            (row: number, index: number) => {
                              const color =
                                index % 2 === 0
                                  ? 'badge-dark'
                                  : 'badge-primary';
                              return (
                                <span
                                  className={`badge ${color} mx-1 my-1 w-70px`}
                                >
                                  Row {row}
                                </span>
                              );
                            }
                          )}
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer className='px-9 card-rounded  w-100'>
            <button
              type='submit'
              id='kt_modal_new_card_submit'
              className='btn btn-primary'
              onClick={closeModal}
            >
              <span className='indicator-label'>Close</span>
            </button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal
        show={show && !locationsResult}
        onHide={onHide}
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Formik
          enableReinitialize
          initialValues={importLocations}
          validationSchema={importLocationsSchemaValidations(intl)}
          onSubmit={(values, actions) => {
            importLocationsFile(values, actions);
            actions.setSubmitting(false);
          }}
        >
          {({ values, errors }) => {
            return (
              <Form>
                <Modal.Header className='px-9 pt-7 card-rounded'>
                  <Modal.Title className='m-0 fw-bolder fs-3'>
                    Import Locations
                    <div className='text-muted fw-bold text-muted d-block fs-7 mt-2'>
                      Upload Locations following the sample Excel file
                      <div>
                        <a
                          href={toAbsoluteUrl(
                            '/examples/SampleLocationImportFromClient.xls'
                          )}
                        >
                          Download Sample file
                        </a>
                        .
                      </div>
                    </div>
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body className='mt-1 px-6 py-3 position-relative z-index-1'>
                  <CustomSelect
                    label='Client'
                    placeholder='Select Client'
                    id='clientId'
                    name='clientId'
                    options={clients}
                    required={true}
                  />
                  <CustomFileInput
                    label='Locations file'
                    name='file'
                    accept='.xls,.xlsx'
                    customText='Valid file types xls or xlsx'
                  />
                </Modal.Body>
                <Modal.Footer className='px-9 card-rounded  w-100'>
                  <ActionsComponent
                    labelSubmit='OK'
                    showCancelButton={true}
                    onCancel={onHide}
                  />
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}
