import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
  isUnautorized: false,
};

export const unautorizedSlice = createSlice({
  name: 'unautorized',
  initialState: initialState,
  reducers: {
    setIsUnautorized: (state, action) => {
      if (!state.isUnautorized) toast.error('Unauthorized');
      state.isUnautorized = action.payload.flag;
    },
  },
});
