import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { EntityModel } from '../../models/EntityModel';
import { RootState } from '../../redux';
import { LocationsList } from './components/locations/LocationsList';
import { LocationsView } from './components/locations/LocationsView';

export function LocationsPage() {
  const intl = useIntl();

  const [location] = useSelector<RootState, EntityModel[]>(
    ({ entities }: any) => [entities.location],
    shallowEqual
  );
  const [franchise] = useSelector<RootState, EntityModel[]>(
    ({ entities }: any) => [entities.franchise],
    shallowEqual
  );

  const breadcrumbs: Array<PageLink> = [
    {
      title: 'Accounts',
      path: '/accounts',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Clients',
      path: `/accounts/${franchise ? franchise.details.accountId : 0}/clients`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Franchises',
      path: `/accounts/${franchise ? franchise.details.accountId : 0}/clients/${
        franchise ? franchise.details.clientId : 0
      }/franchises`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  const breadcrumbsDetail: Array<PageLink> = [
    {
      title: 'Accounts',
      path: '/accounts',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Clients',
      path: `/accounts/${location ? location.details.accountId : 0}/clients`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Franchises',
      path: `/accounts/${location ? location.details.accountId : 0}/clients/${
        location ? location.details.clientId : 0
      }/franchises`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Locations',
      path: `/accounts/${location ? location.details.accountId : 0}/clients/${
        location ? location.details.clientId : 0
      }/franchises/${location ? location.id : 0}/locations`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <Switch>
        <Route
          exact
          path='/accounts/:accountId/clients/:clientId/franchises/:franchiseId/locations'
        >
          <PageTitle breadcrumbs={breadcrumbs}>Franchise Detail</PageTitle>
          <LocationsList />
        </Route>
        <Route path='/accounts/:accountId/clients/:clientId/franchises/:franchiseId/locations/:locationId'>
          <PageTitle breadcrumbs={breadcrumbsDetail}>
            {intl.formatMessage({ id: 'MENU.ENTITIES.LOCATIONSDETAIL' })}
          </PageTitle>
          <LocationsView />
        </Route>
      </Switch>
    </>
  );
}
