import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
export const shippedSchemaValidation = (intl: IntlShape) =>
  Yup.object().shape({
    trackingNumber: Yup.string()
      .ensure()
      .trim()
      .min(3, 'Invalid')
      .max(50, 'Invalid')
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
  });
