//import { colorDarken, colorLighten } from '../../_metronic/assets/ts/_utils';
import { BrandingModel } from '../models/BrandingModel';

type Props = {
  children: React.ReactElement;
  branding: BrandingModel;
};

export function TemplateAccountColors({ children, branding }: Props) {
  const color = branding ? branding.primaryColor : null;
  if (color) {
    // const colotLight: string = colorLighten(color, 10);
    // const colotDark: string = colorDarken(color, 10);
  }

  return (
    <>
      <style type='text/css'>
        {`
          .rdt_TableCol_Sortable div{
            white-space: break-spaces!important;
            padding: 2px
          }
          .is-invalid div{
              border-color: #f1416c!important;
          }
          .is-valid div{
            border-color: #50cd89!important;
        }
        `}
      </style>
      {color && (
        <style type='text/css'>
          {`
          .bg-primary {
            background-color:${color}!important;
          }
          .form-check-input:checked {
            border-color:${color}!important;
          }
          .btn.btn-primary {
            background-color:${color};
          }
          .btn.btn-primary:focus,
          .btn.btn-primary:active,
          .btn.btn-primary:hover  {
            background-color:${color}!important;
          }
          .btn.btn-light-primary, .btn.btn-light-primary i {
            color:${color};
          }
          .btn.btn-light-primary:focus,
          .btn.btn-light-primary:active,
          .btn.btn-light-primary:hover  {
            background-color:${color}!important;
          }
          .btn.btn-active-primary:focus,
          .btn.btn-active-primary:active,
          .btn.btn-active-primary:hover  {
            background-color:${color}!important;
          }
          .btn.btn-active-light-primary {
            color:${color}!important;

          }
          .btn.btn-active-light-primary:focus,
          .btn.btn-active-light-primary:active,
          .btn.btn-active-light-primary:hover  {
            color:${color}!important;
          }
          .page-item.active .page-link {
            background-color:${color};
          }
          .page-link:hover {
            color:${color}
          }
          .page-item:hover .page-link i {
            color:${color}!important
          }
          .stepper.stepper-links .stepper-nav .stepper-item.current .stepper-title {
            color:${color};
          }
          .stepper.stepper-links .stepper-nav .stepper-item.current:after {
            background-color: ${color};
          }
          .text-active-primary.active {
            color:${color}!important;
          }
          .nav-line-tabs .nav-item .nav-link.active {
            border-color:${color}!important;
          }
          .nav-line-tabs .nav-item .nav-link:hover {
            border-color:${color}!important;
          }
          .menu-state-primary .menu-item .menu-link:hover {
            color:${color}!important;
          }
          .text-hover-primary:hover {
            color:${color}!important;
          }
          .scrolltop {
            background-color: ${color};
          }
         
        `}
        </style>
      )}
      {branding && (
        <style type='text/css'>
          {`
          .select-group-relation .modal-header,
          .select-group-relation .modal-footer {
            background-color: ${branding.navbarBackgroundColor}!important;
          }
          .aside.aside-dark {
            background-color: ${branding.navbarBackgroundColor};
          }
          .aside.aside-dark .aside-logo {
            background-color: ${branding.navbarBackgroundColor};
          }
          .aside-dark .menu .menu-item .menu-link.active {
            background-color: ${branding.navbarBackgroundActiveColor};
          }
          .aside-dark .menu .menu-item .menu-link:hover{
            background-color: ${branding.navbarBackgroundActiveColor}!important;
          }
          .aside-dark .menu .menu-item .menu-link.active .menu-title {
            color:${branding.navbarTextActiveColor}
          }
          .aside-dark .menu .menu-item .menu-link.active .menu-icon i{
            color:${branding.navbarIconActiveColor}
          }
          .aside-dark .menu .menu-item .menu-link:hover .menu-title{
            color:${branding.navbarTextActiveColor}!important
          }
          .aside-dark .menu .menu-item .menu-link:hover  .menu-icon i{
            color:${branding.navbarIconActiveColor}!important
          }
          .aside-dark .menu .menu-item.hover .menu-link {
            background-color:${branding.navbarBackgroundActiveColor}!important
          }
          .aside-dark .menu .menu-item.hover .menu-link .menu-icon i{
            color:${branding.navbarIconActiveColor}!important
          }
          .aside-dark .menu .menu-item .menu-link.active .menu-bullet .bullet {
            background-color:${branding.navbarIconActiveColor}!important
          }
          .aside-dark .menu .menu-item .menu-link .menu-bullet .bullet {
            background-color:${branding.navbarIconColor}!important
          }
          .aside-dark .menu .menu-item .menu-link .menu-title {
            color:${branding.navbarTextColor}
          }
          .aside-dark .menu .menu-item .menu-link .menu-icon i {
            color:${branding.navbarIconColor}
          }
          .menu-item .menu-link .menu-icon .svg-icon svg path{
            fill:${branding.navbarIconColor}!important
          }
          .aside-dark .menu .menu-item .menu-link:hover  .svg-icon svg path{
            fill:${branding.navbarIconActiveColor}!important
          }
          .aside-dark .menu .menu-item .menu-link.active .svg-icon svg path {
            fill:${branding.navbarIconActiveColor}!important
          }

          .aside-dark .menu .menu-item.here .menu-link {
            background-color:${branding.navbarBackgroundActiveColor}!important
          }

          .aside-dark .menu .menu-item.here .menu-link {
            background-color:${branding.navbarBackgroundActiveColor}!important
          }
          .aside-dark .menu .menu-item.here .menu-link .menu-icon i {
            color:${branding.navbarIconActiveColor}!important

          }
          `}
        </style>
      )}

      {children}
    </>
  );
}
