interface StatusBadgeProps {
  status:
    | 'New'
    | 'BackOrdered'
    | 'Picking'
    | 'Picked'
    | 'Packing'
    | 'Packed'
    | 'Shipped'
    | 'Cancelled'
    | 'Returned'
    | 'PartialShip';
  colors?: any;
}

// const DefaultColors = {
//   New: '#dc3545',
//   Shipped: '#198754',
//   Packing: '#0dcaf0',
//   BackOrdered: '#6610f2',
//   Picking: '#fd7e14',
//   Picked: '#ffc107',
//   Packed: '#d63384',
//   Cancelled: '#343a40',
//   Returned: '#6c757d',
//   PartialShip: '#0d6efd',
// };

export const DefaultColors = {
  New: '#EF446E',
  Shipped: '#64BD55',
  Packing: '#0dcaf0',
  BackOrdered: '#5B6884',
  Picking: '#DC86DE',
  Picked: '#ffc107',
  Packed: '#00D1FF',
  Cancelled: '#CD5050',
  Returned: '#DBAD30',
  PartialShip: '#0d6efd',
};

export const texts = {
  New: 'New',
  Shipped: 'Shipped',
  Packing: 'Packing',
  BackOrdered: 'Back Ordered',
  Picking: 'Picking',
  Picked: 'Picked',
  Packed: 'Packed',
  Cancelled: 'Cancelled',
  Returned: 'Returned',
  PartialShip: 'Partial Ship',
};

export const StatusBadge = ({
  status,
  colors = DefaultColors,
}: StatusBadgeProps) => {
  return (
    <span
      className='badge  my-0  text-white'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left'
      style={{
        backgroundColor: colors[status],
      }}
    >
      {texts[status]}
    </span>
  );
};
