import { Route, Switch } from 'react-router';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { KitsList } from './components/KitsList';
import { KitView } from './components/KitView';

export function KitsPage() {
  const breadcrumbs: Array<PageLink> = [];

  return (
    <>
      <Switch>
        <Route exact path='/kits'>
          <PageTitle breadcrumbs={breadcrumbs}>Kits List</PageTitle>
          <KitsList />
        </Route>
        <Route path='/kits/:kitId'>
          <PageTitle breadcrumbs={[]}>Kit Details</PageTitle>
          <KitView />
        </Route>
      </Switch>
    </>
  );
}
