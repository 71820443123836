import { CustomFormModal } from '../../../../components/CustomFormModal';
import groupsServices from '../../services/GroupsServices';
import { CustomInput } from '../../../../components/CustomInput';
import { createGroupInitialValues } from '../../utils/utils';
import { groupSchemaValidation } from '../../utils/groupSchemaValidation';
import { PermissionItemModel } from './PermissionItem';
import { GroupForm } from './GroupForm';
interface Props {
  refetch: () => void;
}

const AddButton = (props: any) => {
  return (
    <button
      onClick={props.onClick}
      type='button'
      className='btn btn-primary align-self-center'
      data-kt-menu-trigger='click'
      data-kt-menu-placement='bottom-end'
    >
      <i className='bi bi-plus fs-2'></i>
      Add Group
    </button>
  );
};

export function GroupCreate({ refetch }: Props) {
  const permissions = [
    {
      key: 'accountPermission',
      permission: createGroupInitialValues.accountPermission,
      label: 'Account Permission',
    },
    {
      key: 'clientPermission',
      permission: createGroupInitialValues.clientPermission,
      label: 'Client Permission',
    },

    {
      key: 'franchisePermission',
      permission: createGroupInitialValues.franchisePermission,
      label: 'Franchise Permission',
    },
    {
      key: 'locationPermission',
      permission: createGroupInitialValues.locationPermission,
      label: 'Location Permission',
    },
    {
      key: 'productPermission',
      permission: createGroupInitialValues.productPermission,
      label: 'Product Permission',
    },
    {
      key: 'kitPermission',
      permission: createGroupInitialValues.kitPermission,
      label: 'Kit Permission',
    },
    {
      key: 'orderPermission',
      permission: createGroupInitialValues.orderPermission,
      label: 'Order Permission',
    },
    {
      key: 'inventoryPermission',
      permission: createGroupInitialValues.inventoryPermission,
      label: 'Inventory Permission',
    },
    {
      key: 'userGroupsPermission',
      permission: createGroupInitialValues.userGroupsPermission,
      label: 'User Groups Permissions',
    },
    {
      key: 'warehousePermission',
      permission: createGroupInitialValues.warehousePermission,
      label: 'Warehouse Permissions',
    },
    {
      key: 'warehouseInventoryPermission',
      permission: createGroupInitialValues.warehouseInventoryPermission,
      label: 'Warehouse Inventory Permissions',
    },
    {
      key: 'fulfillmentPermission',
      permission: createGroupInitialValues.fulfillmentPermission,
      label: 'Fulfillment Permissions',
    },
    {
      key: 'reportPermission',
      permission: createGroupInitialValues.reportPermission,
      label: 'Report Permissions',
    },
  ] as PermissionItemModel[];

  const onClose = () => {};
  const onSubmit = (values: any) => {
    return new Promise<any>((resolve, reject) => {
      groupsServices
        .createGroup(values)
        .then((values) => {
          resolve(values);
          refetch();
        })
        .catch((err) => reject(err));
    });
  };

  return (
    <CustomFormModal
      initialValues={createGroupInitialValues}
      onSubmit={onSubmit}
      onClose={onClose}
      successMessage='Group added successfully.'
      title='Add Group'
      buttonLabel='Add'
      schemaValidations={groupSchemaValidation}
      buttonNode={<AddButton />}
      size='lg'
      submitButtonLabel='Add'
    >
      <div className='card-body p-6'>
        <div className='row pb-5'>
          <div className='col-6'>
            <CustomInput
              isRequired={true}
              id='name'
              name='name'
              type='text'
              label='Group Name'
            />
          </div>
        </div>
        <GroupForm
          permissions={permissions}
          userGroupRelations={createGroupInitialValues.userGroupRelations}
        />
      </div>
    </CustomFormModal>
  );
}
