import { FileModel } from '../../../models/FileModel';
import { ProductValueLabelPair } from '../components/CascadeEntitySelection';
import { AvailabilityInfoModel } from '../models/AvailabilityInfoModel';
import { CardAttributeModel, ProductModel } from '../models/ProductModel';
import { ProductInformationCreate } from '../models/ProductInformationCreate';
import { ProductInformationUpdate } from '../models/ProductInformationUpdate';

export const productHelpers = {
  createStep1: (product: ProductModel): ProductInformationCreate => {
    const body = {
      productType: product.productType,
      code: product.code,
      description: product.description,
      premium: product.premium,
      trackInventoryLevel: product.trackInventoryLevel,
      defaultInventoryLevel: product.defaultInventoryLevel,
      defaultLowLevel: product.defaultLowLevel,
      isWebOrderable: product.isWebOrderable,
      maxOrderQuantity: product.maxOrderQuantity,
      shippingUnitSize: product.shippingUnitSize,
      autoReplenishment: product.autoReplenishment,
      autoReplenishProductId: product.autoReplenishProductId,
      autoReplenishKitId: product.autoReplenishKitId,
      isAutoreplenishmentByKit: product.isAutoreplenishmentByKit,
      customProperties: product.customProperties,
    };
    return body;
  },
  step2: (product: ProductModel): CardAttributeModel => {
    return {
      id: product.cardAttributes.id,
      cardCategory: product.cardAttributes?.cardCategory,
      baseProductId: product.cardAttributes?.baseProductId,
      valueType: product.cardAttributes?.valueType,
      value: product.cardAttributes?.value,
      minValue: product.cardAttributes?.minValue,
      maxValue: product.cardAttributes?.maxValue,
      maskingFormat: product.cardAttributes?.maskingFormat,
      canActivate: product.cardAttributes.canActivate,
      mfDataRequired: product.cardAttributes.mfDataRequired,
    };
  },
  updateStep1: (product: ProductModel): ProductInformationUpdate => {
    const body = {
      id: product.id,
      productType: product.productType,
      code: product.code,
      description: product.description,
      premium: product.premium,
      trackInventoryLevel: product.trackInventoryLevel,
      defaultInventoryLevel: product.defaultInventoryLevel,
      defaultLowLevel: product.defaultLowLevel,
      isWebOrderable: product.isWebOrderable,
      maxOrderQuantity: product.maxOrderQuantity,
      shippingUnitSize: product.shippingUnitSize,
      autoReplenishment: product.autoReplenishment,
      autoReplenishProductId: product.autoReplenishProductId,
      autoReplenishKitId: product.autoReplenishKitId,
      isAutoreplenishmentByKit: product.isAutoreplenishmentByKit,
      customProperties: product.customProperties,
    };
    return body;
  },
  initialValues: (product: ProductModel) => {
    let data = { ...product };
    data.autoReplenishmentType = data.autoReplenishKitId ? 'kit' : 'product';
    data.autoReplenishKitId = data.autoReplenishKitId || 0;
    data.autoReplenishProductId = data.autoReplenishProductId || 0;
    data.cardAttributesId = data.cardAttributesId || 0;
    data.defaultInventoryLevel = data.defaultInventoryLevel || 0;
    data.defaultLowLevel = data.defaultLowLevel || 0;
    data.shippingUnitSize = data.shippingUnitSize || 0;
    data.maxOrderQuantity = data.maxOrderQuantity || 0;

    if (data.cardAttributesId) {
      data.cardAttributes.value = data.cardAttributes.value || 0;
      data.cardAttributes.maxValue = data.cardAttributes.maxValue || 0;
      data.cardAttributes.minValue = data.cardAttributes.minValue || 0;
      data.cardAttributes.isBaseStock = data.cardAttributes.baseProductId
        ? true
        : false;
      data.cardAttributes.baseProductId =
        data.cardAttributes.baseProductId || 0;
    } else {
      if (data.productType === 'Card') {
        data.cardAttributes = {
          id: 0,
          cardCategory: 'Pre-Printed_Pre-Persod',
          baseProductId: 0,
          valueType: 'Fixed',
          value: 0,
          minValue: 0,
          maxValue: 0,
          maskingFormat: '',
          isBaseStock: false,
          mfDataRequired: false,
          canActivate: false,
        };
        data.cardAttributesId = data.cardAttributesId || undefined;
      } else {
        data.cardAttributesId = data.cardAttributesId || undefined;
        data.cardAttributes = data.cardAttributes || {};
      }
    }
    if (data.files.length === 1) {
      if (data.files[0].id) {
        data.files[0].type = 'image/' + data.files[0].ext.replace('.', '');
      }
    }
    if (data.files.length === 2) {
      data.files[0].type = 'image/' + data.files[0].ext.replace('.', '');
      data.files[1].type = 'image/' + data.files[1].ext.replace('.', '');
    }

    return data;
  },
  processFiles: (files: FileModel[]) => {
    const data = [...files];
    if (data.length === 1) {
      if (data[0].id) {
        data[0].type = 'image/' + data[0].ext.replace('.', '');
      }
    }
    if (data.length === 2) {
      data[0].type = 'image/' + data[0].ext.replace('.', '');
      data[1].type = 'image/' + data[1].ext.replace('.', '');
    }
    return data;
  },
  createFormDataFiles: (productImages: any) => {
    const nonDeleteFileIds: string[] = [];
    productImages.forEach((x: any) => {
      if (x && x?.id) {
        nonDeleteFileIds.push(x.id);
      }
    });
    const formData = new FormData();
    productImages.forEach((x: any) => {
      if (x && !x.id) {
        formData.append('files', x);
      }
    });
    formData.append('nonDeleteFileIds', nonDeleteFileIds.join(','));
    return formData;
  },
  processEntityValues: (body: AvailabilityInfoModel) => {
    const itemsAccounts: ProductValueLabelPair[] = body.accounts.map((x) => {
      return { value: x.id, label: x.name };
    });
    const itemsClients: ProductValueLabelPair[] = body.clients.map((x) => {
      return { value: x.id, label: x.name };
    });
    const itemsFranchises: ProductValueLabelPair[] = body.franchises.map(
      (x) => {
        return { value: x.id, label: x.name };
      }
    );
    const itemsLocations: ProductValueLabelPair[] = body.locations.map((x) => {
      return { value: x.id, label: x.name };
    });

    return { itemsAccounts, itemsClients, itemsFranchises, itemsLocations };
  },
};
