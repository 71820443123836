//Modals imports
import { Formik, FormikHelpers, Form } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { ActionsComponent } from '../../../components/ActionsComponent';
import { handle400Error } from '../../../utils/handle400Error';
import { useIntl } from 'react-intl';
import mfDataServices from '../services/mfDataServices';
import { MFDataImportSchema } from '../utils/MFDataImportSchema';
import { MFDataUploadModel } from '../models/MFDataUploadModel';
import { ManufacturerDataValidateHeaderModel } from '../models/MFDataValidateHeaderModel';

interface Props {
  refetch: () => void;
  details: ManufacturerDataValidateHeaderModel;
  setDetails: any;
}

export function MFDataSecondaryModal({ refetch, details, setDetails }: Props) {
  const intl = useIntl();
  const onHide = () => {
    setDetails(null);
  };

  const uploadFile = (
    values: MFDataUploadModel,
    actions: FormikHelpers<MFDataUploadModel>
  ) => {
    mfDataServices
      .importMFDataFile(values)
      .then((response) => {
        setDetails(null);
        refetch();
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          handle400Error(error.response.data.errors, actions.setErrors);
        }
      });
  };

  return (
    <>
      {details && details.productId && details.file && (
        <Modal
          show={details.showSecondaryModal}
          onHide={onHide}
          backdrop='static'
          keyboard={false}
          dialogClassName='modal-90w'
          aria-labelledby='example-custom-modal-styling-title'
        >
          <Formik
            enableReinitialize
            initialValues={
              {
                productId: details.productId,
                file: details.file,
              } as MFDataUploadModel
            }
            validationSchema={MFDataImportSchema(intl)}
            onSubmit={uploadFile}
          >
            <Form>
              <Modal.Header className='px-9 pt-7 card-rounded'>
                <Modal.Title className='m-0 fw-bolder fs-3'>
                  Confirm MF data import
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='container-fluid px-10 py-5 position-relative z-index-1'>
                <div className='row'>
                  <div className='col-4'>
                    <div className='fw-bolder mt-5'>Product Code</div>
                    <div className='text-gray-600'>
                      {details.fileProductCode}
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='fw-bolder mt-5'>First card number</div>
                    <div className='text-gray-600'>
                      {details.firstCardNumber}
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='fw-bolder mt-5'>Number of cards</div>
                    <div className='text-gray-600'>
                      {Number(details.cardsCount)}
                    </div>
                  </div>
                </div>
                <div style={{ color: '#EE6F2D' }} className='mt-5'>
                  {!details.productValidation && (
                    <>
                      Product Code{' '}
                      <span className='fw-bolder '>
                        {details.fileProductCode}
                      </span>{' '}
                      from the MF Data file does not match{' '}
                      <span className='fw-bolder'>
                        {details.selectedProductDescription}{' '}
                      </span>
                      <span className='fw-bolder'>
                        ({details.selectedProductCode})
                      </span>
                      .
                    </>
                  )}
                </div>
                {!details.productValidation && (
                  <div className='py-5'>
                    <span className='fw-bolder'>
                      Are you sure to import and associate anyway?
                    </span>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer className='card-rounded  w-100'>
                <ActionsComponent
                  labelSubmit='Yes'
                  labelDiscard='No'
                  showCancelButton={true}
                  onCancel={onHide}
                />
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      )}
    </>
  );
}
