import moment from 'moment';
import { toast } from 'react-toastify';
import {
  markAsComplete,
  nextStatus,
  PIKING,
  PACKED,
  SHIPPED,
  CANCELLED,
  RETURNED,
  showDate,
} from '../../fulfillment/helpers/detailsHelpers';
import { GroupPermissionsFlagsDto } from '../../users/models/GroupModel';
import { OrderViewModel } from '../models/OrderViewModel';

interface Props {
  order: OrderViewModel;
  permissions: GroupPermissionsFlagsDto;
  showPickingModal?: any;
  showPackModal?: any;
  showShippedModal?: any;
  clickEvents?: boolean;
  allShippingUnitsChecked?: boolean;
}

export function OrderStatusLine({
  order,
  showPickingModal,
  showPackModal,
  showShippedModal,
  permissions,
  clickEvents = false,
  allShippingUnitsChecked,
}: Props) {
  const { status, updatedAt, createdAt } = order;
  return (
    <div style={{ backgroundColor: '#e4e6ef' }} className='card'>
      <div className='stepper stepper-pills stepper-row'>
        <div className=''>
          <div className='px-6 px-lg-10 px-xxl-15 py-20'>
            <div className='stepper-nav'>
              <div
                className={`stepper-item ${status ? 'completed' : ''} `}
                data-kt-stepper-element='nav'
              >
                <div className='stepper-line w-40px'></div>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                </div>

                <div className='stepper-label'>
                  <h3 className='stepper-title'>New</h3>

                  <div className='stepper-desc fw-bold'>
                    {moment(createdAt).format('MMM.D')}
                  </div>
                </div>
              </div>
              {(status === CANCELLED || status === RETURNED) && (
                <>
                  <div
                    className='stepper-item completed'
                    data-kt-stepper-element='nav'
                  >
                    <div className='stepper-line w-40px'></div>

                    <div
                      className='stepper-icon w-40px h-40px'
                      style={{ backgroundColor: '#CD5050' }}
                    >
                      {/* <i className='stepper-check fas fa-check'></i> */}
                      <i
                        className='stepper-check fas fa-times'
                        style={{ fontSize: '2em' }}
                      ></i>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>{status}</h3>

                      <div className='stepper-desc fw-bold'>
                        {' '}
                        {moment(updatedAt).format('MMM.D')}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {status === 'BackOrdered' && (
                <>
                  <div
                    className='stepper-item completed'
                    data-kt-stepper-element='nav'
                  >
                    <div className='stepper-line w-40px'></div>

                    <div
                      className='stepper-icon w-40px h-40px'
                      style={{ backgroundColor: '#5B6884' }}
                    >
                      <i
                        className='stepper-check fas fa-pause'
                        style={{ fontSize: '1.4em' }}
                      ></i>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Back Ordered</h3>
                      <div className='stepper-desc fw-bold'>
                        {' '}
                        {moment(updatedAt).format('MMM.D')}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {status !== CANCELLED && status !== RETURNED && (
                <>
                  {/* Init Picking Status */}
                  <div
                    className={`stepper-item  ${
                      markAsComplete(status, PIKING) ? 'completed' : ''
                    } `}
                    data-kt-stepper-element='nav'
                    onClick={
                      clickEvents &&
                      nextStatus(status, PIKING) &&
                      permissions.update
                        ? () => showPickingModal(true)
                        : undefined
                    }
                  >
                    <div className='stepper-line w-40px'></div>
                    <div
                      className={`stepper-item ${
                        clickEvents && nextStatus(status, PIKING)
                          ? 'btn btn-active-light-primary'
                          : ''
                      }`}
                    >
                      <div
                        className='stepper-icon w-40px h-40px'
                        style={{
                          border:
                            clickEvents && nextStatus(status, PIKING)
                              ? 'solid gray'
                              : 'none',
                        }}
                      >
                        <i className='stepper-check fas fa-check'></i>
                      </div>

                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Picking</h3>
                        {showDate(status, PIKING) && (
                          <div className='stepper-desc fw-bold'>
                            {' '}
                            {moment(updatedAt).format('MMM.D')}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Init Status Packed */}
                  <div
                    className={`stepper-item ${
                      markAsComplete(status, PACKED) ? 'completed' : ''
                    } `}
                    data-kt-stepper-element='nav'
                    onClick={() => {
                      if (
                        clickEvents &&
                        nextStatus(status, PACKED) &&
                        permissions.update
                      ) {
                        if (allShippingUnitsChecked) showPackModal(true);
                        else toast.warning('Check Picking List');
                      }
                    }}
                  >
                    <div className='stepper-line w-40px'></div>

                    <div
                      className={`stepper-item ${
                        clickEvents && nextStatus(status, PACKED)
                          ? 'btn btn-active-light-primary'
                          : ''
                      }`}
                    >
                      <div
                        className='stepper-icon w-40px h-40px'
                        style={{
                          border:
                            clickEvents && nextStatus(status, PACKED)
                              ? 'solid gray'
                              : 'none',
                        }}
                      >
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'></span>
                      </div>

                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Packed</h3>
                        {showDate(status, PACKED) && (
                          <div className='stepper-desc fw-bold'>
                            {' '}
                            {moment(updatedAt).format('MMM.D')}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Init Status Shipped */}
                  <div
                    className={`stepper-item ${
                      markAsComplete(status, SHIPPED) ? 'completed' : ''
                    } `}
                    data-kt-stepper-element='nav'
                    onClick={() => {
                      if (
                        clickEvents &&
                        nextStatus(status, SHIPPED) &&
                        permissions.update
                      ) {
                        if (allShippingUnitsChecked || status === 'Packed')
                          showShippedModal(true);
                        else toast.warning('Check Picking List');
                      }
                    }}
                  >
                    <div className='stepper-line w-40px'></div>

                    <div
                      className={`stepper-item ${
                        clickEvents && nextStatus(status, SHIPPED)
                          ? 'btn btn-active-light-primary'
                          : ''
                      }`}
                    >
                      <div
                        className='stepper-icon w-40px h-40px'
                        style={{
                          border:
                            clickEvents && nextStatus(status, SHIPPED)
                              ? 'solid gray'
                              : 'none',
                        }}
                      >
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'></span>
                      </div>

                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Shipped</h3>
                        {showDate(status, SHIPPED) && (
                          <div className='stepper-desc fw-bold'>
                            {' '}
                            {moment(updatedAt).format('MMM.D')}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
