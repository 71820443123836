import { NumberParam, StringParam, BooleanParam } from 'serialize-query-params';
export const initialFilterFulfillment = {
  sortDirection: -1,
  sortColumn: 'orderDate',
  pageNumber: 1,
  pageSize: 10,
  filter: undefined,
  accountId: undefined,
  clientId: undefined,
  franchiseId: undefined,
  locationId: undefined,
  status: undefined,
  origin: undefined,
  expedite: undefined,
};

export type FulfillmentQueryFilterKeys = 'filter' | 'scanningMode';

export const queryParamsFulfillmentConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  filter: StringParam,
  accountId: NumberParam,
  clientId: NumberParam,
  franchiseId: NumberParam,
  locationId: NumberParam,
  status: StringParam,
  origin: StringParam,
  expedite: BooleanParam,
  scanningMode: BooleanParam,
};
