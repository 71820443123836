import { Route, Switch } from 'react-router';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { UserCreate } from './components/users/UserCreate';
import { UsersEdit } from './components/users/UserEdit';
import { UsersList } from './components/users/UsersList';

export function UsersPage() {
  const breadcrumbs: Array<PageLink> = [];

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Users List</PageTitle>
      <Switch>
        <Route path='/users/list'>
          <UsersList />
        </Route>
        <Route path='/users/create'>
          <UserCreate />
        </Route>
        <Route path='/users/edit/:id'>
          <UsersEdit />
        </Route>
      </Switch>
    </>
  );
}
