import { NumberParam, StringParam, BooleanParam } from 'serialize-query-params';
import { AccountCreateModel } from '../models/AccountCreateModel';
import { AccountEditModel } from '../models/AccountEditModel';
import { ClientCreateModel } from '../models/ClientCreateModel';
import { ClientEditModel } from '../models/ClientEditModel';
import { FranchiseCreateModel } from '../models/FranchiseCreateModel';
import { FranchiseModel } from '../models/FranchiseModel';
import { ImportLocationsModel } from '../models/ImportLocationsModel';
import { LocationCreateModel } from '../models/LocationCreateModel';
import { LocationEditModel } from '../models/LocationEditModel';
import { ShippingCarrierInfoModel } from '../models/ShippingCarrierInfoModel';

// Initial values for franchises
export const initialFilterFranchises = {
  sortDirection: 1, // asc => 1||desc => -1
  sortColumn: 'accountName',
  pageNumber: 1,
  pageSize: 10,
  filter: undefined,
  isCorporate: undefined,
  active: 1,
};

// Initial queryParams values for accounts
export const initialFilterAccounts = {
  filter: undefined,
  sortDirection: 1, // asc => 1||desc => -1
  sortColumn: 'name',
  pageNumber: 1,
  pageSize: 10,
  active: 1,
};

// Initial queryParams values
export const initialFilterClients = {
  filter: '',
  sortDirection: 1, // asc => 1||desc => -1
  sortColumn: 'name',
  pageNumber: 1,
  pageSize: 10,
  active: 1,
};

// search params config
export const queryParamsConfigClients = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  filter: StringParam,
  accountId: NumberParam,
  active: BooleanParam,
};
// Default key filters for accounts
export type AccountQueryFilterKeys = 'filter' | 'active';

export const queryParamsAccountConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  filter: StringParam,
  active: BooleanParam,
};

// Default key filters for franchises
export const queryParamsFranchiseConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  isCorporate: BooleanParam,
  filter: StringParam,
  accountId: NumberParam,
  clientId: NumberParam,
  active: BooleanParam,
};

export const createAccountinitialValues: AccountCreateModel = {
  accountKey: '',
  accountName: '',
  accountNumber: '',
  contactName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  postalCode: '',
  isActive: true,
  branding: {
    navbarBackgroundActiveColor: '',
    navbarIconActiveColor: '',
    navbarTextActiveColor: '',
    navbarBackgroundColor: '',
    navbarIconColor: '',
    navbarTextColor: '',
    primaryColor: '',
  },
};

export const editAccountinitialValues: AccountEditModel = {
  id: 0,
  accountKey: '',
  accountName: '',
  accountNumber: '',
  contactName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  postalCode: '',
  isActive: true,
  branding: {
    navbarBackgroundActiveColor: '',
    navbarIconActiveColor: '',
    navbarTextActiveColor: '',
    navbarBackgroundColor: '',
    navbarIconColor: '',
    navbarTextColor: '',
    primaryColor: '',
  },
};

export const createClientInitialValues: ClientCreateModel = {
  accountId: 0,
  key: '',
  name: '',
  autoReplenish: false,
  canActiveCards: false,
  packingListMessage: '',
  customProperties: [],
  orderNote1: '',
  orderNote2: '',
  orderNote3: '',
  allowThirdPartyShipping: false,
  shippingMethod: '',
  shippingMethodAutoReplenishment: '',
  shippingCompanies: [],
};
export const editClientInitialValues: ClientEditModel = {
  id: 0,
  accountId: 0,
  key: '',
  name: '',
  autoReplenish: false,
  canActiveCards: false,
  packingListMessage: '',
  customProperties: [],
  orderNote1: '',
  orderNote2: '',
  orderNote3: '',
  allowThirdPartyShipping: false,
  shippingMethod: '',
  shippingMethodAutoReplenishment: '',
  shippingCompanies: [],
};
export const createfranchiseInitialValues: FranchiseCreateModel = {
  number: '',
  name: '',
  isCorporate: false,
  clientId: 0,
};
export const editfranchiseInitialValues: FranchiseModel = {
  id: 0,
  number: '',
  name: '',
  isCorporate: false,
  clientId: 0,
  isActive: false,
  accountId: 0,
  clientName: '',
  accountName: '',
};

// Locations
export const initialFilterLocations = {
  sortDirection: 1, // asc => 1||desc => -1
  sortColumn: 'name',
  pageNumber: 1,
  pageSize: 10,
  filter: undefined,
  active: 1,
};
export const initialFilterLocationsMaster = {
  sortDirection: 1, // asc => 1||desc => -1
  sortColumn: 'name',
  pageNumber: 1,
  pageSize: 10,
  filter: undefined,
  active: 1,
  accountId: undefined,
  clientId: undefined,
  franchiseId: undefined,
};
export const queryParamsConfigLocations = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  filter: StringParam,
  active: BooleanParam,
  franchiseId: NumberParam,
};

export const queryParamsConfigLocationsMaster = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  filter: StringParam,
  active: BooleanParam,
  franchiseId: NumberParam,
  accountId: NumberParam,
  clientId: NumberParam,
};

export const createLocationInitialValues: LocationCreateModel = {
  number: '',
  name: '',
  autoReplenish: false,
  emailAddress: '',
  forceOut: false,
  franchiseId: 0,
  locationType: '',
  addresses: {
    name: '',
    addressLine1: '',
    city: '',
    state: '',
    postalCode: '',
    attention: '',
    country: '',
    countryCode: '',
  },
};
export const editLocationInitialValues: LocationEditModel = {
  id: 0,
  number: '',
  name: '',
  autoReplenish: false,
  emailAddress: '',
  forceOut: false,
  franchiseId: 0,
  locationType: '',
  addresses: {
    name: '',
    addressLine1: '',
    city: '',
    state: '',
    postalCode: '',
    attention: '',
    country: '',
    countryCode: '',
  },
};

export const initialFilterInventory = {
  sortDirection: 1, // asc => 1||desc => -1
  sortColumn: 'productCode',
  pageNumber: 1,
  pageSize: 10,
  filter: undefined,
  productCode: undefined,
  autoReplenish: undefined,
  locationId: undefined,
  active: true,
};

export const queryParamsInventoryConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  filter: StringParam,
  productCode: StringParam,
  autoReplenish: BooleanParam,
  locationId: NumberParam,
  active: BooleanParam,
};

export const initialValuesShippingCompany: ShippingCarrierInfoModel = {
  accountNumber: '',
  shipBillingOption: 'Prepaid',
  shipperCode: '',
  shipperId: '',
  signatureRequired: false,
  isPrefered: false,

  billingAddress: {
    address1: '',
    address2: '',
    attention: '',
    city: '',
    country: '',
    name: '',
    phone: '',
    state: '',
    zip: '',
  },
};
export const initialFilterLocationInventory = {
  sortDirection: 1, // asc => 1||desc => -1
  sortColumn: 'productCode',
  pageNumber: 1,
  pageSize: 10,
  filter: undefined,
  productCode: undefined,
  autoReplenish: undefined,
  active: true,
  accountId: undefined,
  clientId: undefined,
  franchiseId: undefined,
  locationId: undefined,
};

export const queryParamsLocationInventoryConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  filter: StringParam,
  productCode: StringParam,
  autoReplenish: BooleanParam,
  active: BooleanParam,
  accountId: NumberParam,
  clientId: NumberParam,
  franchiseId: NumberParam,
  locationId: NumberParam,
};

export const importLocationsInitialValues: ImportLocationsModel = {
  clientId: 0,
  file: null,
};
