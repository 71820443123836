import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

const FILE_SIZE = 2000000;
const SUPPORTED_FORMATS = ['.jpg', '.png'];
const FileSchema = Yup.mixed()
  .test('fileSize', 'Max Size: 2MB.', (value) => {
    if (!value) {
      return true;
    }
    return value.size <= FILE_SIZE;
  })
  .test('fileFormat', 'Valid image types JPG and PNG.', (value) => {
    if (!value) {
      return true;
    }
    let ext = '';
    if (value instanceof File) {
      ext = '.' + value.name.replace(/^.*\./, '');
    }
    if (value.id) {
      ext = value.ext;
    }
    return SUPPORTED_FORMATS.includes(ext);
  });

export const accountSchemaValidation = (intl: IntlShape) =>
  Yup.object().shape({
    accountKey: Yup.string()
      .ensure()
      .trim()
      .max(
        50,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 50 })
      )
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    accountNumber: Yup.string()
      .ensure()
      .trim()
      .max(
        50,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 50 })
      ),
    accountName: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 200 })
      )
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    contactName: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 100 })
      )
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    addressLine1: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 100 })
      ),
    addressLine2: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 100 })
      ),
    city: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 100 })
      ),
    state: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 50 })
      ),
    postalCode: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 10 })
      ),
    branding: Yup.object().shape({
      accountLogo: Yup.string()
        .ensure()
        .trim()
        .max(
          200,
          intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 10 })
        ),
      navbarBackgroundColor: Yup.string()
        .ensure()
        .trim()
        .max(
          200,
          intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 10 })
        ),
      navbarIconColor: Yup.string()
        .ensure()
        .trim()
        .max(
          200,
          intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 10 })
        ),
      navbarTextColor: Yup.string()
        .ensure()
        .trim()
        .max(
          200,
          intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 10 })
        ),
      navbarBackgroundActiveColor: Yup.string()
        .ensure()
        .trim()
        .max(
          200,
          intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 10 })
        ),
      navbarIconActiveColor: Yup.string()
        .ensure()
        .trim()
        .max(
          200,
          intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 10 })
        ),
      navbarTextActiveColor: Yup.string()
        .ensure()
        .trim()
        .max(
          200,
          intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 10 })
        ),
      primaryColor: Yup.string()
        .ensure()
        .trim()
        .max(
          200,
          intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 10 })
        ),
      files: Yup.array().nullable(true).of(FileSchema),
    }),
  });
