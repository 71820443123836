import { Fragment, useEffect, useRef, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import ReactSelect from 'react-select';
import { ProductModel } from '../../../modules/products/models/ProductModel';
import productServices from '../../../modules/products/services/ProductsServices';
import { ValueLabelPair } from '../../../models/ValueLabelPair';

type Props = JSX.IntrinsicElements['input'] & {
  label?: string;
  name: string;
  isRequired?: boolean;
  errorServer?: string[];
  autoFocus?: boolean;
  extraFields?: string[];
  addProduct?: boolean;
  addData?: boolean;
  idsCompatibles: number[];
};

export const KitProductSelect = ({
  label,
  name,
  isRequired,
  errorServer,
  autoFocus,
  extraFields,
  addProduct,
  addData,
  idsCompatibles,
  ...props
}: Props): JSX.Element => {
  const [field, meta] = useField(name);
  const inputRef = useRef<HTMLInputElement | null>(null);
  if (field.value === null) field.value = '';
  const { errors, values, setFieldValue } = useFormikContext<any>();

  const required = isRequired ? 'required' : '';
  const errorValue = errors[field.name] ? errors[field.name] : meta.error;

  const [products, setProducts] = useState([]);
  useEffect(() => {
    const productPromise =
      productServices.getAllActiveCompatible(idsCompatibles);

    Promise.all([productPromise]).then(([products]: any) => {
      setProducts(products.data);
    });
  }, [idsCompatibles]);

  const productOptions = products.map(
    (product: ProductModel): ValueLabelPair => {
      return { value: product.id, label: product.code };
    }
  );

  setTimeout(() => {
    if (!!autoFocus && inputRef !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, 200);

  const getValue = (productId: number) => {
    return (
      productOptions.find((po: ValueLabelPair) => po.value === productId) || ''
    );
  };

  return (
    <Fragment>
      {label && <label className={`${required} form-label`}>{label}</label>}
      <ReactSelect
        placeholder='Products'
        isClearable={true}
        name={`productId`}
        options={productOptions}
        value={getValue(values.productId)}
        onBlur={field.onBlur}
        onChange={(v: any) => {
          if (v) {
            setFieldValue('productId', v.value);
            const product = products.find(
              (p: ProductModel) => p.id === v.value
            ) as ProductModel | undefined;
            addProduct && product && setFieldValue('product', product);
            addData &&
              product &&
              setFieldValue('productType', product?.productType);
            addData &&
              product &&
              setFieldValue(
                'maskingFormat',
                product?.cardAttributes?.maskingFormat
              );
          } else {
            setFieldValue('productId', 0);
          }
        }}
      />
      {meta.touched && (meta.error || errors[field.name]) && (
        <div className='invalid-feedback'>{errorValue}</div>
      )}
    </Fragment>
  );
};
