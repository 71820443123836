import { Field } from 'formik';
import { Fragment } from 'react';

export function LocationCheckBox() {
  return (
    <Fragment>
      <div className='row pt-4'>
        <div className='col-lg-4'>
          <div className='form-check'>
            <div className='p-3'>
              <label className='form-check-label'>
                <Field
                  type='checkbox'
                  className='form-check-input'
                  id='autoReplenish'
                  name='autoReplenish'
                />
                <span className=' ms-3'>Auto Replenish</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
