import { Button, Modal } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import clientsServices from '../../services/ClientsServices';

interface Props {
  show: boolean;
  id: number;
  onHide: any;
}
export function ClientDelete({ show, id, onHide }: Props) {
  const intl = useIntl();

  const deleteClient = () => {
    clientsServices.deleteClient(id).then((response) => {
      toast.success(intl.formatMessage({ id: 'CLIENT.DELETED.SUCCESS' }));
      onHide();
    });
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Client Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure to permanently delete this client?</span>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onHide} variant='secondary'>
          Close
        </Button>
        <Button onClick={deleteClient} variant='danger'>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
