import { Route, Switch } from 'react-router';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { AccountsList } from './components/accounts/AccountsList';
import { AccountsView } from './components/accounts/AccountView';

export function AccountsPage() {
  const breadcrumbs: Array<PageLink> = [];

  return (
    <>
      <Switch>
        <Route exact path='/accounts'>
          <PageTitle breadcrumbs={breadcrumbs}>Accounts List</PageTitle>
          <AccountsList />
        </Route>
        <Route path='/accounts/:accountId'>
          <AccountsView />
        </Route>
      </Switch>
    </>
  );
}
