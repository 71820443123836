import axios from 'axios';
import { PagedResult } from '../../../models/pagedResult';

export const FULFILLMENT_URL = `/api/v1/orders/fulfillments`;

const fulfillmentServices = {
  findFulfillment: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(FULFILLMENT_URL, { params: params });
  },
};

export default fulfillmentServices;
