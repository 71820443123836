/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useEffect, useState } from 'react';
import warehousesServices from '../services/WarehousesServices';
import { WarehouseModel } from '../models/WarehouseModel';
import { TableColumn } from 'react-data-table-component';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ListComponent from '../../../components/ListComponent';
import {
  WarehouseQueryFilterKeys,
  initialFilterWarehouses,
  queryParamsWarehouseConfig,
} from '../utils/utils';
import { useQueryParamsReplace } from '../../../utils/useQueryParamsReplace';
import { WarehousesFilter } from './WarehousesFilter';
import { GroupModel } from '../../users/models/GroupModel';
import { RootState } from '../../../redux';
import { CustomBadge } from '../../../components/CustomBadge';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { WarehouseEdit } from './WarehouseEdit';
import { WarehouseCreate } from './WarehouseCreate';
import { WarehouseCreateInventory } from './WarehouseCreateInventory';
import { ActiveWarehouseModel } from '../models/ActiveWarehouseModel';

export function WarehousesList() {
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<WarehouseModel[]>([]);
  const [activeWarehouse, setActiveWarehouse] =
    useState<ActiveWarehouseModel | null>(null);
  const [queryParams, setQueryReplace] = useQueryParamsReplace(
    queryParamsWarehouseConfig
  );
  const [filter, setFilter] = useState('');
  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<WarehouseModel>[] = useMemo(
    () => [
      {
        name: 'ID',
        selector: (row) => row.id,
        sortable: true,
        sortField: 'name',
      },
      {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
        sortField: 'name',
      },
      {
        name: 'Active',
        cell: (row) => {
          return (
            <CustomBadge
              status={row.isActive}
              activeText='Yes'
              inactiveText='No'
            />
          );
        },
        center: true,
      },
      {
        name: 'City',
        selector: (row) => row.city,
        sortable: true,
        wrap: true,
      },
      {
        name: 'State',
        selector: (row) => row.state,
        sortable: true,
        wrap: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // Hides actions where there is only one
  if (group.warehousePermission?.view || group.warehousePermission?.update) {
    columns.push({
      name: 'Actions',
      button: true,
      right: true,
      cell: (row) =>
        row.id && (
          <>
            {/* <div
                className='d-flex'
                style={{
                  marginLeft: 'auto',
                  marginRight: '1px',
                  paddingRight: '6px',
                }}
              > */}
            <Fragment>
              <table
                style={{
                  marginLeft: 'auto',
                  paddingRight: '6px',
                }}
              >
                <tbody>
                  <tr>
                    {group.warehousePermission?.view && (
                      <td>
                        <button
                          onClick={() => handleView(row)}
                          className='btn btn-icon btn-bg-light btn-active-success btn-sm me-1'
                          data-bs-toggle='tooltip'
                          title='View'
                        >
                          <i className='las la-eye fs-2'></i>
                        </button>
                      </td>
                    )}
                    {group.warehousePermission?.view &&
                      group.warehousePermission?.update && (
                        <td>
                          <button
                            onClick={() => handleShowEditModal(row.id)}
                            className='btn btn-icon btn-bg-light btn-active-primary btn-sm me-1'
                            data-bs-toggle='tooltip'
                            title='Edit'
                          >
                            <i className='las la-edit fs-2'></i>
                          </button>
                        </td>
                      )}
                    {group.warehouseInventoryPermission?.create &&
                      row.isActive && (
                        <td
                          style={{
                            paddingRight: '20px',
                          }}
                        >
                          <button
                            onClick={() => handleShowCreateInventoryModal(row)}
                            className='btn btn-icon btn-bg-light btn-active-primary btn-sm me-1'
                            data-bs-toggle='tooltip'
                            title='Add Inventory'
                          >
                            <i className='las la-plus fs-2'></i>
                          </button>
                        </td>
                      )}
                  </tr>
                </tbody>
              </table>
            </Fragment>
          </>
        ),
    });
  }

  // Initialization methods
  useEffect(() => {
    if (history.location.search === '') {
      const newQueryParams = {
        ...initialFilterWarehouses,
      };
      setQueryReplace(newQueryParams);
    }
    if (queryParams.filter) setFilter(queryParams.filter);
    if (queryParams.pageNumber) findWarehouses(queryParams);
    findActiveWarehouse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findWarehouses = function (queryParams: any) {
    warehousesServices.findWarehouses(queryParams).then((response) => {
      setEntities(response.data.items);
      setTotalCount(response.data.totalCount);
    });
  };

  const findActiveWarehouse = function () {
    warehousesServices.getActiveWarehouse().then((response) => {
      setActiveWarehouse(response.data);
    });
  };

  const onFilterChange = (e: any, key: WarehouseQueryFilterKeys) => {
    if (key === 'filter') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };

  const findWithFilters = (params: {
    key: WarehouseQueryFilterKeys;
    value: any;
  }) => {
    const newQueryParams = { ...queryParams };
    newQueryParams[params.key] = params.value;
    newQueryParams.pageNumber = 1;
    setQueryReplace(newQueryParams);
  };

  const debounced = useDebouncedCallback(findWithFilters, 800);

  const handleView = (warehouse: WarehouseModel) => {
    history.push(`/warehouses/${warehouse.id}`);
  };
  const refetch = () => {
    findWarehouses(queryParams);
  };
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedEntity, setSelectedEntity] = useState<WarehouseModel>();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };
  const handleShowCreateModal = () => {
    setShowCreateModal(true);
  };
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedId(0);
  };
  const handleShowEditModal = (id: number) => {
    setSelectedId(id);
    setShowEditModal(true);
  };
  const [showCreateInventoryModal, setShowCreateInventoryModal] =
    useState(false);
  const handleShowCreateInventoryModal = (entity: WarehouseModel) => {
    setShowCreateInventoryModal(true);
    setSelectedEntity(entity);
  };
  const handleCloseCreateInventoryModal = () => {
    setShowCreateInventoryModal(false);
  };
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header py-6'>
          <div className='card-title'>
            {group.warehousePermission?.list && (
              <div className='d-flex align-items-center position-relative my-1'>
                <i className='las la-search position-absolute ms-6 fs-2'></i>
                <input
                  autoComplete='none'
                  type='search'
                  className='form-control form-control-solid w-350px ps-15'
                  value={filter}
                  placeholder='Search by ID, Name, City or State'
                  onChange={(e) => onFilterChange(e, 'filter')}
                />
              </div>
            )}
          </div>

          {activeWarehouse && activeWarehouse.warehouseId === 0 && (
            <div className='text-warning font-weight-bold fs-4 fw-bolder me-1 my-4'>
              There is no active warehouse
            </div>
          )}
          <div className='card-toolbar'>
            {group.warehousePermission?.list && (
              <WarehousesFilter
                queryParams={queryParams}
                setQueryParams={setQueryReplace}
              />
            )}
            {group.warehousePermission?.create && (
              <>
                <button
                  onClick={() => handleShowCreateModal()}
                  type='button'
                  className='btn btn-primary align-self-center'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                >
                  <i className='bi bi-plus fs-2'></i>
                  Add Warehouse
                </button>

                {showCreateModal && (
                  <WarehouseCreate
                    refetch={refetch}
                    show={showCreateModal}
                    onHide={handleCloseCreateModal}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className='card-body px-9 py-3'>
          {group.warehousePermission?.list && (
            <ListComponent
              table={{
                columns,
                data: entities,
                paginationTotalRows: totalCount,
                pointerOnHover: true,
                highlightOnHover: true,
                onRowClicked: handleView,
              }}
              queryParams={queryParams}
              setQueryParams={setQueryReplace}
            />
          )}
        </div>
      </div>
      {showEditModal && (
        <WarehouseEdit
          id={selectedId}
          refetch={refetch}
          show={showEditModal}
          onHide={handleCloseEditModal}
        />
      )}
      {showCreateInventoryModal && selectedEntity && (
        <WarehouseCreateInventory
          show={showCreateInventoryModal}
          refetch={refetch}
          onHide={handleCloseCreateInventoryModal}
          warehouse={selectedEntity}
        />
      )}
    </>
  );
}
