import { Fragment, useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select/dist/declarations/src/Select';
import { CustomFilter } from '../../../components/CustomFilter';
import ReactSelect from 'react-select';

const activeOptions = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];
interface PropsFilter {
  queryParams: any;
  setQueryParams: any;
}
export function WarehousesFilter({ setQueryParams, queryParams }: PropsFilter) {
  // Filter States
  const [isActive, setIsActive] = useState<boolean | null>();
  const isActiveRef = useRef<null | Select>(null);
  const [state, setState] = useState<string | null>();
  useEffect(() => {
    if (queryParams.active || !queryParams.active) {
      setIsActive(queryParams.active);
    }
    if (queryParams.state !== null || queryParams.state !== undefined) {
      setState(queryParams.state);
    }
  }, [queryParams]);

  const handleIsActive = (params: any) => {
    if (params) {
      setIsActive(params.value);
    } else {
      setIsActive(undefined);
    }
  };

  const handleState = (e: any) => {
    if (e.target.value) {
      setState(e.target.value);
    } else {
      setState(undefined);
    }
  };

  const resetFilters = () => {
    isActiveRef.current?.clearValue();
    const newQueryParams = { ...queryParams };
    newQueryParams.pageNumber = 1;
    newQueryParams.active = true;
    newQueryParams.state = undefined;
    setQueryParams(newQueryParams);
  };

  const applyFilter = () => {
    const newQueryParams = { ...queryParams };
    newQueryParams.active = isActive;
    newQueryParams.pageNumber = 1;
    newQueryParams.state = state;
    setQueryParams(newQueryParams);
  };

  return (
    <CustomFilter
      applyFilters={applyFilter}
      resetFilters={resetFilters}
      scroll={false}
    >
      <Fragment>
        <div className='mb-2' onClick={(e) => e.stopPropagation()}>
          <label className='form-label fw-bold'>Active</label>
          <ReactSelect
            placeholder={<FormattedMessage id='COMMON.ALL' />}
            isClearable
            name='isActive'
            id='isActive'
            onChange={handleIsActive}
            options={activeOptions}
            value={
              activeOptions
                ? activeOptions.filter((x: any) => x.value === isActive)[0]
                : null
            }
            ref={isActiveRef}
            defaultValue={activeOptions[0]}
          />
          <label className='form-label fw-bold my-2'>State</label>
          <input
            autoComplete='none'
            type='text'
            className='form-control form-control-sm form-control-solid'
            onChange={(e) => handleState(e)}
          />
        </div>
      </Fragment>
    </CustomFilter>
  );
}
