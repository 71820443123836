import { Field, useFormikContext } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { CustomInput } from '../../../components/CustomInput';
import { CustomSelect } from '../../../components/CustomSelect';
import { CustomTextArea } from '../../../components/CustomTextArea';
import { ValueLabelPair } from '../../../models/ValueLabelPair';
import { KitModel } from '../../kits/models/KitModel';
import kitServices from '../../kits/services/KitsServices';
import { ProductModel } from '../models/ProductModel';
import productServices from '../services/ProductsServices';
export const productsType: Array<ValueLabelPair> = [
  { value: 'Card', label: 'Card' },
  { value: 'Carrier', label: 'Carrier' },
  { value: 'Envelop', label: 'Envelop' },
  { value: 'Label', label: 'Label' },
  { value: 'Box', label: 'Box' },
  { value: 'Misc', label: 'Misc' },
];

export function ProductInformationStep1() {
  const { values, setFieldValue } = useFormikContext<ProductModel>();
  const [kits, setKits] = useState<KitModel[]>([]);
  const [products, setProducts] = useState<ProductModel[]>([]);

  useEffect(() => {
    if (!values.trackInventoryLevel) {
      setFieldValue('defaultInventoryLevel', 0);
      setFieldValue('defaultLowLevel', 0);
    }
    if (values.autoReplenishmentType === 'kit') {
      setFieldValue('isAutoreplenishmentByKit', true);
      setFieldValue('autoReplenishProductId', 0);
    } else {
      setFieldValue('isAutoreplenishmentByKit', false);
      setFieldValue('autoReplenishKitId', 0);
    }

    if (!values.autoReplenishment) {
      setFieldValue('autoReplenishProductId', 0);
      setFieldValue('autoReplenishKitId', 0);
    }
    if (values.productType !== 'Card') {
      setFieldValue('autoReplenishment', false);
    }
  }, [setFieldValue, values]);

  useEffect(() => {
    const kitPromise = kitServices.getAllActive();
    const productPromise = productServices.getAllActive();

    Promise.all([kitPromise, productPromise]).then(([kits, products]: any) => {
      setProducts(products.data);
      setKits(kits.data);
    });
  }, []);

  const kitOptions = kits.map((kit: KitModel): ValueLabelPair => {
    return { value: kit.id, label: kit.code };
  });

  const productOptions = products.map(
    (product: ProductModel): ValueLabelPair => {
      return { value: product.id, label: product.code };
    }
  );

  return (
    <div className='w-100'>
      <div className='row'>
        <div className='fw-bolder text-gray-800 fs-2 mb-10'>
          General Information
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-6'>
          <CustomInput
            isRequired={true}
            id='code'
            name='code'
            type='text'
            label='Code'
          />
        </div>
        <div className='col-6'>
          <CustomSelect
            isRequired={true}
            id='productType'
            options={productsType}
            name='productType'
            label='Product Type'
          />
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-12'>
          <CustomTextArea
            isRequired={true}
            id='description'
            name='description'
            label='Description'
            maxLength={255}
          />
        </div>
      </div>
      <div className='fv-row my-8'>
        <div className='d-flex flex-start'>
          <div className='me-1'>
            <label className='form-label form-check form-check-sm form-check-custom  form-check-solid me-5 me-lg-20'>
              Premium
            </label>
          </div>

          <label className='form-check form-switch form-check-custom form-check-solid'>
            <Field
              className={`form-check-input me-1 ${
                values.premium ? 'bg-primary' : ''
              }`}
              name='premium'
              type='checkbox'
            />

            <span className='form-check-label fw-bold text-muted'>
              {values.premium ? 'Yes' : 'No'}
            </span>
          </label>
        </div>
      </div>
      <div className='row my-8'>
        <div className='fw-bolder text-gray-800 fs-2 m'>Inventory</div>
      </div>
      <div className='fv-row my-4'>
        <div className='d-flex flex-start'>
          <div className='me-1'>
            <label className='form-label form-check form-check-sm form-check-custom  form-check-solid me-5 me-lg-20'>
              Track Inventory Level
            </label>
          </div>

          <label className='form-check form-switch form-check-custom form-check-solid'>
            <Field
              className={`form-check-input me-1 ${
                values.trackInventoryLevel ? 'bg-primary' : ''
              }`}
              name='trackInventoryLevel'
              type='checkbox'
            />

            <span className='form-check-label fw-bold text-muted'>
              {values.trackInventoryLevel ? 'Yes' : 'No'}
            </span>
          </label>
        </div>
      </div>
      <div className='row my-8'>
        <div className='col-6'>
          <CustomInput
            id='defaultInventoryLevel'
            name='defaultInventoryLevel'
            placeholder='Enter value'
            type='number'
            label='Default Optimum Level'
            isDisabled={!values.trackInventoryLevel}
          />
        </div>
        <div className='col-6'>
          <CustomInput
            id='defaultLowLevel'
            name='defaultLowLevel'
            type='number'
            label='Default Low Level'
            isDisabled={!values.trackInventoryLevel}
          />
        </div>
      </div>
      <div className='row my-8'>
        <div className='fw-bolder text-gray-800 fs-2 m'>Order Management</div>
      </div>
      <div className='fv-row my-4'>
        <div className='d-flex flex-start'>
          <div className='me-1'>
            <label className='form-label form-check form-check-sm form-check-custom  form-check-solid me-5 me-lg-20'>
              Web Orderable
            </label>
          </div>

          <label className='form-check form-switch form-check-custom form-check-solid'>
            <Field
              className={`form-check-input me-1 ${
                values.isWebOrderable ? 'bg-primary' : ''
              }`}
              name='isWebOrderable'
              type='checkbox'
            />

            <span className='form-check-label fw-bold text-muted'>
              {values.isWebOrderable ? 'Yes' : 'No'}
            </span>
          </label>
        </div>
      </div>
      <div className='row my-8'>
        <div className='col-6'>
          <CustomInput
            id='maxOrderQuantity'
            name='maxOrderQuantity'
            placeholder='Enter value'
            type='number'
            label='Max Order Quantity'
          />
        </div>
        <div className='col-6'>
          <CustomInput
            id='shippingUnitSize'
            name='shippingUnitSize'
            type='number'
            label='Shipping Unit Size'
          />
        </div>
      </div>
      {values.productType === 'Card' && (
        <Fragment>
          <div className='fv-row my-4'>
            <div className='d-flex flex-start'>
              <div className='me-1'>
                <label className='form-label form-check form-check-sm form-check-custom  form-check-solid me-5 me-lg-20'>
                  Auto Replenishment
                </label>
              </div>

              <label className='form-check form-switch form-check-custom form-check-solid'>
                <Field
                  className={`form-check-input me-1 ${
                    values.autoReplenishment ? 'bg-primary' : ''
                  }`}
                  name='autoReplenishment'
                  type='checkbox'
                />

                <span className='form-check-label fw-bold text-muted'>
                  {values.autoReplenishment ? 'Yes' : 'No'}
                </span>
              </label>
            </div>
          </div>

          <div className='fv-row mt-8'>
            <div className='d-flex flex-stack'>
              <div className='me-5'>
                <label className='fs-5 fw-bold'>Replenish by</label>
              </div>

              <label className='form-check form-check-custom form-check-solid'>
                <Field
                  className={`form-check-input ${
                    values.autoReplenishmentType === 'kit' ? 'bg-primary' : ''
                  }`}
                  name='autoReplenishmentType'
                  type='radio'
                  value='kit'
                  disabled={!values.autoReplenishment}
                />

                <span className='form-check-label fw-bold text-muted'>Kit</span>
              </label>

              <label className='form-check form-check-custom form-check-solid'>
                <Field
                  className={`form-check-input ${
                    values.autoReplenishmentType === 'product'
                      ? 'bg-primary'
                      : ''
                  }`}
                  name='autoReplenishmentType'
                  type='radio'
                  value='product'
                  disabled={!values.autoReplenishment}
                />

                <span className='form-check-label fw-bold text-muted'>
                  Product
                </span>
              </label>
            </div>
            <div className='row mt-8'>
              {values.autoReplenishmentType === 'kit' && (
                <div className='col-6'>
                  <CustomSelect
                    id='autoReplenishKitId'
                    options={kitOptions}
                    name='autoReplenishKitId'
                    label='Kit'
                    disabled={!values.autoReplenishment}
                    isDisabled={!values.autoReplenishment}
                  />
                </div>
              )}
              {values.autoReplenishmentType === 'product' && (
                <div className='col-6'>
                  <CustomSelect
                    id='autoReplenishProductId'
                    options={productOptions}
                    name='autoReplenishProductId'
                    label='Product'
                    isClearable={true}
                    isDisabled={!values.autoReplenishment}
                  />
                </div>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}
