import { Fragment, useEffect, useState } from 'react';
import { CustomBadge } from '../../../components/CustomBadge';
import { ValueLabelPair } from '../../../models/ValueLabelPair';
import { KitModel } from '../../kits/models/KitModel';
import kitServices from '../../kits/services/KitsServices';
import { ProductModel } from '../models/ProductModel';
import productServices from '../services/ProductsServices';

interface Props {
  product: ProductModel;
}

export function ProductViewCardDetails({ product }: Props) {
  const [kits, setKits] = useState<KitModel[]>([]);
  const [products, setProducts] = useState<ProductModel[]>([]);
  useEffect(() => {
    if (!!product.autoReplenishment) {
      const kitPromise = kitServices.getAllActiveKits();
      const productPromise = productServices.getAllActive();

      Promise.all([kitPromise, productPromise]).then(
        ([kits, products]: any) => {
          setProducts(products.data);
          setKits(kits.data);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);
  const kitOptions = kits.map((kit: KitModel): ValueLabelPair => {
    return { value: kit.id, label: kit.code };
  });

  const productOptions = products.map(
    (product: ProductModel): ValueLabelPair => {
      return { value: product.id, label: product.code };
    }
  );
  return (
    <Fragment>
      <div className='p-5'>
        <div className='row my-5'>
          <div className='row py-1 mt-3'>
            <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
              Track Inventory Level
            </div>
            <div className=' fs-5 col-9'>
              <CustomBadge
                status={product.trackInventoryLevel}
                activeText='Yes'
                inactiveText='No'
              />
            </div>
          </div>
          {product?.trackInventoryLevel && (
            <div className='row py-1 mt-3'>
              <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
                Default Inventory Level
              </div>
              <div className='fs-5 col-9'>{product?.defaultInventoryLevel}</div>
            </div>
          )}
          {product?.trackInventoryLevel && (
            <div className='row py-1 mt-3'>
              <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
                Default Low Level
              </div>
              <div className='fs-5 col-9'> {product?.defaultLowLevel}</div>
            </div>
          )}
        </div>
        <div className='row my-5'>
          <div className='row py-1 mt-3'>
            <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
              Web Orderable
            </div>
            <div className=' fs-5 col-9'>
              <CustomBadge
                status={product.isWebOrderable}
                activeText='Yes'
                inactiveText='No'
              />
            </div>
          </div>
          {product?.isWebOrderable && (
            <div className='row py-1 mt-3'>
              <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
                Max Order Quantity
              </div>
              <div className='fs-5 col-9'> {product?.maxOrderQuantity}</div>
            </div>
          )}
          {product?.isWebOrderable && (
            <div className='row py-1 mt-3'>
              <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
                Shipping Unit Size
              </div>
              <div className='fs-5 col-9'> {product?.shippingUnitSize}</div>
            </div>
          )}
        </div>
        {product.productType === 'Card' && (
          <div className='row my-5'>
            <div className='row py-1 mt-3'>
              <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
                Auto Replenishment
              </div>
              <div className=' fs-5 col-9'>
                <CustomBadge
                  status={product.autoReplenishment}
                  activeText='Yes'
                  inactiveText='No'
                />
              </div>
            </div>
            {/* Only display the below fields if product is of type Card.  Otherwise default to auto_replenish=false */}
            {product?.autoReplenishment && (
              <div className='row py-1 mt-3'>
                <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
                  Replenish by{' '}
                  {product.autoReplenishProductId ? 'Product' : 'Kit'}
                </div>
                {product.autoReplenishProductId && (
                  <div className='fs-5 col-9'>
                    {
                      productOptions.filter(
                        (x) => x.value === product?.autoReplenishProductId
                      )[0]?.label
                    }
                  </div>
                )}
                {product.autoReplenishKitId && (
                  <div className='fs-5 col-9'>
                    {
                      kitOptions.filter(
                        (x) => x.value === product?.autoReplenishKitId
                      )[0]?.label
                    }
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {/* Only display the below fields if product is of type Card.  Otherwise default to auto_replenish=false */}
        {product.productType !== 'Card' && (
          <div className='row my-5'>
            <div className='row py-1 mt-3'>
              <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
                Auto Replenishment
              </div>
              <div className=' fs-5 col-9'>
                <CustomBadge
                  status={false}
                  activeText='Yes'
                  inactiveText='No'
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
