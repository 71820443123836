import { Modal } from 'react-bootstrap-v5';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

interface Props {
  show: boolean;
  onClose: () => void;
  errors: string;
}

export function PlaceOrderErrorsModal({ show, onClose, errors }: Props) {
  return (
    <Modal
      show={show}
      backdrop='static'
      keyboard={false}
      dialogClassName='modal-90w'
      aria-labelledby='example-custom-modal-styling-title'
    >
      <div className='card'>
        <div className='card-body d-flex flex-center flex-column p-10'>
          <div
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                '/media/patterns/order-error-symbol.png'
              )})`,
              backgroundRepeat: 'no-repeat',
            }}
            className='p-0 h-100px w-100px'
          ></div>

          <div className='mb-6'>
            Sorry, looks like there are some errors detected, please try again.
          </div>
          <div className='mb-6'>{errors}</div>

          <div className='d-flex flex-center flex-wrap'>
            <button
              type='submit'
              id='close-modal'
              onClick={onClose}
              className='btn btn-light'
            >
              <span className='indicator-label'>Close</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
