import * as Yup from 'yup';

export const kitAvailabilitiesSchema = Yup.object().shape({
  accountId: Yup.number().nullable(),
  clientId: Yup.number().nullable(),
  franchiseId: Yup.number().nullable(),
  locationId: Yup.number().nullable(),
});

export const CreateKitSchemaStep4 = Yup.object().shape({
  availabilities: Yup.array()
    .of(kitAvailabilitiesSchema)
    .min(1, 'You must add at least one availability'),
});
