import { shallowEqual, useSelector } from 'react-redux';
import { CustomFormModal } from '../../../../components/CustomFormModal';
import { EntityModel } from '../../../../models/EntityModel';
import { RootState } from '../../../../redux';
import locationsServices from '../../services/LocationsServices';
import { locationsSchemaValidation } from '../../utils/locationsSchemeValidations';
import { createLocationInitialValues } from '../../utils/utils';
import { AddressForm } from './AddressForm';
import { LocationCheckBox } from './LocationCheckBox';
import { LocationForm } from './LocationForm';

interface Props {
  refetch: () => void;
}

const AddButton = (props: any) => {
  return (
    <button
      onClick={props.onClick}
      type='button'
      className='btn btn-primary align-self-center'
      data-kt-menu-trigger='click'
      data-kt-menu-placement='bottom-end'
    >
      <i className='bi bi-plus fs-2'></i>
      Add Location
    </button>
  );
};

export function LocationsCreate({ refetch }: Props) {
  const [account, client, franchise] = useSelector<RootState, EntityModel[]>(
    ({ entities }: any) => [
      entities.account,
      entities.client,
      entities.franchise,
    ],
    shallowEqual
  );
  const onSubmit = (values: any) => {
    return new Promise<any>((resolve, reject) => {
      locationsServices
        .createLocation(values)
        .then((values) => {
          resolve(values);
          refetch();
        })
        .catch((err) => reject(err));
    });
  };

  const onClose = () => {};

  // TODO datos a completar desde contexto redux o url
  if (franchise) {
    createLocationInitialValues.franchiseId = franchise.id;
  }

  return (
    <div
      className='d-flex justify-content-end'
      data-kt-customer-table-toolbar='base'
    >
      <CustomFormModal
        initialValues={createLocationInitialValues}
        onSubmit={onSubmit}
        onClose={onClose}
        successMessage='Location added successfully.'
        title='Add Location'
        buttonLabel='Add'
        submitButtonLabel='Add'
        schemaValidations={locationsSchemaValidation}
        buttonNode={<AddButton />}
      >
        <>
          <div className='card-body p-3'>
            <div className='row'>
              {account && (
                <div className='col-4'>
                  <div className='form-label'>Account</div>
                  <span className='text-muted'>{account.name}</span>
                </div>
              )}
              {client && (
                <div className='col-4'>
                  <div className='form-label'>Client</div>
                  <span className='text-muted'>{client.name}</span>
                </div>
              )}
              {franchise && (
                <div className='col-4'>
                  <div className='form-label'>Franchise</div>
                  <span className='text-muted'>{franchise.name}</span>
                </div>
              )}
            </div>
            <LocationForm />
            <AddressForm />
            <LocationCheckBox />
          </div>
        </>
      </CustomFormModal>
    </div>
  );
}
