/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from 'react-bootstrap-v5';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../redux';
import { hiddesAccessDeniedModal } from '../redux/accessDenied/accessDeniedSliceActions';

export function AccessDeniedModal() {
  const accessDenied = useSelector<RootState, boolean>(
    ({ accessDenied }: any) => accessDenied.isAccessDenied,
    shallowEqual
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const close = () => {
    dispatch(hiddesAccessDeniedModal());
    history.push('/home');
  };
  return (
    <Modal
      show={accessDenied}
      onHide={close}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header className='bg-danger py-0 w-100 '>
        <Modal.Title className='text-inverse-danger font-weight-bold py-4 fs-2'>
          Access Denied
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='rounded p-5 mb-7'>
        <div className='d-flex align-items-center mb-4'>
          <i className='las la-exclamation-triangle fs-5x text-danger'></i>
          <div className='flex-grow-3 me-4'>
            <div className='fw-bold text-gray-700 fs-6'>
              Sorry about that, but you don’t have permission to access this
              page.
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <span className='text-muted fw-bold text-muted d-block fs-7'>
              Fortunately, you can go to home
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='py-2'>
        <div>
          <button type='button' onClick={close} className='btn btn-light'>
            Go to Home
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
