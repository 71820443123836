import { hiddeLoading, showLoading } from '../redux/loading/loadiingActions';
import { toast } from 'react-toastify';
import { actions } from '../modules/auth';
import { showAccessDeniedModal } from '../redux/accessDenied/accessDeniedSliceActions';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { showUnautorizedModal } from '../redux/unautorized/unautorizedSliceActions';

const nProgress = NProgress.configure({
  easing: 'ease',
  speed: 100,
  showSpinner: false,
  trickleSpeed: 200,
  minimum: 0.1,
});

export default function setupAxios(axios: any, store: any) {
  const { REACT_APP_BASE_URL } = process.env;
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { accessToken },
      } = store.getState();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        config.baseURL = REACT_APP_BASE_URL;
      }
      const noLoading = !!config?.params?.noLoading;
      const { dispatch } = store;
      if (config.method === 'get') {
        nProgress.start();
      }
      if (config.method !== 'get') {
        dispatch(showLoading(config.url, noLoading));
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    function (response: any) {
      const { dispatch } = store;
      if (response.config.method === 'get') nProgress.done();
      dispatch(hiddeLoading(response.config.url));
      return response;
    },
    function axiosRetryInterceptor(error: any) {
      const { dispatch } = store;
      if (error?.response?.config?.method === 'get') nProgress.done();
      dispatch(hiddeLoading(error?.response?.config.url));

      if (
        error?.response?.status === 400 &&
        error?.response?.data?.errors?.nonFieldError
      ) {
        const message = error.response.data.errors.nonFieldError.join('-');
        //Show nonFieldError
        toast.warning(message);
      }
      if (error?.response?.status === 401) {
        dispatch(showUnautorizedModal());
        setTimeout(function () {
          dispatch(actions.logout());
        }, 1000);
      }
      if (error?.response?.status === 403) {
        dispatch(showAccessDeniedModal());
      }
      if (error?.response?.status === 404) {
        if (error?.config?.url === '/api/v1/Users/profile') {
          window.location.replace('/error/user-not-found');
        } else toast.error('Not Found');
      }
      if (error?.response?.status === 500) {
        //const message = error.response.data.hasOwnProperty("message") ? error.response.data.message : error.data !== undefined ? error.data : error;
        window.location.replace(`/error/500`);
      }
      if (!error?.response) {
        window.location.replace(`/error/500`);
      }
      return Promise.reject(error);
    }
  );
}
