import { NumberParam, StringParam } from 'serialize-query-params';
import { AddressModel } from '../models/AddressModel';
import { OrderCreateModel } from '../models/OrderCreateModel';
export type OrdersQueryFilterKeys = 'id';

// Initial queryParams values
export const initialFilterOrders = {
  filter: '',
  sortDirection: -1,
  sortColumn: 'createdAt',
  pageNumber: 1,
  pageSize: 10,
  accountId: undefined,
  clientId: undefined,
  franchiseId: undefined,
  locationId: undefined,
  origin: '',
  status: '',
  orderDateFrom: undefined,
  orderDateTo: undefined,
  orderShipFrom: undefined,
  orderShipTo: undefined,
};

// search params config
export const queryParamsConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  filter: StringParam,
  origin: StringParam,
  status: StringParam,
  accountId: NumberParam,
  clientId: NumberParam,
  franchiseId: NumberParam,
  locationId: NumberParam,
  orderDateFrom: StringParam,
  orderDateTo: StringParam,
  orderShipFrom: StringParam,
  orderShipTo: StringParam,
};

export const initialValuesCreateOrder: OrderCreateModel = {
  locationId: 0,
  clientId: 0,
  details: [],
  orderOrigin: 'Web',
  customerOrderId: '',
  customerMiscData: '',
  shippingMethod: 'Ground',
  shippingCompany: '',
};
export const initialValuesThirdShipTo: AddressModel = {
  name: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
  countryCode: '',
  locationId: 0,
};
