import { Fragment } from 'react';
import { AuditTrailModel } from '../models/AuditTrailModel';

interface Props {
  audit: AuditTrailModel;
}
export function AvailabilityDetails({ audit }: Props) {
  return (
    <Fragment>
      {!!audit.account && (
        <div className='card-body px-10'>
          <div className='row mb-4'>
            {!!audit.account && (
              <div className='col-4'>
                <div className='form-label'>Account</div>
                <span className='text-muted'>{audit.account}</span>
              </div>
            )}
            {!!audit.client && (
              <div className='col-4'>
                <div className='form-label'>Client</div>
                <span className='text-muted'>{audit.client}</span>
              </div>
            )}
            {!!audit.franchise && (
              <div className='col-4'>
                <div className='form-label'>Franchise</div>
                <span className='text-muted'>{audit.franchise}</span>
              </div>
            )}
          </div>

          {!!audit.location && (
            <div className='row mb-4'>
              <div className='col-6'>
                <div className='form-label'>Location</div>
                <span className='text-muted'>{audit.location}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}
