import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';

export function HomePage() {
  const intl = useIntl();
  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.HOME' })}</PageTitle>
    </>
  );
}
