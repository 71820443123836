import { Fragment, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import {
  initialFilterLocationInventory,
  queryParamsLocationInventoryConfig,
} from '../../utils/utils';
import inventoryServices from '../../services/InventoryServices';
import { useDebouncedCallback } from 'use-debounce/lib';
import { RootState } from '../../../../redux';
import { shallowEqual, useSelector } from 'react-redux';
import ListComponent from '../../../../components/ListComponent';
import { useQueryParamsReplace } from '../../../../utils/useQueryParamsReplace';
import { GroupModel } from '../../../users/models/GroupModel';
import { InventoryModel } from '../../models/InventoryModel';
import { LocationEditInventory } from './LocationEditInventory';
import { LocationInventoryMasterFilter } from './LocationInventoryMasterFilter';
import { UserGroupRelationModel } from '../../../auth/models/UserGroupRelationModel';

export function LocationInventoryMasterList() {
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  // Pagination, Filter and Query Params
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<InventoryModel[]>([]);
  const [filter, setFilter] = useState('');
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );

  const [queryParams, setQueryReplace] = useQueryParamsReplace(
    queryParamsLocationInventoryConfig
  );
  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<InventoryModel>[] = useMemo(
    () => [
      {
        name: 'Product Code',
        cell: (row) => {
          return (
            <>
              <div className='col-9'>{row.productCode} </div>
              {row.currentLevel +
                (!!row.qtyOnOrder ? row.qtyOnOrder : 0) +
                (!!row.qtyOnBackorder ? row.qtyOnBackorder : 0) <=
                row.lowLevel && (
                <div className='col-3'>
                  <span className='badge badge-danger mx-3'>Low</span>
                </div>
              )}
            </>
          );
        },
        sortable: true,
        sortField: 'productCode',
        wrap: true,
      },
      {
        name: 'Description',
        selector: (row) => row.productDescription,
        sortable: true,
        sortField: 'description',
        wrap: true,
      },
      {
        name: 'Location',
        selector: (row) => row.locationName,
        sortable: true,
        sortField: 'location',
        wrap: true,
      },
      {
        name: 'Optimum',
        selector: (row) => (row.optimumLevel ? row.optimumLevel : 0),
        sortable: true,
        sortField: 'optimum',
        center: true,
      },
      {
        name: 'Low',
        selector: (row) => (row.lowLevel ? row.lowLevel : 0),
        sortable: true,
        sortField: 'low',
        center: true,
      },
      {
        name: 'Current',
        selector: (row) => (row.currentLevel ? row.currentLevel : ''),
        sortable: true,
        sortField: 'current',
        center: true,
      },
      {
        name: 'On Order',
        selector: (row) => (row.qtyOnOrder ? row.qtyOnOrder : ''),
        sortable: true,
        sortField: 'ordered',
        center: true,
      },
      {
        name: 'Recent Order',
        selector: (row) =>
          row.mostRecentOrderQty ? row.mostRecentOrderQty : '',
        sortable: true,
        sortField: 'recentOrder',
        center: true,
      },
      {
        name: 'Actions',
        button: true,
        cell: (row) =>
          row.id && (
            <>
              <Fragment>
                {group.locationPermission?.view &&
                  group.locationPermission?.update && (
                    <button
                      onClick={() =>
                        handleShowUpdateModal(row.id, row.locationId)
                      }
                      className='btn btn-icon btn-bg-light btn-active-primary btn-sm me-1'
                      data-bs-toggle='tooltip'
                      title='Edit'
                    >
                      <i className='las la-edit fs-2'></i>
                    </button>
                  )}
              </Fragment>
            </>
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //Initialization methods
  useEffect(() => {
    if (history.location.search === '') {
      const newQueryParams = {
        ...initialFilterLocationInventory,
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };

      //&& location) {
      setQueryReplace(newQueryParams);
    }
    if (queryParams.filter) setFilter(queryParams.filter);
    // Call to list inventory if and only if there are location id.
    if (queryParams.pageNumber) {
      findAllInventory(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findAllInventory = function (queryParams: any) {
    inventoryServices.findAllInventory(queryParams).then((response) => {
      setEntities(response.data.items);
      setTotalCount(response.data.totalCount);
    });
  };

  const debounced = useDebouncedCallback((params: any) => {
    const newQueryParams = { ...queryParams };
    if (params.key === 'filter') {
      let filter = params.value;
      newQueryParams.filter = filter;
      newQueryParams.pageNumber = 1;
      setQueryReplace(newQueryParams);
    }
  }, 800);
  const onFilterChange = (e: any, key: string) => {
    if (key === 'filter') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };

  // eslint-disable-next-line
  const [selectedId, setSelectedId] = useState<number>(0);
  const [locationId, setLocationId] = useState<number>(0);
  // eslint-disable-next-line
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  // eslint-disable-next-line
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setSelectedId(0);
    setLocationId(0);
  };
  const handleShowUpdateModal = (id: number, locationId: number) => {
    setShowUpdateModal(true);
    setSelectedId(id);
    setLocationId(locationId);
  };
  const refetch = () => {
    findAllInventory(queryParams);
  };

  return (
    <>
      <div
        style={{
          borderTopRightRadius: '0px',
          borderTopLeftRadius: '0px',
        }}
        className='card'
      >
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            {group.locationPermission?.list && (
              <div className='d-flex align-items-center position-relative my-1'>
                <i className='las la-search position-absolute ms-6 fs-2'></i>
                <input
                  autoComplete='none'
                  type='search'
                  className='form-control form-control-solid w-350px ps-15'
                  value={filter}
                  placeholder='Search by Product Code or Description'
                  onChange={(e) => onFilterChange(e, 'filter')}
                />
              </div>
            )}
          </div>
          <div className='card-toolbar'>
            {group.locationPermission?.list && (
              <LocationInventoryMasterFilter
                queryParams={queryParams}
                setQueryParams={setQueryReplace}
              />
            )}
          </div>
        </div>

        <div className='card-body p-9'>
          <ListComponent
            table={{
              columns,
              data: entities,
              paginationTotalRows: totalCount,
            }}
            queryParams={queryParams}
            setQueryParams={setQueryReplace}
            notPermissions={!group.locationPermission?.list}
          />
        </div>
      </div>
      {showUpdateModal && (
        <LocationEditInventory
          refetch={refetch}
          inventoryId={selectedId}
          locationId={locationId}
          show={showUpdateModal}
          onHide={handleCloseUpdateModal}
        />
      )}
    </>
  );
}
