import { useIntl } from 'react-intl';
import { ProductItemSchemaValidation } from '../utils/additemSchema';
import { ErrorMessage, Field, Formik } from 'formik';
import { KitProductItem } from '../utils/utils';
import { KitProductSelect } from './KitProductSelect';

export interface CascadeProductSelectionsProps {
  addProduct: (data: KitProductItem) => void;
  kitId: number;
  idsCompatibles: number[];
}

export function KitCascadeProductSelections({
  addProduct,
  idsCompatibles,
}: CascadeProductSelectionsProps) {
  const intl = useIntl();
  const initialValues = {
    kitId: 0,
    productId: 0,
    isRequired: false,
    isPrimary: false,
    type: '',
  };

  const addItem = async (values: any, actions: any) => {
    addProduct({
      ...values,
      ...{
        code: values.product.code,
        type: values.product.productType,
        shippingUnitSize: values.product.shippingUnitSize,
      },
    });
  };

  return (
    <>
      <Formik
        onSubmit={(values, actions) => {
          addItem(values, actions);
          actions.resetForm();
          actions.setSubmitting(false);
        }}
        validationSchema={ProductItemSchemaValidation(intl)}
        initialValues={initialValues}
      >
        {(formik) => (
          <>
            <div className='row'>
              <div className='col-4'>
                <KitProductSelect
                  isRequired={true}
                  id='productId'
                  name='productId'
                  type='text'
                  label='Product'
                  addProduct
                  idsCompatibles={idsCompatibles}
                />
                <input type='hidden' name={`type`} />
                <input type='hidden' name={`code`} />
              </div>
              <div className='col-3 form-group'>
                <label className='form-label w-100 text-center mb-4'>
                  Required
                </label>
                <div className='text-center'>
                  <Field
                    className={`form-check-input me-1 ${
                      formik.values.isRequired ? 'bg-primary' : ''
                    }`}
                    name='isRequired'
                    type='checkbox'
                    onChange={formik.handleChange}
                    checked={formik.values.isRequired}
                  />
                </div>
              </div>
              <div className='col-3 form-group'>
                <label className='form-label w-100 text-center mb-4'>
                  Primary
                </label>
                <div className='text-center'>
                  <Field
                    className={`form-check-input me-1 ${
                      formik.values.isPrimary ? 'bg-primary' : ''
                    }`}
                    name='isPrimary'
                    type='checkbox'
                    onChange={formik.handleChange}
                    checked={formik.values.isPrimary}
                  />
                </div>
              </div>
              <div className='col-2'>
                <div className='text-end'>
                  <button
                    type='button'
                    className='btn btn-primary mt-7 p-3'
                    onClick={() => formik.submitForm()}
                    disabled={formik.isSubmitting}
                  >
                    <i className='las la-plus fs-2'></i>Add
                  </button>
                </div>
              </div>
            </div>

            <div className='row mt-5 ms-0'>
              <div className='col-12'>
                <ErrorMessage name='productId'>
                  {(msg) => <div className='text-danger'>{msg}</div>}
                </ErrorMessage>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
