import axios from 'axios';
import { PagedResult } from '../../../models/pagedResult';
import { CardItemModel } from '../models/CardItemModel';
import { MFDataUploadModel } from '../models/MFDataUploadModel';
import { ManufacturerDataValidateHeaderModel } from '../models/MFDataValidateHeaderModel';

export const MFDATA_URL = `/api/v1/manufacturerData`;

const mfDataServices = {
  findMFData: (queryParams: any) => {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(`${MFDATA_URL}/all`, { params: params });
  },
  getCards: () => {
    return axios.get<CardItemModel[]>(`${MFDATA_URL}/cards`);
  },
  validateFile: (body: MFDataUploadModel) => {
    const formData = new FormData();
    formData.append('file', body.file as any);
    return axios.post<ManufacturerDataValidateHeaderModel>(
      `${MFDATA_URL}/headerValidator/${body.productId}`,
      formData
    );
  },
  importMFDataFile: (body: MFDataUploadModel) => {
    const formData = new FormData();
    formData.append('file', body.file as any);
    return axios.post(`${MFDATA_URL}/${body.productId}`, formData);
  },
};

export default mfDataServices;
