import { useIntl } from 'react-intl';
interface Props {
  labelSubmit: string;
  labelDiscard?: string;
  showCancelButton?: boolean;
  onCancel?: () => void;
  disableSubmit?: boolean;
}
export function ActionsComponent({
  labelSubmit,
  labelDiscard,
  showCancelButton,
  disableSubmit,
  onCancel,
}: Props) {
  const intl = useIntl();
  return (
    <div className='text-end'>
      {showCancelButton && (
        <button
          type='button'
          id='kt_modal_new_card_cancel'
          className='btn btn-danger me-3'
          onClick={() => onCancel && onCancel()}
        >
          {!labelDiscard
            ? intl.formatMessage({ id: 'ACTION.CANCEL' })
            : labelDiscard}
        </button>
      )}

      <button
        type='submit'
        id='kt_modal_new_card_submit'
        className='btn btn-primary'
        disabled={!!disableSubmit}
      >
        <span className='indicator-label'>{labelSubmit}</span>
      </button>
    </div>
  );
}
