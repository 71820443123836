import React from 'react';
import { useField, useFormikContext } from 'formik';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';

export function CustomMultiSelect({ ...props }) {
  const [field] = useField(props);
  const { setFieldValue, errors, touched } = useFormikContext();
  const path = field.name.split('.');
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <Select
        {...field}
        {...props}
        value={
          props.options[props.options.findIndex((x) => x.value === field.value)]
        }
        onChange={(val) => {
          // Is Clerable case
          const selectValue =
            val === null ? null : val.hasOwnProperty('value') ? val.value : val;
          setFieldValue(field.name, selectValue);
        }}
      />
      {getPath(path, errors) && getPath(path, touched) && (
        <div
          style={{ fontSize: '0.9rem', fontWeight: '400' }}
          className='text-danger'
        >
          {props.options.length > 0 ? (
            getPath(path, errors)
          ) : (
            <FormattedMessage id='COMMON.GENERAL.SELECT.NO.OPTIONS' />
          )}
        </div>
      )}
    </>
  );
}

function getPath(paths, touched) {
  const location = paths.reduce((object, path) => {
    return (object || {})[path];
  }, touched);
  return location;
}
