import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const warehouseSchemaValidation = (intl: IntlShape) =>
  Yup.object().shape({
    name: Yup.string()
      .ensure()
      .trim()
      .max(
        100,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 100 })
      )
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    description: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 200 })
      ),
    addressLine1: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 200 })
      ),
    addressLine2: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 200 })
      ),
    city: Yup.string()
      .ensure()
      .trim()
      .max(
        100,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 100 })
      ),
    state: Yup.string()
      .ensure()
      .trim()
      .max(
        100,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 100 })
      ),
    postalCode: Yup.string()
      .ensure()
      .trim()
      .max(
        20,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 20 })
      ),
  });
