import { Fragment, useCallback, useEffect, useState } from 'react';
import { CustomInput } from '../../../components/CustomInput';
import { CountryOptionsModel } from '../../../models/CountryOptionsModel';
import { CustomSelect } from '../../../components/CustomSelect';
import { useFormikContext } from 'formik';
import { LocationAddress } from '../../entities/models/LocationModel';
import locationsServices from '../../entities/services/LocationsServices';
import franchisesServices from '../../entities/services/FranchiseServices';
import { ValueLabelPair } from '../../../models/ValueLabelPair';

export function AddressForm() {
  const [countries, setCountries] = useState<CountryOptionsModel[]>([]);
  const [addressList, setAddressList] = useState<ValueLabelPair[]>([]);
  const { setFieldValue } = useFormikContext<LocationAddress>();
  // List Countries
  const countryList = useCallback(() => {
    locationsServices.getCountries().then((values) => {
      const body = values.data;
      const countryOptions: CountryOptionsModel[] = body.map((x) => {
        return { value: x.countryName, label: x.countryName, ...x };
      });
      setCountries(countryOptions);
    });
  }, []);
  // List locations by client
  const addressThirdList = useCallback(() => {
    franchisesServices.locationsAddressThirdParty().then((res) => {
      const body: ValueLabelPair[] = res.data.map((x) => {
        return { value: x.id, label: x.name };
      });
      setAddressList(body);
    });
  }, []);
  // Get address third list
  const getlocationAddress = useCallback((locationId: number) => {
    locationsServices.getLocation(locationId).then((res) => {
      if (res.data && res.data.addresses && res.data.addresses.length > 0) {
        setFieldValue('name', res.data.addresses[0].name);
        setFieldValue('addressLine1', res.data.addresses[0].addressLine1);
        setFieldValue('addressLine2', res.data.addresses[0].addressLine2);
        setFieldValue('country', res.data.addresses[0].country);
        setFieldValue('state', res.data.addresses[0].state);
        setFieldValue('city', res.data.addresses[0].city);
        setFieldValue('postalCode', res.data.addresses[0].postalCode);
        setFieldValue('countryCode', res.data.addresses[0].countryCode);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    countryList();
  }, [countryList]);
  useEffect(() => {
    addressThirdList();
  }, [addressThirdList]);

  // Set countryCode
  const handleCountryChange = (name: string, option: CountryOptionsModel) => {
    setFieldValue('countryCode', option.alpha3);
  };
  // Fills in the form rows
  const handleThirdPartyAddressChange = (
    name: string,
    option: ValueLabelPair
  ) => {
    const selectValue = option && option.value ? option.value : null;
    if (selectValue) {
      getlocationAddress(Number(selectValue));
    }
  };
  return (
    <Fragment>
      <div className='row'>
        <div className='col-12'>
          <CustomSelect
            id='locationId'
            options={addressList}
            name='locationId'
            placeholder='3rd party adresses'
            onChangeOption={handleThirdPartyAddressChange}
          />
        </div>
      </div>
      <div className='row pt-4'>
        <div className='col-12'>
          <CustomInput
            isRequired={true}
            id='name'
            type='text'
            name='name'
            label='Name'
          />
        </div>
      </div>
      <div className='row pt-4'>
        <div className='col-6'>
          <CustomInput
            isRequired={true}
            id='addressLine1'
            type='text'
            name='addressLine1'
            label='Address Line 1'
          />
        </div>
        <div className='col-6'>
          <CustomInput
            id='addressLine2'
            type='text'
            name='addressLine2'
            label='Address Line 2'
          />
        </div>
      </div>

      <div className='row pt-4'>
        <div className='col-6'>
          <CustomSelect
            isRequired={true}
            id='country'
            options={countries}
            name='country'
            label='Country'
            placeholder='Select Country'
            onChangeOption={handleCountryChange}
          />
        </div>

        <div className='col-6'>
          <CustomInput
            isRequired={true}
            id='state'
            type='text'
            name='state'
            label='State'
          />
        </div>
      </div>
      <div className='row pt-4'>
        <div className='col-6'>
          <CustomInput
            isRequired={true}
            id='city'
            type='text'
            name='city'
            label='City'
          />
        </div>
        <div className='col-6'>
          <CustomInput
            isRequired={true}
            id='postalCode'
            type='text'
            name='postalCode'
            label='Postal Code'
          />
        </div>
      </div>
    </Fragment>
  );
}
