import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { OrderCreate, OrderCreateModel } from '../models/OrderCreateModel';
import { OrderDetails } from '../models/OrderDetailsCreateModel';
import { ProductOrderModel } from '../models/ProductOrderModel';

export const productsAndKitsHelpers = {
  processingItems: (
    ugr: UserGroupRelationModel,
    items: ProductOrderModel[]
  ) => {
    const isAdmin = !!ugr.accountId ? false : true;
    const newItems: ProductOrderModel[] = [];
    items.forEach((item) => {
      //if the user is not admin and the product is not web orderable, it is not displayed in the list.
      if (item.webOrderable || isAdmin) {
        newItems.push(item);
      }
    });

    // Place out-of-stock items at the end of the list
    const nonStockItems: ProductOrderModel[] = [];
    const inStockItems: ProductOrderModel[] = [];
    newItems.forEach((item) => {
      const updatedItem: ProductOrderModel = {
        ...item,
        customId: `${item.type}.${item.entityId}`,
      };
      if (item.quantityOnStock === 0) {
        inStockItems.push(updatedItem);
      } else {
        nonStockItems.push(updatedItem);
      }
    });
    return nonStockItems.concat(inStockItems);
  },
  processRequest: (order: OrderCreateModel) => {
    const orderProccess: OrderCreate = {
      locationId: order.locationId,
      clientId: order.clientId,
      details: order.details.map((x) => {
        const data: OrderDetails = {
          entityId: x.entityId,
          quantity: x.quantity,
          activeCards: x.activeCards,
          isKitEntity: x.isKitEntity,
        };
        if (data.activeCards) {
          data.cardValue = x.cardValue;
        }
        return data;
      }),
      orderOrigin: order.orderOrigin,
      customerOrderId: order.customerOrderId,
      customerMiscData: order.customerMiscData,
      shippingCompany: order.shippingCompany,
      shippingMethod: order.shippingMethod,
    };
    return orderProccess;
  },
};
