import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Select from 'react-select/dist/declarations/src/Select';
import ReactSelect from 'react-select';
import { entityServices } from '../../users/services/EntityServices';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { EntityFiltersModel } from '../../../models/EntityFiltersModel';
import { LocationSelectModel } from '../../users/models/LocationSelectModel';
export interface ValueLabelPair {
  value: number;
  label: string;
}

const keyLabel = 'cascadeselects';
interface PropsFilter {
  entityFilters: any;
  setEntityFilters: any;
}
export function CascadeEntitySelections({
  entityFilters,
  setEntityFilters,
}: PropsFilter) {
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  const [accounts, setAccounts] = useState<ValueLabelPair[]>([]);
  const [clients, setClients] = useState<ValueLabelPair[]>([]);
  const [locations, setLocations] = useState<ValueLabelPair[]>([]);

  const [locationsAllData, setLocationsAllData] = useState<
    LocationSelectModel[]
  >([]);

  //Clear refs
  const accountRef = useRef<null | Select>(null);
  const clientsRef = useRef<null | Select>(null);
  const locationsRef = useRef<null | Select>(null);
  // Disable Flags
  const [d1, setD1] = useState(true);
  const [d2, setD2] = useState(true);

  // Get initial values from the url
  const accountsList = useCallback(() => {
    entityServices.getAccounts().then((values) => {
      const body = values.data;
      const items: ValueLabelPair[] =
        body.length > 0
          ? body.map((x) => {
              return { value: x.id, label: x.accountName };
            })
          : [];
      setAccounts(items);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const clientList = useCallback((accountId: number) => {
    entityServices.getClients(accountId).then((values) => {
      const body = values.data;
      const items: ValueLabelPair[] =
        body.length > 0
          ? body.map((x) => {
              return { value: x.id, label: x.name };
            })
          : [];
      setClients(items);
      setD1(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const locationsList = useCallback((clientId: number) => {
    entityServices
      .getLocationsFromClientWithoutThirdParty(clientId)
      .then((values) => {
        setLocationsAllData(values.data);
        const body = values.data;
        const items: ValueLabelPair[] =
          body.length > 0
            ? body.map((x) => {
                return { value: x.id, label: x.name };
              })
            : [];
        setLocations(items);

        setD2(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAccountChange = (option: any) => {
    if (option) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        accountId: option.value,
      }));
      clientList(option.value);
    } else {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        accountId: undefined,
      }));
    }
    // Clear Refs 2 .. N
    clientsRef.current?.clearValue();
    locationsRef.current?.clearValue();
    setD1(true);
    setD2(true);
  };
  const handleClientChange = (option: any) => {
    if (option) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        clientId: option.value,
      }));
      locationsList(option.value);
    } else {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        clientId: undefined,
      }));
    }
    // Clear Refs 3 .. N
    locationsRef.current?.clearValue();
    setD2(true);
  };

  const handleLocationChange = (option: any) => {
    if (option) {
      const location = locationsAllData.find(
        (element) => (element.id = option.value)
      );
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        locationId: option.value,
        franchiseId: location?.franchiseId,
      }));
    } else {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        locationId: undefined,
        franchiseId: undefined,
      }));
    }
  };

  useEffect(() => {
    if (userGroupRelations.accountId === null) {
      accountsList();
    }
    if (entityFilters.accountId) {
      clientList(entityFilters.accountId);
    }
    if (entityFilters.clientId) {
      locationsList(entityFilters.clientId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clients.length === 1) handleClientChange(clients[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);
  useEffect(() => {
    if (accounts.length === 1) handleAccountChange(accounts[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);
  useEffect(() => {
    if (locations.length === 1) handleLocationChange(locations[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  return (
    <Fragment>
      {!userGroupRelations.accountId && (
        <div className='col-md-3'>
          <ReactSelect
            placeholder='Account'
            value={
              accounts[
                accounts.findIndex((x) => x.value === entityFilters.accountId)
              ]
            }
            name={`${keyLabel}.accountId`}
            id={`${keyLabel}.accountId`}
            key={`${keyLabel}.accountId`}
            options={accounts}
            onChange={handleAccountChange}
            ref={accountRef}
          />
        </div>
      )}
      {!userGroupRelations.clientId && (
        <div className='col-md-3'>
          <ReactSelect
            placeholder='Client'
            key={`${keyLabel}.clientId`}
            id={`${keyLabel}.clientId`}
            options={clients}
            name={`${keyLabel}.clientId`}
            ref={clientsRef}
            value={
              clients[
                clients.findIndex((x) => x.value === entityFilters.clientId)
              ]
            }
            isDisabled={d1}
            onChange={handleClientChange}
          />
        </div>
      )}

      {!userGroupRelations.locationId && (
        <div className='col-md-3'>
          <ReactSelect
            placeholder='Location'
            isClearable={!userGroupRelations.locationId ? true : false}
            id={`${keyLabel}.locationId`}
            name={`${keyLabel}.locationId`}
            key={`${keyLabel}.locationId`}
            options={locations}
            ref={locationsRef}
            value={
              locations[
                locations.findIndex((x) => x.value === entityFilters.locationId)
              ]
            }
            isDisabled={d2}
            onChange={handleLocationChange}
          />
        </div>
      )}
    </Fragment>
  );
}
