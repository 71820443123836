import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { WarehouseModel } from '../models/WarehouseModel';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../redux';
import { GroupModel } from '../../users/models/GroupModel';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { NavTabs } from '../../../components/NavTabs';
import { CustomBadge } from '../../../components/CustomBadge';
import warehouseServices from '../services/WarehousesServices';
import { WarehouseEdit } from './WarehouseEdit';
import { WarehouseInventoriesList } from './WarehouseInventoryList';
import { StockAdjustmentList } from './StockAdjustmentList';
import { toast } from 'react-toastify';

interface Params {
  warehouseId: string;
}

export function WarehouseView() {
  const { warehouseId } = useParams<Params>();
  const [warehouse, setWarehouse] = useState<WarehouseModel | null>(null);

  useEffect(() => {
    if (!warehouse) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId, warehouse]);

  const refetch = () => {
    warehouseServices.getWarehouse(Number(warehouseId)).then((res) => {
      setWarehouse(res.data);
    });
  };

  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );

  const intl = useIntl();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    refetch();
  };
  const handleShowUpdateModal = () => {
    setShowUpdateModal(true);
  };

  const menus = [
    {
      title: 'Inventory',
      path: `/warehouses/${warehouseId}`,
      isActive: true,
    },
    {
      title: 'Stock Adjustments',
      path: `/warehouses/${warehouseId}/stockadjustments`,
      isActive: false,
    },
  ];
  const history = useHistory();
  const goBack = () => {
    //history.push(`/warehouses`);
    history.goBack();
  };

  // Run Number
  const [runNumber, setRunNumber] = useState('');
  useEffect(() => {
    warehouseServices.getRunNumber().then((resp) => {
      setRunNumber(resp.data.runNumber);
    });
  }, []);
  const handleGenerateRunNumber = () => {
    warehouseServices
      .generateRunNumber()
      .then((resp) => {
        setRunNumber(resp.data.runNumber);
        toast.success(
          `Generate new run number succefully (${resp.data.runNumber})`
        );
      })
      .catch(() => {
        toast.error('Generate new run number failed');
      });
  };

  // TODO: add skeleton
  if (!warehouse) {
    return <></>;
  }

  return (
    <>
      {group.warehousePermission?.update && showUpdateModal && (
        <WarehouseEdit
          id={warehouse.id}
          refetch={refetch}
          show={showUpdateModal}
          onHide={handleCloseUpdateModal}
        />
      )}

      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='row'>
                <div className='col-9'>
                  <div className='d-flex align-items-center'>
                    <div className='row'>
                      <div className='d-flex align-items-center'>
                        <div className='text-gray-800 text-hover-primary fs-1 fw-bolder me-1'>
                          {warehouse.name}
                        </div>
                        <div className='ms-4'>
                          <CustomBadge
                            status={!!warehouse.isActive}
                            activeText='Active'
                            inactiveText='Inactive'
                          />
                          {warehouse.deactivationReason && (
                            <span className='text-muted ms-4'>
                              {warehouse.isActive
                                ? ''
                                : warehouse.deactivationReason}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='row my-1'>
                        <span className='col-md-6 fw-bolder fs-6 text-muted'>
                          Description
                        </span>
                        <span className='col-md-6  fw-bolder fs-6 text-dark'>
                          {warehouse.description}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='row my-1'>
                        <span className='col-md-6 fw-bolder fs-6 text-muted'>
                          City
                        </span>
                        <span className='col-md-6  fw-bolder fs-6 text-dark'>
                          {warehouse.city}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='row my-1'>
                        <span className='col-md-6 fw-bolder fs-6 text-muted'>
                          Address Line 1
                        </span>
                        <span className='col-md-6  fw-bolder fs-6 text-dark'>
                          {warehouse.addressLine1}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='row my-1'>
                        <span className='col-md-6 fw-bolder fs-6 text-muted'>
                          State
                        </span>
                        <span className='col-md-6  fw-bolder fs-6 text-dark'>
                          {warehouse.state}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='row my-1'>
                        <span className='col-md-6 fw-bolder fs-6 text-muted'>
                          Address Line 2
                        </span>
                        <span className='col-md-6  fw-bolder fs-6 text-dark'>
                          {warehouse.addressLine2}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='row my-1'>
                        <span className='col-md-6 fw-bolder fs-6 text-muted'>
                          Postal Code
                        </span>
                        <span className='col-md-6  fw-bolder fs-6 text-dark'>
                          {warehouse.postalCode}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-3'>
                  <div className='row'>
                    <div className='d-flex'>
                      <div style={{ marginLeft: 'auto' }}>
                        {goBack && (
                          <button
                            onClick={goBack}
                            className='btn btn-light btn-active-primary align-self-center'
                          >
                            <i className='fas fa-arrow-left'></i>
                            {intl.formatMessage({ id: 'ACTION.BACK' })}
                          </button>
                        )}

                        {group.warehousePermission?.update && (
                          <button
                            onClick={() => handleShowUpdateModal()}
                            className='m-2 btn  btn-primary btn-active-primary align-self-center'
                          >
                            <i className='las la-edit fs-2'></i>
                            {intl.formatMessage({ id: 'ACTION.EDIT' })}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='d-flex'>
                      <div style={{ marginLeft: 'auto' }}>
                        <button
                          onClick={() => handleGenerateRunNumber()}
                          className='m-2 btn  btn-success btn-active-primary align-self-center'
                        >
                          Generate RUN #
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='d-flex'>
                      <div
                        style={{
                          marginLeft: 'auto',
                          width: 170,
                          textAlign: 'center',
                        }}
                      >
                        <span className='text-muted ms-4'>LAST RUN # </span>
                        <span className='fw-bolder fs-6'>{runNumber}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {group.warehouseInventoryPermission?.list && (
            <div className='d-flex overflow-auto h-55px'>
              <NavTabs submenu={menus} />
            </div>
          )}
          <Switch>
            <Route path='/warehouses/:warehouseId' exact>
              {group.warehouseInventoryPermission?.list && (
                <WarehouseInventoriesList
                  warehouse={warehouse}
                ></WarehouseInventoriesList>
              )}
            </Route>
            <Route path='/warehouses/:warehouseId/stockadjustments' exact>
              {group.warehouseInventoryPermission?.update && (
                <StockAdjustmentList
                  warehouse={warehouse}
                ></StockAdjustmentList>
              )}
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
}
