import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  requests: Array<any>(),
  noLoading: false,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: initialState,
  reducers: {
    startCall: (state, action) => {
      state.requests.push(action.payload.url);
      state.isLoading = true;
      state.noLoading = action.payload.noLoading;
    },
    endCall: (state, action) => {
      const i = state.requests.indexOf(action.payload.url);
      if (i >= 0) {
        state.requests.splice(i, 1);
      }
      if (state.requests.length === 0) {
        state.noLoading = true;
      }
    },
  },
});
