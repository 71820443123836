import axios from 'axios';
import { PagedResult } from '../../../models/pagedResult';
import { AuditTrailModel } from '../models/AuditTrailModel';

export const AUDIT_URL = `/api/v1/AuditTrails`;

export const auditServices = {
  findAuditTrails: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    console.table(params);
    return axios.get<PagedResult>(AUDIT_URL, { params: params });
  },

  getAuditTrail: (id: number) => {
    return axios.get<AuditTrailModel>(`${AUDIT_URL}/${id}`);
  },
  eventTypeList: () => {
    return axios.get<string[]>(`${AUDIT_URL}/event-types`);
  },
  logTypeList: () => {
    return axios.get<string[]>(`${AUDIT_URL}/log-types`);
  },
};
