import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

const CardAttributes = (intl: IntlShape) =>
  Yup.object().shape({
    cardCategory: Yup.string()
      .ensure()
      .trim()
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    baseProductId: Yup.number().when('isBaseStock', {
      is: true,
      then: Yup.number()
        .positive(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
        .min(1, intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
        .integer(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    }),
    maskingFormat: Yup.string()
      .ensure()
      .trim()
      .min(
        3,
        intl.formatMessage({ id: 'VALIDATION.MIN_LENGTH_FIELD' }, { min: 3 })
      )
      .max(
        30,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 30 })
      )
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    valueType: Yup.string()
      .ensure()
      .trim()
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    value: Yup.number().when(['valueType', 'canActivate'], {
      is: (valueType: string, canActivate: boolean) =>
        valueType === 'Fixed' && canActivate,
      then: Yup.number()
        .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
        .moreThan(
          0,
          intl.formatMessage({ id: 'VALIDATION.MIN.NUMBER' }, { min: 0 })
        )
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    }),
    minValue: Yup.number().when(['valueType', 'canActivate'], {
      is: (valueType: string, canActivate: boolean) =>
        valueType === 'Range' && canActivate,
      then: Yup.number()
        .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
        .min(0, intl.formatMessage({ id: 'VALIDATION.MIN.NUMBER' }, { min: 0 }))
        .nullable(true)
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    }),
    maxValue: Yup.number().when(['valueType', 'canActivate'], {
      is: (valueType: string, canActivate: boolean) =>
        valueType === 'Range' && canActivate,
      then: Yup.number()
        .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
        .moreThan(Yup.ref('minValue'), 'Must be greater than Min Value')
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    }),
    canActivate: Yup.boolean().required(
      intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
    ),
    mfDataRequired: Yup.boolean().required(
      intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
    ),
  });

export const CreateProductSchemaStep2 = (intl: IntlShape) =>
  Yup.object().shape({
    cardAttributes: CardAttributes(intl),
  });
