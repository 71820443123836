import DataTable, {
  SortOrder,
  TableColumn,
  TableProps,
} from 'react-data-table-component';
import { Pagination } from './Pagination';
import { isEqual } from 'lodash';
import { TablePermissionWarning } from './TablePermissionWarning';

interface Props<T> {
  setQueryParams: any;
  queryParams: any;
  table: TableProps<T>;
  customStyles?: any;
  paginationServer?: boolean;
  sortServer?: boolean;
  notPermissions?: boolean;
}
const styles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontSize: '14px',
      color: '#a1a5b7',
    },
  },
  cells: {
    style: {},
  },
  pagination: {
    style: {
      justifyContent: 'flex-start',
    },
  },
};
const paginationRowsPerPageOptions: number[] = [10, 20, 30, 50, 100];

function ListComponent<T>(table: Props<T>) {
  const {
    queryParams,
    setQueryParams,
    table: props,
    customStyles,
    paginationServer = true,
    sortServer = true,
    notPermissions = false,
  } = table;
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    // Update pageSize
    const newQueryParams = {
      ...queryParams,
      pageNumber: 1,
      pageSize: newPerPage,
    };
    if (!isEqual(queryParams, newQueryParams)) {
      setQueryParams!(newQueryParams);
    }
  };
  const handlePageChange = (page: number) => {
    // Update pageNumber
    const newQueryParams = { ...queryParams, pageNumber: page };
    if (!isEqual(queryParams, newQueryParams)) {
      setQueryParams!(newQueryParams);
    }
  };
  const handleSort = (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    const newQueryParams = {
      ...queryParams,
      sortDirection: sortDirection === 'asc' ? 1 : -1,
      sortColumn: selectedColumn.sortField ? selectedColumn.sortField : 'id',
    };
    if (!isEqual(queryParams, newQueryParams)) {
      setQueryParams!(newQueryParams);
    }
  };
  if (notPermissions) {
    return <TablePermissionWarning />;
  }

  return (
    <DataTable
      highlightOnHover={props.highlightOnHover}
      pointerOnHover={props.pointerOnHover}
      onRowClicked={props.onRowClicked}
      columns={props.columns}
      data={props.data}
      pagination
      paginationComponent={Pagination}
      paginationServer={paginationServer}
      paginationTotalRows={props.paginationTotalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      onSort={handleSort}
      sortServer={sortServer}
      customStyles={{ ...styles, ...customStyles }}
      paginationDefaultPage={
        queryParams.pageNumber ? queryParams.pageNumber : 1
      }
      paginationPerPage={queryParams.pageSize ? queryParams.pageSize : 10}
      paginationRowsPerPageOptions={paginationRowsPerPageOptions}
    />
  );
}
export default ListComponent;
