import axios from 'axios';
import { AccountModel } from '../../entities/models/AccountModel';
import { ACCOUNTS_URL } from '../../entities/services/AccountsServices';
import { CLIENTS_URL } from '../../entities/services/ClientsServices';
import { FRANCHISES_URL } from '../../entities/services/FranchiseServices';
import { EntitySelectModel } from '../models/EntitySelectModel';

export const entityServices = {
  getAccounts: () => {
    return axios.get<AccountModel[]>(`${ACCOUNTS_URL}/all`);
  },
  getClients: (accountId: number) => {
    return axios.get<EntitySelectModel[]>(
      `${ACCOUNTS_URL}/${accountId}/clients`
    );
  },
  getFranchises: (clientId: number) => {
    return axios.get<EntitySelectModel[]>(
      `${CLIENTS_URL}/${clientId}/franchises`
    );
  },
  getLocations: (franchiseId: number) => {
    return axios.get<EntitySelectModel[]>(
      `${FRANCHISES_URL}/${franchiseId}/locations`
    );
  },
  getLocationsWithoutThirdParty: (franchiseId: number) => {
    return axios.get<EntitySelectModel[]>(
      `${FRANCHISES_URL}/${franchiseId}/locations/without-third-party`
    );
  },
  getLocationsFromClientWithoutThirdParty: (clientId: number) => {
    return axios.get<EntitySelectModel[]>(
      `${CLIENTS_URL}/${clientId}/locations/without-third-party`
    );
  },
};
