import { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PageLink } from '../../../../_metronic/layout/core';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { IsActiveComponent } from '../../../components/IsActiveComponent';
import { NavTabs } from '../../../components/NavTabs';
import { ViewImages } from '../../../components/ViewImages';
import { RootState } from '../../../redux';
import { GroupModel } from '../../users/models/GroupModel';
import { ProductModel } from '../models/ProductModel';
import productServices from '../services/ProductsServices';
import { ProductViewCardAttributes } from './ProductViewCardAttributes';
import { ProductViewCardDetails } from './ProductViewCardDetails';
import { ProductEdit } from './ProductEdit';
import { ViewAvailabilities } from '../../../components/ViewAvailabilities';
import { CustomBadge } from '../../../components/CustomBadge';

interface Params {
  productId: string;
}
const getMenu = (product: ProductModel, productId: number) => {
  const menus: PageLink[] = [
    {
      title: 'General',
      path: `/products/${product.id}`,
      isActive: true,
    },
  ];
  if (product.cardAttributesId) {
    menus.push({
      title: 'Card Attributes',
      path: `/products/${productId}/card-attributes`,
      isActive: false,
    });
  }
  menus.push({
    title: 'Product Images',
    path: `/products/${productId}/images`,
    isActive: false,
  });
  menus.push({
    title: 'Availability',
    path: `/products/${productId}/availabilities`,
    isActive: false,
  });
  return menus;
};
export function ProductView() {
  const { productId } = useParams<Params>();
  const [product, setproduct] = useState<ProductModel | null>(null);

  useEffect(() => {
    if (!product) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, product]);

  const refetch = () => {
    productServices.getProduct(Number(productId)).then((res) => {
      setproduct(res.data);
    });
  };

  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );

  const intl = useIntl();

  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    refetch();
  };
  const handleShowEditModal = () => {
    setShowEditModal(true);
  };
  const [showCloneModal, setShowCloneModal] = useState(false);
  const handleCloseCloneModal = () => {
    setShowCloneModal(false);
  };
  const handleShowCloneModal = () => {
    setShowCloneModal(true);
  };
  const clone = () => {
    handleCloseCloneModal();
    product && productServices.clone(product.id);
  };
  const history = useHistory();
  const goBack = () => {
    //history.push('/products');
    history.goBack();
  };
  const setActivate = (productId: number) => {
    productServices.activateProduct(productId).then(() => {
      toast.success('Successful activation');
      refetch();
    });
  };

  const setDeactivate = (productId: number, reason?: string) => {
    productServices.deactivateProduct(productId, reason).then(() => {
      toast.success('Successful deactivation');
      refetch();
    });
  };
  const [showActivateModal, setShowActivateModal] = useState(false);
  const handleCloseActivateModal = () => {
    setShowActivateModal(false);
    refetch();
  };
  const handleShowActivateModal = () => {
    setShowActivateModal(true);
  };
  return (
    <Fragment>
      {group.productPermission?.update && product && (
        <IsActiveComponent
          id={Number(productId)}
          isActive={product.isActive}
          onHide={handleCloseActivateModal}
          show={showActivateModal}
          setDeactivate={setDeactivate}
          setActivate={setActivate}
        />
      )}
      {group.productPermission?.update && showEditModal && (
        <ProductEdit
          show={showEditModal}
          refetch={refetch}
          onHide={handleCloseEditModal}
          productId={Number(productId)}
        />
      )}
      {group.productPermission?.update && showCloneModal && (
        <ConfirmationModal
          message='Are you sure to clone this product?'
          show={showCloneModal}
          onCancel={handleCloseCloneModal}
          onAcept={clone}
        />
      )}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='d-flex align-items-center'>
                <div className='row'>
                  <div className='d-flex align-items-center'>
                    <div className='text-gray-800 text-hover-primary fs-1 fw-bolder me-1'>
                      {product?.code}
                    </div>
                    <div className='ms-4'>
                      <span
                        className={`badge ${
                          product?.isActive ? 'bg-success' : 'bg-secondary'
                        } `}
                      >
                        {product?.isActive ? 'Active' : 'Inactive'}
                      </span>
                      {product?.deactivationReason && (
                        <span className='text-muted ms-4'>
                          {product?.isActive ? '' : product.deactivationReason}
                        </span>
                      )}
                    </div>
                    {group.productPermission?.update && (
                      <div className='form-check form-switch form-check-custom  mx-5'>
                        <input
                          className={`form-check-input ${
                            product?.isActive ? 'bg-primary text-white' : ''
                          } `}
                          checked={product?.isActive}
                          type='checkbox'
                          onChange={() => handleShowActivateModal()}
                        />
                        <label
                          className={`form-check-label ${
                            product?.isActive ? 'text-muted' : 'text-muted'
                          } `}
                        >
                          {!product?.isActive ? 'Activate' : 'Deactivate'}
                        </label>
                      </div>
                    )}
                  </div>
                </div>

                <div style={{ marginLeft: 'auto' }}>
                  {goBack && (
                    <button
                      onClick={goBack}
                      className='btn btn-light btn-active-primary align-self-center'
                    >
                      <i className='fas fa-arrow-left'></i>
                      {intl.formatMessage({ id: 'ACTION.BACK' })}
                    </button>
                  )}

                  {group.productPermission?.create && (
                    <button
                      className='m-2 btn btn-light btn-active-primary align-self-center'
                      onClick={handleShowCloneModal}
                    >
                      <i className='fas fa-clone'></i>
                      Clone
                    </button>
                  )}

                  {group.productPermission?.update && (
                    <button
                      onClick={() => handleShowEditModal()}
                      className='m-2 btn  btn-primary btn-active-primary align-self-center'
                    >
                      <i className='las la-edit fs-2'></i>
                      {intl.formatMessage({ id: 'ACTION.EDIT' })}
                    </button>
                  )}
                </div>
              </div>

              <div>
                <div className='row my-1'>
                  <div className='fw-bolder fs-6 text-muted col'>Code</div>
                  <div className='fw-bolder fs-6 text-dark col-10'>
                    {product?.code}
                  </div>
                </div>

                <div className='row my-1'>
                  <div className='fw-bolder fs-6 text-muted col'>
                    Description
                  </div>
                  <div className='fw-bolder fs-6 text-dark  col-10'>
                    {' '}
                    {product?.description}
                  </div>
                </div>

                <div className='row my-1'>
                  <div className='fw-bolder fs-6 text-muted col'>Premium</div>
                  <div className='fw-bolder fs-6 text-dark col-10'>
                    <CustomBadge
                      status={!!product?.premium}
                      activeText='Yes'
                      inactiveText='No'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            {product && (
              <NavTabs submenu={getMenu(product, Number(productId))} />
            )}
          </div>

          <Switch>
            <Route path='/products/:productId' exact>
              {product && <ProductViewCardDetails product={product} />}
            </Route>
            <Route path='/products/:productId/card-attributes' exact>
              {product && <ProductViewCardAttributes product={product} />}
            </Route>
            <Route path='/products/:productId/images' exact>
              {product && <ViewImages files={product.files} />}
            </Route>
            <Route path='/products/:productId/availabilities' exact>
              {product && (
                <ViewAvailabilities availabilities={product.availabilities} />
              )}
            </Route>
          </Switch>
        </div>
      </div>
    </Fragment>
  );
}
