import { Fragment, useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select/dist/declarations/src/Select';
import { CustomFilter } from '../../../components/CustomFilter';
import ReactSelect from 'react-select';
import { initialFilterProducts } from '../utils/utils';
import { productsType } from './ProductInformationStep1';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { shallowEqual, useSelector } from 'react-redux';
import { selectStyles } from '../../../components/CustomSelect';
import { EntityFiltersModel } from '../../../models/EntityFiltersModel';
import { CustomBadgeFilter } from '../../../components/CustomBadgeFilter';
import { FilterBadge } from '../../../models/FilterBadge';
import { FilterCascadeEntitySelections } from '../../../components/FilterCascadeEntitySelection';
const activeOptions = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];
const binaryOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
interface PropsFilter {
  queryParams: any;
  setQueryParams: any;
}

export function ProductsFilter({ setQueryParams, queryParams }: PropsFilter) {
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  // Filter States
  const [isActive, setIsActive] = useState<boolean | null>();
  const [productType, setProductType] = useState<string | null>();
  const [autoReplenish, setAutoReplenish] = useState<boolean | null>();
  const [orderable, setOrderable] = useState<boolean | null>();
  const [trackInventory, setTrackInventory] = useState<boolean | null>();
  const [reset, setReset] = useState<boolean>(false);
  // Default Filters:
  const [shouldApplyFilter, setShouldApplyFilter] = useState<boolean>(false);

  const isActiveRef = useRef<null | Select>(null);
  const productTypeRef = useRef<null | Select>(null);
  const autoReplenishRef = useRef<null | Select>(null);
  const orderableRef = useRef<null | Select>(null);
  const trackInventoryRef = useRef<null | Select>(null);

  //Entity
  const [entityFilters, setEntityFilters] = useState<EntityFiltersModel>({
    accountId: queryParams.accountId ? queryParams.accountId : undefined,
    clientId: queryParams.clientId ? queryParams.clientId : undefined,
    franchiseId: queryParams.franchiseId ? queryParams.franchiseId : undefined,
    locationId: queryParams.locationId ? queryParams.locationId : undefined,
  });

  const handleIsActive = (params: any) => {
    if (params) {
      setIsActive(params.value);
    } else {
      setIsActive(undefined);
    }
  };
  const handleProductType = (params: any) => {
    if (params) {
      setProductType(params.value);
    } else {
      setProductType(undefined);
    }
  };

  const handleAutoReplenish = (params: any) => {
    if (params) {
      setAutoReplenish(params.value);
    } else {
      setAutoReplenish(undefined);
    }
  };
  const handleOrderable = (params: any) => {
    if (params) {
      setOrderable(params.value);
    } else {
      setOrderable(undefined);
    }
  };
  const handleTrackInventory = (params: any) => {
    if (params) {
      setTrackInventory(params.value);
    } else {
      setTrackInventory(undefined);
    }
  };
  const resetFilters = () => {
    setReset(true);
  };

  const applyFilter = () => {
    const newQueryParams = { ...queryParams };
    newQueryParams.active = isActive;
    newQueryParams.productType = productType;
    newQueryParams.autoReplenish = autoReplenish;
    newQueryParams.orderable = orderable;
    newQueryParams.trackInventory = trackInventory;
    newQueryParams.accountId = entityFilters?.accountId;
    newQueryParams.clientId = entityFilters?.clientId;
    newQueryParams.franchiseId = entityFilters?.franchiseId;
    newQueryParams.locationId = entityFilters?.locationId;
    newQueryParams.pageNumber = 1;
    setQueryParams(newQueryParams);
  };
  useEffect(() => {
    if (reset) {
      //setFilteBadges([]);

      isActiveRef.current?.clearValue();
      productTypeRef.current?.clearValue();
      autoReplenishRef.current?.clearValue();
      orderableRef.current?.clearValue();
      trackInventoryRef.current?.clearValue();
      let newQueryParams = {
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };

      setQueryParams({ ...initialFilterProducts, ...newQueryParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  /** START Filter Badges */

  const [filterBadges, setFilteBadges] = useState<FilterBadge[]>([]);
  const [entityFilterBadges, setEntityFilteBadges] = useState<FilterBadge[]>(
    []
  );
  // Get initial values from the url
  useEffect(() => {
    let clearFilters = [];
    if (queryParams.active === true || queryParams.active === false) {
      setIsActive(queryParams.active);
      updateBadge(
        'active',
        queryParams.active === true
          ? 'Active'
          : queryParams.active === false
          ? 'Inactive'
          : '',
        isActiveRef
      );
    } else {
      clearFilters.push('active');
    }

    if (queryParams.productType) {
      setProductType(queryParams.productType);
      updateBadge('productType', queryParams.productType, productTypeRef);
    } else {
      clearFilters.push('productType');
    }

    if (
      queryParams.autoReplenish === true ||
      queryParams.autoReplenish === false
    ) {
      setAutoReplenish(queryParams.autoReplenish);
      updateBadge(
        'autoReplenish',
        queryParams.autoReplenish ? 'Auto Replenish' : 'No Auto Replenish',
        autoReplenishRef
      );
    } else {
      clearFilters.push('autoReplenish');
    }

    if (queryParams.orderable === true || queryParams.orderable === false) {
      setOrderable(queryParams.orderable);
      updateBadge(
        'orderable',
        queryParams.orderable ? 'Orderable' : 'No Orderable',
        orderableRef
      );
    } else {
      clearFilters.push('orderable');
    }

    if (
      queryParams.trackInventory === true ||
      queryParams.trackInventory === false
    ) {
      setTrackInventory(queryParams.trackInventory);
      updateBadge(
        'trackInventory',
        queryParams.trackInventory ? 'Track Inventory' : 'No track Inventory',
        trackInventoryRef
      );
    } else {
      clearFilters.push('trackInventory');
    }

    if (entityFilterBadges.length > 0) {
      entityFilterBadges.forEach((item: FilterBadge) => {
        updateBadge(item.key, item.value, item.ref);
      });
    }

    if (!queryParams.accountId) clearFilters.push('accountId');
    if (!queryParams.clientId) clearFilters.push('clientId');
    if (!queryParams.franchiseId) clearFilters.push('franchiseId');
    if (!queryParams.locationId) clearFilters.push('locationId');

    if (clearFilters.length > 0) {
      clearMultipleFilter(clearFilters);
    }
    // Get initial values from the url
    const newQueryParams = {
      ...entityFilters,
      accountId: queryParams.accountId ? queryParams.accountId : undefined,
      clientId: queryParams.clientId ? queryParams.clientId : undefined,
      franchiseId: queryParams.franchiseId
        ? queryParams.franchiseId
        : undefined,
      locationId: queryParams.locationId ? queryParams.locationId : undefined,
    };
    setEntityFilters(newQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  // Get initial values from the url
  useEffect(() => {
    if (shouldApplyFilter) {
      let clearFilters = [];
      if (queryParams.active === true || queryParams.active === false) {
        setIsActive(queryParams.active);
        updateBadge(
          'active',
          queryParams.active === true
            ? 'Active'
            : queryParams.active === false
            ? 'Inactive'
            : '',
          isActiveRef
        );
      } else {
        clearFilters.push('active');
      }

      if (queryParams.productType) {
        setProductType(queryParams.productType);
        updateBadge('productType', queryParams.productType, productTypeRef);
      } else {
        clearFilters.push('productType');
      }

      if (
        queryParams.autoReplenish === true ||
        queryParams.autoReplenish === false
      ) {
        setAutoReplenish(queryParams.autoReplenish);
        updateBadge(
          'autoReplenish',
          queryParams.autoReplenish ? 'Auto Replenish' : 'No Auto Replenish',
          autoReplenishRef
        );
      } else {
        clearFilters.push('autoReplenish');
      }

      if (queryParams.orderable === true || queryParams.orderable === false) {
        setOrderable(queryParams.orderable);
        updateBadge(
          'orderable',
          queryParams.orderable ? 'Orderable' : 'No Orderable',
          orderableRef
        );
      } else {
        clearFilters.push('orderable');
      }

      if (
        queryParams.trackInventory === true ||
        queryParams.trackInventory === false
      ) {
        setTrackInventory(queryParams.trackInventory);
        updateBadge(
          'trackInventory',
          queryParams.trackInventory ? 'Track Inventory' : 'No track Inventory',
          trackInventoryRef
        );
      } else {
        clearFilters.push('trackInventory');
      }

      if (entityFilterBadges.length > 0) {
        entityFilterBadges.forEach((item: FilterBadge) => {
          updateBadge(item.key, item.value, item.ref);
        });
      }

      if (!queryParams.accountId) clearFilters.push('accountId');
      if (!queryParams.clientId) clearFilters.push('clientId');
      if (!queryParams.franchiseId) clearFilters.push('franchiseId');
      if (!queryParams.locationId) clearFilters.push('locationId');

      if (clearFilters.length > 0) {
        clearMultipleFilter(clearFilters);
      }
      // Get initial values from the url
      const newQueryParams = {
        ...entityFilters,
        accountId: queryParams.accountId ? queryParams.accountId : undefined,
        clientId: queryParams.clientId ? queryParams.clientId : undefined,
        franchiseId: queryParams.franchiseId
          ? queryParams.franchiseId
          : undefined,
        locationId: queryParams.locationId ? queryParams.locationId : undefined,
      };
      setEntityFilters(newQueryParams);
      setShouldApplyFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldApplyFilter, entityFilterBadges]);

  const clearMultipleFilter = (filters: Array<string>) => {
    let badges = filterBadges;
    filters.forEach((element) => {
      badges = badges.filter((item: FilterBadge) => item.key !== element);
    });
    setFilteBadges(badges);
  };
  const clearFilter = (ref: any, keyParam: string) => {
    if (
      keyParam === 'accountId' ||
      keyParam === 'clientId' ||
      keyParam === 'franchiseId'
    ) {
      clearMultipleFilter([
        'accountId',
        'clientId',
        'franchiseId',
        'locationId',
      ]);

      let params = { ...queryParams };
      params['accountId'] = userGroupRelations.accountId
        ? userGroupRelations.accountId
        : undefined;
      params['clientId'] = userGroupRelations.clientId
        ? userGroupRelations.clientId
        : undefined;
      params['franchiseId'] = userGroupRelations.franchiseId
        ? userGroupRelations.franchiseId
        : undefined;
      params['locationId'] = userGroupRelations.locationId
        ? userGroupRelations.locationId
        : undefined;
      // When removing a badge you should be redirected to page 1.
      params['pageNumber'] = 1;
      setQueryParams(params);

      filterBadges
        .find((element) => element.key === 'accountId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'clientId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'franchiseId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'locationId')
        ?.ref.current?.clearValue();
    } else if (ref) {
      ref.current?.clearValue();
      let params = { ...queryParams };
      params[keyParam] = undefined;
      // When removing a badge you should be redirected to page 1.
      params['pageNumber'] = 1;
      setQueryParams(params);
    }

    setFilteBadges(
      filterBadges.filter((item: FilterBadge) => item.key !== keyParam)
    );
  };
  const updateBadge = (key: string, value: string, ref: any) => {
    let badges = filterBadges;
    const finded = badges?.find((item: FilterBadge) => item.key === key);
    if (finded && finded !== undefined) {
      badges.splice(badges.indexOf(finded), 1, {
        key: key,
        value: value,
        ref: ref,
      });
    } else {
      badges.push({
        key: key,
        value: value,
        ref: ref,
      });
    }
    setFilteBadges(badges);
  };

  /** END Filter Badges */

  const showEntitiesFilter = !userGroupRelations.locationId;
  const ActiveFilter = () => (
    <div className='mb-2' onClick={(e) => e.stopPropagation()}>
      <label className='form-label fw-bold'>Active</label>
      <ReactSelect
        placeholder={<FormattedMessage id='COMMON.ALL' />}
        isClearable
        name='isActive'
        id='isActive'
        onChange={handleIsActive}
        options={activeOptions}
        value={
          activeOptions
            ? activeOptions.filter((x: any) => x.value === isActive)[0]
            : null
        }
        ref={isActiveRef}
        defaultValue={activeOptions[0]}
      />
    </div>
  );
  return (
    <>
      {filterBadges?.map((item: FilterBadge) => {
        if (item.value && item.value !== '')
          return (
            <CustomBadgeFilter
              text={item.value}
              key={item.key}
              badgeKey={item.key}
              clearFilter={() => {
                if (item.ref) clearFilter(item.ref, item.key);
              }}
            ></CustomBadgeFilter>
          );
        else return <></>;
      })}

      <CustomFilter
        applyFilters={applyFilter}
        resetFilters={resetFilters}
        scroll={false}
        size={showEntitiesFilter ? 'lg' : 'md'}
      >
        <Fragment>
          <div className='row'>
            <div className='col-md-6'>
              <FilterCascadeEntitySelections
                setReset={setReset}
                reset={reset}
                entityFilters={entityFilters}
                setEntityFilters={setEntityFilters}
                setEntityListBadge={setEntityFilteBadges}
                entityFilterBadges={entityFilterBadges}
                setShowFilterBadges={setShouldApplyFilter}
              />
              {showEntitiesFilter && <ActiveFilter></ActiveFilter>}
            </div>
            <div className={showEntitiesFilter ? 'col-md-6' : 'col-md-12'}>
              {!showEntitiesFilter && <ActiveFilter></ActiveFilter>}
              <div className='mb-2' onClick={(e) => e.stopPropagation()}>
                <label className='form-label fw-bold'>Product Type</label>
                <ReactSelect
                  placeholder={<FormattedMessage id='COMMON.ALL' />}
                  isClearable
                  name='productType'
                  id='productType'
                  onChange={handleProductType}
                  options={productsType}
                  value={
                    productsType
                      ? productsType.filter(
                          (x: any) => x.value === productType
                        )[0]
                      : null
                  }
                  ref={productTypeRef}
                />
              </div>
              <div className='mb-2' onClick={(e) => e.stopPropagation()}>
                <label className='form-label fw-bold'>Auto Replenish</label>
                <ReactSelect
                  placeholder={<FormattedMessage id='COMMON.ALL' />}
                  isClearable
                  name='autoReplenish'
                  id='autoReplenish'
                  onChange={handleAutoReplenish}
                  options={binaryOptions}
                  value={
                    binaryOptions
                      ? binaryOptions.filter(
                          (x: any) => x.value === autoReplenish
                        )[0]
                      : null
                  }
                  ref={autoReplenishRef}
                />
              </div>
              <div className='mb-2' onClick={(e) => e.stopPropagation()}>
                <label className='form-label fw-bold'>Orderable</label>
                <ReactSelect
                  placeholder={<FormattedMessage id='COMMON.ALL' />}
                  isClearable
                  name='orderable'
                  id='orderable'
                  onChange={handleOrderable}
                  options={binaryOptions}
                  value={
                    binaryOptions
                      ? binaryOptions.filter(
                          (x: any) => x.value === orderable
                        )[0]
                      : null
                  }
                  ref={orderableRef}
                />
              </div>
              <div onClick={(e) => e.stopPropagation()}>
                <label className='form-label fw-bold'>TrackInventory</label>
                <ReactSelect
                  placeholder={<FormattedMessage id='COMMON.ALL' />}
                  isClearable
                  name='trackInventory'
                  id='trackInventory'
                  onChange={handleTrackInventory}
                  options={binaryOptions}
                  value={
                    binaryOptions
                      ? binaryOptions.filter(
                          (x: any) => x.value === trackInventory
                        )[0]
                      : null
                  }
                  ref={trackInventoryRef}
                  styles={selectStyles}
                />
              </div>
            </div>
          </div>
        </Fragment>
        {/* Add more filters */}
      </CustomFilter>
    </>
  );
}
