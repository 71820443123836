import { Fragment, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { InventoryModel } from '../models/InventoryModel';
import { RootState } from '../../../redux';
import { shallowEqual, useSelector } from 'react-redux';
import { useQueryParamsReplace } from '../../../utils/useQueryParamsReplace';
import ListComponent from '../../../components/ListComponent';
import { GroupModel } from '../../users/models/GroupModel';
// Inventory imports
import {
  initialFilterInventories,
  queryParamsConfigInventories,
} from '../utils/utils';
import warehouseService from '../services/WarehousesServices';
import { InventoriesFilter } from './InventoriesFilter';
import { WarehouseModel } from '../models/WarehouseModel';
import { WarehouseCreateInventory } from './WarehouseCreateInventory';
import { useDebouncedCallback } from 'use-debounce/lib';
import { WarehouseEditInventory } from './WarehouseEditInventory';
import { WarehouseAdjustStock } from './WarehouseAdjustStock';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';

export function WarehouseInventoriesList({
  warehouse,
}: {
  warehouse: WarehouseModel;
}) {
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );

  // Pagination, Filter and Query Params
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<InventoryModel[]>([]);

  const [queryParams, setQueryReplace] = useQueryParamsReplace(
    queryParamsConfigInventories
  );
  const [filter, setFilter] = useState('');
  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<InventoryModel>[] = useMemo(
    () => [
      {
        name: 'Product Code',
        // selector: (row) => row.productCode,
        cell: (row) => {
          const currentLevel = row.currentLevel ? row.currentLevel : 0;
          const lowLevel = row.lowLevel ? row.lowLevel : 0;
          const quantityOnOrder = row.quantityOnOrder ? row.quantityOnOrder : 0;
          const color =
            currentLevel <= lowLevel
              ? 'badge-danger'
              : currentLevel - quantityOnOrder <= lowLevel
              ? 'badge-warning'
              : '';
          return (
            <>
              <div className='col-9'>{row.productCode} </div>
              {!!color && (
                <div className='col-3'>
                  <span className={`badge ${color} mx-3 align-right`}>Low</span>
                </div>
              )}
            </>
          );
        },
        sortable: true,
        sortField: 'productCode',
        width: '200px',
      },
      {
        name: 'Product Description',
        selector: (row) => row.productDescription || '-',
        sortable: true,
        sortField: 'productDescription',
        width: '200px',
      },
      {
        name: 'Type',
        selector: (row) => row.productType || '-',
        sortable: true,
        sortField: 'type',
      },
      {
        name: 'Current Lvl',
        selector: (row) => row.currentLevel || '-',
        sortable: true,
        sortField: 'currentLevel',
      },
      {
        name: 'Low Lvl',
        selector: (row) => row.lowLevel || '-',
        sortable: true,
        sortField: 'lowLevel',
      },
      {
        name: 'Qty. Orders',
        selector: (row) => row.quantityOrders || '-',
        sortable: false,
        sortField: 'quantityOrders',
      },
      {
        name: 'Avg. Week Qty.',
        selector: (row) => row.averageWeeklyOrder || '-',
        sortable: false,
        sortField: 'averageOrders',
        width: '150px',
      },
      {
        name: 'Weeks left',
        selector: (row) => row.weeksLeftOfInventory || '-',
        sortable: false,
        sortField: 'weeksLeft',
      },
      {
        name: 'Actions',
        button: true,
        cell: (row) =>
          row.id && (
            <>
              <Fragment>
                {group.warehouseInventoryPermission?.view && (
                  <button
                    onClick={() => handleView(row)}
                    className='btn btn-icon btn-bg-light btn-active-success btn-sm me-1'
                    data-bs-toggle='tooltip'
                    title='View'
                  >
                    <i className='las la-eye fs-2'></i>
                  </button>
                )}
                {group.warehouseInventoryPermission?.view &&
                  group.warehouseInventoryPermission?.update &&
                  row.productId && (
                    <button
                      onClick={() =>
                        handleShowUpdateModal(row.productId ? row.productId : 0)
                      }
                      className='btn btn-icon btn-bg-light btn-active-primary btn-sm me-1'
                      data-bs-toggle='tooltip'
                      title='Edit'
                    >
                      <i className='las la-edit fs-2'></i>
                    </button>
                  )}

                {group.warehouseInventoryPermission?.view &&
                  group.warehouseInventoryPermission?.update &&
                  row.productId && (
                    <button
                      onClick={() => handleShowAddAdjustStockModal(row)}
                      className='btn btn-icon btn-bg-light btn-active-primary btn-sm me-1'
                      data-bs-toggle='tooltip'
                      title='Adjust Stock'
                    >
                      <i className='fas fa-boxes fs-2'></i>
                    </button>
                  )}
              </Fragment>
            </>
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  //Initialization methods
  useEffect(() => {
    if (history.location.search === '') {
      const newQueryParams = {
        ...initialFilterInventories,
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };
      setQueryReplace(newQueryParams);
    }

    if (queryParams.pageNumber) {
      findInventories();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findInventories = function () {
    if (warehouse.id) {
      warehouseService
        .findInventories(warehouse.id, {
          ...queryParams,
          warehouseId: warehouse.id,
        })
        .then((response) => {
          setEntities(response.data.items);
          setTotalCount(response.data.totalCount);
        });
    }
  };
  const handleView = (inventory: InventoryModel) => {
    history.push(
      `/warehouses/${warehouse.id}/inventories/${inventory.productId}`
    );
  };
  const [selectedId, setSelectedId] = useState<number>(0);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleShowCreateModal = () => {
    setShowCreateModal(true);
  };
  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  // eslint-disable-next-line
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setSelectedId(0);
  };
  const handleShowUpdateModal = (id: number) => {
    setShowUpdateModal(true);
    setSelectedId(id);
  };

  const [showAddAdjustStockModal, setShowAddAdjustStockModal] = useState(false);
  const [selectedInventory, setSelectedInventory] =
    useState<InventoryModel | null>(null);
  const handleCloseAddAdjustStockModal = () => {
    setShowAddAdjustStockModal(false);
    setSelectedInventory(null);
  };
  const handleShowAddAdjustStockModal = (row: InventoryModel) => {
    setShowAddAdjustStockModal(true);
    setSelectedInventory(row);
  };

  const refetch = () => {
    findInventories();
  };
  const onFilterChange = (e: any, key: string) => {
    if (key === 'filter') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };
  const debounced = useDebouncedCallback((params: any) => {
    const newQueryParams = { ...queryParams };
    if (params.key === 'filter') {
      let filter = params.value;
      newQueryParams.filter = filter;
    }
    newQueryParams.pageNumber = 1;
    setQueryReplace(newQueryParams);
  }, 800);
  return (
    <>
      {group.warehouseInventoryPermission?.list && (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header py-6'>
            <div className='card-title'>
              {group.warehouseInventoryPermission?.list && (
                <div className='d-flex align-items-center position-relative my-1'>
                  <i className='las la-search position-absolute ms-6 fs-2'></i>
                  <input
                    autoComplete='none'
                    type='search'
                    className='form-control form-control-solid w-350px ps-15'
                    value={filter}
                    placeholder='Search by Product Code or Description'
                    onChange={(e) => onFilterChange(e, 'filter')}
                  />
                </div>
              )}
            </div>
            <div className='card-toolbar'>
              <InventoriesFilter
                queryParams={queryParams}
                setQueryParams={setQueryReplace}
              />

              {group.warehouseInventoryPermission?.create &&
                warehouse.isActive && (
                  <>
                    <button
                      onClick={() => handleShowCreateModal()}
                      type='button'
                      className='btn btn-primary align-self-center'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      <i className='las la-edit fs-2'></i>
                      Add Inventory
                    </button>

                    {showCreateModal && (
                      <WarehouseCreateInventory
                        refetch={findInventories}
                        show={showCreateModal}
                        onHide={handleCloseCreateModal}
                        warehouse={warehouse}
                      />
                    )}
                  </>
                )}
            </div>
          </div>

          <div className='card-body px-9 py-3'>
            <ListComponent
              table={{
                columns,
                data: entities,
                paginationTotalRows: totalCount,
                pointerOnHover: true,
                highlightOnHover: true,
                onRowClicked: handleView,
              }}
              queryParams={queryParams}
              setQueryParams={setQueryReplace}
            />
            {showUpdateModal && (
              <WarehouseEditInventory
                refetch={refetch}
                productId={selectedId}
                warehouseId={warehouse.id}
                show={showUpdateModal}
                onHide={handleCloseUpdateModal}
              />
            )}

            {showAddAdjustStockModal && (
              <WarehouseAdjustStock
                refetch={refetch}
                show={showAddAdjustStockModal}
                onHide={handleCloseAddAdjustStockModal}
                warehouse={warehouse}
                productId={selectedInventory?.productId}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
