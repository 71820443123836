import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { RootState } from '../redux';

export function CommonLoadingDialog() {
  const isLoading: boolean = useSelector<RootState, boolean>(
    ({ loading }) => loading.isLoading,
    shallowEqual
  );
  const noLoading: boolean = useSelector<RootState, boolean>(
    ({ loading }) => loading.noLoading,
    shallowEqual
  );

  useEffect(() => {}, [isLoading]);

  return (
    <LoadingOverlay
      active={isLoading && !noLoading}
      spinner
      styles={{
        overlay: (base) => ({
          ...base,
          background: '#E4E6EF',
          opacity: 0.5,
          color: '#000',
          position: 'fixed',
          zIndex: 1100,
        }),
        spinner: (base) => ({
          ...base,
          width: '100px',
          '& svg circle': {
            stroke: '#000',
          },
        }),
      }}
    ></LoadingOverlay>
  );
}
