import { useState } from 'react';
import {
  toAbsoluteUrl,
  toAbsoluteUrlImage,
} from '../../../../_metronic/helpers';
import { ProductOrderModel } from '../models/ProductOrderModel';

interface Props {
  product: ProductOrderModel;
  handleAddProduct: (product: ProductOrderModel, quantity: number) => void;
}

export function ProductCard({ product, handleAddProduct }: Props) {
  const [quantity, setQuantity] = useState(0);
  const [customQuantity, setCustomQuantity] = useState<boolean>(false);

  const handleSelectQuantity = (e: any) => {
    if (e.target.value === 'custom') {
      setCustomQuantity(true);
      product.customQuantity = true;
    } else {
      setQuantity(Number(e.target.value));
    }
  };
  const handleAdd = () => {
    setQuantity(0);
    setCustomQuantity(false);
    product.selected = true;
    handleAddProduct(product, quantity);
  };
  const valueLabel = () => {
    if (product.cardAttributesType === 'Fixed') {
      return `Value fixed: $ ${product.value ? product.value : 'No Value'}`;
    } else if (product.cardAttributesType === 'Range') {
      return `Value range: $  ${
        product.minValue ? product.minValue : 'No Value'
      } - $ ${product.maxValue ? product.maxValue : 'No Value'}`;
    }
    return 'No Value';
  };

  return (
    <>
      <div
        className='card h-350px mb-6'
        style={{ border: product.selected ? '5px solid #50CD89' : '' }}
      >
        <div className='card-body py-6 px-6'>
          <div className='d-flex align-items-center position-relative h-30px'>
            <h3 className='m-0'>{product.name}</h3>
            {product.premium && (
              <i
                style={{
                  background: '#DBAD30',
                  padding: '3px',
                  borderRadius: '5px',
                  color: 'white',
                }}
                className='las la-star fs-2 mx-2'
              ></i>
            )}
          </div>
          <div className='row my-2'>
            <label>
              {product.type} - Code {product.code}
            </label>
          </div>
          <div className='row my-2'>
            <label>{valueLabel()}</label>
          </div>
          {product.path && (
            <div className=' my-2 d-flex justify-content-center'>
              <img
                style={{ height: '150px', width: 'auto', maxWidth: '200px' }}
                src={toAbsoluteUrlImage(product.path)}
                alt='Valid'
              />
            </div>
          )}
          {!product.path && (
            <div className=' my-2 d-flex justify-content-center'>
              <img
                style={{ height: '150px', width: 'auto' }}
                src={toAbsoluteUrl('/media/images/no-image.png')}
                alt='Valid'
              />
            </div>
          )}

          {product.quantityOnStock === 0 && (
            <div className='row my-5'>
              <label className='text-center text-muted'>
                Currently out of stock
              </label>
            </div>
          )}
          {product.quantityOnStock > 0 && (
            <div className='row my-5'>
              <div className='col-md-5'>
                {!customQuantity && (
                  <select
                    style={{ backgroundColor: 'white', borderColor: '#DDDDDD' }}
                    className='form-select form-select-solid  px-2'
                    onChange={handleSelectQuantity}
                    value={quantity}
                  >
                    <option disabled value={0}>
                      Qty.
                    </option>

                    {product.shippingUnitSize > 0 && (
                      <>
                        <option value={product.shippingUnitSize * 1}>
                          {product.shippingUnitSize * 1}
                        </option>
                        <option value={product.shippingUnitSize * 2}>
                          {product.shippingUnitSize * 2}
                        </option>
                        <option value={product.shippingUnitSize * 3}>
                          {product.shippingUnitSize * 3}
                        </option>
                        <option value={product.shippingUnitSize * 4}>
                          {product.shippingUnitSize * 4}
                        </option>
                        <option value={product.shippingUnitSize * 5}>
                          {product.shippingUnitSize * 5}
                        </option>
                      </>
                    )}

                    <option value='custom'>Custom</option>
                  </select>
                )}
                {customQuantity && (
                  <input
                    autoComplete='none'
                    type='number'
                    className='form-control form-control-solid'
                    value={quantity}
                    placeholder=''
                    onChange={handleSelectQuantity}
                    min='1'
                  />
                )}
              </div>
              <div className='col-md-7'>
                <button
                  type='submit'
                  className='btn btn-primary fs-6 px-2'
                  id='kt_subscriptions_create_button'
                  disabled={quantity <= 0}
                  onClick={handleAdd}
                >
                  <span className='indicator-label'>Add to Order</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
