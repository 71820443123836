import { useField, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useRef } from 'react';

interface CustomImageInputProps {
  name: string;
  label?: string;
  accept?: string;
  customText: string;
}

const CustomFileInput = ({
  name,
  label,
  accept = '.txt',
  customText,
}: CustomImageInputProps) => {
  const [field, meta] = useField(name);
  const [file, setFile] = useState<string | null>(null);
  const { errors, setFieldValue } = useFormikContext<any>();
  const inputImageRef = useRef<HTMLInputElement>(null);

  const removeFile = useCallback(() => {
    setFieldValue(name, null);
    setFile(null);
    if (inputImageRef.current) inputImageRef.current.value = '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorValue = errors[field.name] ? errors[field.name] : meta.error;

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files ? e.target.files[0] : null;
    if (!file) {
      return;
    }
    setFile(file.name);
    setFieldValue(name, file);
  };
  useEffect(() => {
    if (!(field.value instanceof File)) {
      removeFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {label && <label className={`form-label mt-5`}>{label}</label>}
      <div>
        <label className='btn btn-primary align-self-center'>
          <i className='las la-paperclip fs-2'></i>
          <input
            ref={inputImageRef}
            type='file'
            name={name}
            accept={accept}
            onChange={onChangeFile}
            hidden={true}
          />
          <span>{!!file ? file : 'Choose a file'}</span>
        </label>
      </div>

      <div className='form-text'>{customText}</div>
      {meta.touched && errorValue && (
        <div className='text-danger'>
          <div style={{ fontSize: '0.925rem' }}>{errorValue}</div>
        </div>
      )}
    </>
  );
};

export default CustomFileInput;
