import { Route, Switch } from 'react-router';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { WarehouseInventoryView } from './components/WarehouseInventoryView';
import { WarehousesList } from './components/WarehousesList';
import { WarehouseView } from './components/WarehouseView';
import { LocationInventoryMasterList } from './../entities/components/locations/LocationInventoryMasterList';

export function WarehousePage() {
  const breadcrumbs: Array<PageLink> = [];

  return (
    <>
      <Switch>
        <Route exact path='/warehouses'>
          <PageTitle breadcrumbs={breadcrumbs}>Warehouse List</PageTitle>
          <WarehousesList />
        </Route>
        <Route exact path='/warehouses/inventory'>
          <PageTitle breadcrumbs={breadcrumbs}>Location Inventory</PageTitle>
          <LocationInventoryMasterList />
        </Route>
        <Route exact path='/warehouses/:warehouseId/inventories/:productId'>
          <PageTitle breadcrumbs={[]}>Product Inventory Details</PageTitle>
          <WarehouseInventoryView />
        </Route>
        <Route path='/warehouses/:warehouseId'>
          <PageTitle breadcrumbs={[]}>Warehouse Details</PageTitle>
          <WarehouseView />
        </Route>
      </Switch>
    </>
  );
}
