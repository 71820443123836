import { Fragment, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { ClientModel } from '../../models/ClientModel';
import {
  initialFilterClients,
  queryParamsConfigClients,
} from '../../utils/utils';
import clientsServices from '../../services/ClientsServices';
import { ClientDelete } from './ClientDelete';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux';
import { EntityModel } from '../../../../models/EntityModel';
import { setEntityClient } from '../../../../redux/entity/entitiesActions';
import { ClientEdit } from './ClientEdit';
import { ClientCreate } from './ClientCreate';
import { initEntityClient } from '../../../../utils/entityUtils';
import { useParams } from 'react-router';
import ListComponent from '../../../../components/ListComponent';
import { useQueryParamsReplace } from '../../../../utils/useQueryParamsReplace';
import { ClientsFilter } from './ClientsFilter';
import { GroupModel } from '../../../users/models/GroupModel';

interface Params {
  accountId: string;
}

export function ClientsList() {
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  const { accountId } = useParams<Params>();

  // Pagination, Filter and Query Params
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<ClientModel[]>([]);

  const [queryParams, setQueryReplace] = useQueryParamsReplace(
    queryParamsConfigClients
  );
  const [filter, setFilter] = useState('');

  const history = useHistory();

  // eslint-disable-next-line
  const handleView = (client: ClientModel) => {
    const entity: EntityModel = initEntityClient(client);
    dispatch(setEntityClient(entity));
    history.push(`/accounts/${accountId}/clients/${client.id}/franchises`);
  };

  // useMemo will only be created once
  const columns: TableColumn<ClientModel>[] = useMemo(
    () => [
      {
        name: 'Key',
        selector: (row) => row.key,
        sortable: true,
        sortField: 'key',
      },
      {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
        sortField: 'name',
      },
      {
        name: 'Account',
        selector: (row) => row.accountName,
        sortable: true,
        sortField: 'accountName',
      },
      {
        name: 'Active',
        cell: (row) => {
          const badgeText = row.isActive ? 'Active' : 'Inactive';
          const color = row.isActive ? 'badge-success' : 'badge-light';
          return <span className={`badge ${color}`}>{badgeText}</span>;
        },
        sortable: false,
      },
      {
        name: 'Actions',
        button: true,
        cell: (row) =>
          row.id && (
            <>
              <Fragment>
                {group.clientPermission?.view && (
                  <button
                    onClick={() => handleView(row)}
                    className='btn btn-icon btn-bg-light btn-active-success btn-sm me-1'
                    data-bs-toggle='tooltip'
                    title='View'
                  >
                    <i className='las la-eye fs-2'></i>
                  </button>
                )}
                {group.clientPermission?.view &&
                  group.clientPermission?.update && (
                    <button
                      onClick={() => handleShowUpdateModal(row.id)}
                      className='btn btn-icon btn-bg-light btn-active-primary btn-sm me-1'
                      data-bs-toggle='tooltip'
                      title='Edit'
                    >
                      <i className='las la-edit fs-2'></i>
                    </button>
                  )}
              </Fragment>
            </>
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleView]
  );

  //Initialization methods
  useEffect(() => {
    if (history.location.search === '' && accountId) {
      setQueryReplace({ ...initialFilterClients, accountId: accountId });
    }
    if (queryParams.filter) {
      setFilter(queryParams.filter);
    }
    if (queryParams.accountId) {
      findClients(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findClients = function (queryParams: any) {
    if (group.clientPermission?.list) {
      clientsServices.findClients(queryParams).then((response) => {
        setEntities(response.data.items);
        setTotalCount(response.data.totalCount);
      });
    }
  };

  const onFilterChange = (e: any, key: string) => {
    if (key === 'filter') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };
  const debounced = useDebouncedCallback((params: any) => {
    const newQueryParams = { ...queryParams };
    if (params.key === 'filter') {
      let filter = params.value;
      newQueryParams.filter = filter;
    }
    newQueryParams.pageNumber = 1;
    setQueryReplace(newQueryParams);
  }, 800);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    findClients(queryParams);
  };

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    findClients(queryParams);
  };
  const handleShowCreateModal = (event: any) => {
    event.preventDefault();
    setShowCreateModal(true);
  };

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    findClients(queryParams);
  };
  const handleShowUpdateModal = (id: number) => {
    setShowUpdateModal(true);
    setSelectedId(id);
  };

  const dispatch = useDispatch();
  return (
    <>
      <ClientDelete
        show={showDeleteModal}
        id={selectedId}
        onHide={handleCloseDeleteModal}
      />
      <ClientCreate show={showCreateModal} onHide={handleCloseCreateModal} />
      <ClientEdit
        show={showUpdateModal}
        id={selectedId}
        onHide={handleCloseUpdateModal}
      />

      <div
        style={{
          borderTopRightRadius: '0px',
          borderTopLeftRadius: '0px',
        }}
        className='mt-0 card mb-5 mb-xl-10 '
      >
        {(group.clientPermission?.list || group.clientPermission?.create) && (
          <div className='card-header py-6'>
            <div className='card-title'>
              {group.clientPermission?.list && (
                <div className='d-flex align-items-center position-relative my-1'>
                  <i className='las la-search position-absolute ms-6 fs-2'></i>
                  <input
                    autoComplete='none'
                    type='search'
                    className='form-control form-control-solid w-350px ps-15'
                    value={filter}
                    placeholder='Search by Key or Name'
                    onChange={(e) => onFilterChange(e, 'filter')}
                  />
                </div>
              )}
            </div>
            <div className='card-toolbar'>
              {group.clientPermission?.list && (
                <ClientsFilter
                  queryParams={queryParams}
                  setQueryParams={setQueryReplace}
                />
              )}
              {group.clientPermission?.create && (
                <button
                  className='btn btn-primary align-self-center float-end'
                  onClick={handleShowCreateModal}
                >
                  <i className='las la-plus fs-2'></i>
                  Add client
                </button>
              )}
            </div>
          </div>
        )}

        <div className='card-body px-9 py-3'>
          <ListComponent
            table={{
              columns,
              data: entities,
              paginationTotalRows: totalCount,
              pointerOnHover: true,
              highlightOnHover: true,
              onRowClicked: handleView,
            }}
            queryParams={queryParams}
            setQueryParams={setQueryReplace}
            notPermissions={!group.clientPermission?.list}
          />
        </div>
      </div>
    </>
  );
}
