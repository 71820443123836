import { EntityModel } from '../../models/EntityModel';
import { entitiesSlice } from './entitiesSlice';

const { actions } = entitiesSlice;

export const setEntityAccount = (account: EntityModel) => (dispatch: any) => {
  dispatch(actions.setAccount({ account }));
};

export const removeEntityAccount = () => (dispatch: any) => {
  dispatch(actions.removeAccount({}));
};

export const setEntityClient = (client: EntityModel) => (dispatch: any) => {
  dispatch(actions.setClient({ client }));
};

export const setEntityGroup = (group: EntityModel) => (dispatch: any) => {
  dispatch(actions.setGroup({ group }));
};

export const removeEntityClient = () => (dispatch: any) => {
  dispatch(actions.removeClient({}));
};

export const setEntityFranchise =
  (franchise: EntityModel) => (dispatch: any) => {
    dispatch(actions.setFranchise({ franchise }));
  };

export const removeEntityFranchise = () => (dispatch: any) => {
  dispatch(actions.removeFranchise({}));
};

export const setEntityLocation = (location: EntityModel) => (dispatch: any) => {
  dispatch(actions.setLocation({ location }));
};

export const removeEntityLocation = () => (dispatch: any) => {
  dispatch(actions.removeLocation({}));
};
