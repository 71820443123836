import axios from 'axios';
import { PagedResult } from '../../../models/pagedResult';
import { GroupModel } from '../models/GroupModel';

export const GROUPS_URL = `/api/v1/groups`;

const groupsServices = {
  getAll: function () {
    return axios.get<GroupModel[]>(`${GROUPS_URL}/all`);
  },
  findGroups: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(GROUPS_URL, { params: params });
  },
  getGroup: (id: number) => {
    return axios.get<GroupModel>(`${GROUPS_URL}/${id}`);
  },
  createGroup: (group: GroupModel) => {
    return axios.post(GROUPS_URL, group);
  },
  updateGroup: (id: number, group: GroupModel) => {
    return axios.put(`${GROUPS_URL}/${id}`, group);
  },
};

export default groupsServices;
