import { useEffect, useRef, useState } from 'react';
import fulfillmentServices from '../services/FulfillmentServices';
import { TableColumn } from 'react-data-table-component';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import ListComponent from '../../../components/ListComponent';
import {
  FulfillmentQueryFilterKeys,
  initialFilterFulfillment,
  queryParamsFulfillmentConfig,
} from '../utils/utils';
import { useQueryParamsReplace } from '../../../utils/useQueryParamsReplace';
import { FulfillmentFilter } from './FulfillmentFilter';
import { GroupModel } from '../../users/models/GroupModel';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { OriginBadge } from '../../../components/OriginBadge';
import { StatusBadge } from '../../../components/StatusBadge';
import moment from 'moment';
import { FulfillmentListModel } from '../models/FulfillmentListModel';
import { ColumCheckbox } from '../../../components/ColumCheckbox';
import _ from 'lodash';
import orderServices from '../../orders/services/OrdersServices';

export function FulfillmentList() {
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<FulfillmentListModel[]>([]);
  const [queryParams, setQueryReplace] = useQueryParamsReplace(
    queryParamsFulfillmentConfig
  );
  const [filter, setFilter] = useState('');
  const history = useHistory();

  const [ordersSelected, setOrdersSelected] = useState<FulfillmentListModel[]>(
    []
  );
  const [selectAllTable, setSelectAllTable] = useState<boolean>(false);
  const [processBeforePrint, setProcessBeforePrint] = useState<boolean>(false);

  const checkedAll =
    selectAllTable ||
    entities.every((e) => {
      return ordersSelected.some((os: FulfillmentListModel) => e.id === os.id);
    });
  const selectAllOrders = (select: boolean) => {
    setSelectAllTable(select);
    if (!select) {
      setOrdersSelected([]);
    }
  };

  const selectRow = (ff: FulfillmentListModel, checked: boolean) => {
    // Add entity
    if (checked) {
      setOrdersSelected([...ordersSelected.filter((e) => e.id !== ff.id), ff]);
      return;
    }
    // Remove select
    setOrdersSelected(ordersSelected.filter((e) => e.id !== ff.id));
  };
  const selectAll = (checked: boolean) => {
    if (checked) {
      const newSelected = [...ordersSelected];
      _.remove(newSelected, (option) =>
        entities.some((entity) => option.id === entity.id)
      );
      setOrdersSelected([...newSelected, ...entities]);
      return;
    }
    const newSelected = [...ordersSelected];
    _.remove(newSelected, (option) =>
      entities.some((entity) => option.id === entity.id)
    );
    setOrdersSelected(newSelected);
  };
  const onPrint = () => {
    const ids = selectAllTable ? [] : ordersSelected.map((o) => o.id);
    orderServices
      .printOrders(ids, selectAllTable, processBeforePrint, queryParams)
      .then((response) => {
        const content = response.headers['content-type'];

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: content })
        );
        const ordersPrintWindow = window.open(url);
        // add auto print script
        var scriptElm = document.createElement('script');
        var inlineCode = document.createTextNode('window.print()');
        scriptElm.appendChild(inlineCode);
        ordersPrintWindow?.document.head.appendChild(scriptElm);
      })
      .then(() => {
        // Recall List
        if (queryParams.pageNumber && processBeforePrint) {
          findFulfillment(queryParams);
        }
      });
  };

  const columns: TableColumn<FulfillmentListModel>[] = [
    {
      ignoreRowClick: true,
      name: (
        <ColumCheckbox
          checked={checkedAll}
          onChecked={(e) => selectAll(e.target.checked)}
        />
      ),
      cell: (row) => (
        <ColumCheckbox
          checked={
            selectAllTable || ordersSelected.some((v) => v.id === row.id)
          }
          onChecked={(e) => selectRow(row, e.target.checked)}
        />
      ),
      minWidth: '50px',
    },
    {
      name: 'Number',
      selector: (row) => row.id,
      sortable: true,
      sortField: 'id',
      minWidth: '110px',
      wrap: true,
    },
    {
      name: 'Date',
      selector: (row) => moment(row.orderDate).format('MM-DD-YYYY'),
      wrap: true,
      minWidth: '140px',
      sortable: true,
      sortField: 'orderDate',
    },
    {
      name: 'Origin',
      cell: (row) => row.origin && <OriginBadge origin={row.origin} />,
      wrap: true,
      minWidth: '140px',
      sortable: true,
      sortField: 'origin',
    },
    {
      name: 'Account',
      selector: (row) => row.account,
      wrap: true,
      minWidth: '150px',
      sortable: true,
      sortField: 'account',
    },
    {
      name: 'Client',
      selector: (row) => row.client,
      wrap: true,
      minWidth: '150px',
      sortable: true,
      sortField: 'client',
    },
    {
      name: 'Ship to',
      selector: (row) => row.shipTo,
      wrap: true,
      minWidth: '200px',
      sortable: true,
      sortField: 'shipTo',
    },
    {
      name: 'Status',
      cell: (row) => row.status && <StatusBadge status={row.status} />,
      wrap: true,
      minWidth: '75px',
      sortable: false,
    },
    {
      name: 'Actions',
      button: true,
      cell: (row) =>
        row.id && (
          <>
            {group.fulfillmentPermission?.view && (
              <button
                onClick={() => handleView(row)}
                className='btn btn-icon btn-bg-light btn-active-success btn-sm me-1'
                data-bs-toggle='tooltip'
                title='View'
              >
                <i className='las la-eye fs-2'></i>
              </button>
            )}
          </>
        ),
    },
  ];

  // Initialization methods
  useEffect(() => {
    if (history.location.search === '') {
      const newQueryParams = {
        ...initialFilterFulfillment,
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };
      setQueryReplace(newQueryParams);
    }

    if (queryParams.filter) setFilter(queryParams.filter);
    if (queryParams.pageNumber) {
      findFulfillment(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findFulfillment = function (queryParams: any) {
    fulfillmentServices.findFulfillment(queryParams).then((response) => {
      if (
        scanningMode &&
        response.data.items.some(
          (i: FulfillmentListModel) => i.id === Number(filter)
        )
      ) {
        history.push(`/fulfillments/${filter}`);
      }
      setEntities(response.data.items);
      setTotalCount(response.data.totalCount);
    });
  };

  const onFilterChange = (e: any, key: FulfillmentQueryFilterKeys) => {
    if (key === 'filter') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };

  const findWithFilters = (params: {
    key: FulfillmentQueryFilterKeys;
    value: any;
  }) => {
    const newQueryParams = { ...queryParams };
    newQueryParams[params.key] = params.value;
    newQueryParams.pageNumber = 1;
    setQueryReplace(newQueryParams);
  };

  const debounced = useDebouncedCallback(findWithFilters, 1000);
  const handleView = (fulfillment: FulfillmentListModel) => {
    history.push(`/fulfillments/${fulfillment.id}`);
  };
  // Pending: Order Status Flow events
  // Picking list
  // Review: Backend enpoints
  // Early on we used the enpoint of order details

  // Auto redirect to orders details
  const [scanningMode, setScanningMode] = useState<boolean>(true);
  const onChangeAutoOrder = (e: any) => {
    setScanningMode(e.currentTarget.checked);
    findWithFilters({
      key: 'scanningMode',
      value: e.currentTarget.checked ? 1 : undefined,
    });
  };
  const filterInput = useRef<any>(null);
  setTimeout(() => {
    if (
      filterInput !== null &&
      filterInput.current &&
      filterInput.current.focus
    ) {
      filterInput.current.focus();
    }
  }, 200);

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header py-6'>
          <div
            className='d-flex w-100'
            style={{
              justifyContent: 'space-between',
              alignItems: 'stretch',
              flexWrap: 'wrap',
            }}
          >
            <div className='card-title'>
              {group.fulfillmentPermission?.list && (
                <>
                  <div className='d-flex align-items-center position-relative my-1'>
                    <i className='las la-search position-absolute ms-6 fs-2'></i>
                    <input
                      autoComplete='none'
                      type='search'
                      className='form-control form-control-solid w-250px ps-15'
                      value={filter}
                      ref={filterInput}
                      placeholder='Search by order number'
                      onChange={(e) => onFilterChange(e, 'filter')}
                    />
                  </div>
                  <div
                    className='form-check form-check-custom form-check-solid ms-5 fw-light'
                    style={{ fontSize: '1rem' }}
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      defaultChecked={scanningMode}
                      onChange={onChangeAutoOrder}
                      id='openOrdersAutomaticallyCheck'
                    />

                    <label
                      className='form-check-label'
                      htmlFor='openOrdersAutomaticallyCheck'
                    >
                      Open Order Detail
                    </label>
                  </div>
                </>
              )}
            </div>
            <div className='card-toolbar'>
              <div className='d-flex align-items-center'></div>
              <div className='d-flex flex-column'>
                <div className='d-flex align-content-between flex-row flex-wrap align-items-center'>
                  {group.fulfillmentPermission?.list && (
                    <>
                      <FulfillmentFilter
                        queryParams={queryParams}
                        setQueryParams={setQueryReplace}
                      />
                      <button
                        type='button'
                        className='btn btn-primary me-3'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='left'
                        onClick={() => onPrint()}
                        // disabled={ordersSelected.length === 0 && !selectAllTable}
                      >
                        <i className='las la-print fs-2'></i>
                        Print
                      </button>
                    </>
                  )}
                </div>

                <div
                  className='p-2 bd-highlight'
                  data-bs-toggle='tooltip'
                  title='Process orders before printing and only print orders of picking'
                >
                  <div
                    className='form-check form-check-custom form-check-solid fw-light'
                    style={{ fontSize: '1rem' }}
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={processBeforePrint}
                      onChange={() =>
                        setProcessBeforePrint(!processBeforePrint)
                      }
                      id='processBeforePrintCheck'
                    />

                    <label
                      className='form-check-label'
                      htmlFor='processBeforePrintCheck'
                    >
                      Process orders before printing
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-100'>
            <div className='fw-bolder fs-6 text-muted ms-3'>
              The {!selectAllTable ? ordersSelected.length : totalCount} orders
              were selected.
              {!selectAllTable && !(ordersSelected.length === totalCount) && (
                <span
                  className='text-primary fw-bolder cursor-pointer ms-2'
                  onClick={() => selectAllOrders(true)}
                >
                  Select all ({totalCount}) of this orders
                </span>
              )}
              {(selectAllTable || ordersSelected.length === totalCount) && (
                <span
                  className='text-primary fw-bolder cursor-pointer ms-2'
                  onClick={() => selectAllOrders(false)}
                >
                  Discard selection.
                </span>
              )}
            </div>
          </div>
        </div>

        <div className='card-body px-9 py-3'>
          {group.fulfillmentPermission?.list && (
            <ListComponent
              table={{
                columns,
                data: entities,
                paginationTotalRows: totalCount,
                pointerOnHover: true,
                highlightOnHover: true,
                onRowClicked: handleView,
              }}
              queryParams={queryParams}
              setQueryParams={setQueryReplace}
            />
          )}
        </div>
      </div>
    </>
  );
}
