import { toAbsoluteUrlImage } from '../../_metronic/helpers';
import { FileModel } from '../models/FileModel';

interface ViewImagesProps {
  files: FileModel[];
}

export function ViewImages({ files }: ViewImagesProps) {
  if (!files || files.length === 0) {
    return (
      <div className='p-5 pt-15 pb-15'>
        <p className='text-center pt-15 pb-15 fs-2  fw-bold text-gray-400'>
          No images
        </p>
      </div>
    );
  }

  return (
    <div className='p-5 pt-15 pb-15'>
      <div className='row'>
        <div className='col text-center'>
          {!!files[0] && (
            <>
              <a
                href={toAbsoluteUrlImage(files[0].path)}
                target='_blank'
                rel='noreferrer'
              >
                <img
                  style={{
                    border: '1px dashed #e4e6ef',
                    objectFit: 'contain',
                  }}
                  className='rounded'
                  alt='Imagen'
                  width={200}
                  height={200}
                  src={toAbsoluteUrlImage(files[0].path)}
                />
              </a>
            </>
          )}
        </div>
        <div className='col text-center'>
          {!!files[1] && (
            <>
              <a
                href={toAbsoluteUrlImage(files[1].path)}
                target='_blank'
                rel='noreferrer'
              >
                <img
                  style={{
                    border: '1px dashed #e4e6ef',
                    objectFit: 'contain',
                  }}
                  className='rounded'
                  alt='Imagen'
                  width={200}
                  height={200}
                  src={toAbsoluteUrlImage(files[1].path)}
                />
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
