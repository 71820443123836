/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useEffect, useState } from 'react';
import kitsServices from '../services/KitsServices';
import { KitModel } from '../models/KitModel';
import { TableColumn } from 'react-data-table-component';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ListComponent from '../../../components/ListComponent';
import {
  KitQueryFilterKeys,
  initialFilterKits,
  queryParamsKitConfig,
} from '../utils/utils';
import { useQueryParamsReplace } from '../../../utils/useQueryParamsReplace';
import { KitsFilter } from './KitsFilter';
import { GroupModel } from '../../users/models/GroupModel';
import { RootState } from '../../../redux';
import { CustomBadge } from '../../../components/CustomBadge';
import { KitCreate } from './KitCreate';
import { KitEdit } from './KitEdit';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import kitServices from '../services/KitsServices';
import { toast } from 'react-toastify';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';

export function KitsList() {
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<KitModel[]>([]);
  const [queryParams, setQueryReplace] =
    useQueryParamsReplace(queryParamsKitConfig);
  const [filter, setFilter] = useState('');
  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<KitModel>[] = useMemo(
    () => [
      {
        name: 'Code',
        selector: (row) => row.code,
        sortable: true,
        sortField: 'code',
      },
      {
        name: 'Description',
        selector: (row) => row.description,
        sortable: true,
        sortField: 'description',
        wrap: true,
      },
      {
        name: 'Orderable',
        cell: (row) => {
          return (
            <CustomBadge
              status={row.webOrderable}
              activeText='Yes'
              inactiveText='No'
            />
          );
        },
        center: true,
      },
      {
        name: 'Shipp.Unit',
        selector: (row) => row.shippingUnitSize || '-',
        right: true,
        sortable: true,
        sortField: 'shippingUnitSize',
      },
      {
        name: 'Products',
        selector: (row) => row.numberOfProductsInKit || '-',
        right: true,
        sortable: true,
        sortField: 'numberOfProducts',
      },
      {
        name: 'Active',
        cell: (row) => {
          return (
            <CustomBadge
              status={row.isActive}
              activeText='Active'
              inactiveText='Inactive'
            />
          );
        },
        center: true,
        sortable: true,
        sortField: 'active',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // Hides actions where there is only one
  if (group.kitPermission?.view || group.kitPermission?.update) {
    columns.push({
      name: 'Actions',
      button: true,
      cell: (row) =>
        row.id && (
          <>
            <Fragment>
              {group.kitPermission?.view && (
                <button
                  onClick={() => handleView(row)}
                  className='btn btn-icon btn-bg-light btn-active-success btn-sm me-1'
                  data-bs-toggle='tooltip'
                  title='View'
                >
                  <i className='las la-eye fs-2'></i>
                </button>
              )}
              {group.kitPermission?.view && group.kitPermission?.update && (
                <button
                  onClick={() => handleShowEditModal(row.id)}
                  className='btn btn-icon btn-bg-light btn-active-primary btn-sm me-1'
                  data-bs-toggle='tooltip'
                  title='Edit'
                >
                  <i className='las la-edit fs-2'></i>
                </button>
              )}
            </Fragment>
          </>
        ),
    });
  }

  // Initialization methods
  useEffect(() => {
    if (history.location.search === '') {
      const newQueryParams = {
        ...initialFilterKits,
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };
      setQueryReplace(newQueryParams);
    }
    if (queryParams.filter) setFilter(queryParams.filter);
    if (queryParams.pageNumber) findKits(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findKits = function (queryParams: any) {
    kitsServices.findKits(queryParams).then((response) => {
      setEntities(response.data.items);
      setTotalCount(response.data.totalCount);
    });
  };

  const onFilterChange = (e: any, key: KitQueryFilterKeys) => {
    if (key === 'filter') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };

  const findWithFilters = (params: { key: KitQueryFilterKeys; value: any }) => {
    const newQueryParams = { ...queryParams };
    newQueryParams[params.key] = params.value;
    newQueryParams.pageNumber = 1;
    setQueryReplace(newQueryParams);
  };

  const debounced = useDebouncedCallback(findWithFilters, 800);

  //const dispatch = useDispatch();
  const handleView = (kit: KitModel) => {
    history.push(`/kits/${kit.id}`);
  };
  const refetch = () => {
    findKits(queryParams);
  };
  const [selectedId, setSelectedId] = useState<number>(0);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    refetch();
  };
  const handleShowCreateModal = () => {
    setShowCreateModal(true);
  };
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedId(0);
    refetch();
  };
  const handleShowEditModal = (id: number) => {
    setSelectedId(id);
    setShowEditModal(true);
  };
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header py-6'>
          <div className='card-title'>
            {group.kitPermission?.list && (
              <div className='d-flex align-items-center position-relative my-1'>
                <i className='las la-search position-absolute ms-6 fs-2'></i>
                <input
                  autoComplete='none'
                  type='search'
                  className='form-control form-control-solid w-400px ps-15'
                  value={filter}
                  placeholder='Search by Kit Code or Description'
                  onChange={(e) => onFilterChange(e, 'filter')}
                />
              </div>
            )}
          </div>
          <div className='card-toolbar'>
            {group.kitPermission?.list && (
              <KitsFilter
                queryParams={queryParams}
                setQueryParams={setQueryReplace}
              />
            )}
            {group.kitPermission?.create && (
              <>
                <button
                  onClick={() => handleShowCreateModal()}
                  type='button'
                  className='btn btn-primary align-self-center'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                >
                  <i className='bi bi-plus fs-2'></i>
                  Add Kit
                </button>

                {showCreateModal && (
                  <KitCreate
                    refetch={refetch}
                    show={showCreateModal}
                    onHide={handleCloseCreateModal}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className='card-body px-9 py-3'>
          {group.kitPermission?.list && (
            <ListComponent
              table={{
                columns,
                data: entities,
                paginationTotalRows: totalCount,
                pointerOnHover: true,
                highlightOnHover: true,
                onRowClicked: handleView,
              }}
              queryParams={queryParams}
              setQueryParams={setQueryReplace}
            />
          )}
        </div>
      </div>
      {showEditModal && (
        <KitEdit
          kitId={selectedId}
          refetch={refetch}
          show={showEditModal}
          onHide={handleCloseEditModal}
        />
      )}
    </>
  );
}
