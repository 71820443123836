import { accessDeniedSlice } from './accessDeniedSlice';

const { actions } = accessDeniedSlice;

export const showAccessDeniedModal = () => (dispatch: any) => {
  dispatch(actions.setIsAccessDenied({ flag: true }));
};

export const hiddesAccessDeniedModal = () => (dispatch: any) => {
  dispatch(actions.setIsAccessDenied({ flag: false }));
};
