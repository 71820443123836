import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

const CustomPropertySchema = Yup.object().shape({
  name: Yup.string().ensure().trim(),
  value: Yup.string().ensure().trim(),
});

export const CreateProductSchemaStep1 = (intl: IntlShape) =>
  Yup.object().shape({
    productType: Yup.string()
      .ensure()
      .trim()
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    code: Yup.string()
      .ensure()
      .trim()
      .max(
        50,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 50 })
      )
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    description: Yup.string()
      .ensure()
      .trim()
      .max(
        255,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 255 })
      )
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    premium: Yup.boolean().required(
      intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
    ),
    trackInventoryLevel: Yup.boolean().required(
      intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
    ),
    defaultInventoryLevel: Yup.number().when('trackInventoryLevel', {
      is: true,
      then: Yup.number()
        .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
        .min(
          Yup.ref('defaultLowLevel'),
          'Default Optimum level should not be less than or equal to default low level'
        )
        .integer(intl.formatMessage({ id: 'VALIDATION.INTEGER_FIELD' }))
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    }),
    defaultLowLevel: Yup.number().when('trackInventoryLevel', {
      is: true,
      then: Yup.number()
        .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
        .min(0, intl.formatMessage({ id: 'VALIDATION.MIN.NUMBER' }, { min: 0 }))
        .integer(intl.formatMessage({ id: 'VALIDATION.INTEGER_FIELD' }))
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    }),
    isWebOrderable: Yup.boolean(),
    maxOrderQuantity: Yup.number()
      .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
      .min(1, intl.formatMessage({ id: 'VALIDATION.MIN.NUMBER' }, { min: 1 }))
      .integer(intl.formatMessage({ id: 'VALIDATION.INTEGER_FIELD' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    shippingUnitSize: Yup.number()
      .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
      .min(1, intl.formatMessage({ id: 'VALIDATION.MIN.NUMBER' }, { min: 1 }))
      .integer(intl.formatMessage({ id: 'VALIDATION.INTEGER_FIELD' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    autoReplenishment: Yup.boolean(),
    autoReplenishProductId: Yup.number().when(
      ['autoReplenishmentType', 'autoReplenishment', 'productType'],
      {
        is: (
          autoReplenishmentType: string,
          autoReplenishment: boolean,
          productType: string
        ) =>
          autoReplenishmentType === 'product' &&
          autoReplenishment === true &&
          productType === 'Card',
        then: Yup.number().nullable(true),
      }
    ),
    autoReplenishKitId: Yup.number().when(
      ['autoReplenishmentType', 'autoReplenishment', 'productType'],
      {
        is: (
          autoReplenishmentType: string,
          autoReplenishment: boolean,
          productType: string
        ) =>
          autoReplenishmentType === 'kit' &&
          autoReplenishment === true &&
          productType === 'Card',
        then: Yup.number()
          .positive(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
          .min(1, intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
          .integer(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
          .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
      }
    ),
    customProperties: Yup.array().of(CustomPropertySchema),
  });
