import { IntlShape } from 'react-intl';
import { CreateKitSchemasStep1 } from './createKitSchemasStep1';
import { CreateKitSchemasStep2 } from './createKitSchemasStep2';
import { createKitSchemasStep3 } from './createKitSchemasStep3';
import { CreateKitSchemaStep4 } from './createKitSchemasStep4';

export const createKitSchemas = (intl: IntlShape) => [
  CreateKitSchemasStep1(intl),
  CreateKitSchemasStep2(intl),
  createKitSchemasStep3(intl),
  CreateKitSchemaStep4,
];
