import { UserGroupRelationModel } from '../modules/auth/models/UserGroupRelationModel';

const getUserType = (ugr: UserGroupRelationModel) => {
  if (!ugr) {
    return;
  }
  if (!!ugr.locationId) {
    return 'location';
  }
  if (!!ugr.franchiseId) {
    return 'franchise';
  }
  if (!!ugr.clientId) {
    return 'client';
  }
  if (!!ugr.accountId) {
    return 'account';
  }
  return 'root';
};

export const showBadge = (ugr: UserGroupRelationModel, badgeType: string) => {
  const userType = getUserType(ugr);
  if (badgeType === 'account' && userType === 'root') {
    return true;
  }
  if (
    badgeType === 'client' &&
    (userType === 'account' || userType === 'root')
  ) {
    return true;
  }
  if (
    badgeType === 'franchise' &&
    (userType === 'client' || userType === 'root' || userType === 'account')
  ) {
    return true;
  }
  if (
    badgeType === 'location' &&
    (userType === 'franchise' ||
      userType === 'root' ||
      userType === 'account' ||
      userType === 'client')
  ) {
    return true;
  }
  return false;
};
