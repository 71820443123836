import { NumberParam, StringParam, BooleanParam } from 'serialize-query-params';

export const initialFilterKits = {
  filter: undefined,
  sortDirection: 1, // asc => 1||desc => -1
  sortColumn: 'code',
  pageNumber: 1,
  pageSize: 10,
  active: 1,
  accountId: undefined,
  clientId: undefined,
  franchiseId: undefined,
  locationId: undefined,
  orderable: undefined,
};

export type KitQueryFilterKeys = 'filter' | 'active';

export const queryParamsKitConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  filter: StringParam,
  active: BooleanParam,
  accountId: NumberParam,
  clientId: NumberParam,
  franchiseId: NumberParam,
  locationId: NumberParam,
  orderable: BooleanParam,
};

export const createKitInitialValues = {
  id: 0,
  isActive: false,
  code: '',
  description: '',
  premium: false,
  webOrderable: false,
  maxOrderQuantity: 1,
  shippingUnitSize: 1,
  products: [],
  files: [],
  availabilities: [],
  cascadeselects: undefined,
  kitProducts: [],
};

export interface KitProductItem {
  productId: number;
  isRequired: boolean;
  isPrimary: boolean;
  type: string;
  code: string;
  kitId: number;
  shippingUnitSize?: number;
}

export const editKitInitialValues = {
  id: 0,
  isActive: false,
  code: '',
  description: '',
  premium: false,
  webOrderable: false,
  maxOrderQuantity: 0,
  shippingUnitSize: 0,
  products: [],
  files: [],
  availabilities: [],
  cascadeselects: undefined,
  kitProducts: [],
};
