import groupsServices from '../../services/GroupsServices';
import { CustomInput } from '../../../../components/CustomInput';
import { groupSchemaValidation } from '../../utils/groupSchemaValidation';
import { PermissionItemModel } from './PermissionItem';
import { Fragment, useEffect, useState } from 'react';
import { editGroupInitialValues } from '../../utils/utils';
import { GroupModel } from '../../models/GroupModel';
//Modals imports
import { Formik, FormikHelpers, Form } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { ActionsComponent } from '../../../../components/ActionsComponent';
import { toast } from 'react-toastify';
import { handle400Error } from '../../../../utils/handle400Error';
import { useIntl } from 'react-intl';
import { GroupForm } from './GroupForm';
interface Props {
  refetch: () => void;
  id: number;
  show: boolean;
  onHide: () => void;
}

export function GroupEdit({ refetch, id, show, onHide }: Props) {
  const intl = useIntl();
  // Init Values & State
  const [group, setGroup] = useState<GroupModel>(editGroupInitialValues);
  const [loading, setLoading] = useState<boolean>(false);

  const permissions = [
    {
      key: 'accountPermission',
      permission: group.accountPermission,
      label: 'Account Permission',
    },
    {
      key: 'clientPermission',
      permission: group.clientPermission,
      label: 'Client Permission',
    },

    {
      key: 'franchisePermission',
      permission: group.franchisePermission,
      label: 'Franchise Permission',
    },
    {
      key: 'locationPermission',
      permission: group.locationPermission,
      label: 'Location Permission',
    },
    {
      key: 'productPermission',
      permission: group.productPermission,
      label: 'Product Permission',
    },
    {
      key: 'kitPermission',
      permission: group.kitPermission,
      label: 'Kit Permission',
    },
    {
      key: 'orderPermission',
      permission: group.orderPermission,
      label: 'Order Permission',
    },
    {
      key: 'inventoryPermission',
      permission: group.inventoryPermission,
      label: 'Inventory Permission',
    },
    {
      key: 'userGroupsPermission',
      permission: group.userGroupsPermission,
      label: 'User Groups Permissions',
    },
    {
      key: 'warehousePermission',
      permission: group.warehousePermission,
      label: 'Warehouse Permissions',
    },
    {
      key: 'warehouseInventoryPermission',
      permission: group.warehouseInventoryPermission,
      label: 'Warehouse Inventory Permissions',
    },
    {
      key: 'fulfillmentPermission',
      permission: group.fulfillmentPermission,
      label: 'Fulfillment Permissions',
    },
    {
      key: 'reportPermission',
      permission: group.reportPermission,
      label: 'Report Permissions',
    },
  ] as PermissionItemModel[];

  const updateLocation = (
    values: GroupModel,
    actions: FormikHelpers<GroupModel>
  ) => {
    groupsServices
      .updateGroup(group.id, values)
      .then(() => {
        toast.success('Group updated successfully.');
        onHide();
        refetch();
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          handle400Error(error.response.data.errors, actions.setErrors);
        }
      });
  };
  // Call the backend when there is a valid id and the modal has to be displayed.
  useEffect(() => {
    if (id && id !== 0 && show) {
      setLoading(true);
      groupsServices.getGroup(id).then((values) => {
        setGroup(values.data);
        setLoading(false);
      });
    }
  }, [id, show]);

  return (
    <>
      {!loading && (
        <Modal
          show={show}
          onHide={onHide}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Formik
            enableReinitialize
            initialValues={group}
            validationSchema={groupSchemaValidation(intl)}
            onSubmit={(values, actions) => {
              updateLocation(values, actions);
              actions.setSubmitting(false);
            }}
          >
            <Form>
              <Modal.Header className='px-9 pt-7 card-rounded'>
                <Modal.Title className='m-0 fw-bolder fs-3'>
                  Edit group
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='mt-1 px-6 py-3 position-relative z-index-1'>
                <Fragment>
                  <div className='fv-row mb-10'>
                    <div className='col-6'>
                      <CustomInput
                        isRequired={true}
                        id='name'
                        name='name'
                        type='text'
                        label='Group Name'
                      />
                    </div>
                  </div>
                  <GroupForm
                    permissions={permissions}
                    userGroupRelations={group.userGroupRelations}
                  />
                </Fragment>
              </Modal.Body>
              <Modal.Footer className='px-9 card-rounded  w-100'>
                <ActionsComponent
                  labelSubmit='Update'
                  showCancelButton={true}
                  onCancel={onHide}
                />
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      )}
    </>
  );
}
