import React, { useState } from 'react';
import { EntityModel } from '../models/EntityModel';
import { NavTabs } from './NavTabs';
import { useIntl } from 'react-intl';
import { GroupPermissionsFlagsDto } from '../modules/users/models/GroupModel';

var camalize = function camalize(str: string) {
  let newStr = str.substring(0, 1).toUpperCase();
  newStr = newStr + str.substring(1).toLowerCase();
  return newStr;
};

type Props = {
  entity: EntityModel;
  editComponent?: (params: any) => JSX.Element;
  desactivateComponent?: (params: any) => JSX.Element;
  goBack?: (params: any) => void;
  refetchIsActive?: () => void;
  setActivate?: (id: number) => void;
  setDeactivate?: (id: number, reason?: string) => void;
  permissions?: GroupPermissionsFlagsDto;
  refetch: (id: number) => void;
  accountLogo?: string;
};

export function EntityDetails({
  entity,
  editComponent,
  goBack,
  desactivateComponent,
  setActivate,
  setDeactivate,
  permissions,
  refetch,
  accountLogo,
}: Props) {
  const keys = Object.keys(entity.details);
  const intl = useIntl();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    refetch(entity.id);
  };
  const handleShowUpdateModal = () => {
    setShowUpdateModal(true);
  };

  const [showDesactivateModal, setShowDesactivateModal] = useState(false);
  const handleCloseDesactivateModal = () => {
    setShowDesactivateModal(false);
  };
  const handleShowDesactivateModal = () => {
    setShowDesactivateModal(true);
  };

  const EditComponentNode = editComponent
    ? () =>
        editComponent({
          id: entity.id,
          onHide: handleCloseUpdateModal,
          show: showUpdateModal,
        })
    : () => null;
  const DesactivateComponentNode = desactivateComponent
    ? () =>
        desactivateComponent({
          id: entity.id,
          isActive: entity.isActive,
          onHide: handleCloseDesactivateModal,
          show: showDesactivateModal,
          setDeactivate,
          setActivate,
        })
    : () => null;

  return (
    <>
      {editComponent && <EditComponentNode />}
      {desactivateComponent && setDeactivate && setActivate && (
        <DesactivateComponentNode />
      )}

      <div
        style={{
          borderBottomRightRadius: '0px',
          borderBottomLeftRadius: '0px',
        }}
        className='card mb-0 mb-xl-0'
      >
        <div className='card-body pt-5 pb-0'>
          <div className='row'>
            {!!accountLogo && (
              <div className='col-md-2 align-self-center'>
                <img style={{ width: '100%' }} src={accountLogo} alt='Valid' />
              </div>
            )}

            <div
              className={
                accountLogo ? 'col-md-10 float-end' : 'col-md-12 float-end'
              }
            >
              <div className='row'>
                <div className='d-flex align-items-center'>
                  <div
                    className='text-gray-800 text-hover-primary fs-1 fw-bolder me-1'
                    style={{ maxWidth: '600px', wordWrap: 'break-word' }}
                  >
                    {entity.name}
                  </div>
                  {entity.hasActive && (
                    <div className='ms-4'>
                      <span
                        className={`badge ${
                          entity.isActive ? 'bg-success' : 'bg-secondary'
                        } `}
                      >
                        {entity.isActive ? 'Active' : 'Inactive'}
                      </span>
                      {entity.deactivatedReason && (
                        <span className='text-muted ms-4'>
                          {entity.isActive ? '' : entity.deactivatedReason}
                        </span>
                      )}
                    </div>
                  )}
                  {desactivateComponent && permissions?.update && (
                    <div className='form-check form-switch form-check-custom  mx-5'>
                      <input
                        className={`form-check-input ${
                          entity.isActive ? 'bg-primary text-white' : ''
                        } `}
                        checked={entity.isActive}
                        type='checkbox'
                        onChange={() => handleShowDesactivateModal()}
                      />
                      <label
                        className={`form-check-label ${
                          entity.isActive ? 'text-muted' : 'text-muted'
                        } `}
                      >
                        {!entity.isActive ? 'Activate' : 'Deactivate'}
                      </label>
                    </div>
                  )}

                  <div style={{ marginLeft: 'auto' }}>
                    {goBack && (
                      <button
                        onClick={goBack}
                        className='btn btn-light btn-active-primary align-self-center'
                      >
                        <i className='fas fa-arrow-left'></i>
                        {intl.formatMessage({ id: 'ACTION.BACK' })}
                      </button>
                    )}
                    {editComponent && permissions?.update && (
                      <button
                        onClick={() => handleShowUpdateModal()}
                        className='m-2 btn  btn-light btn-primary align-self-center'
                      >
                        <i className='las la-edit fs-2'></i>
                        Edit {camalize(entity.type)}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className='row'>
                {keys.map((key, index) => {
                  return (
                    <div
                      key={key}
                      id={key}
                      className={keys.length > 5 ? 'col-md-6' : 'col-md-12'}
                    >
                      <div className='row my-1'>
                        <span
                          className={
                            keys.length > 5
                              ? 'col-md-6 fw-bolder fs-6 text-muted'
                              : 'col-md-3 fw-bolder fs-6 text-muted'
                          }
                        >
                          {camalize(key)}:
                        </span>
                        <span
                          className={
                            keys.length > 5
                              ? 'col-md-6  fw-bolder fs-6 text-dark'
                              : 'col-md-9  fw-bolder fs-6 text-dark'
                          }
                        >
                          {entity.details[key]}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px mt-2'>
            <NavTabs submenu={entity.menu} />
          </div>
        </div>
      </div>
    </>
  );
}
