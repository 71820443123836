import { Route, Switch } from 'react-router';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { ClientsList } from './components/clients/ClientsList';
import { ClientsView } from './components/clients/ClientView';

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Accounts',
    path: '/accounts',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

export function ClientsPage() {
  return (
    <>
      <Switch>
        <Route exact path='/accounts/:accountId/clients'>
          <PageTitle breadcrumbs={breadcrumbs}>Account Detail</PageTitle>
          <ClientsList />
        </Route>
        <Route path='/accounts/:accountId/clients/:clientId'>
          <ClientsView />
        </Route>
      </Switch>
    </>
  );
}
