import warehouseServices from '../services/WarehousesServices';
import { warehouseSchemaValidation } from '../utils/warehouseSchemaValidation';
import { Fragment, useEffect, useState } from 'react';
import { WarehouseModel } from '../models/WarehouseModel';
//Modals imports
import { Formik, FormikHelpers, Form } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { ActionsComponent } from '../../../components/ActionsComponent';
import { toast } from 'react-toastify';
import { handle400Error } from '../../../utils/handle400Error';
import { useIntl } from 'react-intl';
import { WarehouseForm } from './WarehouseForm';

interface Props {
  refetch: () => void;
  id: number;
  show: boolean;
  onHide: () => void;
}

export function WarehouseEdit({ refetch, id, show, onHide }: Props) {
  const intl = useIntl();
  // Init Values & State
  const [warehouse, setWarehouse] = useState<WarehouseModel | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const updateWarehouse = (
    values: WarehouseModel,
    actions: FormikHelpers<WarehouseModel>
  ) => {
    warehouseServices
      .updateWareHouse(id, values)
      .then(() => {
        toast.success('Warehouse updated successfully.');
        onHide();
        refetch();
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          handle400Error(error.response.data.errors, actions.setErrors);
        }
      });
  };
  // Call the backend when there is a valid id and the modal has to be displayed.
  useEffect(() => {
    if (id && id !== 0 && show) {
      setLoading(true);
      warehouseServices.getWarehouse(id).then((values) => {
        setWarehouse(values.data);
        setLoading(false);
      });
    }
  }, [id, show]);

  return (
    <>
      {!loading && warehouse && (
        <Modal
          show={show}
          onHide={onHide}
          backdrop='static'
          keyboard={false}
          dialogClassName='modal-90w'
          aria-labelledby='example-custom-modal-styling-title'
        >
          <Formik
            enableReinitialize
            initialValues={warehouse}
            validationSchema={warehouseSchemaValidation(intl)}
            onSubmit={(values, actions) => {
              updateWarehouse(values, actions);
              actions.setSubmitting(false);
            }}
          >
            <Form>
              <Modal.Header className='px-9 pt-7 card-rounded'>
                <Modal.Title className='m-0 fw-bolder fs-3'>
                  Edit Warehouse
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='container-fluid mt-1 px-10 py-8 position-relative z-index-1'>
                <WarehouseForm />
              </Modal.Body>
              <Modal.Footer className='px-9 card-rounded  w-100'>
                <ActionsComponent
                  labelSubmit='Update'
                  showCancelButton={true}
                  onCancel={onHide}
                />
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      )}
    </>
  );
}
