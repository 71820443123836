/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { PrivateRoutes } from './PrivateRoutes';
import { AuthPage } from '../modules/auth/index';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { RootState } from '../redux';
import { MasterInit } from '../../_metronic/layout/MasterInit';
import { CommonLoadingDialog } from '../components/CommonLoadingDialog';

const Routes: FC = () => {
  const user = useSelector<RootState>(
    ({ auth }: any) => auth.user,
    shallowEqual
  );
  return (
    <>
      <Switch>
        <Route path='/error' component={ErrorsPage} />

        {user ? (
          <>
            <CommonLoadingDialog />
            <MasterLayout>
              <PrivateRoutes />
            </MasterLayout>
          </>
        ) : (
          <Route>
            <AuthPage />
          </Route>
        )}
      </Switch>
      <MasterInit />
    </>
  );
};

export { Routes };
