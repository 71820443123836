import { Modal } from 'react-bootstrap-v5';
import { useIntl } from 'react-intl';
import { Formik, FormikHelpers, Form } from 'formik';
import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { CustomInput } from './CustomInput';

export const desactivateSchemaValidation = (intl: IntlShape) =>
  Yup.object().shape({
    reason: Yup.string()
      .ensure()
      .trim()
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .min(
        3,
        intl.formatMessage({ id: 'VALIDATION.MIN_LENGTH_FIELD' }, { min: 3 })
      )
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 255 })
      ),
  });
export const activateSchemaValidation = (intl: IntlShape) => {};
interface IsActiveModel {
  id: number;
  reason?: string;
}
const initialValues: IsActiveModel = {
  id: 0,
  reason: undefined,
};

interface Props {
  setActivate: (id: number) => void;
  setDeactivate: (id: number, reason?: string) => void;
  id: number;
  isActive: boolean;
  onHide: () => void;
  show: boolean;
}

export function IsActiveComponent({
  id,
  show,
  setActivate,
  setDeactivate,
  onHide,
  isActive,
}: Props) {
  const intl = useIntl();
  const save = (
    values: IsActiveModel,
    actions: FormikHelpers<IsActiveModel>
  ) => {
    if (isActive) {
      setDeactivate(id, values.reason);
    } else {
      setActivate(id);
    }
    onHide();
  };

  return (
    <>
      <Modal show={show} onHide={onHide} backdrop='static' keyboard={false}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            isActive
              ? desactivateSchemaValidation(intl)
              : activateSchemaValidation(intl)
          }
          onSubmit={(values, actions) => {
            save(values, actions);
            actions.setSubmitting(false);
          }}
        >
          <Form>
            <Modal.Body className='mt-1 px-6 py-3 position-relative z-index-1'>
              <div className='d-flex align-items-center mb-4'>
                <i
                  className={`las la-exclamation-triangle fs-5x text-warning`}
                ></i>
                <div className='flex-grow-3 me-4'>
                  <div className='fw-bold text-gray-700 fs-6'>
                    {!isActive
                      ? 'Are you sure you want to activate?'
                      : 'Are you sure you want to deactivate?'}
                  </div>
                </div>
              </div>
              <div>
                {isActive && (
                  <CustomInput
                    id='reason'
                    name='reason'
                    type='text'
                    label='Reason'
                    isRequired={true}
                  />
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className='p-2 card-rounded  w-100'>
              <div className='row'>
                <div className='text-center'>
                  <button
                    type='button'
                    id='desactivate-cancel'
                    className='btn btn-light me-3'
                    onClick={() => onHide()}
                  >
                    {intl.formatMessage({ id: 'ACTION.CANCEL' })}
                  </button>

                  <button
                    type='submit'
                    id='kt_modal_new_card_submit'
                    className='btn btn-warning'
                  >
                    <span className='indicator-label'>
                      {!isActive ? 'Activate' : 'Deactivate'}
                    </span>
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
