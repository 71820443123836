import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useParams, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { toAbsoluteUrlImage } from '../../../../../_metronic/helpers';
import { EntityDetails } from '../../../../components/EntityDetails';
import { IsActiveComponent } from '../../../../components/IsActiveComponent';
import { EntityModel } from '../../../../models/EntityModel';
import { FileModel } from '../../../../models/FileModel';
import { RootState } from '../../../../redux';
import {
  removeEntityAccount,
  setEntityAccount,
} from '../../../../redux/entity/entitiesActions';
import { initEntityAccount } from '../../../../utils/entityUtils';
import { GroupModel } from '../../../users/models/GroupModel';
import { ClientsPage } from '../../ClientsPage';
import accountsServices from '../../services/AccountsServices';
import { AccountEdit } from './AccountEdit';

interface Params {
  accountId: string;
}
export function AccountsView() {
  const { accountId } = useParams<Params>();
  const account = useSelector<RootState, EntityModel>(
    ({ entities }: any) => entities.account,
    shallowEqual
  );
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  useEffect(() => {
    if (!account && pathname.endsWith('clients')) {
      refetch(Number(accountId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = (accountId: number) => {
    accountsServices.getAccount(accountId).then((response) => {
      const entity: EntityModel = initEntityAccount(response.data);
      dispatch(setEntityAccount(entity));
    });
  };

  //Al salir del componente limpiamos el estado
  useEffect(() => {
    return () => {
      dispatch(removeEntityAccount());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();
  const goBack = () => {
    history.goBack();
    setTimeout(() => {
      dispatch(removeEntityAccount());
    }, 200);
  };
  //Activate/Desactivate
  const setActivate = (accountId: number) => {
    accountsServices.activateAccount(accountId).then(() => {
      toast.success('Successful activation');
      refetch(accountId);
    });
  };

  const setDeactivate = (accountId: number, reason?: string) => {
    accountsServices.desactivateAccount(accountId, reason).then(() => {
      toast.success('Successful deactivation');
      refetch(accountId);
    });
  };

  const accountLogo = useSelector<RootState, FileModel | undefined>(
    ({ auth }: any) => auth.branding?.accountLogo,
    shallowEqual
  );
  const logo = accountLogo && toAbsoluteUrlImage(accountLogo.path);

  return (
    <>
      <Route path='/accounts/:accountId/clients' exact>
        {account && (
          <EntityDetails
            entity={account}
            goBack={goBack}
            desactivateComponent={IsActiveComponent}
            setActivate={setActivate}
            setDeactivate={setDeactivate}
            permissions={group.accountPermission}
            editComponent={AccountEdit}
            refetch={refetch}
            accountLogo={logo}
          />
        )}
      </Route>
      <Route path='/accounts/:accountId/clients'>
        <ClientsPage />
      </Route>
    </>
  );
}
