import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const ProductItemSchemaValidation = (intl: IntlShape) =>
  Yup.object().shape({
    productId: Yup.number().min(1, 'Required').required('Required'),
    required: Yup.boolean(),
    primary: Yup.boolean(),
    type: Yup.string(),
  });
