import { Fragment, useCallback, useEffect, useState } from 'react';
import { usersServices } from '../../services/UsersServices';
import { CustomMultiSelect } from '../../../../components/CustomMultiSelect';
import { UserGroupsRelations } from '../../models/UserGroupsRelations';
import { ValueLabelPair } from '../../../../models/ValueLabelPair';

interface Props {
  user: UserGroupsRelations;
  values?: ValueLabelPair[];
}
export function SelectSetGroups({ user, values = [] }: Props) {
  const [groups, setGroups] = useState<ValueLabelPair[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const groupList = useCallback(() => {
    setLoading(true);
    usersServices.getGroups().then((values) => {
      const body = values.data;
      const items: ValueLabelPair[] =
        body.length > 0
          ? body.map((x) => {
              return { value: x.id, label: x.name };
            })
          : [];
      setGroups(items);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    groupList();
  }, [groupList]);

  return (
    <Fragment>
      <div className='row'>
        <div className='col-8'>
          <div className='form-label'>Groups</div>
          {!loading && (
            <CustomMultiSelect
              defaultValue={values.map((x) => {
                return groups.find((y) => y.value === x.value);
              })}
              isMulti
              name='groups'
              options={groups}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}
