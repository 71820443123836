import warehouseServices from '../services/WarehousesServices';
import { warehouseSchemaValidation } from '../utils/warehouseSchemaValidation';
import { WarehouseModel } from '../models/WarehouseModel';
//Modals imports
import { Formik, FormikHelpers, Form } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { ActionsComponent } from '../../../components/ActionsComponent';
import { toast } from 'react-toastify';
import { handle400Error } from '../../../utils/handle400Error';
import { useIntl } from 'react-intl';
import { WarehouseForm } from './WarehouseForm';
import { warehouseInitialValues } from '../utils/utils';

interface Props {
  refetch: () => void;
  show: boolean;
  onHide: () => void;
}

export function WarehouseCreate({ refetch, show, onHide }: Props) {
  const intl = useIntl();
  const createWarehouse = (
    values: WarehouseModel,
    actions: FormikHelpers<WarehouseModel>
  ) => {
    warehouseServices
      .createWareHouse(values)
      .then(() => {
        toast.success('Warehouse added successfully.');
        onHide();
        refetch();
        window.location.reload();
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          handle400Error(error.response.data.errors, actions.setErrors);
        }
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Formik
          enableReinitialize
          initialValues={warehouseInitialValues}
          validationSchema={warehouseSchemaValidation(intl)}
          onSubmit={(values, actions) => {
            createWarehouse(values, actions);
            actions.setSubmitting(false);
          }}
        >
          <Form>
            <Modal.Header className='px-9 pt-7 card-rounded'>
              <Modal.Title className='m-0 fw-bolder fs-3'>
                New Warehouse
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='container-fluid mt-1 px-10 py-8 position-relative z-index-1'>
              <WarehouseForm />
            </Modal.Body>
            <Modal.Footer className='px-9 card-rounded  w-100'>
              <ActionsComponent
                labelSubmit='Create'
                showCancelButton={true}
                onCancel={onHide}
              />
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
