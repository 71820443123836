import * as Yup from 'yup';

export const productAvailabilitiesSchema = Yup.object().shape({
  accountId: Yup.number().integer(),
  clientId: Yup.number().nullable(),
  franchiseId: Yup.number().nullable(),
  locationId: Yup.number().nullable(),
});

export const CreateProductSchemaStep4 = Yup.object().shape({
  availabilities: Yup.array()
    .of(productAvailabilitiesSchema)
    .min(1, 'You must add at least one availability'),
});
