import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Select from 'react-select/dist/declarations/src/Select';
import ReactSelect from 'react-select';
import { entityServices } from '../modules/users/services/EntityServices';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../app/redux';
import { UserGroupRelationModel } from '../modules/auth/models/UserGroupRelationModel';
import { EntityFiltersModel } from '../models/EntityFiltersModel';
import { FilterBadge } from '../models/FilterBadge';
import { showBadge } from '../utils/entityType';
export interface ProductValueLabelPair {
  value: number;
  label: string;
}

const keyLabel = 'cascadeselects';
interface PropsFilter {
  entityFilters: EntityFiltersModel;
  setEntityFilters: any;
  reset: any;
  setReset: any;
  level?: number;
  updateBadge?: any;
  setEntityListBadge?: any;
  entityFilterBadges?: any;
  setShowFilterBadges: any;
}
export function FilterCascadeEntitySelections({
  entityFilters,
  setEntityFilters,
  reset,
  setReset,
  level = 4,
  updateBadge,
  setEntityListBadge,
  entityFilterBadges,
  setShowFilterBadges,
}: PropsFilter) {
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  const [accounts, setAccounts] = useState<ProductValueLabelPair[]>([]);
  const [clients, setClients] = useState<ProductValueLabelPair[]>([]);
  const [franchises, setFranchises] = useState<ProductValueLabelPair[]>([]);
  const [locations, setLocations] = useState<ProductValueLabelPair[]>([]);

  const [showAccountTag, setShowAccountTag] = useState(false);
  const [showClientTag, setShowClientTag] = useState(false);
  const [showFranchiseTag, setShowFranchiseTag] = useState(false);
  const [showLocationTag, setShowLocationTag] = useState(false);

  //Clear refs
  const accountRef = useRef<null | Select>(null);
  const clientsRef = useRef<null | Select>(null);
  const franchiseRef = useRef<null | Select>(null);
  const locationsRef = useRef<null | Select>(null);
  // Disable Flags
  const [d1, setD1] = useState(true);
  const [d2, setD2] = useState(true);
  const [d3, setD3] = useState(true);

  // Get initial values from the url
  const accountsList = useCallback(async () => {
    const values = await entityServices.getAccounts();
    const body = values.data;
    const items: ProductValueLabelPair[] =
      body.length > 0
        ? body.map((x) => {
            return { value: x.id, label: x.accountName };
          })
        : [];
    setAccounts(items);
    /*if (!!entityFilters.accountId) {
      const option = items.filter((x) => x.value === entityFilters.accountId);
      if (option.length === 1) {
        handleAccountChange(option[0]);
      }
    }*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const clientList = useCallback(async (accountId: number) => {
    const values = await entityServices.getClients(accountId);
    const body = values.data;
    const items: ProductValueLabelPair[] =
      body.length > 0
        ? body.map((x) => {
            return { value: x.id, label: x.name };
          })
        : [];
    setClients(items);
    setD1(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const franchiseList = useCallback(async (clientId: number) => {
    const values = await entityServices.getFranchises(clientId);
    const body = values.data;
    const items: ProductValueLabelPair[] =
      body.length > 0
        ? body.map((x) => {
            return { value: x.id, label: x.name };
          })
        : [];
    setFranchises(items);
    setD2(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const locationsList = useCallback(
    async (franchiseId: number) => {
      const values = await entityServices.getLocations(franchiseId);
      const body = values.data;
      const items: ProductValueLabelPair[] =
        body.length > 0
          ? body.map((x) => {
              return { value: x.id, label: x.name };
            })
          : [];
      setLocations(items);
      setD3(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const handleAccountChange = (option: any) => {
    if (option) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        accountId: option.value,
      }));
      clientList(option.value);
      if (!!showBadge(userGroupRelations, 'account')) {
        updateEntityListBadge(option, 'accountId', accountRef);
      }
    } else {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        accountId: undefined,
      }));
    }

    if (!showAccountTag) {
      // Clear Refs 2 .. N
      clientsRef.current?.clearValue();
      franchiseRef.current?.clearValue();
      locationsRef.current?.clearValue();
      setD1(true);
      setD2(true);
      setD3(true);
    }
  };
  const handleClientChange = (option: any) => {
    if (option) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        clientId: option.value,
      }));
      franchiseList(option.value);
      if (level > 1 && !!showBadge(userGroupRelations, 'client')) {
        updateEntityListBadge(option, 'clientId', clientsRef);
      }
    } else {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        clientId: undefined,
      }));
    }

    if (!showClientTag) {
      // Clear Refs 3 .. N
      franchiseRef.current?.clearValue();
      locationsRef.current?.clearValue();
      setD2(true);
      setD3(true);
    }
  };
  const handleFranchiseChange = (option: any) => {
    if (option) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        franchiseId: option.value,
      }));
      locationsList(option.value);
      if (level > 2 && !!showBadge(userGroupRelations, 'franchise')) {
        updateEntityListBadge(option, 'franchiseId', franchiseRef);
      }
    } else {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        franchiseId: undefined,
      }));
    }
    if (!showFranchiseTag) {
      // Clear Refs 4 .. N
      locationsRef.current?.clearValue();
      setD3(true);
    }
  };
  const handleLocationChange = (option: any) => {
    if (option) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        locationId: option.value,
      }));
      if (level > 3 && !!showBadge(userGroupRelations, 'location')) {
        updateEntityListBadge(option, 'locationId', locationsRef);
      }
    } else {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        locationId: undefined,
      }));
    }
  };

  const updateEntityListBadge = (option: any, key: string, ref: any) => {
    if (!setEntityListBadge || !entityFilterBadges) {
      return;
    }
    if (option) {
      const badges = [...entityFilterBadges];
      const finded = entityFilterBadges?.find(
        (item: FilterBadge) => item.key === key
      );
      if (finded && finded !== undefined) {
        badges.splice(badges.indexOf(finded), 1, {
          key: key,
          value: option.label,
          ref: ref,
        });
      } else {
        badges.push({
          key: key,
          value: option.label,
          ref: ref,
        });
      }
      setEntityListBadge(badges);
    } else {
      setEntityListBadge((s: FilterBadge[]) => s.filter((x) => x.key !== key));
    }
  };
  /*useEffect(() => {
    if (userGroupRelations.accountId) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        accountId: userGroupRelations.accountId,
      }));
    }

    if (userGroupRelations.clientId) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        clientId: userGroupRelations.clientId,
      }));
    }

    if (userGroupRelations.franchiseId) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        franchiseId: userGroupRelations.franchiseId,
      }));
    }

    if (userGroupRelations.locationId) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        franchiseId: userGroupRelations.locationId,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);*/

  useEffect(() => {
    if (
      !!entityFilters.accountId &&
      !!entityFilters.clientId &&
      !!entityFilters.franchiseId &&
      !!entityFilters.locationId
    ) {
      accountsList();
      setShowAccountTag(true);
      setShowClientTag(true);
      setShowFranchiseTag(true);
      setShowLocationTag(true);
    } else if (
      !!entityFilters.accountId &&
      !!entityFilters.clientId &&
      !!entityFilters.franchiseId
    ) {
      accountsList();
      setShowAccountTag(true);
      setShowClientTag(true);
      setShowFranchiseTag(true);
    } else if (!!entityFilters.accountId && !!entityFilters.clientId) {
      accountsList();
      setShowAccountTag(true);
      setShowClientTag(true);
    } else if (!!entityFilters.accountId) {
      accountsList();
      setShowAccountTag(true);
    } else {
      accountsList();
    }
    //accountsList();

    /*
    const initAccount = userGroupRelations.accountId || entityFilters.accountId;
    if (initAccount) {
      clientList(initAccount);
    }

    if (userGroupRelations.franchiseId) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        franchiseId: userGroupRelations.franchiseId,
      }));
    } else {
      const initClientId =
        userGroupRelations.clientId || entityFilters.clientId;
      if (initClientId) {
        franchiseList(initClientId);
      }
    }

    if (userGroupRelations.locationId) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        franchiseId: userGroupRelations.locationId,
      }));
    } else {
      const initFranchiseId =
        userGroupRelations.franchiseId || entityFilters.franchiseId;
      if (initFranchiseId) {
        locationsList(initFranchiseId);
      }
    }*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Auto select options:
  useEffect(() => {
    /*if (accounts.length === 1 && !!showAccountTag){
      handleAccountChange(accounts[0]);
    }*/
    if ((accounts.length >= 1 && !!showAccountTag) || accounts.length === 1) {
      const option = accounts.filter(
        (x) => x.value === entityFilters.accountId
      );
      if (option.length === 1) {
        handleAccountChange(option[0]);
        /*setQueryParams((s: any) => ({
          ...s,
          accountId: entityFilters.accountId,
        }));*/
        setShowAccountTag(false);
        setShowFilterBadges(true);
      } else {
        handleAccountChange(accounts[0]);
        setShowAccountTag(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);
  useEffect(() => {
    //if (clients.length === 1) handleClientChange(clients[0]);
    if ((clients.length >= 1 && !!showClientTag) || clients.length === 1) {
      const option = clients.filter((x) => x.value === entityFilters.clientId);
      if (option.length === 1) {
        handleClientChange(option[0]);
        /*setQueryParams((s: any) => ({
          ...s,
          clientId: entityFilters.clientId,
          accountId: entityFilters.accountId,
        }));*/
        setShowClientTag(false);
        setShowFilterBadges(true);
      } else {
        handleClientChange(clients[0]);
        setShowClientTag(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);
  useEffect(() => {
    //if (franchises.length === 1) handleFranchiseChange(franchises[0]);
    if (
      (franchises.length >= 1 && !!showFranchiseTag) ||
      franchises.length === 1
    ) {
      const option = franchises.filter(
        (x) => x.value === entityFilters.franchiseId
      );
      if (option.length === 1) {
        handleFranchiseChange(option[0]);
        /*setQueryParams((s: any) => ({
          ...s,
          clientId: entityFilters.clientId,
          accountId: entityFilters.accountId,
          franchiseId: entityFilters.franchiseId,
        }));*/
        setShowFranchiseTag(false);
        setShowFilterBadges(true);
      } else {
        handleFranchiseChange(franchises[0]);
        setShowFranchiseTag(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [franchises]);
  useEffect(() => {
    //if (locations.length === 1) handleLocationChange(locations[0]);
    if (
      (locations.length >= 1 && !!showLocationTag) ||
      locations.length === 1
    ) {
      const option = locations.filter(
        (x) => x.value === entityFilters.locationId
      );
      if (option.length === 1) {
        handleLocationChange(option[0]);
        /*setQueryParams((s: any) => ({
          ...s,
          clientId: entityFilters.clientId,
          accountId: entityFilters.accountId,
          franchiseId: entityFilters.franchiseId,
          locationId: entityFilters.locationId,
        }));*/
        setShowLocationTag(false);
        setShowFilterBadges(true);
      } else {
        handleLocationChange(locations[0]);
        setShowLocationTag(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);
  /*
  useEffect(() => {
    if (clients.length === 1) handleClientChange(clients[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);
  useEffect(() => {
    if (franchises.length === 1) handleFranchiseChange(franchises[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [franchises]);
  useEffect(() => {
    if (locations.length === 1) handleLocationChange(locations[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);*/

  // Reset Filters
  useEffect(() => {
    if (reset && !userGroupRelations.accountId) {
      // Clear Refs 2 .. N
      accountRef.current?.clearValue();
      clientsRef.current?.clearValue();
      franchiseRef.current?.clearValue();
      locationsRef.current?.clearValue();
      setD1(true);
      setD2(true);
      setD3(true);
      return setReset(false);
    }
    if (reset && !userGroupRelations.clientId) {
      // Clear Refs 2 .. N
      clientsRef.current?.clearValue();
      franchiseRef.current?.clearValue();
      locationsRef.current?.clearValue();
      setD2(true);
      setD3(true);
      return setReset(false);
    }
    if (reset && !userGroupRelations.franchiseId) {
      // Clear Refs 2 .. N
      franchiseRef.current?.clearValue();
      locationsRef.current?.clearValue();
      setD3(true);
      return setReset(false);
    }
    if (reset && !userGroupRelations.locationId) {
      locationsRef.current?.clearValue();
      return setReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);
  return (
    <Fragment>
      <div className='row'>
        {!userGroupRelations.accountId && (
          <div className='mb-2' onClick={(e) => e.stopPropagation()}>
            <label className='form-label'>Account</label>
            <ReactSelect
              placeholder='All'
              value={
                accounts[
                  accounts.findIndex((x) => x.value === entityFilters.accountId)
                ]
              }
              isClearable={!userGroupRelations.accountId ? true : false}
              name={`${keyLabel}.accountId`}
              id={`${keyLabel}.accountId`}
              key={`${keyLabel}.accountId`}
              options={accounts}
              onChange={handleAccountChange}
              ref={accountRef}
            />
          </div>
        )}
        {!userGroupRelations.clientId && level > 1 && (
          <div className='mb-2' onClick={(e) => e.stopPropagation()}>
            <label className='form-label'>Client</label>
            <ReactSelect
              placeholder='All'
              isClearable={!userGroupRelations.clientId ? true : false}
              key={`${keyLabel}.clientId`}
              id={`${keyLabel}.clientId`}
              options={clients}
              name={`${keyLabel}.clientId`}
              ref={clientsRef}
              value={
                clients[
                  clients.findIndex((x) => x.value === entityFilters.clientId)
                ]
              }
              isDisabled={d1}
              onChange={handleClientChange}
            />
          </div>
        )}
        {!userGroupRelations.franchiseId && level > 2 && (
          <div className='mb-2' onClick={(e) => e.stopPropagation()}>
            <label className='form-label'>Franchise</label>
            <ReactSelect
              placeholder='All'
              isClearable={!userGroupRelations.franchiseId ? true : false}
              id={`${keyLabel}.franchiseId`}
              key={`${keyLabel}.franchiseId`}
              options={franchises}
              name={`${keyLabel}.franchiseId`}
              ref={franchiseRef}
              value={
                franchises[
                  franchises.findIndex(
                    (x) => x.value === entityFilters.franchiseId
                  )
                ]
              }
              isDisabled={d2}
              onChange={handleFranchiseChange}
            />
          </div>
        )}
        {!userGroupRelations.locationId && level > 3 && (
          <div className='mb-2' onClick={(e) => e.stopPropagation()}>
            <label className='form-label'>Location</label>
            <ReactSelect
              placeholder='All'
              isClearable={!userGroupRelations.locationId ? true : false}
              id={`${keyLabel}.locationId`}
              name={`${keyLabel}.locationId`}
              key={`${keyLabel}.locationId`}
              options={locations}
              ref={locationsRef}
              value={
                locations[
                  locations.findIndex(
                    (x) => x.value === entityFilters.locationId
                  )
                ]
              }
              isDisabled={d3}
              onChange={handleLocationChange}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}
