import { useField, useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';
import { useRef, useState } from 'react';
import { toAbsoluteUrl, toAbsoluteUrlImage } from '../../_metronic/helpers';

interface CustomImageInputProps {
  name: string;
  title: string;
  accept?: string;
  recommended?: string;
  width?: string;
  height?: string;
}

export const urlImage = 'https://www.valid-pnp-test.solisystems.com/';
const CustomImageInput = ({
  name,
  title,
  accept = '.png, .jpg',
  recommended = '',
  width = '125px',
  height = '125px',
}: CustomImageInputProps) => {
  const [field, meta] = useField(name);
  const [image, setImage] = useState<string | null>(null);
  const { errors, setFieldValue } = useFormikContext<any>();
  const inputImageRef = useRef<HTMLInputElement>(null);

  const removeImg = useCallback(() => {
    setFieldValue(name, null);
    setImage(null);
    if (inputImageRef.current) inputImageRef.current.value = '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (field.value?.id) {
      const x = toAbsoluteUrlImage(field.value?.path);
      setImage(x);
    } else if (!(field.value instanceof File)) {
      removeImg();
    }
  }, [field.value, field.value?.id, field.value?.path, name, removeImg]);

  const errorValue = errors[field.name] ? errors[field.name] : meta.error;
  const imageStyle = !!image
    ? {
        backgroundImage: `url(${image})`,
      }
    : {};
  const onChangeImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files ? e.target.files[0] : null;
    if (!file) {
      return;
    }
    const blobImage = URL.createObjectURL(file);
    setImage(blobImage);
    setFieldValue(name, file);
  };

  return (
    <>
      <div className='fw-bolder fs-4 mb-6'>{title}</div>
      <div
        className='image-input image-input-outline'
        data-kt-image-input='true'
        style={{
          backgroundImage: toAbsoluteUrl('public/media/images/blank.png'),
        }}
      >
        <div
          className={`image-input-wrapper w-${width} h-${height}`}
          style={imageStyle}
        ></div>
        <label
          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='change'
          data-bs-toggle='tooltip'
          title='Change avatar'
        >
          <i className='bi bi-pencil-fill fs-7'></i>
          <input
            ref={inputImageRef}
            type='file'
            name={`file_${name}`}
            accept={accept}
            onChange={onChangeImg}
          />
        </label>
        <span
          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='cancel'
          data-bs-toggle='tooltip'
          title='Cancel avatar'
        >
          <i className='bi bi-x fs-2'></i>
        </span>
        <span
          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='remove'
          data-bs-toggle='tooltip'
          title='Remove avatar'
          onClick={() => removeImg()}
        >
          <i className='bi bi-x fs-2'></i>
        </span>
      </div>
      <div className='form-text'>
        Valid image types JPG and PNG. Max Size: 2MB
        {' ' + recommended}
      </div>
      {errorValue && (
        <div className='text-danger'>
          <div data-field='avatar' data-validator='file'>
            {errorValue}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomImageInput;
