import { Fragment, useEffect, useRef } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select/dist/declarations/src/Select';
import { CustomFilter } from '../../../components/CustomFilter';
import ReactSelect from 'react-select';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { EntityFiltersModel } from '../../../models/EntityFiltersModel';
import { FilterBadge } from '../../../models/FilterBadge';
import { CustomBadgeFilter } from '../../../components/CustomBadgeFilter';
import { FilterCascadeEntitySelections } from '../../../components/FilterCascadeEntitySelection';
import { productsType } from '../../products/components/ProductInformationStep1';
import { initialFilterInventories } from '../utils/utils';

interface PropsFilter {
  queryParams: any;
  setQueryParams: any;
}
const activeOptions = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];
const inventoryLevelOptions = [
  { value: 'All', label: 'All' },
  { value: 'Low', label: 'Low' },
  { value: 'NotLow', label: 'Not Low' },
];
const productTypeOptions = productsType;
export function InventoriesFilter({
  setQueryParams,
  queryParams,
}: PropsFilter) {
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  // Default Filters:
  const [shouldApplyFilter, setShouldApplyFilter] = useState<boolean>(false);
  // Filter States
  const [isActive, setIsActive] = useState<boolean | null>();
  const isActiveRef = useRef<null | Select>(null);
  const [from, setFrom] = useState<string | null>();
  const fromRef = useRef<any>(null);

  const [to, setTo] = useState<string | null>();
  const toRef = useRef<any>(null);

  const [reset, setReset] = useState<boolean>(false);
  const [quantityDays, setQuantityDays] = useState<number | null>();
  const quantityDaysRef = useRef<any>(null);

  // Filter by ProductType and  Inventory level of the products
  const [productType, setProductType] = useState<'' | null>();
  const [inventoryLevel, setInventoryLevel] = useState<'' | null>();
  const productTypeRef = useRef<null | Select>(null);
  const inventoryLevelRef = useRef<null | Select>(null);

  //Entity
  const [entityFilters, setEntityFilters] = useState<EntityFiltersModel>({
    accountId: queryParams.accountId ? queryParams.accountId : null,
    clientId: queryParams.clientId ? queryParams.clientId : null,
    franchiseId: queryParams.franchiseId ? queryParams.franchiseId : null,
    locationId: queryParams.locationId ? queryParams.locationId : null,
  });

  const handleIsActive = (params: any) => {
    if (params) {
      setIsActive(params.value);
    } else {
      setIsActive(undefined);
    }
  };

  const handleFrom = (e: any) => {
    if (e.target.value) {
      setFrom(e.target.value);
    } else {
      setFrom(undefined);
    }
  };

  const handleTo = (e: any) => {
    if (e.target.value) {
      setTo(e.target.value);
    } else {
      setTo(undefined);
    }
  };

  const handleQuantityDays = (e: any) => {
    if (e.target.value) {
      setQuantityDays(e.target.value);
    } else {
      setQuantityDays(undefined);
    }
  };
  const handleProductType = (params: any) => {
    if (params) {
      setProductType(params.value);
    } else {
      setProductType(undefined);
    }
  };
  const handleInventoryLevel = (params: any) => {
    if (params) {
      setInventoryLevel(params.value);
    } else {
      setInventoryLevel(undefined);
    }
  };
  const resetFilters = () => {
    setReset(true);
  };

  const applyFilter = () => {
    const newQueryParams = { ...queryParams };
    newQueryParams.from = from;
    newQueryParams.to = to;
    newQueryParams.pageNumber = 1;
    newQueryParams.active = isActive;
    newQueryParams.accountId = entityFilters?.accountId;
    newQueryParams.clientId = entityFilters?.clientId;
    newQueryParams.franchiseId = entityFilters?.franchiseId;
    newQueryParams.locationId = entityFilters?.locationId;
    newQueryParams.quantityDays = quantityDays;
    newQueryParams.productType = productType;
    newQueryParams.inventoryLevel = inventoryLevel;
    setQueryParams(newQueryParams);
  };
  useEffect(() => {
    if (reset) {
      isActiveRef.current?.clearValue();
      inventoryLevelRef.current?.clearValue();
      productTypeRef.current?.clearValue();

      let newQueryParams = {
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };
      setTo(undefined);
      setFrom(undefined);
      setQuantityDays(90);
      setQueryParams({ ...initialFilterInventories, ...newQueryParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const showEntitiesFilter = !userGroupRelations.locationId;

  /** START Filter Badges */
  const [filterBadges, setFilteBadges] = useState<FilterBadge[]>([]);
  const [entityFilterBadges, setEntityFilteBadges] = useState<FilterBadge[]>(
    []
  );
  // Get initial values from the url
  useEffect(() => {
    let clearFilters = [];
    if (!!queryParams.inventoryLevel) {
      updateBadge(
        'inventoryLevel',
        'Inventory Level: ' + queryParams.inventoryLevel,
        inventoryLevelRef
      );
      setInventoryLevel(queryParams.inventoryLevel);
    } else {
      clearFilters.push('inventoryLevel');
    }
    if (!!queryParams.productType) {
      updateBadge(
        'productType',
        'Product Type: ' + queryParams.productType,
        productTypeRef
      );
      setProductType(queryParams.productType);
    } else {
      clearFilters.push('productType');
    }

    if (!!queryParams.active || queryParams.active === false) {
      updateBadge(
        'active',
        queryParams.active === true
          ? 'Active'
          : queryParams.active === false
          ? 'Inactive'
          : '',
        isActiveRef
      );
      setIsActive(queryParams.active);
    } else {
      clearFilters.push('active');
    }

    if (queryParams.from !== null && queryParams.from !== undefined) {
      setFrom(queryParams.from);
      updateBadge('from', 'Lvl. from: ' + queryParams.from, fromRef);
    } else {
      clearFilters.push('from');
    }
    if (queryParams.to !== null && queryParams.to !== undefined) {
      setTo(queryParams.to);
      updateBadge('to', 'Lvl. to: ' + queryParams.to, toRef);
    } else {
      clearFilters.push('to');
    }
    if (
      queryParams.quantityDays !== null &&
      queryParams.quantityDays !== undefined
    ) {
      setQuantityDays(queryParams.quantityDays);
      updateBadge(
        'quantityDays',
        queryParams.quantityDays + ' Days',
        quantityDaysRef
      );
    } else {
      clearFilters.push('quantityDays');
    }
    if (entityFilterBadges.length > 0) {
      entityFilterBadges.forEach((item: FilterBadge) => {
        updateBadge(item.key, item.value, item.ref);
      });
    }

    if (!queryParams.accountId) clearFilters.push('accountId');
    if (!queryParams.clientId) clearFilters.push('clientId');
    if (!queryParams.franchiseId) clearFilters.push('franchiseId');
    if (!queryParams.locationId) clearFilters.push('locationId');

    if (clearFilters.length > 0) {
      clearMultipleFilter(clearFilters);
    }

    // Get initial values from the url
    const newQueryParams = {
      ...entityFilters,
      accountId: queryParams.accountId ? queryParams.accountId : undefined,
      clientId: queryParams.clientId ? queryParams.clientId : undefined,
      franchiseId: queryParams.franchiseId
        ? queryParams.franchiseId
        : undefined,
      locationId: queryParams.locationId ? queryParams.locationId : undefined,
    };
    setEntityFilters(newQueryParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  // Get initial values from the url
  useEffect(() => {
    if (shouldApplyFilter) {
      let clearFilters = [];
      if (!!queryParams.inventoryLevel) {
        updateBadge(
          'inventoryLevel',
          'Inventory Level: ' + queryParams.inventoryLevel,
          inventoryLevelRef
        );
        setInventoryLevel(queryParams.inventoryLevel);
      } else {
        clearFilters.push('inventoryLevel');
      }
      if (!!queryParams.productType) {
        updateBadge(
          'productType',
          'Product Type: ' + queryParams.productType,
          productTypeRef
        );
        setProductType(queryParams.productType);
      } else {
        clearFilters.push('productType');
      }

      if (!!queryParams.active || queryParams.active === false) {
        updateBadge(
          'active',
          queryParams.active === true
            ? 'Active'
            : queryParams.active === false
            ? 'Inactive'
            : '',
          isActiveRef
        );
        setIsActive(queryParams.active);
      } else {
        clearFilters.push('active');
      }

      if (queryParams.from !== null && queryParams.from !== undefined) {
        setFrom(queryParams.from);
        updateBadge('from', 'Lvl. from: ' + queryParams.from, fromRef);
      } else {
        clearFilters.push('from');
      }
      if (queryParams.to !== null && queryParams.to !== undefined) {
        setTo(queryParams.to);
        updateBadge('to', 'Lvl. to: ' + queryParams.to, toRef);
      } else {
        clearFilters.push('to');
      }
      if (
        queryParams.quantityDays !== null &&
        queryParams.quantityDays !== undefined
      ) {
        setQuantityDays(queryParams.quantityDays);
        updateBadge(
          'quantityDays',
          queryParams.quantityDays + ' Days',
          quantityDaysRef
        );
      } else {
        clearFilters.push('quantityDays');
      }
      if (entityFilterBadges.length > 0) {
        entityFilterBadges.forEach((item: FilterBadge) => {
          updateBadge(item.key, item.value, item.ref);
        });
      }

      if (!queryParams.accountId) clearFilters.push('accountId');
      if (!queryParams.clientId) clearFilters.push('clientId');
      if (!queryParams.franchiseId) clearFilters.push('franchiseId');
      if (!queryParams.locationId) clearFilters.push('locationId');

      if (clearFilters.length > 0) {
        clearMultipleFilter(clearFilters);
      }

      // Get initial values from the url
      const newQueryParams = {
        ...entityFilters,
        accountId: queryParams.accountId ? queryParams.accountId : undefined,
        clientId: queryParams.clientId ? queryParams.clientId : undefined,
        franchiseId: queryParams.franchiseId
          ? queryParams.franchiseId
          : undefined,
        locationId: queryParams.locationId ? queryParams.locationId : undefined,
      };
      setEntityFilters(newQueryParams);
      setShouldApplyFilter(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldApplyFilter, entityFilterBadges]);

  const clearMultipleFilter = (filters: Array<string>) => {
    let badges = filterBadges;
    filters.forEach((element) => {
      badges = badges.filter((item: FilterBadge) => item.key !== element);
    });
    setFilteBadges(badges);
  };
  const clearFilter = (ref: any, keyParam: string) => {
    if (
      keyParam === 'accountId' ||
      keyParam === 'clientId' ||
      keyParam === 'franchiseId'
    ) {
      clearMultipleFilter([
        'accountId',
        'clientId',
        'franchiseId',
        'locationId',
      ]);
      setEntityFilteBadges([]);
      let params = { ...queryParams };
      params['accountId'] = userGroupRelations.accountId
        ? userGroupRelations.accountId
        : undefined;
      params['clientId'] = userGroupRelations.clientId
        ? userGroupRelations.clientId
        : undefined;
      params['franchiseId'] = userGroupRelations.franchiseId
        ? userGroupRelations.franchiseId
        : undefined;
      params['locationId'] = userGroupRelations.locationId
        ? userGroupRelations.locationId
        : undefined;
      // When removing a badge you should be redirected to page 1.
      params['pageNumber'] = 1;
      setQueryParams(params);

      filterBadges
        .find((element) => element.key === 'accountId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'clientId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'franchiseId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'locationId')
        ?.ref.current?.clearValue();
    } else if (ref) {
      if (ref.current?.type === 'number') {
        ref.current.value = null;
      } else {
        ref.current?.clearValue();
      }
      let params = { ...queryParams };
      params[keyParam] = undefined;
      // When removing a badge you should be redirected to page 1.
      params['pageNumber'] = 1;
      setQueryParams(params);
    }

    setFilteBadges(
      filterBadges.filter((item: FilterBadge) => item.key !== keyParam)
    );
  };
  const updateBadge = (key: string, value: string, ref: any) => {
    let badges = filterBadges;
    const finded = badges?.find((item: FilterBadge) => item.key === key);
    if (finded && finded !== undefined) {
      badges.splice(badges.indexOf(finded), 1, {
        key: key,
        value: value,
        ref: ref,
      });
    } else {
      badges.push({
        key: key,
        value: value,
        ref: ref,
      });
    }
    setFilteBadges(badges);
  };

  /** END Filter Badges */

  return (
    <>
      {filterBadges?.map((item: FilterBadge) => {
        if (item.value && item.value !== '')
          return (
            <CustomBadgeFilter
              text={item.value}
              key={item.key}
              badgeKey={item.key}
              clearFilter={() => {
                if (item.ref) clearFilter(item.ref, item.key);
              }}
            ></CustomBadgeFilter>
          );
        else return <></>;
      })}

      <CustomFilter
        applyFilters={applyFilter}
        resetFilters={resetFilters}
        scroll={false}
        size={showEntitiesFilter ? 'lg' : 'md'}
      >
        <Fragment>
          <div className='row'>
            <div className='col-md-6'>
              <FilterCascadeEntitySelections
                setReset={setReset}
                reset={reset}
                entityFilters={entityFilters}
                setEntityFilters={setEntityFilters}
                setEntityListBadge={setEntityFilteBadges}
                entityFilterBadges={entityFilterBadges}
                setShowFilterBadges={setShouldApplyFilter}
              />

              <div className='my-2' onClick={(e) => e.stopPropagation()}>
                <label className='form-label fw-bold'>Product Type</label>
                <ReactSelect
                  maxMenuHeight={400}
                  placeholder={<FormattedMessage id='COMMON.ALL' />}
                  isClearable
                  name='productType'
                  id='productType'
                  onChange={handleProductType}
                  options={productTypeOptions}
                  value={
                    productTypeOptions
                      ? productTypeOptions.filter(
                          (x: any) => x.value === productType
                        )[0]
                      : null
                  }
                  ref={productTypeRef}
                />
              </div>
              <div className='mb-2' onClick={(e) => e.stopPropagation()}>
                <label className='form-label fw-bold'>Inventory Level</label>
                <ReactSelect
                  maxMenuHeight={400}
                  placeholder={<FormattedMessage id='COMMON.ALL' />}
                  isClearable
                  name='inventoryLevel'
                  id='inventoryLevel'
                  onChange={handleInventoryLevel}
                  options={inventoryLevelOptions}
                  value={
                    inventoryLevelOptions
                      ? inventoryLevelOptions.filter(
                          (x: any) => x.value === inventoryLevel
                        )[0]
                      : null
                  }
                  ref={inventoryLevelRef}
                />
              </div>
            </div>
            <div className={showEntitiesFilter ? 'col-md-6' : 'col-md-12'}>
              <div className='mb-2' onClick={(e) => e.stopPropagation()}>
                <label className='form-label fw-bold'>Active</label>
                <ReactSelect
                  placeholder={<FormattedMessage id='COMMON.ALL' />}
                  isClearable
                  name='isActive'
                  id='isActive'
                  onChange={handleIsActive}
                  options={activeOptions}
                  value={
                    activeOptions
                      ? activeOptions.filter(
                          (x: any) => x.value === isActive
                        )[0]
                      : null
                  }
                  ref={isActiveRef}
                  defaultValue={activeOptions[0]}
                />
                <label className='form-label fw-bold my-2'>
                  Current Level From
                </label>
                <input
                  ref={fromRef}
                  autoComplete='none'
                  type='number'
                  className='form-control form-control-sm form-control-solid'
                  defaultValue={from ? from : ''}
                  onChange={(e) => handleFrom(e)}
                />
                <label className='form-label fw-bold my-2'>
                  Current Level To
                </label>
                <input
                  ref={toRef}
                  autoComplete='none'
                  type='number'
                  className='form-control form-control-sm form-control-solid'
                  defaultValue={to ? to : ''}
                  onChange={(e) => handleTo(e)}
                />
                <label className='form-label fw-bold my-2'>Quantity Days</label>
                <input
                  ref={quantityDaysRef}
                  autoComplete='none'
                  type='number'
                  className='form-control form-control-sm form-control-solid'
                  defaultValue={quantityDays ? quantityDays : 90}
                  onChange={(e) => handleQuantityDays(e)}
                />
              </div>
            </div>
          </div>
          <div className='row'></div>
        </Fragment>
      </CustomFilter>
    </>
  );
}
