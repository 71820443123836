//Modals imports
import { Formik, FormikHelpers, Form } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { ActionsComponent } from '../../../components/ActionsComponent';
import { handle400Error } from '../../../utils/handle400Error';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useState } from 'react';
import mfDataServices from '../services/mfDataServices';
import { CustomSelect } from '../../../components/CustomSelect';
import { MFDataImportSchema } from '../utils/MFDataImportSchema';
import CustomFileInput from '../../../components/CustomFileInput';
import { MFDataUploadModel } from '../models/MFDataUploadModel';
import { uploadInitialValues } from '../utils/utils';
import { ManufacturerDataValidateHeaderModel } from '../models/MFDataValidateHeaderModel';
import productServices from '../../products/services/ProductsServices';

interface Props {
  refetch?: () => void;
  show: boolean;
  onHide: () => void;
  setDetails: any;
}
interface ProductOption {
  value: number;
  label: string;
}
export function MFDataImportModal({
  refetch,
  show,
  onHide,
  setDetails,
}: Props) {
  const intl = useIntl();
  const [cards, setCards] = useState<ProductOption[]>([]);

  const cardList = useCallback(() => {
    productServices.getAllActiveMFProducts().then((values) => {
      const body = values.data;
      const items: ProductOption[] =
        body.length > 0
          ? body
              .filter((y) => y.productType === 'Card')
              .map((x) => {
                return { value: x.id, label: `${x.code} - ${x.description}` };
              })
          : [];

      setCards(items);
    });
  }, []);
  useEffect(() => {
    cardList();
    return () => {
      setCards([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const uploadFile = (
    values: MFDataUploadModel,
    actions: FormikHelpers<MFDataUploadModel>
  ) => {
    mfDataServices
      .validateFile(values)
      .then((response) => {
        const body = response.data;
        setDetails((s: ManufacturerDataValidateHeaderModel) => ({
          ...s,
          ...body,
          showSecondaryModal: true,
          ...values,
        }));
        onHide();
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          handle400Error(error.response.data.errors, actions.setErrors);
        }
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Formik
          enableReinitialize
          initialValues={uploadInitialValues}
          validationSchema={MFDataImportSchema(intl)}
          onSubmit={uploadFile}
        >
          <Form>
            <Modal.Header className='px-9 pt-7 card-rounded'>
              <Modal.Title className='m-0 fw-bolder fs-3'>
                Import MF Data file
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='container-fluid mt-1 px-10 py-8 position-relative z-index-1'>
              <CustomSelect
                label='Product'
                placeholder='Select Product'
                id='productId'
                name='productId'
                options={cards}
              />
              <CustomFileInput
                label='MF Data file'
                name='file'
                accept='.txt'
                customText='Valid file types .txt.'
              />
            </Modal.Body>
            <Modal.Footer className='px-9 card-rounded  w-100'>
              <ActionsComponent
                labelSubmit='Import'
                showCancelButton={true}
                onCancel={onHide}
              />
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
