import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const locationInventorySchemaValidation = (intl: IntlShape) =>
  Yup.object().shape({
    lowLevel: Yup.number()
      .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
      .min(1, intl.formatMessage({ id: 'VALIDATION.MIN.NUMBER' }, { min: 1 }))
      .integer(intl.formatMessage({ id: 'VALIDATION.INTEGER_FIELD' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
  });
