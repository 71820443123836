import { Route, Switch } from 'react-router';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { ProductsList } from './components/ProductsList';
import { ProductView } from './components/ProductView';

export function ProductsPage() {
  const breadcrumbs: Array<PageLink> = [];

  return (
    <>
      <Switch>
        <Route exact path='/products'>
          <PageTitle breadcrumbs={breadcrumbs}>Products List</PageTitle>
          <ProductsList />
        </Route>
        <Route path='/products/:productId'>
          <PageTitle breadcrumbs={[]}>Product Details</PageTitle>
          <ProductView />
        </Route>
      </Switch>
    </>
  );
}
