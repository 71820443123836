import locationServices from '../../services/LocationsServices';
import { editLocationInitialValues } from '../../utils/utils';
import { shallowEqual, useSelector } from 'react-redux';
import { locationsSchemaValidation } from '../../utils/locationsSchemeValidations';
import { Fragment, useEffect, useState } from 'react';
import { RootState } from '../../../../redux';
import { EntityModel } from '../../../../models/EntityModel';
import { LocationEditModel } from '../../models/LocationEditModel';
import { LocationForm } from './LocationForm';
import { AddressForm } from './AddressForm';
import { LocationCheckBox } from './LocationCheckBox';
//Modals imports
import { Formik, FormikHelpers, Form } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { ActionsComponent } from '../../../../components/ActionsComponent';
import { toast } from 'react-toastify';
import { handle400Error } from '../../../../utils/handle400Error';
import { useIntl } from 'react-intl';

interface Props {
  refetch: (id: number) => void;
  id: number;
  show: boolean;
  onHide: () => void;
}

export function LocationEdit({ refetch, id, show, onHide }: Props) {
  const intl = useIntl();
  // Init Values & State
  const [location, setLocation] = useState<LocationEditModel>(
    editLocationInitialValues
  );
  const [loading, setLoading] = useState<boolean>(false);
  // Obtain redux state values
  const [account, client, franchise] = useSelector<RootState, EntityModel[]>(
    ({ entities }: any) => [
      entities.account,
      entities.client,
      entities.franchise,
    ],
    shallowEqual
  );

  const updateLocation = (
    values: LocationEditModel,
    actions: FormikHelpers<LocationEditModel>
  ) => {
    locationServices
      .updateLocation(id, values)
      .then(() => {
        toast.success('Location updated successfully.');
        onHide();
        refetch(id);
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          handle400Error(error.response.data.errors, actions.setErrors);
        }
      });
  };
  // Call the backend when there is a valid id and the modal has to be displayed.
  useEffect(() => {
    if (id && id !== 0 && show) {
      setLoading(true);
      locationServices.getLocation(id).then((values) => {
        const location = values.data;
        const body: LocationEditModel = {
          ...location,
          addresses: values.data.addresses[0],
        };
        setLocation(body);
        setLoading(false);
      });
    }
  }, [id, show]);

  return (
    <>
      {!loading && (
        <Modal
          show={show}
          onHide={onHide}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Formik
            enableReinitialize
            initialValues={location}
            validationSchema={locationsSchemaValidation(intl)}
            onSubmit={(values, actions) => {
              updateLocation(values, actions);
              actions.setSubmitting(false);
            }}
          >
            <Form>
              <Modal.Header className='px-9 pt-7 card-rounded'>
                <Modal.Title className='m-0 fw-bolder fs-3'>
                  Edit Location
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='mt-1 px-6 py-3 position-relative z-index-1'>
                <Fragment>
                  <div className='card-body p-3'>
                    <div className='row'>
                      {account && (
                        <div className='col-4'>
                          <div className='form-label'>Account</div>
                          <span className='text-muted'>{account.name}</span>
                        </div>
                      )}
                      {client && (
                        <div className='col-4'>
                          <div className='form-label'>Client</div>
                          <span className='text-muted'>{client.name}</span>
                        </div>
                      )}
                      {franchise && (
                        <div className='col-4'>
                          <div className='form-label'>Franchise</div>
                          <span className='text-muted'>{franchise.name}</span>
                        </div>
                      )}
                    </div>
                    <LocationForm />
                    <AddressForm />
                    <LocationCheckBox />
                  </div>
                </Fragment>
              </Modal.Body>
              <Modal.Footer className='px-9 card-rounded  w-100'>
                <ActionsComponent
                  labelSubmit='Update'
                  showCancelButton={true}
                  onCancel={onHide}
                />
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      )}
    </>
  );
}
