import { Fragment, useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CustomFilter } from '../../../components/CustomFilter';
import { ValueLabelPair } from '../../../models/ValueLabelPair';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import productServices from '../../products/services/ProductsServices';
import { EntityFiltersModel } from '../../../models/EntityFiltersModel';
import { initialFilterBackorderedProduct } from '../utils/utils';
import ReactSelect from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';
import { FilterBadge } from '../../../models/FilterBadge';
import { CustomBadgeFilter } from '../../../components/CustomBadgeFilter';
import { FilterCascadeEntitySelections } from '../../../components/FilterCascadeEntitySelection';

interface PropsFilter {
  queryParams: any;
  setQueryParams: any;
}
export function BackorderedProductFilter({
  setQueryParams,
  queryParams,
}: PropsFilter) {
  // Filter States
  const [reset, setReset] = useState<boolean>(false);
  const [entityFilters, setEntityFilters] = useState<EntityFiltersModel>({
    accountId: queryParams.accountId ? queryParams.accountId : null,
    clientId: queryParams.clientId ? queryParams.clientId : null,
    franchiseId: queryParams.franchiseId ? queryParams.franchiseId : null,
    locationId: queryParams.locationId ? queryParams.locationId : null,
  });
  // Default Filters:
  const [shouldApplyFilter, setShouldApplyFilter] = useState<boolean>(false);

  const productRef = useRef<null | Select>(null);
  const [productId, setProductId] = useState<number | null | undefined>(
    queryParams.productId ? queryParams.productId : null
  );
  const [productLabel, setProductLabel] = useState<string>('');

  const [activeProducts, setActiveProducts] = useState<ValueLabelPair[]>([]);
  const getAllActiveProducts = useCallback(() => {
    productServices.getAllActiveProducts().then((values) => {
      const body = values.data;
      const items: ValueLabelPair[] =
        body.length > 0
          ? body.map((x) => {
              return { value: x.id, label: x.code };
            })
          : [];
      setActiveProducts(items);
    });
  }, []);
  useEffect(() => {
    getAllActiveProducts();
  }, [getAllActiveProducts]);
  const handleProductChange = (params: any) => {
    if (params) {
      setProductLabel(params.label);
      setProductId(params.value);
    } else {
      setProductId(undefined);
      setProductLabel('');
    }
  };

  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );

  const resetFilters = () => {
    setReset(true);
  };

  const applyFilter = () => {
    const newQueryParams = { ...queryParams };
    newQueryParams.accountId = entityFilters?.accountId;
    newQueryParams.clientId = entityFilters?.clientId;
    newQueryParams.productId = productId;

    setQueryParams(newQueryParams);
  };
  useEffect(() => {
    if (reset) {
      productRef.current?.clearValue();

      let newQueryParams = {
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };
      setProductId(initialFilterBackorderedProduct.productId);

      setQueryParams({ ...initialFilterBackorderedProduct, ...newQueryParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  /** START Filter Badges */

  const [filterBadges, setFilteBadges] = useState<FilterBadge[]>([]);
  const [entityFilterBadges, setEntityFilteBadges] = useState<FilterBadge[]>(
    []
  );
  // Get initial values from the url
  useEffect(() => {
    let clearFilters = [];

    if (!!queryParams.productId) {
      setProductId(queryParams.productId);
      updateBadge('productId', 'Product: ' + productLabel, productRef);
    } else {
      clearFilters.push('productId');
    }

    if (entityFilterBadges.length > 0) {
      entityFilterBadges.forEach((item: FilterBadge) => {
        updateBadge(item.key, item.value, item.ref);
      });
    }

    if (!queryParams.accountId) clearFilters.push('accountId');
    if (!queryParams.clientId) clearFilters.push('clientId');
    if (!queryParams.franchiseId) clearFilters.push('franchiseId');
    if (!queryParams.locationId) clearFilters.push('locationId');

    if (clearFilters.length > 0) {
      clearMultipleFilter(clearFilters);
    }

    // Get initial values from the url
    const newQueryParams = {
      ...entityFilters,
      accountId: queryParams.accountId ? queryParams.accountId : undefined,
      clientId: queryParams.clientId ? queryParams.clientId : undefined,
      franchiseId: queryParams.franchiseId
        ? queryParams.franchiseId
        : undefined,
      locationId: queryParams.locationId ? queryParams.locationId : undefined,
    };
    setEntityFilters(newQueryParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);
  // Get initial values from the url
  useEffect(() => {
    if (shouldApplyFilter) {
      let clearFilters = [];

      if (!!queryParams.productId) {
        setProductId(queryParams.productId);
        updateBadge('productId', 'Product: ' + productLabel, productRef);
      } else {
        clearFilters.push('productId');
      }

      if (entityFilterBadges.length > 0) {
        entityFilterBadges.forEach((item: FilterBadge) => {
          updateBadge(item.key, item.value, item.ref);
        });
      }

      if (!queryParams.accountId) clearFilters.push('accountId');
      if (!queryParams.clientId) clearFilters.push('clientId');
      if (!queryParams.franchiseId) clearFilters.push('franchiseId');
      if (!queryParams.locationId) clearFilters.push('locationId');

      if (clearFilters.length > 0) {
        clearMultipleFilter(clearFilters);
      }

      // Get initial values from the url
      const newQueryParams = {
        ...entityFilters,
        accountId: queryParams.accountId ? queryParams.accountId : undefined,
        clientId: queryParams.clientId ? queryParams.clientId : undefined,
        franchiseId: queryParams.franchiseId
          ? queryParams.franchiseId
          : undefined,
        locationId: queryParams.locationId ? queryParams.locationId : undefined,
      };
      setEntityFilters(newQueryParams);
      setShouldApplyFilter(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldApplyFilter, entityFilterBadges]);

  const clearMultipleFilter = (filters: Array<string>) => {
    let badges = filterBadges;
    filters.forEach((element) => {
      badges = badges.filter((item: FilterBadge) => item.key !== element);
    });
    setFilteBadges(badges);
  };
  const clearFilter = (ref: any, keyParam: string) => {
    if (
      keyParam === 'accountId' ||
      keyParam === 'clientId' ||
      keyParam === 'franchiseId'
    ) {
      clearMultipleFilter([
        'accountId',
        'clientId',
        'franchiseId',
        'locationId',
      ]);
      setEntityFilteBadges([]);
      let params = { ...queryParams };
      params['accountId'] = userGroupRelations.accountId
        ? userGroupRelations.accountId
        : undefined;
      params['clientId'] = userGroupRelations.clientId
        ? userGroupRelations.clientId
        : undefined;
      params['franchiseId'] = userGroupRelations.franchiseId
        ? userGroupRelations.franchiseId
        : undefined;
      params['locationId'] = userGroupRelations.locationId
        ? userGroupRelations.locationId
        : undefined;
      // When removing a badge you should be redirected to page 1.
      params['pageNumber'] = 1;
      setQueryParams(params);

      filterBadges
        .find((element) => element.key === 'accountId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'clientId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'franchiseId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'locationId')
        ?.ref.current?.clearValue();
    } else if (ref) {
      if (ref.current?.type === 'date') {
        ref.current.value = null;
      } else {
        ref.current?.clearValue();
      }
      let params = { ...queryParams };
      params[keyParam] = undefined;
      // When removing a badge you should be redirected to page 1.
      params['pageNumber'] = 1;
      setQueryParams(params);
    }

    setFilteBadges(
      filterBadges.filter((item: FilterBadge) => item.key !== keyParam)
    );
  };
  const updateBadge = (key: string, value: string, ref: any) => {
    let badges = filterBadges;
    const finded = badges?.find((item: FilterBadge) => item.key === key);
    if (finded && finded !== undefined) {
      badges.splice(badges.indexOf(finded), 1, {
        key: key,
        value: value,
        ref: ref,
      });
    } else {
      badges.push({
        key: key,
        value: value,
        ref: ref,
      });
    }
    setFilteBadges(badges);
  };

  /** END Filter Badges */
  return (
    <>
      {filterBadges?.map((item: FilterBadge) => {
        if (item.value && item.value !== '')
          return (
            <CustomBadgeFilter
              text={item.value}
              key={item.key}
              badgeKey={item.key}
              clearFilter={() => {
                if (item.ref) clearFilter(item.ref, item.key);
              }}
            ></CustomBadgeFilter>
          );
        else return <></>;
      })}
      <CustomFilter applyFilters={applyFilter} resetFilters={resetFilters}>
        <Fragment>
          <FilterCascadeEntitySelections
            setReset={setReset}
            reset={reset}
            entityFilters={entityFilters}
            setEntityFilters={setEntityFilters}
            level={2}
            setEntityListBadge={setEntityFilteBadges}
            entityFilterBadges={entityFilterBadges}
            setShowFilterBadges={setShouldApplyFilter}
          />

          <div className='mb-4' onClick={(e) => e.stopPropagation()}>
            <label className='form-label fw-bold'>Product</label>
            <ReactSelect
              maxMenuHeight={400}
              isClearable
              name='productId'
              id='productId'
              onChange={handleProductChange}
              options={activeProducts}
              value={
                activeProducts
                  ? activeProducts.filter((x: any) => x.value === productId)[0]
                  : null
              }
              ref={productRef}
            />
          </div>
        </Fragment>
      </CustomFilter>{' '}
    </>
  );
}
