import { FileModel } from '../../../models/FileModel';
import { AccountEditModel } from '../models/AccountEditModel';
import { AccountModel } from '../models/AccountModel';

export const accountHelpers = {
  createFormDataFiles: (accountImages: any, fileId?: number) => {
    const nonDeleteFileIds: string[] = [];
    if (accountImages && accountImages[0] === null && fileId && fileId !== 0) {
      const id = fileId ? Number(fileId) * -1 : 0;
      nonDeleteFileIds.push(id.toString());
    }

    const formData = new FormData();
    accountImages.forEach((x: any) => {
      if (x && !x.id) {
        formData.append('files', x);
      }
    });
    formData.append('nonDeleteFileIds', nonDeleteFileIds.join(','));
    return formData;
  },
  processFiles: (files: FileModel[]) => {
    const data = [...files];
    if (data.length === 1) {
      if (data[0].id) {
        data[0].type = 'image/' + data[0].ext.replace('.', '');
      }
    }
    if (data.length === 2) {
      data[0].type = 'image/' + data[0].ext.replace('.', '');
      data[1].type = 'image/' + data[1].ext.replace('.', '');
    }
    return data;
  },

  initialValues: (account: AccountModel) => {
    const data: AccountEditModel = {
      id: account.id,
      accountKey: account.accountKey,
      accountName: account.accountName,
      accountNumber: account.accountNumber,
      contactName: account.contactName,
      addressLine1: account.addressLine1,
      addressLine2: account.addressLine2,
      city: account.city,
      state: account.state,
      postalCode: account.postalCode,
      isActive: account.isActive,
      branding: {
        files: [],
        navbarBackgroundColor: account.branding?.navbarBackgroundColor
          ? account.branding?.navbarBackgroundColor
          : '',
        navbarIconColor: account.branding?.navbarIconColor
          ? account.branding?.navbarIconColor
          : '',
        navbarTextColor: account.branding?.navbarTextColor
          ? account.branding?.navbarTextColor
          : '',
        navbarBackgroundActiveColor: account.branding
          ?.navbarBackgroundActiveColor
          ? account.branding?.navbarBackgroundActiveColor
          : '',
        navbarIconActiveColor: account.branding?.navbarIconActiveColor
          ? account.branding?.navbarIconActiveColor
          : '',
        navbarTextActiveColor: account.branding?.navbarTextActiveColor
          ? account.branding?.navbarTextActiveColor
          : '',
        primaryColor: account.branding?.primaryColor
          ? account.branding?.primaryColor
          : '',
      },
    };
    if (account.branding?.accountLogo) {
      if (account.branding?.accountLogo.id) {
        const file: FileModel = {
          id: account.branding?.accountLogo?.id,
          name: account.branding?.accountLogo?.name,
          path: account.branding?.accountLogo?.path,
          ext: account.branding?.accountLogo?.ext,
          size: account.branding?.accountLogo?.size
            ? account.branding?.accountLogo?.size
            : 0,
          type: 'image/' + account.branding?.accountLogo?.ext.replace('.', ''),
        };
        data.branding?.files?.push(file);
      }
    }

    return data;
  },
};
