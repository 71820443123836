import { useAuth } from 'oidc-react';
import { useState } from 'react';
import { toast } from 'react-toastify';

export function Access() {
  const authCap = useAuth();
  const [loading, setLoading] = useState(false);

  const signIn = () => {
    setLoading(true);
    authCap
      .signIn()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        toast.error('Unable to access authentication server');
        setLoading(false);
      });
  };
  return (
    <>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In Inventory Management System</h1>
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          onClick={signIn}
        >
          <span className='indicator-progress' style={{ display: 'block' }}>
            Access
            {loading && (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            )}
          </span>
        </button>
      </div>
      {process.env.REACT_APP_ENV !== 'production' && (
        <div className='text-muted'>
          {' '}
          {process.env.REACT_APP_VERSION}
          {' | '} {process.env.REACT_APP_ENV}
        </div>
      )}
    </>
  );
}
