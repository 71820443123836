import { Fragment } from 'react';

type Props = {
  activeTab: string;
  setActiveTab: any;
  tabs: BasicTabModel[];
};
export interface BasicTabModel {
  title: string;
  id: string;
}
export function BasicTabs({ activeTab, setActiveTab, tabs }: Props) {
  const onChangeTab = (tabId: string) => {
    setActiveTab(tabId);
  };
  return (
    <Fragment>
      <div className='d-flex overflow-auto h-55px'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          {tabs.map((x, index) => {
            return (
              <li key={index} className='nav-item'>
                <div
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (x.id === activeTab && 'active')
                  }
                  key={x.id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => onChangeTab(x.id)}
                >
                  {x.title}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Fragment>
  );
}
