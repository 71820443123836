const Status = {
  NEW: 'New',
  PIKING: 'Picking',
  BACKORDERED: 'BackOrdered',
  PACKED: 'Packed',
  SHIPPED: 'Shipped',
  CANCELLED: 'Cancelled',
  RETURNED: 'Returned',
};
export const {
  NEW,
  PIKING,
  BACKORDERED,
  PACKED,
  SHIPPED,
  CANCELLED,
  RETURNED,
} = Status;
export const getEquivalenceStatus = (status: string) => {
  if (status === NEW) {
    return 0;
  }
  if (status === BACKORDERED) {
    return 1;
  }
  if (status === PIKING) {
    return 2;
  }
  if (status === PACKED) {
    return 3;
  }
  if (status === SHIPPED) {
    return 4;
  }
  return -1;
};

export const markAsComplete = (status: string, current: string) => {
  const x = getEquivalenceStatus(status);
  const y = getEquivalenceStatus(current);
  return y <= x;
};
export const showDate = (status: string, current: string) => {
  const x = getEquivalenceStatus(current);
  const y = getEquivalenceStatus(status);
  return x === y;
};
export const nextStatus = (status: string, nextStatus: string) => {
  const x = getEquivalenceStatus(status);
  const y = getEquivalenceStatus(nextStatus);
  return x < y;
};
export const makePikingRequest = (from: string, to: string) => {
  const x = getEquivalenceStatus(from);
  const y = getEquivalenceStatus(to);
  return x < 2 && 2 <= y;
};
