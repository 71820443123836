import { ErrorMessage, useFormikContext } from 'formik';
import { UserGroupRelationModel } from '../../../auth/models/UserGroupRelationModel';
import { GroupModel } from '../../models/GroupModel';
import { CascadeEntitySelections } from '../../../../components/CascadeEntitySelections';
export interface ValueLabelPair {
  value: number;
  label: string;
}
const allValues = {
  accountId: null,
  clientId: null,
  franchiseId: null,
  locationId: null,
  locationName: '',
  franchiseName: '',
  clientName: '',
  accountName: '',
};
export function UserGroupNRelacions() {
  const { setFieldValue, values } = useFormikContext<GroupModel>();
  const handleAddItem = () => {
    if (!values.cascadeselects) {
      return setFieldValue('userGroupRelations', [
        ...values.userGroupRelations,
        allValues as UserGroupRelationModel,
      ]);
    }

    if (values.cascadeselects) {
      return setFieldValue('userGroupRelations', [
        ...values.userGroupRelations,
        values.cascadeselects,
      ]);
    }
  };
  const handleRemoveItem = (idx: number) => {
    if (values.userGroupRelations.length > 0) {
      setFieldValue(
        'userGroupRelations',
        values.userGroupRelations.filter((item, index) => index !== idx)
      );
    }
  };

  return (
    <div>
      <div className='row'>
        <div className='col-10'>
          <CascadeEntitySelections keyLabel='cascadeselects' />
        </div>
        <div className='col-2'>
          <div className='text-end'>
            <button
              onClick={handleAddItem}
              type='button'
              className='btn btn-primary mt-7 p-3'
            >
              <i className='las la-plus fs-2'></i>Add
            </button>
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-12'>
          <ErrorMessage name='userGroupRelations'>
            {(msg) => <div className='text-danger'>{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
      <div className='table-responsive mt-10'>
        <table className='table table-row-dashed table-row-gray-300 align-middle g-4'>
          <thead>
            <tr className='fw-bolder text-muted'>
              <th className='min-w-150px'>Account</th>
              <th className='min-w-150px'>Client</th>
              <th className='min-w-150px'>Franchise</th>
              <th className='min-w-150px'>Locations</th>
            </tr>
          </thead>
          <tbody className='border-0'>
            {values.userGroupRelations.map((x, index) => {
              return (
                <tr key={`userGroupRelations.${index}`}>
                  <td>{x.accountId ? x.accountName : 'All'}</td>
                  <td>{x.clientId ? x.clientName : 'All'}</td>
                  <td>{x.franchiseId ? x.franchiseName : 'All'}</td>
                  <td>{x.locationId ? x.locationName : 'All'}</td>
                  <td>
                    <button
                      onClick={() => {
                        handleRemoveItem(index);
                      }}
                      type='button'
                      id='buton-delete'
                      className='btn btn-active-danger p-3'
                    >
                      <i className='las la-times fs-2'></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
