import { useEffect } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useParams, useLocation } from 'react-router';
import { EntityDetails } from '../../../../components/EntityDetails';
import { EntityModel } from '../../../../models/EntityModel';
import { RootState } from '../../../../redux';
import {
  removeEntityFranchise,
  setEntityFranchise,
} from '../../../../redux/entity/entitiesActions';
import { initEntityFranchise } from '../../../../utils/entityUtils';
import { LocationsPage } from '../../LocationsPage';
import franchiseServices from '../../services/FranchiseServices';
import franchisesServices from '../../services/FranchiseServices';
import { toast } from 'react-toastify';
import { IsActiveComponent } from '../../../../components/IsActiveComponent';
import { GroupModel } from '../../../users/models/GroupModel';
import { FranchiseEdit } from './FranchiseEdit';
interface Params {
  accountId: string;
  clientId: string;
  franchiseId: string;
}
export function FranchisesView() {
  const { franchiseId } = useParams<Params>();
  const [franchise] = useSelector<RootState, EntityModel[]>(
    ({ entities }: any) => [entities.franchise],
    shallowEqual
  );
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!franchise && pathname.endsWith('locations')) {
      refetch(Number(franchiseId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //Al salir del componente limpiamos el estado
  useEffect(() => {
    return () => {
      dispatch(removeEntityFranchise());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const goBack = () => {
    history.goBack();
    setTimeout(() => {
      dispatch(removeEntityFranchise());
    }, 200);
  };

  const setActivate = (franchiseId: number) => {
    franchisesServices.activateFranchise(franchiseId).then(() => {
      toast.success('Successful activation');
      refetch(franchiseId);
    });
  };

  const setDeactivate = (franchiseId: number, reason?: string) => {
    franchisesServices.desactivateFranchise(franchiseId, reason).then(() => {
      toast.success('Successful deactivation');
      refetch(franchiseId);
    });
  };
  const refetch = (franchiseId: number) => {
    franchiseServices.getFranchise(franchiseId).then((response) => {
      const entity: EntityModel = initEntityFranchise(response.data);
      dispatch(setEntityFranchise(entity));
    });
  };
  return (
    <>
      <Route
        path='/accounts/:accountId/clients/:clientId/franchises/:franchiseId/locations'
        exact
      >
        {franchise && (
          <EntityDetails
            entity={franchise}
            goBack={goBack}
            desactivateComponent={IsActiveComponent}
            setActivate={setActivate}
            setDeactivate={setDeactivate}
            permissions={group.franchisePermission}
            editComponent={FranchiseEdit}
            refetch={refetch}
          />
        )}
      </Route>
      <Route path='/accounts/:accountId/clients/:clientId/franchises/:franchiseId/locations'>
        <LocationsPage />
      </Route>
    </>
  );
}
