import { Route, Switch } from 'react-router';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { BackorderedProduct } from './components/BackorderedProduct';
import { LowLevelProduct } from './components/LowLevelProduct';
import { ProductOrderHistory } from './components/ProductOrderHistory';
import { ProductUsageForecasting } from './components/ProductUsageForecasting';
import { StandardOrders } from './components/StandardOrders';

export function ReportsPage() {
  const breadcrumbs: Array<PageLink> = [];

  return (
    <>
      <Switch>
        <Route path='/reports/low-level-product'>
          <PageTitle breadcrumbs={breadcrumbs}>
            Warehouse - Low Level Product
          </PageTitle>
          <LowLevelProduct />
        </Route>
        <Route path='/reports/backordered-product' exact>
          <PageTitle breadcrumbs={breadcrumbs}>
            Report - Backordered Product
          </PageTitle>
          <BackorderedProduct />
        </Route>
        <Route path='/reports/standar-orders'>
          <PageTitle breadcrumbs={breadcrumbs}>
            Report - Standard Orders
          </PageTitle>
          <StandardOrders />
        </Route>
        <Route path='/reports/product-order-history'>
          <PageTitle breadcrumbs={breadcrumbs}>
            Report - Product Order History
          </PageTitle>
          <ProductOrderHistory />
        </Route>
        <Route path='/reports/product-usage'>
          <PageTitle breadcrumbs={breadcrumbs}>
            Report - Product Usage
          </PageTitle>
          <ProductUsageForecasting />
        </Route>
      </Switch>
    </>
  );
}
