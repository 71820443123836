import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap-v5';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux';
import * as auth from '../modules/auth/redux/AuthRedux';
import { useAuth } from 'oidc-react';
import { LoginUserModel } from '../modules/auth/models/LoginUserModel';
import { GroupModel } from '../modules/users/models/GroupModel';
import { usersServices } from '../modules/users/services/UsersServices';
import { UserGroupRelationModel } from '../modules/auth/models/UserGroupRelationModel';
import { getGroupsByToken } from '../modules/auth/services/AuthServices';
import { useHistory } from 'react-router-dom';

import accountsServices from '../modules/entities/services/AccountsServices';

const parseName = (g: GroupModel, gr: UserGroupRelationModel) => {
  return `${g.name}`;
};
interface GroupChecksI {
  group: GroupModel;
  onSelectUGR: (g: GroupModel, ugr: UserGroupRelationModel) => void;
}

const GroupChecks = ({ group, onSelectUGR }: GroupChecksI) => {
  const userGroupRelations = group.userGroupRelations;

  const selectUGR = (ugr: UserGroupRelationModel) => {
    onSelectUGR(group, ugr);
  };

  return (
    <>
      {userGroupRelations.map((ugr: UserGroupRelationModel) => {
        return (
          <div
            key={`UserGroupRelation-${group.id}-${ugr.id}`}
            className='form-check form-check-custom form-check-solid m-5'
          >
            <label style={{ fontSize: '1.2em' }} className='form-check-label'>
              <input
                onChange={() => selectUGR(ugr)}
                name='group'
                className='form-check-input'
                style={{ marginRight: '1em' }}
                type='radio'
                value={group.id}
                id='flexCheckboxSm'
              />
              {parseName(group, ugr)}
            </label>
          </div>
        );
      })}
    </>
  );
};
interface Props {
  show: boolean;
  onHide: any;
}
export function SelectGroup({ show, onHide }: Props) {
  const groups = useSelector<RootState, Array<GroupModel>>(
    ({ auth }: any) => auth.user.groups,
    shallowEqual
  );
  const user = useSelector<RootState, LoginUserModel>(
    ({ auth }: any) => auth.user,
    shallowEqual
  );

  const [selectedUGR, setSelectedUGR] = useState<
    { ugr: UserGroupRelationModel; g: GroupModel } | undefined
  >();
  const authCap = useAuth();

  const onSelectUGR = (g: GroupModel, ugr: UserGroupRelationModel) => {
    setSelectedUGR({ g, ugr });
  };

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (show) {
      getGroupsByToken().then(({ data: groups }) => {
        const newUser = { ...user, ...{ groups } };
        dispatch(auth.actions.fulfillUser(newUser));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const confirm = () => {
    if (selectedUGR) {
      const { ugr } = selectedUGR;
      usersServices.selectGroup(user.id, ugr.id).then(() => {
        dispatch(auth.actions.setGroup(selectedUGR));
        setSelectedUGR(undefined);
        onHide();
        if (selectedUGR.ugr.accountId) {
          accountsServices
            .getAccountBranding(selectedUGR.ugr.accountId)
            .then((response) => {
              if (response.data) {
                dispatch(auth.actions.setBranding(response.data));
              } else {
                dispatch(auth.actions.removeBranding());
              }
            });
        } else {
          dispatch(auth.actions.removeBranding());
        }
      });
      history.push('/home');
    }
  };
  const logout = () => {
    authCap.signOutRedirect();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop='static'
      keyboard={false}
      className='select-group-relation'
    >
      <Modal.Header className='px-9 pt-7 card-rounded h-150px w-100 bg-dark'>
        <Modal.Title className='m-0 text-white fw-bolder fs-3'>
          Select User Group
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ marginTop: '-40px' }}
        className='shadow-lg card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
      >
        {groups.length > 0 && (
          <>
            <span>What do you want to work with?</span>
            {groups.map((group: GroupModel) => {
              return (
                <GroupChecks
                  key={`Group-${group.id}`}
                  group={group}
                  onSelectUGR={onSelectUGR}
                ></GroupChecks>
              );
            })}
          </>
        )}
        {groups.length === 0 && (
          <h3>
            Your user is not associated with any entity. Please contact support
          </h3>
        )}
      </Modal.Body>

      <Modal.Footer
        style={{ marginTop: '-40px' }}
        className='px-9 pt-7 card-rounded  w-100 bg-dark'
      >
        {groups.length > 0 && (
          <Button
            disabled={!setSelectedUGR}
            onClick={confirm}
            variant='secondary'
          >
            Confirm
          </Button>
        )}
        {groups.length === 0 && (
          <Button onClick={logout} variant='secondary'>
            Logout
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
