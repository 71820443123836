import { Route, Switch } from 'react-router';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { OrdersCreate } from './components/OrdersCreate';
import { OrdersList } from './components/OrdersList';
import { OrderView } from './components/OrderView';

export function OrdersPage() {
  const breadcrumbs: Array<PageLink> = [];

  return (
    <>
      <Switch>
        <Route exact path='/orders/list'>
          <PageTitle breadcrumbs={breadcrumbs}>Orders List</PageTitle>
          <OrdersList></OrdersList>
        </Route>
        <Route path='/orders/create'>
          <PageTitle breadcrumbs={[]}>Create Order</PageTitle>
          <OrdersCreate />
        </Route>
        <Route path='/orders/create-selection/:accountId/:clientId/:franchiseId/:locationId'>
          <PageTitle breadcrumbs={[]}>Create Order</PageTitle>
          <OrdersCreate />
        </Route>
        <Route path='/orders/:orderId'>
          <PageTitle breadcrumbs={[]}>Order Details</PageTitle>
          <OrderView />
        </Route>
      </Switch>
    </>
  );
}
