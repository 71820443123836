import { NumberParam, StringParam } from 'serialize-query-params';

// Initial queryParams values
export const initialFilter = {
  userEmail: '',
  sortDirection: -1,
  sortColumn: 'timeStamp',
  pageNumber: 1,
  pageSize: 10,
  dateFrom: undefined,
  dateTo: undefined,
  eventType: undefined,
  logType: undefined,
  accountId: undefined,
  clientId: undefined,
  franchiseId: undefined,
  locationId: undefined,
  orderable: undefined,
};

// search params config
export const queryParamsConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  userEmail: StringParam,
  dateFrom: StringParam,
  dateTo: StringParam,
  eventType: StringParam,
  logType: StringParam,
  accountId: NumberParam,
  clientId: NumberParam,
  franchiseId: NumberParam,
  locationId: NumberParam,
};
