import { Fragment, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useHistory } from 'react-router-dom';
import {
  initialFilterOrders,
  OrdersQueryFilterKeys,
  queryParamsConfig,
} from '../utils/utils';
import ListComponent from '../../../components/ListComponent';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import orderServices from '../services/OrdersServices';
import { GroupModel } from '../../users/models/GroupModel';
import moment from 'moment';
import { OriginBadge } from '../../../components/OriginBadge';
import { StatusBadge } from '../../../components/StatusBadge';
import { OrdersFilter } from './OrdersFilter';
import { useQueryParamsReplace } from '../../../utils/useQueryParamsReplace';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { CustomStatusPopover } from '../../../components/CustomStatusPopover';
import { OrderListModel } from '../models/OrderListModel';

export function OrdersList() {
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<OrderListModel[]>([]);
  const [queryParams, setQueryReplace] =
    useQueryParamsReplace(queryParamsConfig);
  const [filter, setFilter] = useState('');
  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<OrderListModel>[] = [
    {
      name: 'Number',
      selector: (row) => row.id,
      sortable: true,
      sortField: 'number',
    },
    {
      name: 'Date',
      selector: (row) => moment(row.createdAt).format('MM-DD-YYYY'),
      sortable: true,
      sortField: 'date',
    },
    {
      name: 'Origin',
      cell: (row) => <OriginBadge origin={row.origin} />,
      sortable: true,
      sortField: 'origin',
      center: true,
    },
    {
      name: 'Location #',
      sortField: 'locationNumber',
      sortable: true,
      selector: (row) => row.locationNumber,
      center: true,
      width: '200px',
    },
  ];
  const userType = entityType(userGroupRelations);
  if (userGroupRelations && userType === 'Account') {
    columns.push({
      name: 'Client',
      selector: (row) => row.client,
      sortable: true,
      sortField: 'client',
      wrap: true,
    });
  }
  if ((userGroupRelations && userType === 'Client') || userType === 'Account') {
    columns.push({
      name: 'Franchise',
      selector: (row) => row.franchise,
      sortable: true,
      sortField: 'franchise',
      wrap: true,
    });
  }
  if (
    (userGroupRelations && userType === 'Franchise') ||
    userType === 'Client' ||
    userType === 'Account'
  ) {
    columns.push({
      name: 'Location',
      selector: (row) => row.location,
      sortable: true,
      sortField: 'location',
      wrap: true,
    });
  }
  columns.push(
    {
      name: 'Status',
      cell: (row) => {
        return (
          <Fragment>
            {row.status === 'Shipped' ? (
              <CustomStatusPopover
                shipDate={row.shipDate}
                shippingTrackingNbr={row.shippingTrackingNbr}
                shippingCompany={row.shippingCompany}
                status={row.status}
                orderNumber={row.id}
              />
            ) : (
              <StatusBadge status={row.status} />
            )}
          </Fragment>
        );
      },
      sortable: true,
      sortField: 'status',
      center: true,
    },
    {
      name: 'Actions',
      button: true,
      cell: (row) =>
        row.id && (
          <>
            {group.orderPermission?.view && (
              <button
                onClick={() => handleView(row)}
                className='btn btn-icon btn-bg-light btn-active-success btn-sm me-1'
                data-bs-toggle='tooltip'
                title='View'
              >
                <i className='las la-eye fs-2'></i>
              </button>
            )}
          </>
        ),
    }
  );

  // Initialization methods
  useEffect(() => {
    if (history.location.search === '') {
      const newQueryParams = {
        ...initialFilterOrders,
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };
      setQueryReplace(newQueryParams);
    }
    if (queryParams.filter) setFilter(queryParams.filter);
    if (queryParams.pageNumber) findOrders(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findOrders = function (queryParams: any) {
    orderServices.findOrders(queryParams).then((response: any) => {
      setEntities(response.data.items);
      setTotalCount(response.data.totalCount);
    });
  };

  const onFilterChange = (value: any, key: any) => {
    if (key === 'filter') setFilter(value);
    debounced({ key: key, value: value });
  };

  const findWithFilters = (params: {
    key: OrdersQueryFilterKeys;
    value: any;
  }) => {
    const newQueryParams = { ...queryParams };
    newQueryParams[params.key] = params.value;
    newQueryParams.pageNumber = 1;
    setQueryReplace(newQueryParams);
  };

  const debounced = useDebouncedCallback(findWithFilters, 800);

  const handleCreateOrders = () => {
    history.push('create');
  };

  const handleView = (order: OrderListModel) => {
    history.push(`/orders/${order.id}`);
  };

  return (
    <>
      <div className='card'>
        <div className='card-header py-6'>
          <div className='card-title'>
            {group.orderPermission?.list && (
              <>
                <div className='d-flex align-items-center position-relative my-1'>
                  <i className='las la-search position-absolute ms-6 fs-2'></i>
                  <input
                    autoComplete='none'
                    type='search'
                    className='form-control form-control-solid w-250px ps-15'
                    value={filter}
                    placeholder='Search by order number'
                    onChange={(e) => onFilterChange(e.target.value, 'filter')}
                  />
                </div>
              </>
            )}
          </div>
          <div className='card-toolbar'>
            {group.orderPermission?.list && (
              <OrdersFilter
                queryParams={queryParams}
                setQueryParams={setQueryReplace}
              ></OrdersFilter>
            )}
            {group.orderPermission?.create && (
              <>
                <button
                  type='button'
                  className='btn btn-primary align-self-center'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  onClick={handleCreateOrders}
                >
                  <i className='bi bi-plus fs-2'></i>
                  Create Order
                </button>
              </>
            )}
          </div>
        </div>

        <div className='card-body px-9 py-3'>
          <ListComponent
            table={{
              columns,
              data: entities,
              paginationTotalRows: totalCount,
              pointerOnHover: true,
              highlightOnHover: true,
              onRowClicked: handleView,
            }}
            queryParams={queryParams}
            setQueryParams={setQueryReplace}
          />
        </div>
      </div>
    </>
  );
}
const entityType = (ugr: UserGroupRelationModel) => {
  if (ugr.accountId && ugr.clientId && ugr.franchiseId && ugr.locationId) {
    return 'Location';
  }
  if (ugr.accountId && ugr.clientId && ugr.franchiseId && !ugr.locationId) {
    return 'Franchise';
  }
  if (ugr.accountId && ugr.clientId && !ugr.franchiseId && !ugr.locationId) {
    return 'Client';
  }
  return 'Account';
};
