/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useEffect, useState } from 'react';
import productsServices from '../services/ProductsServices';
import { ProductModel } from '../models/ProductModel';
import { TableColumn } from 'react-data-table-component';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ListComponent from '../../../components/ListComponent';
import {
  ProductQueryFilterKeys,
  initialFilterProducts,
  queryParamsProductConfig,
} from '../utils/utils';
import { useQueryParamsReplace } from '../../../utils/useQueryParamsReplace';
import { ProductsFilter } from './ProductsFilter';
import { GroupModel } from '../../users/models/GroupModel';
import { RootState } from '../../../redux';
import { CustomBadge } from '../../../components/CustomBadge';
import { ProductCreate } from './ProductCreate';
import { ProductEdit } from './ProductEdit';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';

export function ProductsList() {
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<ProductModel[]>([]);
  const [queryParams, setQueryReplace] = useQueryParamsReplace(
    queryParamsProductConfig
  );
  const [filter, setFilter] = useState('');
  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<ProductModel>[] = useMemo(
    () => [
      {
        name: 'Code',
        selector: (row) => row.code,
        sortable: true,
        sortField: 'code',
        minWidth: '150px',
        wrap: true,
      },
      {
        name: 'Description',
        selector: (row) => row.description,
        sortable: true,
        sortField: 'description',
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'Type',
        sortable: true,
        sortField: 'type',
        selector: (row) => row.productType,
      },
      {
        name: 'Auto',
        cell: (row) => {
          return (
            <CustomBadge
              status={row.autoReplenishment}
              activeText='Yes'
              inactiveText='No'
            />
          );
        },
        minWidth: '80px',
      },
      {
        name: 'Track',
        cell: (row) => {
          return (
            <CustomBadge
              status={row.trackInventoryLevel}
              activeText='Yes'
              inactiveText='No'
            />
          );
        },
        minWidth: '80px',
      },
      {
        name: 'S.U.',
        selector: (row) => row.shippingUnitSize || '-',
        sortable: true,
        sortField: 'shippingUnitSize',
        minWidth: '80px',
      },
      {
        name: 'Opt.Lvl',
        sortable: true,
        sortField: 'defaultInventoryLevel',
        selector: (row) => row.defaultInventoryLevel || '-',
      },
      {
        name: 'Low Lvl',
        sortable: true,
        sortField: 'defaultLowLevel',
        selector: (row) => row.defaultLowLevel || '-',
      },
      {
        name: 'Active',
        cell: (row) => {
          return (
            <CustomBadge
              status={row.isActive}
              activeText='Active'
              inactiveText='Inactive'
            />
          );
        },
        sortable: true,
        sortField: 'active',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // Hides actions where there is only one
  if (group.productPermission?.view || group.productPermission?.update) {
    columns.push({
      name: 'Actions',
      button: true,
      cell: (row) =>
        row.id && (
          <>
            <Fragment>
              {group.productPermission?.view &&
                group.productPermission?.update && (
                  <button
                    onClick={() => handleShowEditModal(row.id)}
                    className='btn btn-icon btn-bg-light btn-active-primary btn-sm me-1'
                    data-bs-toggle='tooltip'
                    title='Edit'
                  >
                    <i className='las la-edit fs-2'></i>
                  </button>
                )}
            </Fragment>
          </>
        ),
    });
  }

  // Initialization methods
  useEffect(() => {
    if (history.location.search === '') {
      const newQueryParams = {
        ...initialFilterProducts,
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };
      setQueryReplace(newQueryParams);
    }

    if (queryParams.filter) setFilter(queryParams.filter);
    if (queryParams.pageNumber) findProducts(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findProducts = function (queryParams: any) {
    productsServices.findProducts(queryParams).then((response) => {
      setEntities(response.data.items);
      setTotalCount(response.data.totalCount);
    });
  };

  const onFilterChange = (e: any, key: ProductQueryFilterKeys) => {
    if (key === 'filter') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };

  const findWithFilters = (params: {
    key: ProductQueryFilterKeys;
    value: any;
  }) => {
    const newQueryParams = { ...queryParams };
    newQueryParams[params.key] = params.value;
    newQueryParams.pageNumber = 1;
    setQueryReplace(newQueryParams);
  };

  const debounced = useDebouncedCallback(findWithFilters, 800);

  const refetch = () => {
    findProducts(queryParams);
  };
  const [selectedId, setSelectedId] = useState<number>(0);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    refetch();
  };
  const handleShowCreateModal = () => {
    setShowCreateModal(true);
  };
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedId(0);
    refetch();
  };
  const handleShowEditModal = (id: number) => {
    setSelectedId(id);
    setShowEditModal(true);
  };
  //
  const handleView = (product: ProductModel) => {
    history.push(`/products/${product.id}`);
  };

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header py-6'>
          <div className='card-title'>
            {group.productPermission?.list && (
              <div className='d-flex align-items-center position-relative my-1'>
                <i className='las la-search position-absolute ms-6 fs-2'></i>
                <input
                  autoComplete='none'
                  type='search'
                  className='form-control form-control-solid w-400px ps-15'
                  value={filter}
                  placeholder='Search by Product Code or Description'
                  onChange={(e) => onFilterChange(e, 'filter')}
                />
              </div>
            )}
          </div>
          <div className='card-toolbar'>
            {group.productPermission?.list && (
              <ProductsFilter
                queryParams={queryParams}
                setQueryParams={setQueryReplace}
              />
            )}
            {group.productPermission?.create && (
              <>
                <button
                  onClick={() => handleShowCreateModal()}
                  type='button'
                  className='btn btn-primary align-self-center'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                >
                  <i className='bi bi-plus fs-2'></i>
                  Add Product
                </button>

                {showCreateModal && (
                  <ProductCreate
                    refetch={refetch}
                    show={showCreateModal}
                    onHide={handleCloseCreateModal}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className='card-body px-9 py-3'>
          {group.productPermission?.list && (
            <ListComponent
              table={{
                columns,
                data: entities,
                paginationTotalRows: totalCount,
                pointerOnHover: true,
                highlightOnHover: true,
                onRowClicked: handleView,
              }}
              queryParams={queryParams}
              setQueryParams={setQueryReplace}
            />
          )}
        </div>
      </div>
      {showEditModal && (
        <ProductEdit
          productId={selectedId}
          refetch={refetch}
          show={showEditModal}
          onHide={handleCloseEditModal}
        />
      )}
    </>
  );
}
