import axios from 'axios';
import { ItemModel } from '../../../models/ItemModel';
import { PagedResult } from '../../../models/pagedResult';
import { FranchiseCreateModel } from '../models/FranchiseCreateModel';
import { FranchiseModel } from '../models/FranchiseModel';

export const FRANCHISES_URL = `/api/v1/franchises`;

const franchisesServices = {
  findFranchises: (queryParams: any) => {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(FRANCHISES_URL, { params: params });
  },
  createFranchise: (franchise: FranchiseCreateModel) => {
    return axios.post(FRANCHISES_URL, franchise);
  },
  getFranchise: (id: number) => {
    return axios.get<FranchiseModel>(`${FRANCHISES_URL}/${id}`);
  },
  updateFranchise: (id: number, franchise: FranchiseModel) => {
    return axios.put<FranchiseModel>(`${FRANCHISES_URL}/${id}`, franchise);
  },
  desactivateFranchise: (id: number, reason?: string) => {
    const body = {
      id,
      reason,
    };
    return axios.put(`${FRANCHISES_URL}/deactivate/${id}`, body);
  },
  activateFranchise: (id: number) => {
    return axios.put(`${FRANCHISES_URL}/activate/${id}`);
  },
  locationsAddressThirdParty: () => {
    return axios.get<ItemModel[]>(`${FRANCHISES_URL}/locations/third-party`);
  },
};

export default franchisesServices;
