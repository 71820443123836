interface ColumCheckboxProps {
  onChecked: (value: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  checked?: boolean;
}

export const ColumCheckbox = ({
  onChecked,
  disabled = false,
  checked = false,
}: ColumCheckboxProps) => {
  return (
    <div className='form-check form-check-sm form-check-custom form-check-solid'>
      <input
        className='form-check-input widget-9-check'
        type='checkbox'
        disabled={disabled}
        checked={checked}
        onChange={onChecked}
      />
    </div>
  );
};
