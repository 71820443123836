import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAccessDenied: false,
};

export const accessDeniedSlice = createSlice({
  name: 'accessDenied',
  initialState: initialState,
  reducers: {
    setIsAccessDenied: (state, action) => {
      state.isAccessDenied = action.payload.flag;
    },
  },
});
