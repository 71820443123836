import { Field } from 'formik';
import { shallowEqual, useSelector } from 'react-redux';
import { CustomFormModal } from '../../../../components/CustomFormModal';
import { CustomInput } from '../../../../components/CustomInput';
import { EntityModel } from '../../../../models/EntityModel';
import { RootState } from '../../../../redux';
import franchisesServices from '../../services/FranchiseServices';
import { franchiseSchemaValidation } from '../../utils/franchiseSchemeValidations';
import { createfranchiseInitialValues } from '../../utils/utils';
interface Props {
  refetch: () => void;
}

const AddButton = (props: any) => {
  return (
    <button
      onClick={props.onClick}
      type='button'
      className='btn btn-primary align-self-center'
      data-kt-menu-trigger='click'
      data-kt-menu-placement='bottom-end'
    >
      <i className='bi bi-plus fs-2'></i>
      Add Franchise
    </button>
  );
};

export function FranchiseCreate({ refetch }: Props) {
  const [account, client] = useSelector<RootState, EntityModel[]>(
    ({ entities }: any) => [entities.account, entities.client],
    shallowEqual
  );
  const onSubmit = (values: any) => {
    return new Promise<any>((resolve, reject) => {
      franchisesServices
        .createFranchise(values)
        .then((values) => {
          resolve(values);
          refetch();
        })
        .catch((err) => reject(err));
    });
  };

  const onClose = () => {};

  // TODO datos a completar desde contexto redux o url
  if (client) {
    createfranchiseInitialValues.clientId = client.id;
  }

  return (
    <div
      className='d-flex justify-content-end'
      data-kt-customer-table-toolbar='base'
    >
      <CustomFormModal
        initialValues={createfranchiseInitialValues}
        onSubmit={onSubmit}
        onClose={onClose}
        successMessage='Franchise added successfully.'
        title='Add Franchise'
        buttonLabel='Add'
        submitButtonLabel='Add'
        schemaValidations={franchiseSchemaValidation}
        buttonNode={<AddButton />}
      >
        <>
          <div className='card-body p-3'>
            <div className='row'>
              {account && (
                <div className='col-6'>
                  <div className='form-label'>Account</div>
                  <span className='text-muted'>{account.name}</span>
                </div>
              )}
              {client && (
                <div className='col-6'>
                  <div className='form-label'>Client</div>
                  <span className='text-muted'>{client.name}</span>
                </div>
              )}
            </div>
            <div className='row pt-4'>
              <div className='col-6'>
                <CustomInput
                  isRequired={true}
                  id='number'
                  name='number'
                  type='string'
                  label='Number'
                />
              </div>
              <div className='col-6'>
                <CustomInput
                  isRequired={true}
                  id='name'
                  type='text'
                  name='name'
                  label='Name'
                />
              </div>
            </div>

            <div className='row pt-4'>
              <div className='form-check'>
                <div className='p-3'>
                  <Field
                    type='checkbox'
                    className='form-check-input'
                    id='isCorporate'
                    name='isCorporate'
                  />
                  <label className='form-check-label ms-3'>
                    Corporate Franchise
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      </CustomFormModal>
    </div>
  );
}
