export interface ErrorObject {
  [key: string]: string[];
}
interface ErrorObjectString {
  [key: string]: string;
}
export const handle400Error = (
  backendErrors: ErrorObject,
  setErrors: any,
  setStatus?: any
) => {
  let errors: ErrorObjectString = {};
  for (let key in backendErrors) {
    errors[key] = backendErrors[key].join(' ');
  }
  setErrors(errors);
  //setStatus(errors,400);
};
