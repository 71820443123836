interface OriginBadgeProps {
  origin: 'Web' | 'Auto' | 'Phone' | 'Email' | 'ForceOut' | 'ThirdParty';
}

const colors = {
  Web: '#12347d',
  Auto: '#1BA0F4',
  Phone: '#9C0E0E',
  Email: '#007B4F',
  ForceOut: '#ffc107',
  ThirdParty: '#6f42c1',
};

export const OriginBadge = ({ origin }: OriginBadgeProps) => {
  // TODO: implement Auto with icon

  return (
    <span
      className='badge badge-light fw-bolder '
      style={{
        backgroundColor: 'transparent',
        borderColor: colors[origin],
        borderWidth: 1,
        borderStyle: 'solid',
        color: colors[origin],
      }}
    >
      {origin}
    </span>
  );
};
