import { Route, Switch } from 'react-router';
import { PageTitle } from '../../../_metronic/layout/core';
import { MFDataList } from './components/MFDataList';

export function MFDataPage() {
  return (
    <>
      <Switch>
        <Route exact path='/mfData'>
          <PageTitle breadcrumbs={[]}>MF Data</PageTitle>
          <MFDataList />
        </Route>
      </Switch>
    </>
  );
}
