import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const locationsSchemaValidation = (intl: IntlShape) =>
  Yup.object().shape({
    number: Yup.number()
      .typeError('Number must be a number')
      .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
      .min(0, intl.formatMessage({ id: 'VALIDATION.MIN.NUMBER' }, { min: 0 }))
      .integer(intl.formatMessage({ id: 'VALIDATION.INTEGER_FIELD' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    name: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 200 })
      )
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    emailAddress: Yup.string()
      .email('Wrong email format')
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    locationType: Yup.string().required(
      intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
    ),
    franchiseId: Yup.number()
      .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
      .min(1, intl.formatMessage({ id: 'VALIDATION.MIN.NUMBER' }, { min: 1 }))
      .integer(intl.formatMessage({ id: 'VALIDATION.INTEGER_FIELD' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    deliverDaysUps: Yup.number().integer(
      intl.formatMessage({ id: 'VALIDATION.INTEGER_FIELD' })
    ),
    deliverDaysFedex: Yup.number().integer(
      intl.formatMessage({ id: 'VALIDATION.INTEGER_FIELD' })
    ),
    addresses: Yup.object().shape({
      name: Yup.string()
        .ensure()
        .trim()
        .max(
          200,
          intl.formatMessage(
            { id: 'VALIDATION.MAX_LENGTH_FIELD' },
            { max: 200 }
          )
        )
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
      addressLine1: Yup.string()
        .ensure()
        .trim()
        .max(
          200,
          intl.formatMessage(
            { id: 'VALIDATION.MAX_LENGTH_FIELD' },
            { max: 200 }
          )
        )
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
      addressLine2: Yup.string()
        .ensure()
        .trim()
        .max(
          200,
          intl.formatMessage(
            { id: 'VALIDATION.MAX_LENGTH_FIELD' },
            { max: 200 }
          )
        ),
      addressLine3: Yup.string()
        .ensure()
        .trim()
        .max(
          200,
          intl.formatMessage(
            { id: 'VALIDATION.MAX_LENGTH_FIELD' },
            { max: 200 }
          )
        ),
      city: Yup.string()
        .ensure()
        .trim()
        .max(
          100,
          intl.formatMessage(
            { id: 'VALIDATION.MAX_LENGTH_FIELD' },
            { max: 100 }
          )
        )
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
      state: Yup.string()
        .ensure()
        .trim()
        .max(
          100,
          intl.formatMessage(
            { id: 'VALIDATION.MAX_LENGTH_FIELD' },
            { max: 100 }
          )
        )
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
      postalCode: Yup.string()
        .ensure()
        .trim()
        .max(
          20,
          intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 20 })
        )
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
      attention: Yup.string()
        .ensure()
        .trim()
        .max(
          256,
          intl.formatMessage(
            { id: 'VALIDATION.MAX_LENGTH_FIELD' },
            { max: 256 }
          )
        ),
      country: Yup.string()
        .ensure()
        .trim()
        .max(
          256,
          intl.formatMessage(
            { id: 'VALIDATION.MAX_LENGTH_FIELD' },
            { max: 256 }
          )
        )
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
      countryCode: Yup.string()
        .ensure()
        .trim()
        .max(
          10,
          intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 10 })
        )
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    }),
  });
