import axios from 'axios';
import { AvailabilityModel } from '../../../models/AvailabilityModel';
import { PagedResult } from '../../../models/pagedResult';
import { AvailabilityInfoModel } from '../../products/models/AvailabilityInfoModel';
import {
  CreateKitCardAttributeModel,
  CreateKitModel,
  KitFile,
  KitProductModel,
} from '../models/CreateKitModel';
import { KitInformationCreate } from '../models/KitInformationCreate';
import { KitModel } from '../models/KitModel';

export const KIT_URL = `/api/v1/kits`;

const kitServices = {
  getAllActive: function () {
    const url = `${KIT_URL}/all/active`;
    return axios.get<PagedResult>(url);
  },
  getAllActiveKits: function () {
    const url = `${KIT_URL}/all/active/kits`;
    return axios.get<PagedResult>(url);
  },
  getAll: function () {
    return axios.get<KitModel[]>(`${KIT_URL}/all`);
  },
  findKits: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(KIT_URL, { params: params });
  },
  getKit: (id: number) => {
    return axios.get<KitModel>(`${KIT_URL}/${id}`);
  },
  productInformationCreate: (kitInformation: KitInformationCreate) => {
    return axios.post<CreateKitModel>(`${KIT_URL}/information`, kitInformation);
  },
  productInformationEdit: (kitInformation: KitInformationCreate) => {
    return axios.put<CreateKitModel>(
      `${KIT_URL}/information/${kitInformation.id}`,
      kitInformation
    );
  },
  productSelection: (kitID: number, products: KitProductModel[]) => {
    return axios.post<CreateKitModel>(`${KIT_URL}/${kitID}/products`, products);
  },
  productImages: (kitId: number, body: any) => {
    return axios.post<{ files: KitFile[] }>(`${KIT_URL}/${kitId}/images`, body);
  },
  getAvailabilitiesInfo: () => {
    return axios.get<AvailabilityInfoModel>(`${KIT_URL}/availabilities/info`);
  },
  getAvailabilitiesInfoByProducts: (
    ids: number[],
    availabilityInfo: AvailabilityInfoModel
  ) => {
    const stringIds = ids
      .map((id) => {
        return 'ids=' + id;
      })
      .join('&');
    return axios.post<AvailabilityInfoModel>(
      `${KIT_URL}/availabilities/info?${stringIds}`,
      availabilityInfo,
      { params: { noLoading: true } }
    );
  },
  kitAvailabilities: (kitId: number, availabilities: AvailabilityModel[]) => {
    return axios.post<CreateKitCardAttributeModel>(
      `${KIT_URL}/${kitId}/availabilities`,
      availabilities
    );
  },
  desactivateKit: (id: number, reason?: string) => {
    const body = {
      id,
      reason,
    };
    return axios.put(`${KIT_URL}/deactivate/${id}`, body);
  },
  activateKit: (id: number) => {
    return axios.put(`${KIT_URL}/activate/${id}`);
  },
  clone: (id: number) => {
    return axios.post(`${KIT_URL}/clone/${id}`, { id });
  },
};

export default kitServices;
