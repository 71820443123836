export function Welcome() {
  return (
    <>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Welcome</h1>
      </div>

      <div className='text-center w-100 mt-5 '>
        <h4
          className='indicator-progress text-gray-600'
          style={{ display: 'block' }}
        >
          Please wait...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </h4>
      </div>
    </>
  );
}
