import React, { Suspense, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { FallbackView } from '../../_metronic/partials';
import { AccountsPage } from '../modules/entities/AccountsPage';
import { EntitiesPage } from '../modules/entities/EntitiesPage';
import { SelectGroup } from '../components/SelectGroup';
import { HomePage } from '../modules/home/HomePage';
import { UsersPage } from '../modules/users/UsersPage';
import { RootState } from '../redux';
import * as auth from '../modules/auth/redux/AuthRedux';
import { LoginUserModel } from '../modules/auth/models/LoginUserModel';
import { GroupsPage } from '../modules/users/GroupsPage';
import { usersServices } from '../modules/users/services/UsersServices';
import { GroupModel } from '../modules/users/models/GroupModel';
import { AccessDeniedModal } from '../components/AccessDeniedModal';
import { KitsPage } from '../modules/kits/KitsPage';
import { ProductsPage } from '../modules/products/ProductsPage';
import { AuditPage } from '../modules/audit/AuditPage';
import { OrdersPage } from '../modules/orders/OrdersPage';
import { WarehousePage } from '../modules/warehouses/WarehousePage';
import { FulfillmentPage } from '../modules/fulfillment/FulfillmentPage';
import { MFDataPage } from '../modules/mfData/MFDataPage';
import { ReportsPage } from '../modules/reports/ReportsPage';
import accountsServices from '../modules/entities/services/AccountsServices';

export function PrivateRoutes() {
  const dispatch = useDispatch();
  const accessDenied = useSelector<RootState, boolean>(
    ({ accessDenied }: any) => accessDenied.isAccessDenied,
    shallowEqual
  );

  const groups = useSelector<RootState, Array<GroupModel>>(
    ({ auth }: any) => auth.user.groups,
    shallowEqual
  );
  const user = useSelector<RootState, LoginUserModel>(
    ({ auth }: any) => auth.user,
    shallowEqual
  );

  const group = useSelector<RootState>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  const [showSelectGroupeModal, setShowSelectGroupeModal] = useState(false);

  const userGroupRelations = groups
    .map((g: GroupModel) => g.userGroupRelations)
    .flat();
  useEffect(() => {
    if (userGroupRelations.length === 1) {
      usersServices.selectGroup(user.id, userGroupRelations[0].id).then(() => {
        dispatch(
          auth.actions.setGroup({ g: groups[0], ugr: userGroupRelations[0] })
        );
      });
      if (userGroupRelations[0].accountId) {
        accountsServices
          .getAccountBranding(userGroupRelations[0].accountId)
          .then((response) => {
            if (response.data) {
              dispatch(auth.actions.setBranding(response.data));
            } else {
              dispatch(auth.actions.removeBranding());
            }
          });
      } else {
        dispatch(auth.actions.removeBranding());
      }
    } else if (!group) {
      setShowSelectGroupeModal(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleCloseModal = () => {
    setShowSelectGroupeModal(false);
  };

  return (
    <>
      {accessDenied && <AccessDeniedModal />}
      <SelectGroup
        show={showSelectGroupeModal}
        onHide={handleCloseModal}
      ></SelectGroup>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Suspense fallback={<FallbackView />}>
          <Switch>
            <Redirect exact from='/auth/callback' to='/home' />

            <Redirect exact from='/' to='/home' />

            <Route path='/home' component={HomePage} />

            {!group ? (
              <></>
            ) : (
              <>
                <Route path='/users' component={UsersPage} />
                <Route path='/inventory' component={HomePage} />
                <Route path='/products' component={ProductsPage} />
                <Route path='/kits' component={KitsPage} />
                <Route path='/orders' component={HomePage} />
                <Route path='/roles' component={HomePage} />
                <Route path='/accounts' component={AccountsPage} />
                <Route path='/groups' component={GroupsPage} />
                <Route path='/audit' component={AuditPage} />
                <Route path='/orders' component={OrdersPage} />
                <Route path='/warehouses' component={WarehousePage} />
                <Route path='/entities' component={EntitiesPage} />
                <Route path='/fulfillments' component={FulfillmentPage} />
                <Route path='/mfData' component={MFDataPage} />
                <Route path='/reports' component={ReportsPage} />
              </>
            )}

            <Redirect to='error/404' />
            <Redirect to='error/500' />
          </Switch>
        </Suspense>
      </QueryParamProvider>
    </>
  );
}
