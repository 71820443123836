import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Fragment, useCallback } from 'react';
import { CustomSelect } from '../../../components/CustomSelect';
import { ValueLabelPair } from '../../../models/ValueLabelPair';
import { ClientEditModel } from '../../entities/models/ClientEditModel';
import { LocationEditModel } from '../../entities/models/LocationEditModel';
import { LocationAddress } from '../../entities/models/LocationModel';
import clientsServices from '../../entities/services/ClientsServices';
import locationsServices from '../../entities/services/LocationsServices';
import { OrderCreateModel } from '../models/OrderCreateModel';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
const generateStringItem = (size: number) => {
  if (size === 0) {
    return 0;
  }
  if (size === 1) {
    return `${size} item.`;
  }
  if (size > 1) {
    return `${size} items.`;
  }
};
export const originTypes: Array<ValueLabelPair> = [
  { value: 'Web', label: 'Web' },
  { value: 'Phone', label: 'Phone' },
  { value: 'Email', label: 'Email' },
  //{ value: 'Auto', label: 'Auto' },
  //{ value: 'ForceOut', label: 'ForceOut' },
  //{ value: 'ThirdParty', label: 'ThirdParty' },
];
interface Props {
  setOrder: any;
}
export function PlaceOrderResume({ setOrder }: Props) {
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  const { setFieldValue, values } = useFormikContext<OrderCreateModel>();
  const [location, setLocation] = useState<LocationEditModel | null>(null);
  const shippingMethodOptions: Array<ValueLabelPair> = [
    { value: 'Ground', label: 'Ground' },
    { value: 'Next Day Air', label: 'Next Day Air' },
    { value: 'Second Day Ai', label: 'Second Day Air' },
  ];
  const [shippingCompanies, setShippingCompanies] = useState<
    Array<ValueLabelPair>
  >([]);

  const locationDetails = useCallback((locationId: number) => {
    locationsServices.getLocation(locationId).then((values) => {
      const body: LocationEditModel = {
        ...values.data,
        addresses: values.data.addresses[0],
      };
      setLocation(body);
    });
  }, []);
  const clientDetails = useCallback((clientId: number) => {
    clientsServices.getClient(clientId).then((response) => {
      const clientEdit: ClientEditModel = {
        id: response.data.id,
        accountId: response.data.accountId,
        key: response.data.key,
        name: response.data.name,
        autoReplenish: response.data.autoReplenish,
        canActiveCards: response.data.canActiveCards,
        allowThirdPartyShipping: response.data.allowThirdPartyShipping,

        packingListMessage: response.data.packingListMessage,
        shippingMethod: response.data.shippingMethod,
        shippingMethodAutoReplenishment:
          response.data.shippingMethodAutoReplenishment,
        orderNote1: response.data.orderNote1,
        orderNote2: response.data.orderNote2,
        orderNote3: response.data.orderNote3,
        customProperties: response.data.customProperties,
        shippingCompanies: response.data.shippingCompanies,
      };
      const options: Array<ValueLabelPair> = [];
      clientEdit.shippingCompanies.forEach((element) => {
        options.push({
          value: element.shipperCode,
          label: element.shipperCode,
        });
      });
      setShippingCompanies(options);

      if (options.length === 1)
        setFieldValue('shippingCompany', options[0].value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (values.locationId > 0) {
      locationDetails(values.locationId);
    }
    if (values.clientId > 0) {
      clientDetails(values.clientId);
    }
    return () => {
      setLocation(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.locationId, values.clientId]);
  useEffect(() => {
    setOrder((s: OrderCreateModel) => ({
      ...s,
      shippingCompany: values.shippingCompany,
      shippingMethod: values.shippingMethod,
      orderOrigin: values.orderOrigin,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.shippingCompany, values.shippingMethod, values.orderOrigin]);

  return (
    <Fragment>
      {userGroupRelations && !userGroupRelations.accountId && (
        <div className='mb-5'>
          <CustomSelect
            id='orderOrigin'
            options={originTypes}
            name='orderOrigin'
            label='Origin'
          />
        </div>
      )}
      <h5 className='mb-5'>
        Total: {generateStringItem(values.details.length)}
      </h5>
      <div className='mb-3'>
        <h5 className='mb-1'>Ship To: </h5>
        <div className='mb-0'>
          <div className='d-flex mx-10 mt-3'>
            {location?.addresses && formattedAdddress(location?.addresses)}
          </div>
        </div>
      </div>
      <div className='mb-7'>
        <CustomSelect
          label='Shipping Company'
          id='shippingCompany'
          name='shippingCompany'
          options={shippingCompanies}
        />
      </div>
      <div className='mb-7'>
        <CustomSelect
          label='Shipping Method'
          id='shippingMethod'
          name='shippingMethod'
          options={shippingMethodOptions}
        />
      </div>
    </Fragment>
  );
}

export const formattedAdddress = (
  addresses: LocationAddress,
  showName: boolean = true
) => {
  const stringAdddress = [];
  showName && addresses.name && stringAdddress.push(addresses.name);
  addresses.addressLine1 && stringAdddress.push(addresses.addressLine1);
  addresses.addressLine2 && stringAdddress.push(addresses.addressLine2);
  addresses.addressLine3 && stringAdddress.push(addresses.addressLine3);
  addresses.city && stringAdddress.push(addresses.city);
  addresses.state && stringAdddress.push(addresses.state);
  addresses.postalCode && stringAdddress.push(addresses.postalCode);
  addresses.countryCode && stringAdddress.push(addresses.countryCode);
  return stringAdddress.join(', ');
};
