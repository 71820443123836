import { useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import ListComponent from '../../../components/ListComponent';
import { useQueryParamsReplace } from '../../../utils/useQueryParamsReplace';
import { ReportBackorderedProductModel } from '../models/ReportBackorderedProductModel';
import { reportsServices } from '../services/ReportsServices';
import {
  initialFilterBackorderedProduct,
  queryParamsBackorderedProductConfig,
} from '../utils/utils';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { shallowEqual, useSelector } from 'react-redux';
import { BackorderedProductFilter } from './BackorderedProductFilter';

export function BackorderedProduct() {
  // Pagination, Filter and Query Params
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<ReportBackorderedProductModel[]>([]);

  const [queryParams, setQueryParams] = useQueryParamsReplace(
    queryParamsBackorderedProductConfig
  );

  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<ReportBackorderedProductModel>[] = useMemo(
    () => [
      {
        name: 'Client',
        selector: (row) => row.client,
        sortable: true,
        sortField: 'client',
      },
      {
        name: 'Product Code',
        selector: (row) => row.productCode,
        sortable: true,
        sortField: 'productCode',
      },
      {
        name: 'Quantity',
        selector: (row) => row.quantity,
        sortable: false,
        sortField: 'quantity',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  //Initialization methods
  useEffect(() => {
    if (history.location.search === '') {
      const newQueryParams = {
        ...initialFilterBackorderedProduct,
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };
      setQueryParams(newQueryParams);
    }

    if (queryParams.pageNumber) find(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const find = function (queryParams: any) {
    reportsServices.backorderedProduct(queryParams).then((response) => {
      setEntities(response.data.items);
      setTotalCount(response.data.totalCount);
    });
  };
  const download = function () {
    reportsServices.backorderedProductDownload(queryParams).then((response) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'backordered-product.csv');
      link.click();
    });
  };

  const customTableStyles = {
    rows: {
      style: {},
    },
    headCells: {
      style: {
        fontSize: '14px',
        color: '#a1a5b7',
      },
    },
    cells: {
      style: {},
    },
    pagination: {
      style: {
        justifyContent: 'flex-start',
      },
    },
  };

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header py-6'>
          <div className='card-title'></div>
          <div className='card-toolbar'>
            <BackorderedProductFilter
              queryParams={queryParams}
              setQueryParams={setQueryParams}
            />
            <button
              type='button'
              className='btn btn-primary align-self-center'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              onClick={(e) => {
                e.preventDefault();
                download();
              }}
            >
              <i className='fas fa-download fs-2'></i>
              Download
            </button>
          </div>
        </div>

        <div className='card-body px-9 py-3'>
          <ListComponent
            table={{
              columns,
              data: entities,
              paginationTotalRows: totalCount,
            }}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            customStyles={customTableStyles}
          />
        </div>
      </div>
    </>
  );
}
