import { useFormikContext } from 'formik';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Select from 'react-select/dist/declarations/src/Select';
import ReactSelect, { SingleValue } from 'react-select';
import { entityServices } from '../../users/services/EntityServices';
import kitServices from '../services/KitsServices';
import { AvailabilityInfoModel } from '../../products/models/AvailabilityInfoModel';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
export interface ValueLabelPair {
  value: number;
  label: string;
}
const keyLabel = 'cascadeselects';
type Props = {
  ids: number[];
};
export function KitCascadeEntitySelection({ ids }: Props) {
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  const [accounts, setAccounts] = useState<ValueLabelPair[]>([]);
  const [clients, setClients] = useState<ValueLabelPair[]>([]);
  const [franchises, setFranchises] = useState<ValueLabelPair[]>([]);
  const [locations, setLocations] = useState<ValueLabelPair[]>([]);
  const [info, setInfo] = useState<AvailabilityInfoModel>({
    accounts: [],
    clients: [],
    franchises: [],
    locations: [],
    userType: '',
  });
  // Clearable Values
  const [isClearableAccounts, setIsClearableAccounts] =
    useState<boolean>(false);
  const [isClearableClients, setIsClearableClients] = useState<boolean>(false);
  const [isClearableFranchises, setIsClearableFranchises] =
    useState<boolean>(false);
  const [isClearableLocations, setIsClearableLocations] =
    useState<boolean>(false);

  const { setFieldValue, values } = useFormikContext<any>();

  //Clear refs
  const clientsRef = useRef<null | Select>(null);
  const franchiseRef = useRef<null | Select>(null);
  const locationsRef = useRef<null | Select>(null);

  const [d1, setD1] = useState(true);
  const [d2, setD2] = useState(true);
  const [d3, setD3] = useState(true);

  const availabilitiesInfo = useCallback(() => {
    kitServices.getAvailabilitiesInfo().then((response) => {
      if (response.status === 200) {
        const body = response.data;
        setInfo(body);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const availabilitiesInfoByProducts = useCallback(
    (type: string, info: AvailabilityInfoModel) => {
      kitServices
        .getAvailabilitiesInfoByProducts(ids, info)
        .then((response) => {
          if (type === 'Account') {
            const body = response.data.accounts;
            const items: ValueLabelPair[] =
              body.length > 0
                ? body.map((x) => {
                    return { value: x.id, label: x.name };
                  })
                : [];
            setAccounts(items);
            if (info.accounts.length === items.length) {
              setIsClearableAccounts(true);
            }
          }
          if (type === 'Client') {
            const body = response.data.clients;
            const items: ValueLabelPair[] =
              body.length > 0
                ? body.map((x) => {
                    return { value: x.id, label: x.name };
                  })
                : [];
            setClients(items);
            setD1(false);
            if (info.clients.length === items.length) {
              setIsClearableClients(true);
            }
          }
          if (type === 'Franchise') {
            const body = response.data.franchises;
            const items: ValueLabelPair[] =
              body.length > 0
                ? body.map((x) => {
                    return { value: x.id, label: x.name };
                  })
                : [];
            setFranchises(items);
            setD2(false);
            if (info.franchises.length === items.length) {
              setIsClearableFranchises(true);
            }
          }
          if (type === 'Location') {
            const body = response.data.locations;
            const items: ValueLabelPair[] =
              body.length > 0
                ? body.map((x) => {
                    return { value: x.id, label: x.name };
                  })
                : [];
            setLocations(items);
            setD3(false);
            if (info.locations.length === items.length) {
              setIsClearableLocations(true);
            }
          }
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [ids]
  );
  useEffect(() => {
    availabilitiesInfo();
  }, [availabilitiesInfo]);

  useEffect(() => {
    if (ids.length > 0 && info?.accounts?.length > 0) {
      availabilitiesInfoByProducts('Account', info);
      setIsClearableAccounts(false);
      setIsClearableClients(false);
      setIsClearableFranchises(false);
      setIsClearableLocations(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info, ids]);

  // Auto select options:
  useEffect(() => {
    if (accounts.length > 0) {
      handleAccountChange(accounts[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);
  useEffect(() => {
    if (clients.length > 0) {
      handleClientChange(clients[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);
  useEffect(() => {
    if (franchises.length > 0) {
      handleFranchiseChange(franchises[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [franchises]);
  useEffect(() => {
    if (locations.length > 0) {
      handleLocationChange(locations[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  const clientList = useCallback(
    (accountId: number) => {
      entityServices.getClients(accountId).then((values) => {
        const data: AvailabilityInfoModel = {
          accounts: accounts.map((x) => {
            return { id: x.value, name: x.label };
          }),
          clients: values.data,
          franchises: [],
          locations: [],
          userType: info.userType,
        };
        availabilitiesInfoByProducts('Client', data);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accounts]
  );
  const franchiseList = useCallback(
    (clientId: number) => {
      entityServices.getFranchises(clientId).then((values) => {
        const data: AvailabilityInfoModel = {
          ...info,
          accounts: accounts.map((x) => {
            return { id: x.value, name: x.label };
          }),
          clients: clients.map((x) => {
            return { id: x.value, name: x.label };
          }),
          franchises: values.data,
          locations: [],
          userType: info.userType,
        };
        availabilitiesInfoByProducts('Franchise', data);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accounts, clients]
  );
  const locationsList = useCallback(
    (franchiseId: number) => {
      entityServices.getLocations(franchiseId).then((values) => {
        const data: AvailabilityInfoModel = {
          accounts: accounts.map((x) => {
            return { id: x.value, name: x.label };
          }),
          clients: clients.map((x) => {
            return { id: x.value, name: x.label };
          }),
          franchises: franchises.map((x) => {
            return { id: x.value, name: x.label };
          }),
          locations: values.data,
          userType: info.userType,
        };
        availabilitiesInfoByProducts('Location', data);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accounts, clients, franchises]
  );
  const handleAccountChange = (option: SingleValue<ValueLabelPair>) => {
    const selectValue =
      option && option.hasOwnProperty('value') ? option.value : null;
    const selectLabel =
      option && option.hasOwnProperty('label') ? option.label : '';
    setFieldValue(`${keyLabel}.accountId`, selectValue);
    setFieldValue(`${keyLabel}.accountName`, selectLabel);
    if (selectValue) clientList(selectValue);
    // Clear Refs 2 .. N
    clientsRef.current?.clearValue();
    franchiseRef.current?.clearValue();
    locationsRef.current?.clearValue();
    setD1(true);
    setD2(true);
    setD3(true);
  };
  const handleClientChange = (option: any) => {
    // Is Clerable case
    const selectValue =
      option && option.hasOwnProperty('value') ? option.value : null;
    const selectLabel =
      option && option.hasOwnProperty('label') ? option.label : '';
    setFieldValue(`${keyLabel}.clientId`, selectValue);
    setFieldValue(`${keyLabel}.clientName`, selectLabel);
    if (selectValue) franchiseList(selectValue);
    // Clear Refs 3 .. N
    franchiseRef.current?.clearValue();
    locationsRef.current?.clearValue();
    setD2(true);
    setD3(true);
  };
  const handleFranchiseChange = (option: any) => {
    // Is Clerable case
    const selectValue =
      option && option.hasOwnProperty('value') ? option.value : null;
    const selectLabel =
      option && option.hasOwnProperty('label') ? option.label : '';
    setFieldValue(`${keyLabel}.franchiseId`, selectValue);
    setFieldValue(`${keyLabel}.franchiseName`, selectLabel);
    if (selectValue) locationsList(selectValue);
    // Clear Refs 4 .. N
    locationsRef.current?.clearValue();
    setD3(true);
  };
  const handleLocationChange = (option: any) => {
    // Is Clerable case
    const selectValue =
      option && option.hasOwnProperty('value') ? option.value : null;
    const selectLabel =
      option && option.hasOwnProperty('label') ? option.label : '';
    setFieldValue(`${keyLabel}.locationId`, selectValue);
    setFieldValue(`${keyLabel}.locationName`, selectLabel);
  };
  return (
    <Fragment>
      <div style={{ minWidth: '200px' }}>
        <label className='form-label'>Account</label>
        <ReactSelect
          placeholder='All'
          value={
            accounts[
              accounts.findIndex(
                (x) => x.value === values?.cascadeselects?.accountId
              )
            ]
          }
          isClearable={isClearableAccounts}
          //isDisabled={values?.cascadeselects?.accountId ? true : false}
          isDisabled={userGroupRelations.accountId ? true : false}
          name={`${keyLabel}.accountId`}
          id={`${keyLabel}.accountId`}
          key={`${keyLabel}.accountId`}
          options={accounts}
          onChange={handleAccountChange}
          autoFocus={true}
        />
      </div>
      <div style={{ minWidth: '200px' }}>
        <label className='form-label'>Client</label>
        <ReactSelect
          placeholder='All'
          isClearable={isClearableClients}
          key={`${keyLabel}.clientId`}
          id={`${keyLabel}.clientId`}
          options={clients}
          name={`${keyLabel}.clientId`}
          ref={clientsRef}
          value={
            clients[
              clients.findIndex(
                (x) => x.value === values?.cascadeselects?.clientId
              )
            ]
          }
          isDisabled={d1 || userGroupRelations.clientId ? true : false}
          onChange={handleClientChange}
        />
      </div>
      <div style={{ minWidth: '200px' }}>
        <label className='form-label'>Franchise</label>
        <ReactSelect
          placeholder='All'
          isClearable={isClearableFranchises}
          id={`${keyLabel}.franchiseId`}
          key={`${keyLabel}.franchiseId`}
          options={franchises}
          name={`${keyLabel}.franchiseId`}
          ref={franchiseRef}
          value={
            franchises[
              franchises.findIndex(
                (x) => x.value === values?.cascadeselects?.franchiseId
              )
            ]
          }
          isDisabled={d2 || userGroupRelations.franchiseId ? true : false}
          onChange={handleFranchiseChange}
        />
      </div>
      <div style={{ minWidth: '200px' }}>
        <label className='form-label'>Location</label>
        <ReactSelect
          placeholder='All'
          isClearable={isClearableLocations}
          id={`${keyLabel}.locationId`}
          name={`${keyLabel}.locationId`}
          key={`${keyLabel}.locationId`}
          options={locations}
          ref={locationsRef}
          value={
            locations[
              locations.findIndex(
                (x) => x.value === values?.cascadeselects?.locationId
              )
            ]
          }
          autoFocus={true}
          isDisabled={d3 || userGroupRelations.locationId ? true : false}
          onChange={handleLocationChange}
        />
      </div>
    </Fragment>
  );
}
