import { Modal } from 'react-bootstrap-v5';

interface Props {
  message: string;
  show: boolean;
  onCancel: () => void;
  onAcept: () => void;
}

export function ConfirmationModal({ message, show, onCancel, onAcept }: Props) {
  return (
    <Modal show={show} onHide={onCancel} backdrop='static' keyboard={false}>
      <Modal.Body className='mt-1 px-6 py-3 position-relative z-index-1'>
        <div className='d-flex align-items-center mb-4'>
          <i className={`las la-exclamation-triangle fs-5x text-warning`}></i>
          <div className='flex-grow-3 me-4'>
            <div className='fw-bold text-gray-700 fs-6 ms-10'>{message}</div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='p-2 card-rounded  w-100'>
        <div className='row'>
          <div className='text-center'>
            <button
              type='button'
              className='btn btn-light me-3'
              onClick={onCancel}
            >
              No
            </button>

            <button
              type='submit'
              id='kt_modal_new_card_submit'
              className='btn btn-warning'
              onClick={onAcept}
            >
              <span className='indicator-label'>Yes </span>
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
