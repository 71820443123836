import { NumberParam, StringParam } from 'serialize-query-params';
import { MFDataUploadModel } from '../models/MFDataUploadModel';
export const initialFilterMFData = {
  sortDirection: 1,
  sortColumn: 'id',
  pageNumber: 1,
  pageSize: 10,
  filter: undefined,
};

export type MFDataQueryFilterKeys = 'filter';

export const queryParamsMFDataConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  filter: StringParam,
};

export const uploadInitialValues: MFDataUploadModel = {
  productId: 0,
  file: null,
};
