import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select/dist/declarations/src/Select';
import { CustomFilter } from '../../../../components/CustomFilter';
import ReactSelect from 'react-select';
import { FilterBadge } from '../../../../models/FilterBadge';
import { CustomBadgeFilter } from '../../../../components/CustomBadgeFilter';

const isCoporateOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
const activeOptions = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];
interface PropsFilter {
  queryParams: any;
  setQueryParams: any;
}
export function FranchisesFilter({ setQueryParams, queryParams }: PropsFilter) {
  // Filter States
  const [isCorporate, setIsCorporate] = useState<boolean | null>();
  const [isActive, setIsActive] = useState<boolean | null>();
  const isCorporateRef = useRef<null | Select>(null);
  const isActiveRef = useRef<null | Select>(null);

  // Get initial values from the url
  useEffect(() => {
    let clearFilters = [];

    if (
      queryParams.isCorporate !== null ||
      queryParams.isCorporate !== undefined
    ) {
      setIsCorporate(queryParams.isCorporate);
      if (queryParams.isCorporate === true || queryParams.isCorporate === false)
        updateBadge(
          'isCorporate',
          queryParams.isCorporate === true
            ? 'Corporate'
            : queryParams.isCorporate === false
            ? 'No Corporate'
            : '',
          isCorporateRef
        );
      else clearFilters.push('isCorporate');
    }

    if (queryParams.active || !queryParams.active) {
      setIsActive(queryParams.active);
      if (queryParams.active === true || queryParams.active === false)
        updateBadge(
          'active',
          queryParams.active === true
            ? 'Active'
            : queryParams.active === false
            ? 'Inactive'
            : '',
          isActiveRef
        );
      else clearFilters.push('active');
    }

    if (clearFilters.length > 0) {
      clearMultipleFilter(clearFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const handleIsCorporate = (params: any) => {
    if (params) {
      setIsCorporate(params.value);
    } else {
      setIsCorporate(undefined);
    }
  };

  const handleIsActive = (params: any) => {
    if (params) {
      setIsActive(params.value);
    } else {
      setIsActive(undefined);
    }
  };

  const resetFilters = () => {
    // Clear All Ref
    isCorporateRef.current?.clearValue();
    // Update QueryParams: Review if
    const newQueryParams = { ...queryParams };
    newQueryParams.pageNumber = 1;
    newQueryParams.isCorporate = undefined;
    newQueryParams.active = true;
    setQueryParams(newQueryParams);
  };

  const applyFilter = () => {
    const newQueryParams = { ...queryParams };
    newQueryParams.isCorporate = isCorporate;
    newQueryParams.active = isActive;
    newQueryParams.pageNumber = 1;
    setQueryParams(newQueryParams);
  };

  /** START Filter Badges */
  const [filterBadges, setFilteBadges] = useState<FilterBadge[]>([]);
  const clearMultipleFilter = (filters: Array<string>) => {
    let badges = filterBadges;
    filters.forEach((element) => {
      badges = badges.filter((item: FilterBadge) => item.key !== element);
    });
    setFilteBadges(badges);
  };
  const clearFilter = (ref: any, keyParam: string) => {
    if (
      keyParam === 'accountId' ||
      keyParam === 'clientId' ||
      keyParam === 'franchiseId'
    ) {
      clearMultipleFilter([
        'accountId',
        'clientId',
        'franchiseId',
        'locationId',
      ]);
      let params = { ...queryParams };
      params['accountId'] = undefined;
      params['clientId'] = undefined;
      params['franchiseId'] = undefined;
      params['locationId'] = undefined;
      // When removing a badge you should be redirected to page 1.
      params['pageNumber'] = 1;
      setQueryParams(params);

      filterBadges
        .find((element) => element.key === 'accountId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'clientId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'franchiseId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'locationId')
        ?.ref.current?.clearValue();
    } else if (ref) {
      ref.current?.clearValue();
      let params = { ...queryParams };
      params[keyParam] = undefined;
      // When removing a badge you should be redirected to page 1.
      params['pageNumber'] = 1;
      setQueryParams(params);
    }

    setFilteBadges(
      filterBadges.filter((item: FilterBadge) => item.key !== keyParam)
    );
  };
  const updateBadge = (key: string, value: string, ref: any) => {
    let badges = filterBadges;
    const finded = badges?.find((item: FilterBadge) => item.key === key);
    if (finded && finded !== undefined) {
      badges.splice(badges.indexOf(finded), 1, {
        key: key,
        value: value,
        ref: ref,
      });
    } else {
      badges.push({
        key: key,
        value: value,
        ref: ref,
      });
    }
    setFilteBadges(badges);
  };
  /** END Filter Badges */

  return (
    <>
      {filterBadges?.map((item: FilterBadge) => {
        if (item.value && item.value !== '')
          return (
            <CustomBadgeFilter
              text={item.value}
              key={item.key}
              badgeKey={item.key}
              clearFilter={() => {
                if (item.ref) clearFilter(item.ref, item.key);
              }}
            ></CustomBadgeFilter>
          );
        else return <></>;
      })}
      <CustomFilter applyFilters={applyFilter} resetFilters={resetFilters}>
        <div onClick={(e) => e.stopPropagation()}>
          <label className='form-label fw-bold'>Is Corporate Franchise?</label>
          <ReactSelect
            placeholder={<FormattedMessage id='COMMON.ALL' />}
            isClearable
            name='isCorporate'
            id='isCorporate'
            onChange={handleIsCorporate}
            options={isCoporateOptions}
            value={
              isCoporateOptions
                ? isCoporateOptions.filter(
                    (x: any) => x.value === isCorporate
                  )[0]
                : null
            }
            ref={isCorporateRef}
          />
          <label className='form-label fw-bold'>Active</label>
          <ReactSelect
            placeholder={<FormattedMessage id='COMMON.ALL' />}
            isClearable
            name='isActive'
            id='isActive'
            onChange={handleIsActive}
            options={activeOptions}
            value={
              activeOptions
                ? activeOptions.filter((x: any) => x.value === isActive)[0]
                : null
            }
            ref={isActiveRef}
            defaultValue={activeOptions[0]}
          />
        </div>
        {/* Add more filters */}
      </CustomFilter>
    </>
  );
}
