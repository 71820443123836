import { ErrorMessage, useFormikContext } from 'formik';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CustomPropertyCreate } from '../../models/CustomPropertyCreate';
export interface ValueLabelPair {
  value: number;
  label: string;
}

export function CustomAttributes() {
  const { setFieldValue, values } = useFormikContext<any>();

  const [name, setName] = useState<string>();
  const nameRef = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState<string>();

  const [rowKey, setRowKey] = useState<string>();
  const [editName, setEditName] = useState<string>();
  const [editValue, setEditValue] = useState<string>();

  const handleAddItem = () => {
    let alreadyExist = false;
    values.customProperties.forEach((element: CustomPropertyCreate) => {
      if (element.name === name) {
        alreadyExist = true;
        toast.warning(`The attibute ${name} already exists`);
        return;
      }
    });
    if (!alreadyExist) {
      setFieldValue('customProperties', [
        ...values.customProperties,
        { name: name, value: value },
      ]);
      setName('');
      setValue('');
      if (nameRef && nameRef.current) nameRef.current.focus();
    }
  };
  const handleRemoveItem = (idx: number) => {
    if (values.customProperties.length > 0) {
      setFieldValue(
        'customProperties',
        values.customProperties.filter(
          (item: CustomPropertyCreate, index: number) => index !== idx
        )
      );
    }
  };
  const handleEditRow = () => {
    if (editName && editValue) {
      const customProperties = [...values.customProperties];
      const prop = customProperties.find(
        (p: CustomPropertyCreate) => p.name === rowKey
      );
      if (prop) {
        let alreadyExist = false;
        if (editName !== rowKey) {
          customProperties.forEach((element: CustomPropertyCreate) => {
            if (element.name === editName) {
              alreadyExist = true;
              toast.warning(`The attibute ${editName} already exists`);
              return;
            }
          });
        }

        if (!alreadyExist) {
          prop.name = editName;
          prop.value = editValue;
          setFieldValue('customProperties', customProperties);
          setRowKey(undefined);
        }
      }
    } else {
      toast.warning(`Both fields are required.`);
    }
  };
  return (
    <div
      onKeyPress={(e) => {
        if (e.key === 'Enter' && !rowKey) handleAddItem();
        if (e.key === 'Enter' && rowKey) handleEditRow();
      }}
    >
      <div className='row'>
        <div className='col-4'>
          <label className='form-label'>Name</label>
          <input
            autoComplete='none'
            type='text'
            className='form-control'
            value={name}
            onChange={(e) => setName(e.target.value)}
            ref={nameRef}
          />
        </div>
        <div className='col-4'>
          <label className='form-label'>Value</label>

          <input
            autoComplete='none'
            type='text'
            className='form-control'
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className='col-4'>
          <div className=''>
            <button
              onClick={handleAddItem}
              type='button'
              className='btn btn-primary mt-8 p-3'
              disabled={
                name === undefined ||
                value === undefined ||
                name === '' ||
                value === ''
              }
            >
              <i className='las la-plus fs-2'></i>Add Attribute
            </button>
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-12'>
          <ErrorMessage name='customAttributes'>
            {(msg) => <div className='text-danger'>{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
      <div className='table-responsive mt-10'>
        <table className='table table-row-dashed table-row-gray-300 align-middle g-4'>
          <thead>
            <tr className='fw-bolder text-muted'>
              <th className=''>Name</th>
              <th className=''>Value</th>
            </tr>
          </thead>
          <tbody className='border-0'>
            {values.customProperties.map(
              (x: CustomPropertyCreate, index: number) => {
                return (
                  <tr key={`userGroupRelations.${index}`}>
                    {rowKey !== x.name && (
                      <>
                        <td className='px-8'>{x.name}</td>
                        <td className='px-8'>{x.value}</td>
                        <td style={{ width: '120px' }}>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveItem(index);
                            }}
                            className='btn btn-icon btn-bg-light btn-active-danger btn-sm me-1'
                            data-bs-toggle='tooltip'
                            title='Delete'
                            type='button'
                          >
                            <i className='las la-trash fs-2'></i>
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setRowKey(x.name);
                              setEditName(x.name);
                              setEditValue(x.value);
                            }}
                            className='btn btn-icon btn-bg-light btn-active-primary btn-sm me-1'
                            data-bs-toggle='tooltip'
                            title='Edit'
                            type='button'
                          >
                            <i className='las la-edit fs-2'></i>
                          </button>
                        </td>
                      </>
                    )}
                    {rowKey === x.name && (
                      <>
                        <td>
                          <input
                            autoComplete='none'
                            type='text'
                            className='form-control'
                            value={editName}
                            onChange={(e) => setEditName(e.target.value)}
                          />{' '}
                        </td>
                        <td>
                          <input
                            autoComplete='none'
                            type='text'
                            className='form-control'
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                          />{' '}
                        </td>
                        <td style={{ width: '120px' }}>
                          <button
                            onClick={() => {
                              handleEditRow();
                            }}
                            className='btn btn-icon btn-bg-light btn-active-success btn-sm me-1'
                            data-bs-toggle='tooltip'
                            title='Save'
                            type='button'
                          >
                            <i className='las la-check fs-2'></i>
                          </button>
                          <button
                            onClick={(e) => {
                              setRowKey(undefined);
                            }}
                            className='btn btn-icon btn-bg-light btn-active-danger btn-sm me-1'
                            data-bs-toggle='tooltip'
                            title='Cancel'
                            type='button'
                          >
                            <i className='las la-times fs-2'></i>
                          </button>
                        </td>
                      </>
                    )}
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
