import { useEffect, useState } from 'react';
import fulfillmentServices from '../services/mfDataServices';

import { TableColumn } from 'react-data-table-component';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ListComponent from '../../../components/ListComponent';
import {
  MFDataQueryFilterKeys,
  initialFilterMFData,
  queryParamsMFDataConfig,
} from '../utils/utils';
import { useQueryParamsReplace } from '../../../utils/useQueryParamsReplace';
import { GroupModel } from '../../users/models/GroupModel';
import { RootState } from '../../../redux';
import { MFDataImportModal } from './MFDataImportModal';
import { MFDataSecondaryModal } from './MFDataSecondaryModal';
import { ManufacturerDataValidateHeaderModel } from '../models/MFDataValidateHeaderModel';
import { MFDataListModel } from '../models/MFDataListModel';
import { MFStatusBadge } from './MFStatus';

export function MFDataList() {
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );

  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<MFDataListModel[]>([]);
  const [queryParams, setQueryReplace] = useQueryParamsReplace(
    queryParamsMFDataConfig
  );
  const [filter, setFilter] = useState('');
  const history = useHistory();

  const columns: TableColumn<MFDataListModel>[] = useMemo(
    () => [
      {
        name: 'Status',
        selector: (row) => row.statusName,
        sortable: true,
        sortField: 'statusName',
        minWidth: '100px',
        wrap: true,
        cell: (row) => {
          return <MFStatusBadge status={row.statusName} />;
        },
      },
      {
        name: 'Code',
        selector: (row) => row.productCode,
        sortable: true,
        sortField: 'code',
        minWidth: '150px',
        wrap: true,
      },
      {
        name: 'Description',
        selector: (row) => row.productDescription,
        wrap: true,
        minWidth: '150px',
        sortable: true,
        sortField: 'description',
      },
      {
        name: 'From',
        cell: (row) => row.from,
        wrap: true,
        minWidth: '150px',
        sortable: true,
        sortField: 'from',
      },
      {
        name: 'To',
        selector: (row) => row.to,
        wrap: true,
        minWidth: '150px',
        sortable: true,
        sortField: 'to',
      },
      {
        name: 'Quantity',
        selector: (row) => row.quantity,
        wrap: true,
        minWidth: '150px',
        sortable: true,
        sortField: 'quantity',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Initialization methods
  useEffect(() => {
    if (history.location.search === '') {
      setQueryReplace(initialFilterMFData);
    }
    if (queryParams.filter) setFilter(queryParams.filter);
    if (queryParams.pageNumber) findMFData(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findMFData = function (queryParams: any) {
    fulfillmentServices.findMFData(queryParams).then((response) => {
      setEntities(response.data.items);
      setTotalCount(response.data.totalCount);
    });
  };

  const onFilterChange = (e: any, key: MFDataQueryFilterKeys) => {
    if (key === 'filter') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };

  const findWithFilters = (params: {
    key: MFDataQueryFilterKeys;
    value: any;
  }) => {
    const newQueryParams = { ...queryParams };
    newQueryParams[params.key] = params.value;
    newQueryParams.pageNumber = 1;
    setQueryReplace(newQueryParams);
  };

  const debounced = useDebouncedCallback(findWithFilters, 800);
  // Modal: Import File
  const [showImportFileModal, setShowImportFileModal] = useState(false);
  const handleShowImportFileModal = () => {
    setShowImportFileModal(true);
  };
  const handleCloseImportFileModal = () => {
    setShowImportFileModal(false);
  };
  // Modal: Question
  const [details, setDetails] =
    useState<ManufacturerDataValidateHeaderModel | null>();
  const refetch = () => {
    findMFData(queryParams);
  };

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header py-6'>
          <div className='card-title'>
            {group.warehouseInventoryPermission?.list && (
              <div className='d-flex align-items-center position-relative my-1'>
                <i className='las la-search position-absolute ms-6 fs-2'></i>
                <input
                  autoComplete='none'
                  type='search'
                  className='form-control form-control-solid w-350px ps-15'
                  value={filter}
                  placeholder='Search by Product Code or Description'
                  onChange={(e) => onFilterChange(e, 'filter')}
                />
              </div>
            )}
          </div>
          <div className='card-toolbar'>
            {group.warehouseInventoryPermission?.create && (
              <>
                <button
                  onClick={() => handleShowImportFileModal()}
                  type='button'
                  className='btn btn-primary align-self-center'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                >
                  <i className='las la-upload fs-2'></i>
                  Import MF Data file
                </button>

                {showImportFileModal && (
                  <MFDataImportModal
                    show={showImportFileModal}
                    onHide={handleCloseImportFileModal}
                    setDetails={setDetails}
                  />
                )}
                {!!details && !!details.showSecondaryModal && (
                  <MFDataSecondaryModal
                    details={details}
                    setDetails={setDetails}
                    refetch={refetch}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className='card-body px-9 py-3'>
          {group.warehouseInventoryPermission?.list && (
            <ListComponent
              table={{
                columns,
                data: entities,
                paginationTotalRows: totalCount,
              }}
              queryParams={queryParams}
              setQueryParams={setQueryReplace}
            />
          )}
        </div>
      </div>
    </>
  );
}
