import axios from 'axios';
import { CountryModel } from '../../../models/CountryModel';
import { PagedResult } from '../../../models/pagedResult';
import { ImportLocationsModel } from '../models/ImportLocationsModel';
import { LocationCreateModel } from '../models/LocationCreateModel';
import { LocationEditModel } from '../models/LocationEditModel';
import { LocationModel } from '../models/LocationModel';

export const LOCATIONS_URL = `/api/v1/locations`;
const LOCALIZATION_URL = `/api/v1/localization`;
const locationsServices = {
  findLocations: (queryParams: any) => {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(LOCATIONS_URL, { params: params });
  },
  createLocation: (location: LocationCreateModel) => {
    const body = {
      ...location,
      addresses: [location.addresses],
    };
    return axios.post(LOCATIONS_URL, body);
  },
  getLocation: (id: number) => {
    return axios.get<LocationModel>(`${LOCATIONS_URL}/${id}`);
  },
  updateLocation: (id: number, location: LocationEditModel) => {
    const body = {
      ...location,
      addresses: [location.addresses],
    };
    return axios.put<LocationModel>(`${LOCATIONS_URL}/${id}`, body);
  },
  getCountries: () => {
    return axios.get<CountryModel[]>(`${LOCALIZATION_URL}/countries`);
  },
  desactivateLocation: (id: number, reason?: string) => {
    const body = {
      id,
      reason,
    };
    return axios.put(`${LOCATIONS_URL}/deactivate/${id}`, body);
  },
  activateLocation: (id: number) => {
    return axios.put(`${LOCATIONS_URL}/activate/${id}`);
  },
  importLocationsFile: (data: ImportLocationsModel) => {
    const formData = new FormData();
    formData.append('file', data.file as any);
    return axios.post(`${LOCATIONS_URL}/import/${data.clientId}`, formData);
  },
};

export default locationsServices;
