import { useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { InventoryModel, ShippingUnitModel } from '../models/InventoryModel';
import { useQueryParamsReplace } from '../../../utils/useQueryParamsReplace';
import ListComponent from '../../../components/ListComponent';

import {
  initialFilterShippingUnits,
  queryParamsConfigShippingUnits,
} from '../utils/utils';
import warehouseServices from '../services/WarehousesServices';

export function ShippingUnitList({ inventory }: { inventory: InventoryModel }) {
  // Pagination, Filter and Query Params
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<ShippingUnitModel[]>([]);

  const [queryParams, setQueryReplace] = useQueryParamsReplace(
    queryParamsConfigShippingUnits
  );
  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<ShippingUnitModel>[] = useMemo(
    () => [
      {
        name: 'SU #',
        selector: (row) => row.number || '-',
        sortable: true,
        sortField: 'number',
        minWidth: '150px',
      },
      {
        name: 'Starting card number',
        selector: (row) => row.startingNumber || '-',
        sortField: 'startingNumber',
        sortable: true,
        minWidth: '200px',
        wrap: true,
      },
      {
        name: 'Ending card number',
        selector: (row) => row.endingNumber || '-',
        sortField: 'endingNumber',
        sortable: true,
        minWidth: '200px',
        wrap: true,
      },
      {
        name: 'Current card number',
        selector: (row) => row.currentNumber || '-',
        sortable: true,
        sortField: 'currentNumber',
        minWidth: '200px',
        wrap: true,
      },
      {
        name: 'Quantity',
        selector: (row) => row.quantity || '-',
        sortable: true,
        sortField: 'quantity',
      },
      {
        name: '',
        cell: (row) => {
          return (
            <>
              {row.isFull && (
                <div className='col-3'>
                  <span
                    className={`badge mx-3 align-right`}
                    style={{ backgroundColor: '#6610f2' }}
                  >
                    FULL
                  </span>
                </div>
              )}
            </>
          );
        },
        sortable: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //Initialization methods
  useEffect(() => {
    if (history.location.search === '') {
      setQueryReplace({
        ...initialFilterShippingUnits,
      });
    }

    if (queryParams.pageNumber) {
      findInventories();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findInventories = function () {
    warehouseServices
      .getInventoryDetailsShippingUnits(
        Number(inventory.warehouseId),
        Number(inventory.productId),
        queryParams
      )
      .then((res) => {
        setEntities(res.data.items);
        setTotalCount(res.data.totalCount);
      });
  };
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body px-9 py-3'>
          <ListComponent
            table={{
              columns,
              data: entities,
              paginationTotalRows: totalCount,
            }}
            queryParams={queryParams}
            setQueryParams={setQueryReplace}
          />
        </div>
      </div>
    </>
  );
}
