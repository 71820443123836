import { useHistory } from 'react-router-dom';

export function TablePermissionWarning() {
  const history = useHistory();

  const goToHome = () => {
    history.push('/home');
  };
  return (
    <>
      <div className='card h-md-100'>
        <div className='card-body d-flex flex-column flex-center'>
          <div className='mb-2'>
            <div className='flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center card-rounded-bottom h-200px mh-150px pt-2'>
              <div className='m-auto text-center'>
                <i className='las la-exclamation-triangle fa-10x text-danger'></i>
              </div>
            </div>

            <h1 className='fw-bold text-gray-800 text-center lh-lg   mb-lg-12 pt-2'>
              Sorry about that, but you don’t have <br></br>
              <span className='fw-boldest'>permission</span> to access this data
              table.
            </h1>
          </div>

          <div className='text-center'>
            <button
              type='button'
              onClick={goToHome}
              className='btn btn-primary'
            >
              Go to Home
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
