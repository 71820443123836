import { Fragment, useEffect, useState } from 'react';
import { userEditGroupInitialValues } from '../../utils/utils';
import { usersServices } from '../../services/UsersServices';
import { UserGroupsRelations } from '../../models/UserGroupsRelations';
import { SelectSetGroups } from './SelectSetGroups';
import { userSchemaValidation } from '../../utils/userShemaValidations';
import { ValueLabelPair } from '../../../../models/ValueLabelPair';
import { UserModel } from '../../models/UserModel';
//Modals imports
import { Formik, FormikHelpers, Form } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { ActionsComponent } from '../../../../components/ActionsComponent';
import { toast } from 'react-toastify';
import { handle400Error } from '../../../../utils/handle400Error';
import { useIntl } from 'react-intl';

interface Props {
  refetch: () => void;
  show: boolean;
  onHide: () => void;
  user: UserModel;
}

export function UserGroupsEdit({ refetch, show, onHide, user }: Props) {
  const id = user.id;
  const intl = useIntl();
  // Init Values & State
  const [userGroupRelations, setUserGroupRelations] =
    useState<UserGroupsRelations>(userEditGroupInitialValues);
  const [valueLabelPair, setValueLabelPair] = useState<ValueLabelPair[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const updateUserGroups = (values: any, actions: FormikHelpers<any>) => {
    const body = {
      id: values.id,
      groups: values.groups.map((x: ValueLabelPair) => {
        return x.value;
      }),
    };

    usersServices
      .setUserGroups(body, id)
      .then(() => {
        toast.success('User groups updated successfully.');
        onHide();
        refetch();
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          handle400Error(error.response.data.errors, actions.setErrors);
        }
      });
  };
  // Call the backend when there is a valid id and the modal has to be displayed.
  useEffect(() => {
    if (id && id !== 0 && show) {
      setLoading(true);
      usersServices.getUserGroups(user.capID).then((values) => {
        const items: number[] =
          values.data.length > 0
            ? values.data.map((x) => {
                return x.id;
              })
            : [];
        const pairs: ValueLabelPair[] =
          values.data.length > 0
            ? values.data.map((x) => {
                return { value: Number(x.id), label: x.name };
              })
            : [];

        setValueLabelPair(pairs);
        setUserGroupRelations({ id, groups: items });
        setLoading(false);
      });
    }
    return () => {
      setUserGroupRelations(userEditGroupInitialValues);
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, show]);

  const initValues = {
    id: user.id,
    groups: valueLabelPair,
  };
  return (
    <>
      {!loading && (
        <Modal
          show={show}
          onHide={onHide}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={userSchemaValidation(intl)}
            onSubmit={(values, actions) => {
              updateUserGroups(values, actions);
              actions.setSubmitting(false);
            }}
          >
            <Form>
              <Modal.Header className='px-9 pt-7 card-rounded'>
                <Modal.Title className='m-0 fw-bolder fs-3'>
                  Edit User Groups
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='mt-1 px-6 py-3 position-relative z-index-1'>
                <Fragment>
                  <div className='row pb-5'>
                    <div className='col-6'>
                      <div className='form-label'>Name</div>
                      <span className='text-muted'>{user.name}</span>
                    </div>
                    <div className='col-6'>
                      <div className='form-label'>Email</div>
                      <span className='text-muted'>{user.name}</span>
                    </div>
                  </div>
                  
                  <SelectSetGroups
                    user={userGroupRelations}
                    values={valueLabelPair}
                  />
                </Fragment>
              </Modal.Body>
              <Modal.Footer className='px-9 card-rounded  w-100'>
                <ActionsComponent
                  labelSubmit='Update'
                  showCancelButton={true}
                  onCancel={onHide}
                />
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      )}
    </>
  );
}
