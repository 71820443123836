import { unautorizedSlice } from './unautorizedSlice';

const { actions } = unautorizedSlice;

export const showUnautorizedModal = () => (dispatch: any) => {
  dispatch(actions.setIsUnautorized({ flag: true }));
};

export const hiddesUnautorizedModal = () => (dispatch: any) => {
  dispatch(actions.setIsUnautorized({ flag: false }));
};
