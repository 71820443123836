interface CustomBadgeProps {
  status: boolean;
  activeText?: string;
  inactiveText?: string;
}

export const CustomBadge = ({
  status,
  activeText = 'Active',
  inactiveText = 'Inactive',
}: CustomBadgeProps) => {
  const badgeText = status ? activeText : inactiveText;
  const color = status ? 'badge-success' : 'badge-light';

  return <span className={`badge ${color}`}>{badgeText}</span>;
};
