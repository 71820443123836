import { OrderViewModel } from '../../orders/models/OrderViewModel';
import { Formik, FormikHelpers, Form, Field, getIn } from 'formik';
import { packedSchemaValidation } from '../utils/packedSchemaValidation';
import { useIntl } from 'react-intl';
import { useEffect, useRef } from 'react';

interface Props {
  show: boolean;
  order: OrderViewModel;
  onHide: () => void;
  handleOpenConfirmationModal: (trackingNumber: string) => void;
}
interface PackOrderModel {
  trackingNumber: string;
}

export function UpdateTrakingNbrForm({
  show,
  order,
  onHide,
  handleOpenConfirmationModal,
}: Props) {
  const intl = useIntl();
  const save = (
    values: PackOrderModel,
    actions: FormikHelpers<PackOrderModel>
  ) => {
    handleOpenConfirmationModal(values.trackingNumber);
  };
  const saveOnBlur = (trackingNumber: string) => {
    handleOpenConfirmationModal(trackingNumber);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <div className='col-7 d-flex align-items-end'>
      {show && (
        <Formik
          initialValues={{ trackingNumber: order.shippingTrackingNbr }}
          validationSchema={packedSchemaValidation(intl)}
          onSubmit={(values, actions) => {
            save(values, actions);
          }}
        >
          {({ values, setFieldError }) => (
            <Form>
              <div className='row'>
                <div className='col-9 d-flex align-items-center'>
                  <Field name='trackingNumber'>
                    {({
                      field,
                      form: { touched, errors, values },
                      meta,
                    }: any) => (
                      <div>
                        <input
                          type='text'
                          {...field}
                          className='form-control'
                          style={{
                            padding: '2px',
                            width: '150px',
                            fontWeight: 'bold',
                          }}
                          onBlur={() => {
                            if (values.trackingNumber.length <= 3) {
                              return setFieldError('trackingNumber', 'Invalid');
                            }
                            return saveOnBlur(values.trackingNumber);
                          }}
                          ref={inputRef}
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <div className='col-3 d-flex align-items-center'>
                  <Field
                    name='trackingNumber'
                    render={({ form }: any) => {
                      const error = getIn(form.errors, 'trackingNumber');
                      return error ? (
                        <div className='text-danger'>{error}</div>
                      ) : null;
                    }}
                  />
                </div>
                {/* <div className='col-1'>
                  <button type='submit' className='btn  btn-success'>
                    <i className='las la-check fs-2'></i>
                  </button>
                </div>*/}
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
