interface StatusBadgeProps {
  status: 'Pending' | 'Processing' | 'Processed' | 'Failed';
  colors?: any;
}

const DefaultColors = {
  Pending: '#EE6F2D',
  Processing: '#5B6884',
  Processed: '#52C06A',
  Failed: '#CD5050',
};

const texts = {
  Pending: 'Pending',
  Processing: 'Processing',
  Processed: 'Processed',
  Failed: 'Failed',
};

export const MFStatusBadge = ({
  status,
  colors = DefaultColors,
}: StatusBadgeProps) => {
  return (
    <span
      className='badge  my-0  text-white'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left'
      style={{
        backgroundColor: colors[status],
      }}
    >
      {texts[status]}
    </span>
  );
};
