import { PaginationComponentProps } from 'react-data-table-component';

function getNumberOfPages(rowCount: number, rowsPerPage: number) {
  return Math.ceil(rowCount / rowsPerPage);
}

function getPages(page: number, pagesCount: number, paginationSize: number) {
  const result: number[] = [];
  if (!page) {
    return result;
  }

  if (pagesCount === 1) {
    result.push(1);
    return result;
  }

  if (pagesCount < page) {
    return result;
  }

  if (pagesCount < paginationSize + 1) {
    for (let i = 1; i < pagesCount + 1; i++) {
      result.push(i);
    }
    return result;
  }

  if (page === 1) {
    for (let i = 1; i < paginationSize + 1; i++) {
      if (i < pagesCount) {
        result.push(i);
      }
    }
    return result;
  }

  if (page === pagesCount) {
    for (let i = pagesCount - paginationSize + 1; i <= pagesCount; i++) {
      if (i <= pagesCount) {
        result.push(i);
      }
    }
    return result;
  }

  const shiftCount = Math.floor(paginationSize / 2);
  if (shiftCount < 1) {
    result.push(page);
    return result;
  }

  //
  if (page < shiftCount + 2) {
    for (let i = 1; i < paginationSize + 1; i++) {
      result.push(i);
    }
    return result;
  }

  if (pagesCount - page < shiftCount + 2) {
    for (let i = pagesCount - paginationSize; i < pagesCount + 1; i++) {
      result.push(i);
    }
    return result;
  }

  for (let i = page - shiftCount; i < page; i++) {
    if (i > 0) {
      result.push(i);
    }
  }
  result.push(page);
  for (let i = page + 1; i < page + shiftCount + 1; i++) {
    if (i <= pagesCount) {
      result.push(i);
    }
  }

  return result;
}

export function Pagination({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  currentPage,
}: PaginationComponentProps) {
  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1, pages);
  };
  const handleFirstPageClick = () => {
    onChangePage(1, pages);
  };
  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1, pages);
  };
  const handleLastPageClick = () => {
    onChangePage(pages, pages);
  };
  const handlePageNumber = (e: any) => {
    onChangePage(Number(e.target.value), pages);
  };
  const handleRowsPerPage = (e: any) => {
    onChangeRowsPerPage(Number(e.target.value), currentPage);
  };

  const pages = getNumberOfPages(rowCount, rowsPerPage);
  const numberVisiblePages = 4;
  const pageItems = getPages(currentPage, pages, numberVisiblePages);
  const nextDisabled = currentPage === pages || pages === 0;
  const previosDisabled = currentPage === 1;

  const first = (currentPage - 1) * rowsPerPage + 1;
  const last =
    currentPage * rowsPerPage < rowCount ? currentPage * rowsPerPage : rowCount;
  return (
    <nav>
      <ul
        className='pagination'
        style={{ justifyContent: 'left', marginTop: '1em' }}
      >
        <li className='page-item'>
          <button
            className='page-link'
            onClick={handleFirstPageClick}
            disabled={previosDisabled}
            aria-disabled={previosDisabled}
            aria-label='previous page'
          >
            <i className='fas fa-angle-double-left'></i>
          </button>
        </li>
        <li className='page-item'>
          <button
            className='page-link'
            onClick={handleBackButtonClick}
            disabled={previosDisabled}
            aria-disabled={previosDisabled}
            aria-label='previous page'
          >
            <i className='fas fa-angle-left'></i>
          </button>
        </li>

        {pageItems[0] > 1 && (
          <li className='page-item'>
            <button className='page-link' aria-label='previous page'>
              ...
            </button>
          </li>
        )}
        {pageItems.map((page) => {
          const className =
            page === currentPage ? 'page-item active' : 'page-item';

          return (
            <li key={page} className={className}>
              <button
                className='page-link'
                onClick={handlePageNumber}
                value={page}
              >
                {page}
              </button>
            </li>
          );
        })}

        {pageItems[pageItems.length - 1] < pages && (
          <li className='page-item'>
            <button className='page-link' aria-label='previous page'>
              ...
            </button>
          </li>
        )}
        <li className='page-item'>
          <button
            className='page-link'
            onClick={handleNextButtonClick}
            disabled={nextDisabled}
            aria-disabled={nextDisabled}
            aria-label='next page'
          >
            <i className='fas fa-angle-right'></i>
          </button>
        </li>
        <li className='page-item'>
          <button
            className='page-link'
            onClick={handleLastPageClick}
            disabled={nextDisabled}
            aria-disabled={nextDisabled}
            aria-label='next page'
          >
            <i className='fas fa-angle-double-right'></i>
          </button>
        </li>
        <li
          className='page-item'
          style={{
            marginLeft: 'auto',
            marginRight: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          Showing {first} to {last} of {rowCount} Results
        </li>
        <li className='page-item'>
          <select
            className='form-select form-select-solid'
            onChange={handleRowsPerPage}
            style={{ fontSize: '0.75em' }}
            defaultValue={rowsPerPage}
          >
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='30'>30</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        </li>
      </ul>
    </nav>
  );
}
