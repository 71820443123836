import { Fragment, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useQueryParams } from 'use-query-params';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import groupsServices from '../../services/GroupsServices';
import { GroupModel } from '../../models/GroupModel';
import { GroupCreate } from './GroupCreate';
import { GroupEdit } from './GroupEdit';
import {
  GroupQueryFilterKeys,
  initialFilterGroups,
  queryParamsGroupConfig,
} from '../../utils/utils';
import ListComponent from '../../../../components/ListComponent';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../redux';

export function GroupsList() {
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<GroupModel[]>([]);
  const [queryParams, setQueryParams] = useQueryParams(queryParamsGroupConfig);
  const [filter, setFilter] = useState('');
  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<GroupModel>[] = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
        sortField: 'groupName',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // Hides actions where there is only one
  if (group.userGroupsPermission?.view && group.userGroupsPermission.update) {
    columns.push({
      name: 'Actions',
      button: true,
      cell: (row) =>
        row.id && (
          <>
            <Fragment>
              {group.userGroupsPermission?.view &&
                group.userGroupsPermission.update && (
                  <button
                    onClick={() => handleShowUpdateModal(row.id)}
                    className='btn btn-icon btn-bg-light btn-active-primary btn-sm me-1'
                    data-bs-toggle='tooltip'
                    title='Edit'
                  >
                    <i className='las la-edit fs-2'></i>
                  </button>
                )}
            </Fragment>
          </>
        ),
    });
  }
  // Initialization methods
  useEffect(() => {
    if (history.location.search === '') setQueryParams(initialFilterGroups);
    if (queryParams.name) setFilter(queryParams.name);
    if (queryParams.pageNumber) findGroups(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findGroups = function (queryParams: any) {
    groupsServices.findGroups(queryParams).then((response: any) => {
      setEntities(response.data.items);
      setTotalCount(response.data.totalCount);
    });
  };

  const onFilterChange = (e: any, key: GroupQueryFilterKeys) => {
    if (key === 'name') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };

  const findWithFilters = (params: {
    key: GroupQueryFilterKeys;
    value: any;
  }) => {
    const newQueryParams = { ...queryParams };
    newQueryParams[params.key] = params.value;
    newQueryParams.pageNumber = 1;
    setQueryParams(newQueryParams);
  };

  const debounced = useDebouncedCallback(findWithFilters, 800);
  const refetch = () => {
    findGroups(queryParams);
  };
  //Modal Edit
  const [selectedId, setSelectedId] = useState<number>(0);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setSelectedId(0);
  };
  const handleShowUpdateModal = (id: number) => {
    setShowUpdateModal(true);
    setSelectedId(id);
  };
  return (
    <>
      <div className='card'>
        <div className='card-header py-6'>
          <div className='card-title'>
            {group.userGroupsPermission?.list && (
              <div className='d-flex align-items-center position-relative my-1'>
                <i className='las la-search position-absolute ms-6 fs-2'></i>
                <input
                  autoComplete='none'
                  type='search'
                  className='form-control form-control-solid w-250px ps-15'
                  value={filter}
                  placeholder='Search by Name'
                  onChange={(e) => onFilterChange(e, 'name')}
                />
              </div>
            )}
          </div>
          <div className='card-toolbar'>
            {group.userGroupsPermission?.create && (
              <GroupCreate refetch={refetch} />
            )}
          </div>
        </div>

        <div className='card-body px-9 py-3'>
          {group.userGroupsPermission?.list && (
            <ListComponent
              table={{
                columns,
                data: entities,
                paginationTotalRows: totalCount,
              }}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
            />
          )}
        </div>
      </div>
      {showUpdateModal && (
        <GroupEdit
          refetch={refetch}
          id={selectedId}
          show={showUpdateModal}
          onHide={handleCloseUpdateModal}
        />
      )}
    </>
  );
}
