import { Field, Formik, useFormikContext } from 'formik';
import { CustomInput } from '../../../../components/CustomInput';
import { ShippingCarrierInfoModel } from '../../models/ShippingCarrierInfoModel';
import * as Yup from 'yup';
import { initialValuesShippingCompany } from '../../utils/utils';
import { ClientCreateModel } from '../../models/ClientCreateModel';
import { CustomSelect } from '../../../../components/CustomSelect';
import { ValueLabelPair } from '../../../../models/ValueLabelPair';
import { Modal } from 'react-bootstrap-v5';
import { useState } from 'react';

interface PropsFilter {
  shippingCarrierInfo?: ShippingCarrierInfoModel | null;
  addShippingCompany: any;
  hiddeForm: any;
}
export const ShippingCompanyValidation = () =>
  Yup.object().shape({
    shipperCode: Yup.string().required('Required'),
    accountNumber: Yup.string().required('Required'),
    shipBillingOption: Yup.string().required('Required'),
    shipperId: Yup.string().required('Required'),
    signatureRequired: Yup.boolean().required('Required'),
  });

export function ShippingCompany({
  shippingCarrierInfo,
  addShippingCompany,
  hiddeForm,
}: PropsFilter) {
  const { setFieldValue, values } = useFormikContext<ClientCreateModel>();
  const [showReeplaceModal, setShowReeplaceModal] = useState(false);
  const [companyReplace, setCompanyReplace] = useState<string>();

  const addItem = async (shippingValues: any, actions: any) => {
    //New
    if (!shippingCarrierInfo) {
      let already: ShippingCarrierInfoModel | null = null;
      values.shippingCompanies.forEach((element) => {
        if (shippingValues.shipperCode === element.shipperCode) {
          already = element;
          return;
        }
      });
      if (already) {
        setShowReeplaceModal(true);
        setCompanyReplace(shippingValues.shipperCode);
      } else {
        actions.resetForm();
        setFieldValue('shippingCompanies', [
          ...values.shippingCompanies,
          shippingValues,
        ]);
        addShippingCompany(shippingValues);
      }
    }
    //Update
    else {
      actions.resetForm();
      setFieldValue('shippingCompanies', [
        ...values.shippingCompanies.filter(
          (x: any) => x.shipperCode !== shippingValues.shipperCode
        ),
        shippingValues,
      ]);
      addShippingCompany(shippingValues);
    }
  };
  const replaceItem = (shippingValues: any) => {
    setFieldValue('shippingCompanies', [
      ...values.shippingCompanies.filter(
        (x: any) => x.shipperCode !== shippingValues.shipperCode
      ),
      shippingValues,
    ]);
    addShippingCompany(shippingValues);
  };

  const shipperOptions: Array<ValueLabelPair> = [
    { value: 'UPS', label: 'UPS' },
    { value: 'USPS', label: 'USPS' },
    { value: 'FedEx', label: 'FedEx' },
    { value: 'DHL', label: 'DHL' },
    { value: 'Mail Innovations', label: 'Mail Innovations' },
  ];

  return (
    <>
      <Formik
        onSubmit={(values, actions) => {
          addItem(values, actions);
          actions.setSubmitting(false);
        }}
        validationSchema={ShippingCompanyValidation()}
        initialValues={
          shippingCarrierInfo
            ? shippingCarrierInfo
            : initialValuesShippingCompany
        }
      >
        {(formik) => (
          <>
            <div className='row pt-4'>
              <div className='col-4'>
                <CustomSelect
                  isRequired={true}
                  label='Company'
                  id='shipperCode'
                  name='shipperCode'
                  options={shipperOptions}
                />
              </div>
              <div className='col-4'>
                <CustomInput
                  isRequired={true}
                  id='accountNumber'
                  name='accountNumber'
                  type='text'
                  label='Account Number'
                />
              </div>
              <div className='col-4'>
                <CustomInput
                  isRequired={true}
                  id='shipperId'
                  name='shipperId'
                  type='text'
                  label='Shipper Id'
                />
              </div>
            </div>
            <div className='row pt-4'>
              <div className='col-6'>
                <div className='form-check'>
                  <div className='p-3'>
                    <Field
                      type='checkbox'
                      className='form-check-input'
                      id='isPrefered'
                      name='isPrefered'
                      onClick={(e: any) => {
                        if (
                          e.target.checked &&
                          values.shippingCompanies.length > 0
                        ) {
                          values.shippingCompanies.map(
                            (x: ShippingCarrierInfoModel, index: number) => {
                              x.isPrefered = false;
                              return x;
                            }
                          );
                          setFieldValue('shippingCompanies', [
                            ...values.shippingCompanies,
                          ]);
                        }
                      }}
                    />
                    <label htmlFor='isPrefered' className='ms-3'>
                      Mark as Preferred
                    </label>
                  </div>
                </div>
              </div>{' '}
              <div className='col-6'>
                <div className='form-check'>
                  <div className='p-3'>
                    <Field
                      type='checkbox'
                      className='form-check-input'
                      id='signatureRequired'
                      name='signatureRequired'
                    />
                    <label htmlFor='signatureRequired' className='ms-3'>
                      Signature Required
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='row pt-4'>
              <div className='col-12'>
                <div className='d-flex flex-stack'>
                  <div className='me-5'>
                    <label className='fs-5 fw-bold'>Billing option</label>
                  </div>

                  <label className='form-check form-check-custom form-check-solid'>
                    <Field
                      className={`form-check-input ${
                        formik.values.shipBillingOption === 'Prepaid'
                          ? 'bg-primary'
                          : ''
                      }`}
                      name='shipBillingOption'
                      type='radio'
                      value='Prepaid'
                    />

                    <span className='form-check-label fw-bold text-muted'>
                      Prepaid
                    </span>
                  </label>

                  <label className='form-check form-check-custom form-check-solid'>
                    <Field
                      className={`form-check-input ${
                        formik.values.shipBillingOption ===
                        'Bill to Third Party'
                          ? 'bg-primary'
                          : ''
                      }`}
                      name='shipBillingOption'
                      type='radio'
                      value='Bill to Third Party'
                    />

                    <span className='form-check-label fw-bold text-muted'>
                      Bill to Third Party
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div className='row mt-10 pt-4'>
              <div className='fw-bolder text-gray-800 fs-2'>Bill to</div>
            </div>

            <div className='row pt-4'>
              <div className='col-4'>
                <CustomInput
                  id='billingAddress.name'
                  name='billingAddress.name'
                  label='Name'
                  type='text'
                />
              </div>

              <div className='col-4'>
                <CustomInput
                  id='billingAddress.address1'
                  name='billingAddress.address1'
                  label='Address Line 1'
                  type='text'
                />
              </div>

              <div className='col-4'>
                <CustomInput
                  id='billingAddress.address2'
                  name='billingAddress.address2'
                  label='Address Line 2'
                  type='text'
                />
              </div>
            </div>

            <div className='row pt-4'>
              <div className='col-4'>
                <CustomInput
                  id='billingAddress.country'
                  name='billingAddress.country'
                  label='Country'
                  type='text'
                />
              </div>

              <div className='col-4'>
                <CustomInput
                  id='billingAddress.state'
                  name='billingAddress.state'
                  label='State'
                  type='text'
                />
              </div>

              <div className='col-4'>
                <CustomInput
                  id='billingAddress.city'
                  name='billingAddress.city'
                  label='City'
                  type='text'
                />
              </div>
            </div>

            <div className='row pt-4'>
              <div className='col-4'>
                <CustomInput
                  id='billingAddress.zip'
                  name='billingAddress.zip'
                  label='ZIP Code'
                  type='text'
                />
              </div>

              <div className='col-4'>
                <CustomInput
                  id='billingAddress.phone'
                  name='billingAddress.phone'
                  label='Phone'
                  type='text'
                />
              </div>
            </div>
            <div className='row pt-4'>
              <div className='col-12'>
                <div className='text-start'>
                  <button
                    type='button'
                    className='btn btn-light me-3'
                    onClick={() => hiddeForm()}
                  >
                    Cancel
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary '
                    onClick={() => formik.submitForm()}
                    disabled={formik.isSubmitting}
                  >
                    {shippingCarrierInfo ? 'Update' : 'Add'}
                  </button>
                </div>
              </div>
            </div>

            <Modal show={showReeplaceModal} backdrop='static' keyboard={false}>
              <Modal.Body className='mt-1 px-8 py-8 position-relative z-index-1'>
                <div className='d-flex align-items-center'>
                  <i
                    className={`las la-exclamation-circle fs-5x text-warning`}
                  ></i>
                  <div className='flex-grow-3 ms-4'>
                    <div className='fw-bold text-gray-700 fs-6'>
                      It is not possible to add the same company twice. ¿Do you
                      want to replace the current [{companyReplace}] with this
                      new information?
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className='border-0'>
                <div className='text-end'>
                  <button
                    type='button'
                    id='kt_modal_new_card_cancel'
                    className='btn btn-danger me-3'
                    onClick={() => {
                      setShowReeplaceModal(false);
                    }}
                  >
                    No
                  </button>
                  <button
                    type='submit'
                    id='kt_modal_new_card_submit'
                    className='btn btn-primary'
                    onClick={() => {
                      replaceItem(formik.values);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
}
