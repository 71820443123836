import { useIntl } from 'react-intl';
import { ClientCreateModel } from '../../models/ClientCreateModel';
import { Formik, Form, FormikHelpers, Field, ErrorMessage } from 'formik';
import clientsServices from '../../services/ClientsServices';
import { handle400Error } from '../../../../utils/handle400Error';
import { toast } from 'react-toastify';
import { CustomInput } from '../../../../components/CustomInput';
import { ActionsComponent } from '../../../../components/ActionsComponent';
import { createClientInitialValues } from '../../utils/utils';
import { clientSchemaValidation } from '../../utils/clientShemaValidations';
import { ValueLabelPair } from '../../../../models/ValueLabelPair';
import { CustomSelect } from '../../../../components/CustomSelect';
import { Modal } from 'react-bootstrap-v5';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../redux';
import { EntityModel } from '../../../../models/EntityModel';
import { useEffect, useState } from 'react';
import { BasicTabModel, BasicTabs } from '../../../../components/BasicTabs';
import { CustomAttributes } from './CustomAttributes';
import { ShippingCompany } from './ShippingCompany';
import { ShippingCarrierInfoModel } from '../../models/ShippingCarrierInfoModel';
interface Props {
  show: boolean;
  onHide: any;
}

const tabs: BasicTabModel[] = [
  {
    title: 'General',
    id: 'general',
  },
  {
    title: 'Shipping Company',
    id: 'shipping-company',
  },
  {
    title: 'Custom Attributes',
    id: 'custom-attributes',
  },
];

export function ClientCreate({ show, onHide }: Props) {
  const [activeTab, setActiveTab] = useState<string>('general');
  useEffect(() => {
    setActiveTab('general');
  }, [show]);
  const [account] = useSelector<RootState, EntityModel[]>(
    ({ entities }: any) => [entities.account],
    shallowEqual
  );
  // i18n
  const intl = useIntl();

  const shippingMethodOptions: Array<ValueLabelPair> = [
    { value: 'Ground', label: 'Ground' },
    { value: 'Next Day Air', label: 'Next Day Air' },
    { value: 'Second Day Air', label: 'Second Day Air' },
  ];

  const saveClient = (
    values: ClientCreateModel,
    actions: FormikHelpers<ClientCreateModel>
  ) => {
    clientsServices
      .createClient(values)
      .then(() => {
        toast.success(intl.formatMessage({ id: 'CLIENT.CREATED.SUCCESS' }));
        onHide();
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          handle400Error(error.response.data.errors, actions.setErrors);
        }
      });
  };
  if (account) {
    createClientInitialValues.accountId = account.id;
  }

  const [showShippingCompanyForm, setShowShippingCompanyForm] = useState(false);
  const [selectedShippingCompany, setSelectedShippingCompany] = useState<
    ShippingCarrierInfoModel | null | undefined
  >(null);

  const handleShowShippingCompanyForm = (
    company?: ShippingCarrierInfoModel
  ) => {
    if (showShippingCompanyForm) {
      setShowShippingCompanyForm(false);
      setTimeout(() => {
        setSelectedShippingCompany(company);
        setShowShippingCompanyForm(true);
      }, 200);
    } else {
      setSelectedShippingCompany(company);
      setShowShippingCompanyForm(true);
    }
  };
  const handleHiddeShippingCompanyForm = () => {
    setSelectedShippingCompany(null);
    setShowShippingCompanyForm(false);
  };
  const addShippingCompany = (shippingCompany: ShippingCarrierInfoModel) => {
    setShowShippingCompanyForm(false);
  };

  const [showDeleteShippingCompany, setShowDeleteShippingCompany] =
    useState(false);
  const [companyDelete, setCompanyDelete] = useState<string>();

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop='static'
        keyboard={false}
        size='lg'
      >
        <Formik
          initialValues={createClientInitialValues}
          validationSchema={clientSchemaValidation(intl)}
          onSubmit={(values, actions) => {
            saveClient(values, actions);
            actions.setSubmitting(false);
          }}
        >
          {(formik) => (
            <>
              {(formik.errors.name ||
                formik.errors.key ||
                formik.errors.shippingMethod ||
                formik.errors.shippingMethodAutoReplenishment ||
                formik.errors.packingListMessage ||
                formik.errors.orderNote1 ||
                formik.errors.orderNote2 ||
                formik.errors.orderNote3) &&
                setActiveTab('general')}

              <Form
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
              >
                <Modal.Header className='px-9 pt-7 card-rounded'>
                  <Modal.Title className='m-0 fw-bolder fs-3'>
                    Add Client
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className=' mt-1 px-6 py-3 position-relative z-index-1 '>
                  <BasicTabs
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    tabs={tabs}
                  />
                  {activeTab === 'general' && (
                    <div className='card-body '>
                      <div className='row mt-5 mb-5 ms-0'>
                        <div className='col-12'>
                          <ErrorMessage name='shippingCompanies'>
                            {(msg) => <div className='text-danger'>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-6'>
                          <CustomInput
                            isRequired={true}
                            id='name'
                            name='name'
                            type='text'
                            label='Name'
                          />
                        </div>
                        <div className='col-6'>
                          <CustomInput
                            isRequired={true}
                            id='key'
                            name='key'
                            type='text'
                            label='Key'
                          />
                        </div>
                      </div>
                      <div className='row pt-4'>
                        <div className='col-12'>
                          <div className='form-check'>
                            <div className='p-3'>
                              <Field
                                type='checkbox'
                                className='form-check-input'
                                id='autoReplenish'
                                name='autoReplenish'
                              />
                              <label htmlFor='autoReplenish' className='ms-3'>
                                Auto Replenish
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='form-check'>
                            <div className='p-3'>
                              <Field
                                type='checkbox'
                                className='form-check-input'
                                id='canActiveCards'
                                name='canActiveCards'
                              />
                              <label htmlFor='canActiveCards' className='ms-3'>
                                Can Active Cards
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='form-check'>
                            <div className='p-3'>
                              <Field
                                type='checkbox'
                                className='form-check-input'
                                id='allowThirdPartyShipping'
                                name='allowThirdPartyShipping'
                              />
                              <label
                                htmlFor='allowThirdPartyShipping'
                                className='ms-3'
                              >
                                Allow Third Party Shipping
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row pt-4'>
                        <div className='col-6'>
                          <CustomSelect
                            isRequired={true}
                            label='Default Shipping Method - Web'
                            placeholder='Select Method'
                            id='shippingMethod'
                            name='shippingMethod'
                            options={shippingMethodOptions}
                          />
                        </div>
                        <div className='col-6'>
                          <CustomSelect
                            isRequired={true}
                            label='Default Shipping Method - Auto'
                            placeholder='Select Method'
                            id='shippingMethodAutoReplenishment'
                            name='shippingMethodAutoReplenishment'
                            options={shippingMethodOptions}
                          />
                        </div>
                      </div>
                      <div className='row pt-4'>
                        <div className='col-12'>
                          <CustomInput
                            id='packingListMessage'
                            name='packingListMessage'
                            type='text'
                            label='Packing List Message'
                          />
                        </div>
                      </div>

                      <div className='row pt-4'>
                        <div className='col-4'>
                          <CustomInput
                            id='orderNote1'
                            name='orderNote1'
                            type='text'
                            label='Order Note 1'
                          />
                        </div>
                        <div className='col-4'>
                          <CustomInput
                            id='orderNote2'
                            name='orderNote2'
                            type='text'
                            label='Order Note 2'
                          />
                        </div>
                        <div className='col-4'>
                          <CustomInput
                            id='orderNote3'
                            name='orderNote3'
                            type='text'
                            label='Order Note 3'
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === 'shipping-company' && (
                    <div className='card-body pt-2'>
                      <div className='row mt-5 mb-5 ms-0'>
                        <div className='col-12'>
                          <ErrorMessage name='shippingCompanies'>
                            {(msg) => <div className='text-danger'>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className='mt-5 mb-5 d-flex'>
                        {formik.values.shippingCompanies.map(
                          (x: ShippingCarrierInfoModel, index: number) => {
                            return (
                              <>
                                <div key={x.shipperCode}>
                                  <button
                                    type='button'
                                    key={`${x.shipperCode}-form`}
                                    className='btn btn-link ms-5'
                                    onClick={() =>
                                      handleShowShippingCompanyForm(x)
                                    }
                                  >
                                    {x.shipperCode}
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      setShowDeleteShippingCompany(true);
                                      setCompanyDelete(x.shipperCode);
                                    }}
                                    key={`${x.shipperCode}-delete`}
                                    className='btn btn-icon btn-bg-light btn-active-danger btn-sm me-1 w-20px h-20px ms-1'
                                    data-bs-toggle='tooltip'
                                    title='Remove'
                                    type='button'
                                  >
                                    <i className='las la-times fs-5'></i>
                                  </button>
                                </div>
                              </>
                            );
                          }
                        )}{' '}
                        <button
                          onClick={() => {
                            handleShowShippingCompanyForm();
                          }}
                          type='button'
                          className='btn btn-primary align-self-center ms-5'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                        >
                          <i className='bi bi-plus fs-2'></i>
                          Add Company
                        </button>
                      </div>{' '}
                      {showShippingCompanyForm && (
                        <div className='border border-dark rounded px-5 py-5'>
                          <ShippingCompany
                            shippingCarrierInfo={selectedShippingCompany}
                            addShippingCompany={addShippingCompany}
                            hiddeForm={handleHiddeShippingCompanyForm}
                          ></ShippingCompany>
                        </div>
                      )}
                    </div>
                  )}
                  {activeTab === 'custom-attributes' && (
                    <div className='card-body pt-10'>
                      <CustomAttributes></CustomAttributes>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer className='px-9 card-rounded  w-100'>
                  <ActionsComponent
                    labelSubmit='Add'
                    showCancelButton={true}
                    onCancel={onHide}
                  />
                </Modal.Footer>
              </Form>

              <Modal
                show={showDeleteShippingCompany}
                backdrop='static'
                keyboard={false}
              >
                <Modal.Body className='mt-1 px-8 py-8 position-relative z-index-1'>
                  <div className='d-flex align-items-center'>
                    <i
                      className={`las la-exclamation-circle fs-5x text-warning`}
                    ></i>
                    <div className='flex-grow-3 ms-4'>
                      <div className='fw-bold text-gray-700 fs-6'>
                        Are you sure you want to delete {companyDelete} Shipping
                        Company?
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                  <div className='text-end'>
                    <button
                      type='button'
                      id='kt_modal_new_card_cancel'
                      className='btn btn-danger me-3'
                      onClick={() => {
                        setShowDeleteShippingCompany(false);
                        setCompanyDelete(undefined);
                      }}
                    >
                      No
                    </button>
                    <button
                      type='submit'
                      id='kt_modal_new_card_submit'
                      className='btn btn-primary'
                      onClick={() => {
                        formik.setFieldValue(
                          'shippingCompanies',
                          formik.values.shippingCompanies.filter(
                            (item: ShippingCarrierInfoModel, idx: number) =>
                              item.shipperCode !== companyDelete
                          )
                        );
                        setShowDeleteShippingCompany(false);
                        setCompanyDelete(undefined);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
}
