import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const franchiseSchemaValidation = (intl: IntlShape) =>
  Yup.object().shape({
    number: Yup.number()
      .typeError('Number must be a number')
      .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
      .min(0, intl.formatMessage({ id: 'VALIDATION.MIN.NUMBER' }, { min: 0 }))
      .integer(intl.formatMessage({ id: 'VALIDATION.INTEGER_FIELD' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    name: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 100 })
      )
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
  });
