import { AvailabilityModel } from '../models/AvailabilityModel';

interface Props {
  availabilities: AvailabilityModel[];
}
export function ViewAvailabilities({ availabilities }: Props) {
  return (
    <div className='p-5'>
      <div className='row'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle g-4'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Account</th>
                <th className='min-w-150px'>Client</th>
                <th className='min-w-150px'>Franchise</th>
                <th className='min-w-150px'>Locations</th>
              </tr>
            </thead>
            <tbody className='border-0'>
              {availabilities.map((x, index) => {
                return (
                  <tr key={`availabilities.${index}`}>
                    <td>{x.accountId ? x.accountName : 'All'}</td>
                    <td>{x.clientId ? x.clientName : 'All'}</td>
                    <td>{x.franchiseId ? x.franchiseName : 'All'}</td>
                    <td>{x.locationId ? x.locationName : 'All'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
