import { LoginUserModel } from '../models/LoginUserModel';
export class UsersTableMock {
  public static table: Array<LoginUserModel> = [
    // {
    //   id: 1,
    //   username: 'admin',
    //   password: 'demo',
    //   email: 'admin@demo.com',
    //   auth: {
    //     accessToken: 'access-token-8f3ae836da744329a6f93bf20594b5cc',
    //     refreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
    //   },
    //   roles: [1], // Administrator
    //   fullname: 'Nicolás Cabaña',
    //   firstname: 'Nicolás',
    //   lastname: 'Cabaña',
    //   groups: [
    //     { id: 1, name:'Group 1', entity:'Client 1'},
    //     { id: 2, name:'Group 2', entity:'Franchise 2'},
    //     { id: 3, name:'Group 3', entity:'Franchise 33'},
    //   ]
    // },
    // {
    //   id: 2,
    //   username: 'user',
    //   password: 'demo',
    //   email: 'user@demo.com',
    //   auth: {
    //     accessToken: 'access-token-6829bba69dd3421d8762-991e9e806dbf',
    //     refreshToken: 'access-token-f8e4c61a318e4d618b6c199ef96b9e55',
    //   },
    //   roles: [2], // Manager
    //   fullname: 'Odalys Paz',
    //   firstname: 'Odalys',
    //   lastname: 'Paz',
    // },
  ];
}
