import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { ProductItemSchemaValidation } from './additemSchema';
import { KitProductItem } from './utils';

export const CreateKitSchemasStep2 = (intl: IntlShape) =>
  Yup.object().shape({
    products: Yup.array()
      .of(ProductItemSchemaValidation(intl))
      .min(2, 'At least two products are required in the Kit.')
      .test(
        'products',
        'You must add at least one required product in the Kit.',
        (value: any) => {
          for (const pi of value) {
            if (pi.isRequired) return true;
          }
          return false;
        }
      )
      .test(
        'products',
        'No more than one Product of type Card in the Kit.',
        (value: any) => {
          return (
            (value as KitProductItem[]).filter(
              (p: KitProductItem) => p.type === 'Card'
            ).length <= 1
          );
        }
      ),
  });
