import { shallowEqual, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { EntityModel } from '../../models/EntityModel';
import { RootState } from '../../redux';
import { FranchisesList } from './components/franchises/FranchisesList';
import { FranchisesView } from './components/franchises/FranchiseView';

export function FranchisesPage() {
  const client = useSelector<RootState, EntityModel>(
    ({ entities }: any) => entities.client,
    shallowEqual
  );

  const breadcrumbs: Array<PageLink> = [
    {
      title: 'Accounts',
      path: '/accounts',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Clients',
      path: `/accounts/${client ? client.parentId : 0}/clients`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <Switch>
        <Route exact path='/accounts/:accountId/clients/:clientId/franchises'>
          <PageTitle breadcrumbs={breadcrumbs}>Client Detail </PageTitle>
          <FranchisesList />
        </Route>
        <Route path='/accounts/:accountId/clients/:clientId/franchises/:franchiseId'>
          <FranchisesView />
        </Route>
      </Switch>
    </>
  );
}
