import { Fragment, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useHistory, useParams } from 'react-router-dom';
import {
  initialFilterInventory,
  queryParamsInventoryConfig,
} from '../../utils/utils';
import inventoryServices from '../../services/InventoryServices';
import { useDebouncedCallback } from 'use-debounce/lib';
import { RootState } from '../../../../redux';
import { EntityModel } from '../../../../models/EntityModel';
import { shallowEqual, useSelector } from 'react-redux';
import ListComponent from '../../../../components/ListComponent';
import { useQueryParamsReplace } from '../../../../utils/useQueryParamsReplace';
import { GroupModel } from '../../../users/models/GroupModel';
import { InventoryModel } from '../../models/InventoryModel';
import { LocationEditInventory } from './LocationEditInventory';
import { LocationInventoryFilter } from './LocationInventoryFilter';
interface Params {
  accountId: string;
  clientId: string;
  franchiseId: string;
  locationId: string;
}

export function LocationInventoryList() {
  const { accountId, locationId, franchiseId, clientId } = useParams<Params>();
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  // Pagination, Filter and Query Params
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<InventoryModel[]>([]);
  const [filter, setFilter] = useState('');

  const [queryParams, setQueryReplace] = useQueryParamsReplace(
    queryParamsInventoryConfig
  );
  const history = useHistory();

  const [location] = useSelector<RootState, EntityModel[]>(
    ({ entities }: any) => [entities.location],
    shallowEqual
  );

  // useMemo will only be created once
  const columns: TableColumn<InventoryModel>[] = useMemo(
    () => [
      {
        name: 'Product Code',
        cell: (row) => {
          return (
            <>
              <div className='col-9'>{row.productCode} </div>
              {row.currentLevel +
                (!!row.qtyOnOrder ? row.qtyOnOrder : 0) +
                (!!row.qtyOnBackorder ? row.qtyOnBackorder : 0) <=
                row.lowLevel && (
                <div className='col-3'>
                  <span className='badge badge-danger mx-3'>Low</span>
                </div>
              )}
            </>
          );
        },
        sortable: true,
        sortField: 'productCode',
      },
      {
        name: 'Description',
        selector: (row) => row.productDescription,
        sortable: true,
        sortField: 'description',
      },
      {
        name: 'Optimum',
        selector: (row) => (row.optimumLevel ? row.optimumLevel : ''),
        sortable: true,
        sortField: 'optimum',
        center: true,
      },
      {
        name: 'Low',
        selector: (row) => (row.lowLevel ? row.lowLevel : ''),
        sortable: true,
        sortField: 'low',
        center: true,
      },
      {
        name: 'Current',
        selector: (row) => (row.currentLevel ? row.currentLevel : ''),
        sortable: true,
        sortField: 'current',
        center: true,
      },
      {
        name: 'On Order',
        selector: (row) => (row.qtyOnOrder ? row.qtyOnOrder : ''),
        sortable: true,
        sortField: 'ordered',
        center: true,
      },
      {
        name: 'Recent Order',
        selector: (row) =>
          row.mostRecentOrderQty ? row.mostRecentOrderQty : '',
        sortable: true,
        sortField: 'recentOrder',
        center: true,
      },
      {
        name: 'Actions',
        button: true,
        cell: (row) =>
          row.id && (
            <>
              <Fragment>
                {group.locationPermission?.view &&
                  group.locationPermission?.update && (
                    <button
                      onClick={() => handleShowUpdateModal(row.id)}
                      className='btn btn-icon btn-bg-light btn-active-primary btn-sm me-1'
                      data-bs-toggle='tooltip'
                      title='Edit'
                    >
                      <i className='las la-edit fs-2'></i>
                    </button>
                  )}
              </Fragment>
            </>
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //Initialization methods
  useEffect(() => {
    if (history.location.search === '' && location) {
      setQueryReplace({
        ...initialFilterInventory,
        locationId: location.id,
      });
    }
    if (queryParams.filter) setFilter(queryParams.filter);
    // Call to list inventory if and only if there are location id.
    if (queryParams.locationId && queryParams.pageNumber) {
      findInventory(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, location]);

  const findInventory = function (queryParams: any) {
    inventoryServices
      .findInventory(location.id, queryParams)
      .then((response) => {
        setEntities(response.data.items);
        setTotalCount(response.data.totalCount);
      });
  };

  const debounced = useDebouncedCallback((params: any) => {
    const newQueryParams = { ...queryParams };
    if (params.key === 'filter') {
      let filter = params.value;
      newQueryParams.filter = filter;
      newQueryParams.pageNumber = 1;
      setQueryReplace(newQueryParams);
    }
  }, 800);
  const onFilterChange = (e: any, key: string) => {
    if (key === 'filter') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };

  // eslint-disable-next-line
  const [selectedId, setSelectedId] = useState<number>(0);
  // eslint-disable-next-line
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  // eslint-disable-next-line
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setSelectedId(0);
  };
  const handleShowUpdateModal = (id: number) => {
    setShowUpdateModal(true);
    setSelectedId(id);
  };
  const refetch = () => {
    findInventory(queryParams);
  };
  const handleCreateOrder = () => {
    history.push(
      `/orders/create-selection/${accountId}/${clientId}/${franchiseId}/${locationId}`
    );
  };

  return (
    <>
      <div
        style={{
          borderTopRightRadius: '0px',
          borderTopLeftRadius: '0px',
        }}
        className='card'
      >
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            {group.locationPermission?.list && (
              <div className='d-flex align-items-center position-relative my-1'>
                <i className='las la-search position-absolute ms-6 fs-2'></i>
                <input
                  autoComplete='none'
                  type='search'
                  className='form-control form-control-solid w-350px ps-15'
                  value={filter}
                  placeholder='Search by Product Code or Description'
                  onChange={(e) => onFilterChange(e, 'filter')}
                />
              </div>
            )}
          </div>
          <div className='card-toolbar'>
            {group.locationPermission?.list && (
              <LocationInventoryFilter
                queryParams={queryParams}
                setQueryParams={setQueryReplace}
              />
            )}
            {group.orderPermission?.create && (
              <button
                onClick={() => handleCreateOrder()}
                type='button'
                className='btn btn-primary align-self-center'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                title='Create Order'
              >
                <i className='fas fa-shopping-cart'></i> Create Order
              </button>
            )}
          </div>
        </div>

        <div className='card-body p-9'>
          <ListComponent
            table={{
              columns,
              data: entities,
              paginationTotalRows: totalCount,
            }}
            queryParams={queryParams}
            setQueryParams={setQueryReplace}
            notPermissions={!group.locationPermission?.list}
          />
        </div>
      </div>
      {showUpdateModal && (
        <LocationEditInventory
          refetch={refetch}
          inventoryId={selectedId}
          locationId={location.id}
          show={showUpdateModal}
          onHide={handleCloseUpdateModal}
        />
      )}
    </>
  );
}
