import axios from 'axios';
import { LoginUserModel } from '../models/LoginUserModel';
import { UserGroupRelationModel } from '../models/UserGroupRelationModel';

export const GET_USER_BY_ACCESSTOKEN_URL = `/api/v1/Users/profile`;
export const AUDIT_URL = `/api/v1/AuditTrails`;
export const GET_GROUPS_BY_ACCESSTOKEN_URL = `/api/v1/groups/user-groups`;
export const LOGIN_URL = `/api/v1/auth/login`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, { email, password });
}

export function getUserByToken() {
  return axios.get<LoginUserModel>(`${GET_USER_BY_ACCESSTOKEN_URL}`);
}

export function getGroupsByToken() {
  return axios.get<UserGroupRelationModel[]>(
    `${GET_GROUPS_BY_ACCESSTOKEN_URL}`
  );
}

export function postUserLoggedIn() {
  const dto = {
    action: 'USER LOGGED IN',
    tableName: ''
  };

  return axios.post(AUDIT_URL, dto);
}

export function postUserLoggedOut() {
  const dto = {
    action: 'USER LOGGED OUT',
    tableName: ''
  };

  return axios.post(AUDIT_URL, dto);
}
