import axios from 'axios';
import { PagedResult } from '../../../models/pagedResult';
import { UserGroupModel } from '../../auth/models/UserGroupModel';
import { GET_GROUPS_BY_ACCESSTOKEN_URL } from '../../auth/services/AuthServices';
import { GroupList } from '../models/GroupList';
import { UserCreateModel } from '../models/UserCreateModel';
import { UserEditGroup } from '../models/UserEditGroup';
import { UserEditModel } from '../models/UserEditModel';
import { UserGroupsRelations } from '../models/UserGroupsRelations';
import { GROUPS_URL } from './GroupsServices';

export const USERS_URL = `/api/v1/users`;

export const usersServices = {
  findUsers: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(USERS_URL, { params: params });
  },
  createUser: (user: UserCreateModel) => {
    return axios.post(USERS_URL, user);
  },
  /*getUser: (id: number) => {
    return axios.get<UserModel>(`${USERS_URL}/${id}`);
  },*/
  getUser: (id: number) => {
    return axios.get<UserEditGroup>(`${USERS_URL}/${id}`);
  },
  updateUser: (id: number, user: UserEditModel) => {
    return axios.put(`${USERS_URL}/${id}`, user);
  },
  deleteUser: (id: number) => {
    return axios.delete(`${USERS_URL}/${id}`);
  },
  selectGroup: (userId: number, groupId: number) => {
    return axios.put(`${USERS_URL}/user-group-relation/select/${userId}`, {
      id: groupId,
    });
  },
  getGroups: () => {
    return axios.get<GroupList[]>(`${GROUPS_URL}/all`);
  },
  getUserGroups: (userId: number) => {
    return axios.get<UserGroupModel[]>(
      `${GET_GROUPS_BY_ACCESSTOKEN_URL}/${userId}`
    );
  },
  setUserGroups: (user: UserGroupsRelations, id: number) => {
    return axios.put<UserGroupsRelations>(`${USERS_URL}/${id}`, user);
  },
};
