import { Fragment } from 'react';
import { CustomInput } from '../../../components/CustomInput';
import { CustomTextArea } from '../../../components/CustomTextArea';

export function WarehouseForm() {
  return (
    <Fragment>
      <div className='row mb-2'>
        <div className='col-6'>
          <CustomInput
            isRequired={true}
            id='name'
            name='name'
            type='text'
            label='Name'
          />
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-12'>
          <CustomTextArea
            id='description'
            name='description'
            label='Description'
            maxLength={255}
          />
        </div>
      </div>
      <div className='row pt-4'>
        <div className='col-12'>
          <CustomInput
            id='addressLine1'
            type='text'
            name='addressLine1'
            label='Address Line 1'
          />
        </div>
      </div>
      <div className='row pt-4'>
        <div className='col-12'>
          <CustomInput
            id='addressLine2'
            type='text'
            name='addressLine2'
            label='Address Line 2'
          />
        </div>
      </div>
      <div className='row pt-4'>
        <div className='col-12'>
          <CustomInput id='city' type='text' name='city' label='City' />
        </div>
      </div>
      <div className='row pt-4'>
        <div className='col-6'>
          <CustomInput id='state' type='text' name='state' label='State' />
        </div>
        <div className='col-6'>
          <CustomInput
            id='postalCode'
            type='text'
            name='postalCode'
            label='Postal Code'
          />
        </div>
      </div>
    </Fragment>
  );
}
