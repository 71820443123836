import { useFormikContext } from 'formik';
import { Fragment } from 'react';
import { CustomInput } from '../../../../components/CustomInput';
import { InventoryModel } from '../../models/InventoryModel';

export function LocationInventoryForm() {
  const { values } = useFormikContext<InventoryModel>();
  return (
    <Fragment>
      <div className='row mb-2'>
        <div className='col-12'>
          <span className='col-md-12 fw-bolder fs-6 text-muted'>
            Product: {values.productCode} - {values.productDescription}
          </span>
        </div>
        <div className='col-12'>
          <span className='col-md-12 fw-bolder fs-6 text-muted'>
            Location: {values.locationName}
          </span>
        </div>
      </div>
      <div className='row mb-2 my-4'>
        <div className='col-6'>
          <CustomInput
            id='currentLevel'
            name='currentLevel'
            label='Current Level'
            isRequired={true}
            type='number'
          />
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-6'>
          <CustomInput
            id='optimumLevel'
            name='optimumLevel'
            label='Optimum Level'
            isRequired={true}
            type='number'
          />
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-6'>
          <CustomInput
            id='lowLevel'
            name='lowLevel'
            label='Low Level'
            isRequired={true}
            type='number'
          />
        </div>
      </div>
    </Fragment>
  );
}
