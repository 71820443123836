/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';
import { createProductinitialValues } from '../utils/utils';
import { Formik, Form, FormikValues } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { CardAttributeModel, ProductModel } from '../models/ProductModel';
import { StepperComponent } from '../../../../_metronic/assets/ts/components';
import { createProductSchemas } from '../utils/createProductSchemasValidations';
import { ProductImagesStep3 } from './ProductImagesStep3';
import { ProductInformationStep1 } from './ProductInformationStep1';
import { useIntl } from 'react-intl';
import productServices from '../services/ProductsServices';
import { ProductInformationCreate } from '../models/ProductInformationCreate';
import { productHelpers } from '../helpers/product';
import { handle400Error } from '../../../utils/handle400Error';
import { toast } from 'react-toastify';
import { ProductCardAttributesStep2 } from './ProductCardAttributesStep2';
import { ProductAvailabilityStep4 } from './ProductAvailabilityStep4';
interface Props {
  refetch: () => void;
  show: boolean;
  onHide: () => void;
}

export function ProductCreate({ show, refetch, onHide }: Props) {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(
    createProductSchemas(intl)[0]
  );
  const [initValues] = useState<ProductModel>(createProductinitialValues);
  const [isSubmitButton, setSubmitButton] = useState(false);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = (values: ProductModel) => {
    if (!stepper.current) {
      return;
    }
    const currentIndex = stepper.current.currentStepIndex;
    const totatStepsNumber = stepper.current.totatStepsNumber;

    let backIndex = currentIndex - 1;
    if (currentIndex === 3 && values.productType !== 'Card') {
      backIndex = 1;
    }
    setSubmitButton(backIndex === totatStepsNumber);
    setCurrentSchema(createProductSchemas(intl)[backIndex - 1]);
    stepper.current.goto(backIndex);
  };

  const submitStep = (values: ProductModel, actions: FormikValues) => {
    if (!stepper.current) {
      return;
    }
    const currentIndex = stepper.current.currentStepIndex;
    const totatStepsNumber = stepper.current.totatStepsNumber;
    let nextIndex = currentIndex === 4 ? currentIndex : currentIndex + 1;

    if (currentIndex === 1 && values.productType !== 'Card') {
      nextIndex = 3;
    }
    setCurrentSchema(createProductSchemas(intl)[nextIndex - 1]);
    if (currentIndex === 1) {
      step1(nextIndex, values, actions);
    }
    if (currentIndex === 2) {
      step2(nextIndex, values, actions);
    }
    if (currentIndex === 3) {
      step3(nextIndex, values, actions);
    }
    if (currentIndex === 4) {
      step4(nextIndex, values, actions);
    }
  };
  const step1 = useCallback(
    (nextIndex: number, product: ProductModel, actions: FormikValues) => {
      if (product.id === 0) {
        const body: ProductInformationCreate =
          productHelpers.createStep1(product);
        productServices
          .productInformationCreate(body)
          .then((values) => {
            const body = values.data;
            if (stepper.current) {
              stepper.current.goto(nextIndex);
              product.id = body.id;
            }
          })
          .catch((error) => {
            setCurrentSchema(createProductSchemas(intl)[0]);
            if (error?.response?.status === 400) {
              handle400Error(error.response.data.errors, actions.setErrors);
            }
          });
      } else if (stepper.current) {
        stepper.current.goto(nextIndex);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const step2 = useCallback(
    (nextIndex: number, product: ProductModel, actions: FormikValues) => {
      if (product.cardAttributes.id === 0) {
        const body: CardAttributeModel = productHelpers.step2(product);
        productServices
          .productCardAttributeCreate(product.id, body)
          .then((values) => {
            const body = values.data;
            if (stepper.current) {
              stepper.current.goto(nextIndex);
              product.cardAttributes.id = body.cardAttributes.id;
            }
          })
          .catch((error) => {
            setCurrentSchema(createProductSchemas(intl)[1]);
            if (error?.response?.status === 400) {
              handle400Error(error.response.data.errors, actions.setErrors);
            }
          });
      } else if (stepper.current) {
        stepper.current.goto(nextIndex);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const step3 = useCallback(
    (nextIndex: number, product: ProductModel, actions: FormikValues) => {
      const body: any = productHelpers.createFormDataFiles(product.files);
      productServices
        .productImages(product.id, body)
        .then((values) => {
          if (stepper.current) {
            product.files = productHelpers.processFiles(values.data.files);
            stepper.current.goto(nextIndex);
            setSubmitButton(true);
          }
        })
        .catch((error) => {
          setCurrentSchema(createProductSchemas(intl)[2]);
          if (error?.response?.status === 400) {
            handle400Error(error.response.data.errors, actions.setErrors);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const step4 = useCallback(
    (nextIndex: number, product: ProductModel, actions: FormikValues) => {
      if (product.availabilities.length > 0) {
        productServices
          .productAvailabilities(product.id, product.availabilities)
          .then((values) => {
            if (stepper.current) {
              refetch();
              onHide();
              toast.success('Product successfully added');
            }
          })
          .catch((error) => {
            setCurrentSchema(createProductSchemas(intl)[3]);
            if (error?.response?.status === 400) {
              handle400Error(error.response.data.errors, actions.setErrors);
              if (stepper.current) {
                stepper.current.goto(4);
              }
            }
          });
      } else if (stepper.current) {
        stepper.current.goto(nextIndex);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef, show]);

  return (
    <>
      {!loading && (
        <Modal
          show={show}
          onHide={onHide}
          backdrop='static'
          keyboard={false}
          size='xl'
        >
          <Modal.Header className='px-9 pt-7 card-rounded'>
            <Modal.Title className='m-0 fw-bolder fs-3'>
              New Product
            </Modal.Title>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={onHide}
            >
              <i className='las la-times fs-2'></i>
            </div>
          </Modal.Header>
          <Modal.Body className='mt-1 px-6 py-3 position-relative z-index-1'>
            <div
              ref={stepperRef}
              className='stepper stepper-links d-flex flex-column pt-5'
              id='kt_create_account_stepper'
            >
              <div className='stepper-nav mb-5'>
                <div
                  className='stepper-item current'
                  data-kt-stepper-element='nav'
                >
                  <h3 className='stepper-title'>Product Information</h3>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>Card Attributes</h3>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>Product Images</h3>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>Availability</h3>
                </div>
              </div>

              <Formik
                validationSchema={currentSchema}
                initialValues={initValues}
                onSubmit={submitStep}
              >
                {(props) => (
                  <Form
                    className='mx-auto pt-4 pb-10'
                    style={{
                      minWidth: '90%',
                    }}
                    id='kt_create_account_form'
                  >
                    <div className='current' data-kt-stepper-element='content'>
                      <ProductInformationStep1 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <ProductCardAttributesStep2 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <ProductImagesStep3 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      {props.values.id && (
                        <ProductAvailabilityStep4 product={props.values} />
                      )}
                    </div>

                    <div className='d-flex flex-stack pt-15'>
                      <div className='mr-2'>
                        <button
                          onClick={() => prevStep(props.values)}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <i className='fas fa-arrow-left fs-5 me-3'></i>
                          <span>Back</span>
                        </button>
                      </div>

                      <div>
                        <button
                          className='btn btn-lg btn-primary'
                          type='submit'
                        >
                          {!isSubmitButton && (
                            <>
                              <span className='indicator-label me-3'>
                                Continue
                              </span>
                              <i className='fas fa-arrow-right fs-5'></i>
                            </>
                          )}
                          {isSubmitButton && (
                            <span className='indicator-label'>Save</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
