import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { PageLink } from '../../_metronic/layout/core';

type Props = {
  submenu: Array<PageLink>;
};
export function NavTabs({ submenu }: Props) {
  const location = useLocation();
  return (
    <div className='d-flex overflow-auto h-55px'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        {submenu.map((x, index) => {
          return (
            <li key={index} className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === x.path && 'active')
                }
                key={index}
                to={x.path}
              >
                {x.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
