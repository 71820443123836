interface CustomBadgeFilterProps {
  text?: string;
  badgeKey: string;
  clearFilter?: any;
  canBeRemoved?: boolean;
}

export const CustomBadgeFilter = ({
  text,
  badgeKey,
  clearFilter,
  canBeRemoved = true,
}: CustomBadgeFilterProps) => {
  return (
    <span
      key={`${badgeKey}-delete-span`}
      className='badge badge-light me-2 fs-7  '
    >
      {text}
      {canBeRemoved && (
        <button
          onClick={(e) => clearFilter()}
          key={`${badgeKey}-delete`}
          className='btn btn-icon btn-bg-light btn-active-light btn-sm me-1 w-20px h-20px ms-1'
          data-bs-toggle='tooltip'
          title='Clear filter'
          type='button'
        >
          <i className='las la-times fs-5'></i>
        </button>
      )}
    </span>
  );
};
