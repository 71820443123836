import { Fragment, useEffect, useState } from 'react';
import { UserModel } from '../../models/UserModel';
import { TableColumn } from 'react-data-table-component';
import {
  initialFilter,
  queryParamsConfig,
  userEditInitialValues,
} from '../../utils/utils';
import { useQueryParams } from 'use-query-params';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ListComponent from '../../../../components/ListComponent';
import { usersServices } from '../../services/UsersServices';
import { UserGroupsEdit } from './UserGroupsEdit';
import { GroupModel } from '../../models/GroupModel';
import { RootState } from '../../../../redux';

export function UsersList() {
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  // Pagination, Filter and Query Params
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<UserModel[]>([]);

  const [queryParams, setQueryParams] = useQueryParams(queryParamsConfig);
  const [filter, setFilter] = useState('');

  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<UserModel>[] = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
        sortField: 'name',
        wrap: true,
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        sortable: true,
        sortField: 'email',
        wrap: true,
      },
      {
        name: 'Cap User Name',
        selector: (row) => row.capUserName,
        sortable: true,
        sortField: 'capUserName',
        wrap: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // Hides actions where there is only one
  if (group.userGroupsPermission?.view && group.userGroupsPermission.update) {
    columns.push({
      name: 'Actions',
      button: true,
      cell: (row) =>
        row.id && (
          <>
            <Fragment>
              {group.userGroupsPermission?.view &&
                group.userGroupsPermission.update && (
                  <button
                    onClick={() => handleShowUpdateModal(row)}
                    className='btn btn-icon btn-bg-light btn-active-primary btn-sm me-1'
                    data-bs-toggle='tooltip'
                    title='Edit'
                  >
                    <i className='las la-edit fs-2'></i>
                  </button>
                )}
            </Fragment>
          </>
        ),
    });
  }

  //Initialization methods
  useEffect(() => {
    if (history.location.search === '') setQueryParams(initialFilter);
    findUsers(queryParams);
    if (queryParams.filter) setFilter(queryParams.filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findUsers = function (queryParams: any) {
    usersServices.findUsers(queryParams).then((response) => {
      setEntities(response.data.items);
      setTotalCount(response.data.totalCount);
    });
  };

  const onFilterChange = (e: any, key: string) => {
    if (key === 'filter') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };
  const debounced = useDebouncedCallback((params: any) => {
    const newQueryParams = { ...queryParams };
    if (params.key === 'filter') {
      let filter = params.value;
      newQueryParams.filter = filter;
    }
    newQueryParams.pageNumber = 1;
    setQueryParams(newQueryParams);
  }, 800);

  const refetch = () => {
    findUsers(queryParams);
  };
  //Modal Edit
  const [selectedUser, setSelectedUser] = useState<UserModel>(
    userEditInitialValues
  );
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setSelectedUser(userEditInitialValues);
  };
  const handleShowUpdateModal = (user: UserModel) => {
    setShowUpdateModal(true);
    setSelectedUser(user);
  };

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header py-6'>
          <div className='card-title'>
            {group.userGroupsPermission?.list && (
              <div className='d-flex align-items-center position-relative my-1'>
                <i className='las la-search position-absolute ms-6 fs-2'></i>
                <input
                  autoComplete='none'
                  type='search'
                  className='form-control form-control-solid w-300px ps-15'
                  value={filter}
                  placeholder='Search by Name or Email'
                  onChange={(e) => onFilterChange(e, 'filter')}
                />
              </div>
            )}
          </div>
        </div>

        <div className='card-body px-9 py-3'>
          {group.userGroupsPermission?.list && (
            <ListComponent
              table={{
                columns,
                data: entities,
                paginationTotalRows: totalCount,
              }}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
            />
          )}
        </div>
      </div>
      {showUpdateModal && (
        <UserGroupsEdit
          refetch={refetch}
          user={selectedUser}
          show={showUpdateModal}
          onHide={handleCloseUpdateModal}
        />
      )}
    </>
  );
}
