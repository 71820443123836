import { Fragment } from 'react';
import { CustomBadge } from '../../../components/CustomBadge';
import { ProductModel } from '../models/ProductModel';

interface Props {
  product: ProductModel;
}

export function ProductViewCardAttributes({ product }: Props) {
  return (
    <Fragment>
      <div className='p-5'>
        {product.cardAttributes.value &&
          product.cardAttributes.valueType === 'Fixed' && (
            <div className='row py-1 mt-3' id='1'>
              <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
                Value
              </div>
              <div className='fs-5 col-9'>${product.cardAttributes.value}</div>
            </div>
          )}
        {product.cardAttributes.value == null &&
          product.cardAttributes.valueType === 'Fixed' && (
            <div className='row py-1 mt-3' id='2'>
              <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
                Value
              </div>
              <div className='fs-5 col-9'>NA</div>
            </div>
          )}
        {product.cardAttributes.valueType === 'Range' &&
          product.cardAttributes.minValue === null &&
          product.cardAttributes.maxValue === null && (
            <div className='row py-1 mt-3' id='3'>
              <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
                Value
              </div>
              <div className='fs-5 col-9'>NA</div>
            </div>
          )}
        {product.cardAttributes.valueType === 'Range' &&
          product.cardAttributes.minValue !== null &&
          product.cardAttributes.maxValue !== null && (
            <div className='row py-1 mt-3' id='4'>
              <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
                Value
              </div>
              <div className='fs-5 col-9'>
                ${product.cardAttributes.minValue} - $
                {product.cardAttributes.maxValue}
              </div>
            </div>
          )}
        {product.cardAttributes.maskingFormat && (
          <div className='row py-1 mt-3'>
            <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
              Masking Format
            </div>

            <div className='fs-5 col-9'>
              {product.cardAttributes.maskingFormat}
            </div>
          </div>
        )}
        <div className='row py-1 mt-3'>
          <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
            Can Activate
          </div>
          <div className=' fs-5 col-9'>
            <CustomBadge
              status={product.cardAttributes.canActivate}
              activeText='Yes'
              inactiveText='No'
            />
          </div>
        </div>
        <div className='row py-1 mt-3'>
          <div className='fw-bold fs-5 text-gray-400 text-capitalize col'>
            Manufacturer Data Required
          </div>
          <div className=' fs-5 col-9'>
            <CustomBadge
              status={product.cardAttributes.mfDataRequired}
              activeText='Yes'
              inactiveText='No'
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
