import { Fragment, useEffect, useState } from 'react';
import accountsServices from '../../services/AccountsServices';
import { AccountModel } from '../../models/AccountModel';
import { TableColumn } from 'react-data-table-component';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { AccountCreate } from './AccountCreate';
import { AccountEdit } from './AccountEdit';
import ListComponent from '../../../../components/ListComponent';
import {
  AccountQueryFilterKeys,
  initialFilterAccounts,
  queryParamsAccountConfig,
} from '../../utils/utils';
import { useQueryParamsReplace } from '../../../../utils/useQueryParamsReplace';
import { AccountsFilter } from './AccountsFilter';
import { GroupModel } from '../../../users/models/GroupModel';
import { RootState } from '../../../../redux';

export function AccountsList() {
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<AccountModel[]>([]);
  const [queryParams, setQueryReplace] = useQueryParamsReplace(
    queryParamsAccountConfig
  );
  const [filter, setFilter] = useState('');
  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<AccountModel>[] = useMemo(
    () => [
      {
        name: 'Key',
        selector: (row) => row.accountKey,
        sortable: true,
        sortField: 'accountKey',
      },
      {
        name: 'Name',
        selector: (row) => row.accountName,
        sortable: true,
        sortField: 'accountName',
      },
      {
        name: 'Number',
        selector: (row) => row.accountNumber,
        sortable: true,
        sortField: 'accountNumber',
      },
      {
        name: 'Active',
        cell: (row) => {
          const badgeText = row.isActive ? 'Active' : 'Inactive';
          const color = row.isActive ? 'badge-success' : 'badge-light';
          return <span className={`badge ${color}`}>{badgeText}</span>;
        },
        sortable: false,
      },
      {
        name: 'Actions',
        button: true,
        cell: (row) =>
          row.id && (
            <>
              <Fragment>
                <button
                  onClick={() => handleView(row)}
                  className='btn btn-icon btn-bg-light btn-active-success btn-sm me-1'
                  data-bs-toggle='tooltip'
                  title='View'
                >
                  <i className='las la-eye fs-2'></i>
                </button>
                {group.accountPermission?.view &&
                  group.accountPermission?.update && (
                    <button
                      onClick={() => handleShowUpdateModal(row.id)}
                      className='btn btn-icon btn-bg-light btn-active-primary btn-sm me-1'
                      data-bs-toggle='tooltip'
                      title='Edit'
                    >
                      <i className='las la-edit fs-2'></i>
                    </button>
                  )}
              </Fragment>
            </>
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Initialization methods
  useEffect(() => {
    if (history.location.search === '') setQueryReplace(initialFilterAccounts);
    if (queryParams.filter) setFilter(queryParams.filter);
    if (queryParams.pageNumber) findAccounts(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findAccounts = function (queryParams: any) {
    if (group.accountPermission?.list) {
      accountsServices.findAccounts(queryParams).then((response) => {
        setEntities(response.data.items);
        setTotalCount(response.data.totalCount);
      });
    }
  };

  const onFilterChange = (e: any, key: AccountQueryFilterKeys) => {
    if (key === 'filter') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };

  const findWithFilters = (params: {
    key: AccountQueryFilterKeys;
    value: any;
  }) => {
    const newQueryParams = { ...queryParams };
    newQueryParams[params.key] = params.value;
    newQueryParams.pageNumber = 1;
    setQueryReplace(newQueryParams);
  };

  const debounced = useDebouncedCallback(findWithFilters, 800);

  //const dispatch = useDispatch();
  const handleView = (account: AccountModel) => {
    //const entity: EntityModel = initEntityAccount(account);

    //dispatch(setEntityAccount(entity));
    history.push(`/accounts/${account.id}/clients`);
  };
  const refetch = () => {
    findAccounts(queryParams);
  };
  const [selectedId, setSelectedId] = useState<number>(0);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setSelectedId(0);
  };
  const handleShowUpdateModal = (id: number) => {
    setShowUpdateModal(true);
    setSelectedId(id);
  };
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        {(group.accountPermission?.list || group.accountPermission?.create) && (
          <div className='card-header py-6'>
            <div className='card-title'>
              {group.accountPermission?.list && (
                <div className='d-flex align-items-center position-relative my-1'>
                  <i className='las la-search position-absolute ms-6 fs-2'></i>
                  <input
                    autoComplete='none'
                    type='search'
                    className='form-control form-control-solid w-350px ps-15'
                    value={filter}
                    placeholder='Search by Key, Name or Number'
                    onChange={(e) => onFilterChange(e, 'filter')}
                  />
                </div>
              )}
            </div>
            <div className='card-toolbar'>
              {group.accountPermission?.list && (
                <AccountsFilter
                  queryParams={queryParams}
                  setQueryParams={setQueryReplace}
                />
              )}

              {group.accountPermission?.create && (
                <AccountCreate refetch={refetch} />
              )}
            </div>
          </div>
        )}

        <div className='card-body px-9 py-3'>
          <ListComponent
            table={{
              columns,
              data: entities,
              paginationTotalRows: totalCount,
              pointerOnHover: true,
              highlightOnHover: true,
              onRowClicked: handleView,
            }}
            queryParams={queryParams}
            setQueryParams={setQueryReplace}
            notPermissions={!group.accountPermission?.list}
          />
        </div>
      </div>
      {showUpdateModal && (
        <AccountEdit
          id={selectedId}
          show={showUpdateModal}
          refetch={refetch}
          onHide={handleCloseUpdateModal}
        />
      )}
    </>
  );
}
