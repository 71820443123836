import warehouseServices from '../services/WarehousesServices';
import { WarehouseModel } from '../models/WarehouseModel';
//Modals imports
import { Formik, FormikHelpers, Form, ErrorMessage } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { ActionsComponent } from '../../../components/ActionsComponent';
import { toast } from 'react-toastify';
import { handle400Error } from '../../../utils/handle400Error';
import { useIntl } from 'react-intl';
import { warehouseAdjustStockInitialValues } from '../utils/utils';
import { AddStockAdjustmentModel } from '../models/AddStockAdjustmentModel';
import { warehouseAdjustStockSchemaValidation } from '../utils/warehouseAdjustStockSchemaValidation';
import { CustomAllProductSelect } from '../../../components/CustomAllProductSelect';
import { ProductModel } from '../../products/models/ProductModel';
import { CustomInput } from '../../../components/CustomInput';
import { CustomTextArea } from '../../../components/CustomTextArea';
import { useEffect, useState } from 'react';
import productServices from '../../products/services/ProductsServices';
import { ShippingUnitListSelection } from './ShippingUnitListSelection';

interface Props {
  refetch: () => void;
  show: boolean;
  onHide: () => void;
  warehouse: WarehouseModel;
  productId?: number;
}

export function WarehouseAdjustStock({
  refetch,
  show,
  onHide,
  warehouse,
  productId,
}: Props) {
  const intl = useIntl();
  const [currentLevel, setCurrentLevel] = useState(0);
  const [currentLevelError, setCurrentLevelError] = useState('');
  const [newLevel] = useState(0);
  const [product, setProduct] = useState<ProductModel | null>(null);

  const loadCurrentLevel = (warehouseId: number, productId: number) => {
    warehouseServices
      .getCurrentLevel(warehouseId, productId)
      .then((resp: any) => {
        if (resp.data.success) {
          setCurrentLevel(resp.data.currentLevel);
        } else {
          toast.warning(resp.data.message);
          setCurrentLevel(0);
          setCurrentLevelError(resp.data.message);
        }
        return resp.data.currentLevel;
      });
  };
  useEffect(() => {
    if (warehouse.id && productId) {
      loadCurrentLevel(warehouse.id, productId);
      productServices.getProduct(productId).then((prdData) => {
        setProduct(prdData.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const createWarehouseAdjustStock = (
    values: any,
    actions: FormikHelpers<AddStockAdjustmentModel>
  ) => {
    if (product) {
      const addStockData = {
        productId: product.id,
        productType: product.productType,
        warehouseId: warehouse.id,
        newLevel: values.newLevel,
        reason: values.reason,
        currentLevel: values.currentLevel || currentLevel,
        removeAll: false,
        shippingUnitsToRemove: [],
      };
      if (product.productType === 'Card') {
        addStockData.shippingUnitsToRemove = values.shippingUnitsToRemove;
        addStockData.removeAll = values.removeAll;
      }

      warehouseServices
        .addWarehouseAdjustStock(warehouse.id, addStockData)
        .then((response: any) => {
          console.log(response);
          const result = response.data;
          if (!result.isCard)
            toast.success(
              `Warehouse Inventory successfully adjusted. New Level ${result.newLevel}`
            );
          else {
            if (result.totalShippingUnitsDeleted <= 5) {
              toast.success(
                `Inventory updated. Shipping units deleted: ${result.deletedShippingUnits}`
              );
            } else {
              const deletedShippingUnits =
                result.deletedShippingUnits.split(',');
              let [su1, su2, su3, su4, su5, ...rest] = deletedShippingUnits;
              let shippingUnitsForMessage = [su1, su2, su3, su4, su5].join(',');

              toast.success(
                `Inventory updated. Total shipping units deleted: ${result.totalShippingUnitsDeleted}. Shipping units deleted: ${shippingUnitsForMessage} and ${rest.length} more.`
              );
            }
          }
          onHide();
          refetch();
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            handle400Error(error.response.data.errors, actions.setErrors);
          }
        });
    }
  };

  const onChangeProduct = (
    p: ProductModel,
    values: AddStockAdjustmentModel,
    setFieldValue: any
  ) => {
    if (warehouse.id && p.id) {
      const currentLevel = loadCurrentLevel(warehouse.id, p.id);
      setFieldValue('currentLevel', currentLevel);
      setProduct(p);
    } else {
      setCurrentLevel(0);
      setFieldValue('currentLevel', 0);
      setProduct(null);
    }
  };

  warehouseAdjustStockInitialValues.warehouseId = warehouse.id;
  warehouseAdjustStockInitialValues.productId = product ? product.id : 0;
  warehouseAdjustStockInitialValues.productType = product
    ? product.productType
    : '';
  warehouseAdjustStockInitialValues.currentLevel = currentLevel
    ? currentLevel
    : 0;
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
        size='lg'
      >
        <Formik
          enableReinitialize
          initialValues={warehouseAdjustStockInitialValues}
          validationSchema={warehouseAdjustStockSchemaValidation(intl)}
          validateOnChange
          onSubmit={(values, actions) => {
            createWarehouseAdjustStock(values, actions);
            actions.setSubmitting(false);
          }}
        >
          {({ setFieldValue, values, errors }) => {
            return (
              <Form>
                <Modal.Header className='px-9 pt-7 card-rounded'>
                  <Modal.Title className='m-0 fw-bolder fs-3'>
                    Adjust Stock
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className='container-fluid mt-1  pt-8 position-relative z-index-1'>
                  <>
                    <div className='row mb-2'>
                      <div className='col-6'>
                        <div className='row mb-2'>
                          <label className='col-lg-4 fw-bolder form-label'>
                            Warehouse
                          </label>

                          <div className='col-lg-8'>
                            <span className=' fs-6 text-muted'>
                              {warehouse.name}
                            </span>
                          </div>
                        </div>

                        <div className='row mb-2'>
                          <label className='col-lg-4 fw-bolder form-label pt-3'>
                            Product
                          </label>

                          <div className='col-lg-8'>
                            {!productId && (
                              <div className='me-5'>
                                <CustomAllProductSelect
                                  isRequired={true}
                                  id='productId'
                                  name='productId'
                                  type='text'
                                  addData={true}
                                  onChangeProduct={(p) =>
                                    onChangeProduct(p, values, setFieldValue)
                                  }
                                  isDisabled={productId ? true : false}
                                />
                                <div className='col-12 my-2'>
                                  <ErrorMessage name='productId'>
                                    {(msg) => (
                                      <div className='text-danger'>{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </div>
                            )}
                            {productId && product && (
                              <div className=' fs-6 text-muted  pt-2'>
                                {product.code}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className='col-6'>
                        <div className='row  mb-2'>
                          <label className='col-lg-4 fw-bolder form-label'>
                            Current Level
                          </label>

                          <div className='col-lg-8'>
                            {!currentLevelError && (
                              <span className='fs-6  text-muted'>
                                {currentLevel}
                              </span>
                            )}

                            {currentLevelError && (
                              <span className='fs-6'>-</span>
                            )}
                          </div>
                          {currentLevelError && (
                            <div className='col-12 my-2 text-center'>
                              <ErrorMessage name='currentLevel'>
                                {(msg) => (
                                  <div className='text-danger'>
                                    {currentLevelError}
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          )}
                        </div>

                        {product?.productType !== 'Card' && (
                          <div className='row'>
                            <label className='col-lg-4 fw-bolder form-label pt-3'>
                              New Level
                            </label>

                            <div className='col-lg-8'>
                              {product && product.productType !== 'Card' && (
                                <div className='me-5'>
                                  <CustomInput
                                    id='newLevel'
                                    name='newLevel'
                                    isRequired={true}
                                    type='number'
                                  />
                                </div>
                              )}

                              {product && product.productType === 'Card' && (
                                <div className='fw-bolder fs-6 text-gray-800 pt-3'>
                                  {newLevel}
                                </div>
                              )}
                              {!product && (
                                <div className='fw-bolder fs-6 text-gray-800 pt-3'>
                                  -
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-2 mt-5'>
                      <div className='col-12'>
                        <CustomTextArea
                          isRequired={true}
                          id='reason'
                          name='reason'
                          label='Reason of adjustment'
                          rows={2}
                          maxLength={255}
                        />
                      </div>
                    </div>

                    {!!product && product.productType === 'Card' && (
                      <div className='row mb-2 mt-8'>
                        <div className='col-12'>
                          <ShippingUnitListSelection
                            product={product}
                            warehouse={warehouse}
                            fieldName='shippingUnitsToRemove'
                          ></ShippingUnitListSelection>
                        </div>
                        <div className='col-12 my-2 text-center'>
                          <ErrorMessage name='shippingUnitsToRemove'>
                            {(msg) => <div className='text-danger'>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    )}
                  </>
                </Modal.Body>
                <Modal.Footer className='px-9 card-rounded  w-100'>
                  <ActionsComponent
                    labelSubmit='Adjust'
                    showCancelButton={true}
                    onCancel={onHide}
                    disableSubmit={
                      product?.productType === 'Card' &&
                      values.shippingUnitsToRemove.length === 0 &&
                      !values.removeAll
                    }
                  />
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}
