import axios from 'axios';
import { AvailabilityModel } from '../../../models/AvailabilityModel';
import { PagedResult } from '../../../models/pagedResult';
import { AvailabilityInfoModel } from '../models/AvailabilityInfoModel';
import { CreateProductCardAttributeModel } from '../models/CreateProductCardAttributeModel';
import { CardAttributeModel, ProductModel } from '../models/ProductModel';
import { ProductInformationCreate } from '../models/ProductInformationCreate';
import { ProductInformationUpdate } from '../models/ProductInformationUpdate';

export const PRODUCT_URL = `/api/v1/products`;

const productServices = {
  getAll: function () {
    return axios.get<ProductModel[]>(`${PRODUCT_URL}/all`);
  },
  getAllActiveCompatible: function (ids: number[]) {
    let stringIds: string = '';
    ids.forEach((id) => {
      stringIds = stringIds + 'ids=' + id + '&';
    });
    return axios.get<ProductModel[]>(
      `${PRODUCT_URL}/all/compatible?${stringIds}`
    );
  },
  getAllActive: function () {
    return axios.get<ProductModel[]>(`${PRODUCT_URL}/all/active`);
  },
  findProducts: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(PRODUCT_URL, { params: params });
  },
  getProduct: (id: number) => {
    return axios.get<ProductModel>(`${PRODUCT_URL}/${id}`);
  },
  getAllActiveProducts: () => {
    return axios.get<ProductModel[]>(`${PRODUCT_URL}/all/active`);
  },
  getAllActiveMFProducts: () => {
    const params = {
      MFDataRequired: true,
    };
    return axios.get<ProductModel[]>(`${PRODUCT_URL}/all/active`, {
      params: params,
    });
  },
  productInformationCreate: (productInformation: ProductInformationCreate) => {
    return axios.post<ProductModel>(
      `${PRODUCT_URL}/information`,
      productInformation
    );
  },
  productCardAttributeCreate: (
    productId: number,
    cardInformation: CardAttributeModel
  ) => {
    return axios.post<ProductModel>(
      `${PRODUCT_URL}/${productId}/card-attributes`,
      cardInformation
    );
  },
  productImages: (productId: number, productImages: any) => {
    return axios.post<CreateProductCardAttributeModel>(
      `${PRODUCT_URL}/${productId}/images`,
      productImages
    );
  },
  getAvailabilitiesInfo: () => {
    return axios.get<AvailabilityInfoModel>(
      `${PRODUCT_URL}/availabilities/info`
    );
  },
  productAvailabilities: (
    productId: number,
    availabilities: AvailabilityModel[]
  ) => {
    return axios.post<CreateProductCardAttributeModel>(
      `${PRODUCT_URL}/${productId}/availabilities`,
      availabilities
    );
  },
  // Update
  productInformationUpdate: (productInformation: ProductInformationUpdate) => {
    return axios.put<ProductModel>(
      `${PRODUCT_URL}/information/${productInformation.id}`,
      productInformation
    );
  },
  productCardAttributeUpdate: (
    productId: number,
    cardInformation: CardAttributeModel
  ) => {
    return axios.put<ProductModel>(
      `${PRODUCT_URL}/${productId}/card-attributes/${cardInformation.id}`,
      cardInformation
    );
  },
  clone: (productId: number) => {
    return axios.post(`${PRODUCT_URL}/clone/${productId}`);
  },
  activateProduct: (productId: number) => {
    return axios.put(`${PRODUCT_URL}/activate/${productId}`);
  },
  deactivateProduct: (productId: number, reason?: string) => {
    const body = {
      id: productId,
      reason,
    };
    return axios.put(`${PRODUCT_URL}/deactivate/${productId}`, body);
  },
  getAllForSelect: function () {
    return axios.get<ProductModel[]>(`${PRODUCT_URL}/all/with-inactives`);
  },
};

export default productServices;
