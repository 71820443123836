import { Route, Switch } from 'react-router';
import { PageTitle } from '../../../_metronic/layout/core';
import { FulfillmentList } from './components/FulfillmentList';
import { FulfillmentOrderView } from './components/FulfillmentOrderView';

export function FulfillmentPage() {
  return (
    <>
      <Switch>
        <Route exact path='/fulfillments'>
          <PageTitle breadcrumbs={[]}>Fulfillment Orders</PageTitle>
          <FulfillmentList />
        </Route>
        <Route path='/fulfillments/:orderId'>
          <PageTitle breadcrumbs={[]}>Fulfillment Order Details</PageTitle>
          <FulfillmentOrderView />
        </Route>
      </Switch>
    </>
  );
}
