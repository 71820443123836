import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const groupSchemaValidation = (intl: IntlShape) =>
  Yup.object().shape({
    name: Yup.string()
      .ensure()
      .trim()
      .min(
        3,
        intl.formatMessage({ id: 'VALIDATION.MIN_LENGTH_FIELD' }, { min: 3 })
      )
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 50 })
      )
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
  });
