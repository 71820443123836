import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const warehouseAdjustStockSchemaValidation = (intl: IntlShape) =>
  Yup.object().shape({
    productId: Yup.number()
      .positive(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .min(
        1,
        intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }, { min: 1 })
      )
      .integer(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    warehouseId: Yup.number()
      .positive(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .min(
        1,
        intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }, { min: 1 })
      )
      .integer(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),

    currentLevel: Yup.number(),
    productType: Yup.string(),
    //   .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
    //   .min(1, intl.formatMessage({ id: 'VALIDATION.MIN.NUMBER' }, { min: 1 }))
    //   .integer(intl.formatMessage({ id: 'VALIDATION.INTEGER_FIELD' }))
    //   .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
    newLevel: Yup.number().when('productType', {
      is: (productType: string) => productType !== 'Card',
      then: Yup.number()
        .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
        .min(1, intl.formatMessage({ id: 'VALIDATION.MIN.NUMBER' }, { min: 1 }))
        .integer(intl.formatMessage({ id: 'VALIDATION.INTEGER_FIELD' }))
        .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    }),

    reason: Yup.string()
      .ensure()
      .trim()
      .max(
        250,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 250 })
      )
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),

    removeAll: Yup.boolean(),
    shippingUnitsToRemove: Yup.array()
      .of(Yup.number())
      .when(['productType', 'removeAll'], {
        is: (productType: string, removeAll: boolean) => {
          return productType === 'Card' && !removeAll;
        },
        then: Yup.array()
          .of(Yup.number())
          .min(1, 'You must select at least one'),
      }),
  });
