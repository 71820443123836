import { Formik, FormikHelpers, Form } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { useIntl } from 'react-intl';
import { AddressModel } from '../models/AddressModel';
import { initialValuesThirdShipTo } from '../utils/utils';
import { addressThirdShipToShema } from '../utils/addressThirdShipToShema';
import { AddressForm } from './AddressForm';
import orderServices from '../services/OrdersServices';
import { LocationAddress } from '../../entities/models/LocationModel';
import { Dispatch, SetStateAction } from 'react';
import { handle400Error } from '../../../utils/handle400Error';
import { EntityFiltersModel } from '../../../models/EntityFiltersModel';

interface Props {
  setEntityFilters: Dispatch<SetStateAction<EntityFiltersModel>>;
  show: boolean;
  onHide: () => void;
  setShowChangeButton: Dispatch<SetStateAction<boolean>>;
  setProductsAdded: any;
}

export function AddressThirdShipToModal({
  setEntityFilters,
  show,
  onHide,
  setShowChangeButton,
  setProductsAdded,
}: Props) {
  const intl = useIntl();
  const createAddressThirdShipTo = (
    values: AddressModel,
    actions: FormikHelpers<AddressModel>
  ) => {
    if (values.locationId) {
      //Make Post
      setProductsAdded([]);
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        locationId: values.locationId,
        franchiseId: undefined,
      }));
      onHide();
      setShowChangeButton(true);
    } else {
      // Set locationId
      const body = values as LocationAddress;
      orderServices
        .createThirdPartyLocation(body)
        .then((res) => {
          setProductsAdded([]);
          setEntityFilters((s: EntityFiltersModel) => ({
            ...s,
            locationId: res.data.id,
          }));
          onHide();
          setShowChangeButton(true);
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            handle400Error(error.response.data.errors, actions.setErrors);
          }
        });
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Formik
          enableReinitialize
          initialValues={initialValuesThirdShipTo}
          validationSchema={addressThirdShipToShema(intl)}
          onSubmit={(values, actions) => {
            createAddressThirdShipTo(values, actions);
            actions.setSubmitting(false);
          }}
        >
          {({ values }) => (
            <Form>
              <Modal.Header className='px-9 pt-7 card-rounded'>
                <Modal.Title className='m-0 fw-bolder fs-3'>
                  Select or add 3rd party ship-to this order
                </Modal.Title>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  onClick={onHide}
                >
                  <i className='las la-times fs-2'></i>
                </div>
              </Modal.Header>
              <Modal.Body className='container-fluid mt-1 px-10 py-8 position-relative z-index-1'>
                <AddressForm />
              </Modal.Body>
              <Modal.Footer className='px-9 card-rounded  w-100'>
                <div className='text-end'>
                  <button
                    type='reset'
                    id='close'
                    className='btn btn-light me-3'
                  >
                    Discard
                  </button>

                  <button type='submit' id='save' className='btn btn-primary'>
                    <span className='indicator-label'>
                      {values.locationId ? 'Save' : 'Ok'}
                    </span>
                  </button>
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
