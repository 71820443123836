import { Fragment, useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select/dist/declarations/src/Select';
import { CustomFilter } from '../../../components/CustomFilter';
import ReactSelect from 'react-select';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { initialFilterKits } from '../utils/utils';
import { EntityFiltersModel } from '../../../models/EntityFiltersModel';
import { FilterBadge } from '../../../models/FilterBadge';
import { CustomBadgeFilter } from '../../../components/CustomBadgeFilter';
import { FilterCascadeEntitySelections } from '../../../components/FilterCascadeEntitySelection';

const activeOptions = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];
const binaryOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
interface PropsFilter {
  queryParams: any;
  setQueryParams: any;
}
export function KitsFilter({ setQueryParams, queryParams }: PropsFilter) {
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  // Filter States
  const [isActive, setIsActive] = useState<boolean | null>();
  const [orderable, setOrderable] = useState<boolean | null>();
  const [reset, setReset] = useState<boolean>(false);
  const isActiveRef = useRef<null | Select>(null);
  const orderableRef = useRef<null | Select>(null);

  // Default Filters:
  const [shouldApplyFilter, setShouldApplyFilter] = useState<boolean>(false);

  //Entity
  const [entityFilters, setEntityFilters] = useState<EntityFiltersModel>({
    accountId: queryParams.accountId ? queryParams.accountId : null,
    clientId: queryParams.clientId ? queryParams.clientId : null,
    franchiseId: queryParams.franchiseId ? queryParams.franchiseId : null,
    locationId: queryParams.locationId ? queryParams.locationId : null,
  });

  const handleIsActive = (params: any) => {
    if (params) {
      setIsActive(params.value);
    } else {
      setIsActive(undefined);
    }
  };
  const handleOrderable = (params: any) => {
    if (params) {
      setOrderable(params.value);
    } else {
      setOrderable(undefined);
    }
  };
  const resetFilters = () => {
    setReset(true);
  };

  const applyFilter = () => {
    const newQueryParams = { ...queryParams };
    newQueryParams.active = isActive;
    newQueryParams.orderable = orderable;
    newQueryParams.pageNumber = 1;
    newQueryParams.accountId = entityFilters?.accountId;
    newQueryParams.clientId = entityFilters?.clientId;
    newQueryParams.franchiseId = entityFilters?.franchiseId;
    newQueryParams.locationId = entityFilters?.locationId;
    setQueryParams(newQueryParams);
  };

  useEffect(() => {
    if (reset) {
      isActiveRef.current?.clearValue();
      orderableRef.current?.clearValue();
      let newQueryParams = {
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };

      setQueryParams({ ...initialFilterKits, ...newQueryParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  /** START Filter Badges */

  const [filterBadges, setFilteBadges] = useState<FilterBadge[]>([]);
  const [entityFilterBadges, setEntityFilteBadges] = useState<FilterBadge[]>(
    []
  );
  // Get initial values from the url
  useEffect(() => {
    let clearFilters = [];

    if (queryParams.active === true || queryParams.active === false) {
      setIsActive(queryParams.active);
      updateBadge(
        'active',
        queryParams.active === true
          ? 'Active'
          : queryParams.active === false
          ? 'Inactive'
          : '',
        isActiveRef
      );
    } else {
      clearFilters.push('active');
    }

    if (queryParams.orderable) {
      setOrderable(queryParams.orderable);
      updateBadge(
        'orderable',
        queryParams.orderable ? 'Orderable' : '',
        orderableRef
      );
    } else {
      clearFilters.push('orderable');
    }
    if (entityFilterBadges.length > 0) {
      entityFilterBadges.forEach((item: FilterBadge) => {
        updateBadge(item.key, item.value, item.ref);
      });
    }
    if (!queryParams.accountId) clearFilters.push('accountId');
    if (!queryParams.clientId) clearFilters.push('clientId');
    if (!queryParams.franchiseId) clearFilters.push('franchiseId');
    if (!queryParams.locationId) clearFilters.push('locationId');

    if (clearFilters.length > 0) {
      clearMultipleFilter(clearFilters);
    }
    // Get initial values from the url
    const newQueryParams = {
      ...entityFilters,
      accountId: queryParams.accountId ? queryParams.accountId : undefined,
      clientId: queryParams.clientId ? queryParams.clientId : undefined,
      franchiseId: queryParams.franchiseId
        ? queryParams.franchiseId
        : undefined,
      locationId: queryParams.locationId ? queryParams.locationId : undefined,
    };
    setEntityFilters(newQueryParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  // Get initial values from the url
  useEffect(() => {
    if (shouldApplyFilter) {
      let clearFilters = [];

      if (queryParams.active === true || queryParams.active === false) {
        setIsActive(queryParams.active);
        updateBadge(
          'active',
          queryParams.active === true
            ? 'Active'
            : queryParams.active === false
            ? 'Inactive'
            : '',
          isActiveRef
        );
      } else {
        clearFilters.push('active');
      }

      if (queryParams.orderable) {
        setOrderable(queryParams.orderable);
        updateBadge(
          'orderable',
          queryParams.orderable ? 'Orderable' : '',
          orderableRef
        );
      } else {
        clearFilters.push('orderable');
      }
      if (entityFilterBadges.length > 0) {
        entityFilterBadges.forEach((item: FilterBadge) => {
          updateBadge(item.key, item.value, item.ref);
        });
      }
      if (!queryParams.accountId) clearFilters.push('accountId');
      if (!queryParams.clientId) clearFilters.push('clientId');
      if (!queryParams.franchiseId) clearFilters.push('franchiseId');
      if (!queryParams.locationId) clearFilters.push('locationId');

      if (clearFilters.length > 0) {
        clearMultipleFilter(clearFilters);
      }
      // Get initial values from the url
      const newQueryParams = {
        ...entityFilters,
        accountId: queryParams.accountId ? queryParams.accountId : undefined,
        clientId: queryParams.clientId ? queryParams.clientId : undefined,
        franchiseId: queryParams.franchiseId
          ? queryParams.franchiseId
          : undefined,
        locationId: queryParams.locationId ? queryParams.locationId : undefined,
      };
      setEntityFilters(newQueryParams);
      setShouldApplyFilter(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldApplyFilter, entityFilterBadges]);

  const clearMultipleFilter = (filters: Array<string>) => {
    let badges = filterBadges;
    filters.forEach((element) => {
      badges = badges.filter((item: FilterBadge) => item.key !== element);
    });
    setFilteBadges(badges);
  };
  const clearFilter = (ref: any, keyParam: string) => {
    if (
      keyParam === 'accountId' ||
      keyParam === 'clientId' ||
      keyParam === 'franchiseId'
    ) {
      clearMultipleFilter([
        'accountId',
        'clientId',
        'franchiseId',
        'locationId',
      ]);
      setEntityFilteBadges([]);
      let params = { ...queryParams };
      params['accountId'] = userGroupRelations.accountId
        ? userGroupRelations.accountId
        : undefined;
      params['clientId'] = userGroupRelations.clientId
        ? userGroupRelations.clientId
        : undefined;
      params['franchiseId'] = userGroupRelations.franchiseId
        ? userGroupRelations.franchiseId
        : undefined;
      params['locationId'] = userGroupRelations.locationId
        ? userGroupRelations.locationId
        : undefined;
      // When removing a badge you should be redirected to page 1.
      params['pageNumber'] = 1;
      setQueryParams(params);

      filterBadges
        .find((element) => element.key === 'accountId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'clientId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'franchiseId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'locationId')
        ?.ref.current?.clearValue();
    } else if (ref) {
      ref.current?.clearValue();
      let params = { ...queryParams };
      params[keyParam] = undefined;
      // When removing a badge you should be redirected to page 1.
      params['pageNumber'] = 1;
      setQueryParams(params);
    }

    setFilteBadges(
      filterBadges.filter((item: FilterBadge) => item.key !== keyParam)
    );
  };
  const updateBadge = (key: string, value: string, ref: any) => {
    let badges = filterBadges;
    const finded = badges?.find((item: FilterBadge) => item.key === key);
    if (finded && finded !== undefined) {
      badges.splice(badges.indexOf(finded), 1, {
        key: key,
        value: value,
        ref: ref,
      });
    } else {
      badges.push({
        key: key,
        value: value,
        ref: ref,
      });
    }
    setFilteBadges(badges);
  };

  /** END Filter Badges */

  return (
    <>
      {filterBadges?.map((item: FilterBadge) => {
        if (item.value && item.value !== '')
          return (
            <CustomBadgeFilter
              text={item.value}
              key={item.key}
              badgeKey={item.key}
              clearFilter={() => {
                if (item.ref) clearFilter(item.ref, item.key);
              }}
            ></CustomBadgeFilter>
          );
        else return <></>;
      })}

      <CustomFilter
        applyFilters={applyFilter}
        resetFilters={resetFilters}
        scroll={false}
      >
        <Fragment>
          <FilterCascadeEntitySelections
            setReset={setReset}
            reset={reset}
            entityFilters={entityFilters}
            setEntityFilters={setEntityFilters}
            setEntityListBadge={setEntityFilteBadges}
            entityFilterBadges={entityFilterBadges}
            setShowFilterBadges={setShouldApplyFilter}
          />

          <div className='mb-2' onClick={(e) => e.stopPropagation()}>
            <label className='form-label fw-bold'>Active</label>
            <ReactSelect
              placeholder={<FormattedMessage id='COMMON.ALL' />}
              isClearable
              name='isActive'
              id='isActive'
              onChange={handleIsActive}
              options={activeOptions}
              value={
                activeOptions
                  ? activeOptions.filter((x: any) => x.value === isActive)[0]
                  : null
              }
              ref={isActiveRef}
              defaultValue={activeOptions[0]}
            />
          </div>

          <div onClick={(e) => e.stopPropagation()}>
            <label className='form-label fw-bold'>Orderable</label>
            <ReactSelect
              placeholder={<FormattedMessage id='COMMON.ALL' />}
              isClearable
              name='orderable'
              id='orderable'
              onChange={handleOrderable}
              options={binaryOptions}
              value={
                binaryOptions
                  ? binaryOptions.filter((x: any) => x.value === orderable)[0]
                  : null
              }
              ref={orderableRef}
            />
          </div>
        </Fragment>
      </CustomFilter>
    </>
  );
}
