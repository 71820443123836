import React, { useState } from 'react';
import moment from 'moment';
import { DefaultColors, texts } from './StatusBadge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

interface Props {
  shipDate: string;
  shippingTrackingNbr: string;
  shippingCompany: string;
  status:
    | 'New'
    | 'BackOrdered'
    | 'Picking'
    | 'Picked'
    | 'Packing'
    | 'Packed'
    | 'Shipped'
    | 'Cancelled'
    | 'Returned'
    | 'PartialShip';
  orderNumber: number;
}
export const urlCompany = (
  shippingCompany: string,
  shippingTrackingNbr: string
) => {
  if (shippingCompany === 'FedEx') {
    return `https://www.fedex.com/fedextrack/?trknbr=${shippingTrackingNbr}`;
  }
  if (shippingCompany === 'UPS') {
    return `https://www.ups.com/track?loc=en_US&tracknum=${shippingTrackingNbr}`;
  }
  if (shippingCompany === 'DHL') {
    return `https://www.dhl.com/us-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=${shippingTrackingNbr}`;
  }
  if (shippingCompany === 'USPS') {
    return `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${shippingTrackingNbr}%2C&tABt=false`;
  }

  return `https://www.aftership.com/track/ups-mi/${shippingTrackingNbr}`;
};
export function CustomStatusPopover({
  shipDate,
  shippingTrackingNbr,
  status,
  shippingCompany,
  orderNumber,
}: Props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const popover = (
    <Popover
      id='popover-basic'
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      style={{ maxWidth: '100%' }}
    >
      <Popover.Body>
        <form className='form form-label-right'>
          <div className='px-5 py-2'>
            <div id='kt_example_header' className='fs-5 text-dark fw-bolder'>
              Status Info
            </div>
          </div>

          <div className='separator border-gray-200'></div>

          <div className='px-5 py-5'>
            <p className='m-0'>
              <label className='fw-bolder'>Ship Date: </label>{' '}
              {shipDate
                ? moment(shipDate).format('MM-DD-YYYY')
                : 'No Ship Date'}
            </p>
            <p className='m-0'>
              <label className='fw-bolder'>Tracking Number: </label>{' '}
              {shippingTrackingNbr ? (
                <a
                  href={`${urlCompany(shippingCompany, shippingTrackingNbr)}`}
                  className='fw-bolder'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {shippingTrackingNbr}
                </a>
              ) : (
                'No Tracking Number'
              )}
            </p>
            <p className='m-0'>
              <label className='fw-bolder'>Shipping Company: </label>{' '}
              {shippingCompany ? shippingCompany : 'No Shipping Company'}
            </p>
          </div>
        </form>
      </Popover.Body>
    </Popover>
  );
  return (
    <div className='d-flex justify-content-end'>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement='auto'
        overlay={popover}
        show={showTooltip}
        delay={{ show: 250, hide: 300 }}
      >
        <span
          className='badge  my-0  text-white'
          style={{
            backgroundColor: DefaultColors[status],
          }}
          key={`${orderNumber}.span`}
          id={`${orderNumber}.span`}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {texts[status]}
        </span>
      </OverlayTrigger>
    </div>
  );
}
