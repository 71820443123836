import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const CreateKitSchemasStep1 = (intl: IntlShape) =>
  Yup.object().shape({
    code: Yup.string()
      .ensure()
      .trim()
      .max(
        50,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 50 })
      )
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    description: Yup.string()
      .ensure()
      .trim()
      .max(
        255,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 255 })
      )
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    premium: Yup.boolean().required(
      intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
    ),
    webOrderable: Yup.boolean(),
    maxOrderQuantity: Yup.number()
      .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
      .min(1, intl.formatMessage({ id: 'VALIDATION.MIN.NUMBER' }, { min: 1 }))
      .integer(intl.formatMessage({ id: 'VALIDATION.INTEGER_FIELD' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    shippingUnitSize: Yup.number()
      .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_FIELD' }))
      .min(1, intl.formatMessage({ id: 'VALIDATION.MIN.NUMBER' }, { min: 1 }))
      .integer(intl.formatMessage({ id: 'VALIDATION.INTEGER_FIELD' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
  });
