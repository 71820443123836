import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap-v5';
import { handle400Error } from '../utils/handle400Error';
import { ActionsComponent } from './ActionsComponent';
import React, { useState } from 'react';

interface CustomFormModalProps {
  initialValues: any;
  onClose: () => void;
  onSubmit: (values: any) => Promise<any>;
  schemaValidations: any;
  title: string;
  children: React.ReactElement;
  successMessage: string;
  buttonLabel?: string;
  buttonNode?: JSX.Element;
  size?: 'lg' | 'sm' | 'xl' | undefined;
  submitButtonLabel?: string;
}

export function CustomFormModal(props: CustomFormModalProps) {
  // i18n
  const intl = useIntl();
  const {
    initialValues,
    onSubmit,
    onClose,
    successMessage,
    schemaValidations,
    children,
    title,
    buttonLabel,
    buttonNode,
    size,
    submitButtonLabel,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
    onClose();
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  // Error
  // Save Function
  const submit = (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    onSubmit(values)
      .then(() => {
        toast.success(successMessage);
        handleCloseModal();
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          handle400Error(error.response.data.errors, actions.setErrors);
        }
      });
  };

  return (
    <>
      {!!buttonNode &&
        React.cloneElement(buttonNode, {
          ...{ onClick: () => handleShowModal() },
        })}

      {!buttonNode && (
        <button
          className='btn btn-primary align-self-center'
          onClick={() => handleShowModal()}
        >
          {buttonLabel || ''}
        </button>
      )}

      {showModal && (
        <Modal
          show={showModal}
          backdrop='static'
          keyboard={false}
          size={size ? size : 'lg'}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={schemaValidations(intl)}
            onSubmit={(values, actions) => {
              submit(values, actions);
              actions.setSubmitting(false);
            }}
          >
            {(props: FormikProps<typeof initialValues>) => (
              <Form>
                <Modal.Header className='px-9 pt-7 card-rounded'>
                  <Modal.Title className='m-0 fw-bolder fs-3'>
                    {title}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className='mt-1 px-6 py-3 position-relative z-index-1'>
                  {children}
                </Modal.Body>

                <Modal.Footer className='px-9 card-rounded  w-100'>
                  <ActionsComponent
                    labelSubmit={
                      submitButtonLabel
                        ? submitButtonLabel
                        : intl.formatMessage({ id: 'ACTION.SUBMIT' })
                    }
                    showCancelButton={true}
                    onCancel={handleCloseModal}
                  />
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
}
