import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

const FILE_SIZE = 2000000;
const SUPPORTED_FORMATS = ['.jpg', '.png'];
const FileSchema = Yup.mixed()
  .test('fileSize', 'Max Size: 2MB.', (value) => {
    if (!value) {
      return true;
    }
    return value.size <= FILE_SIZE;
  })
  .test('fileFormat', 'Valid image types JPG and PNG.', (value) => {
    if (!value) {
      return true;
    }
    let ext = '';
    if (value instanceof File) {
      ext = '.' + value.name.replace(/^.*\./, '');
    }
    if (value.id) {
      ext = value.ext;
    }
    return SUPPORTED_FORMATS.includes(ext);
  });

export const createKitSchemasStep3 = (intl: IntlShape) =>
  Yup.object().shape({
    files: Yup.array().of(FileSchema),
  });
