import { NumberParam, StringParam, BooleanParam } from 'serialize-query-params';
import { ProductModel } from '../models/ProductModel';

export const initialFilterProducts = {
  filter: undefined,
  sortDirection: 1, // asc => 1||desc => -1
  sortColumn: 'code',
  pageNumber: 1,
  pageSize: 10,
  active: 1,
  productType: undefined,
  autoReplenish: undefined,
  orderable: undefined,
  trackInventory: undefined,
  accountId: undefined,
  clientId: undefined,
  franchiseId: undefined,
  locationId: undefined,
};

export type ProductQueryFilterKeys = 'filter' | 'active';

export const queryParamsProductConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  filter: StringParam,
  active: BooleanParam,
  productType: StringParam,
  autoReplenish: BooleanParam,
  orderable: BooleanParam,
  trackInventory: BooleanParam,
  accountId: NumberParam,
  clientId: NumberParam,
  franchiseId: NumberParam,
  locationId: NumberParam,
};

export const createProductinitialValues: ProductModel = {
  id: 0,
  isActive: false,
  productType: 'Card',
  code: '',
  description: '',
  premium: false,
  trackInventoryLevel: false,
  defaultInventoryLevel: 0,
  defaultLowLevel: 0,
  isWebOrderable: true,
  maxOrderQuantity: 1,
  shippingUnitSize: 1,
  autoReplenishment: false,
  autoReplenishmentType: 'product',
  isAutoreplenishmentByKit: false,
  autoReplenishProductId: 0,
  autoReplenishKitId: 0,
  deactivatedReason: '',
  createdAt: undefined,
  createdBy: undefined,
  productCreationStep: '',
  customProperties: [],
  files: [],
  cardAttributes: {
    id: 0,
    cardCategory: 'Pre-Printed_Pre-Persod',
    baseProductId: 0,
    valueType: 'Fixed',
    value: 0,
    minValue: 0,
    maxValue: 0,
    maskingFormat: '',
    isBaseStock: false,
    mfDataRequired: false,
    canActivate: false,
  },
  availabilities: [],
  cardAttributesId: 0,
  deactivationReason: '',
};

export const editProductinitialValues = {
  isActive: false,
  productType: 'Card',
  code: '',
  description: '',
  premium: false,
  trackInventoryLevel: false,
  defaultInventoryLevel: 0,
  defaultLowLevel: 0,
  isWebOrderable: false,
  maxOrderQuantity: 0,
  shippingUnitSize: 1,
  autoReplenishment: false,
  autoReplenishmentType: 'product',
  autoReplenishProductId: 0,
  autoReplenishKitId: 0,
  deactivatedReason: '',
  createdAt: undefined,
  createdBy: undefined,
  productCreationStep: '',
  customProperties: [],
  files: [],
  cardAttributes: {
    id: 0,
    cardCategory: 'Pre-Printed_Pre-Persod',
    baseProductId: 0,
    valueType: 'Fixed',
    value: 0,
    minValue: 0,
    maxValue: 0,
    maskingFormat: 'xyz',
    isBaseStock: false,
  },
  productAvailabilities: [],
  cardAttributesId: 0,
};
