/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';
import { editKitInitialValues } from '../utils/utils';
import { Formik, Form, FormikValues, FormikProps } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { CreateKitModel, KitProductModel } from '../models/CreateKitModel';
import { StepperComponent } from '../../../../_metronic/assets/ts/components';
import { createKitSchemas } from '../utils/createKitSchemasValidations';
import { useIntl } from 'react-intl';
import { KitInformationStep1 } from './KitInformationStep1';
import { kitHelpers } from '../helpers/kit';
import kitServices from '../services/KitsServices';
import { handle400Error } from '../../../utils/handle400Error';
import { KitInformationCreate } from '../models/KitInformationCreate';
import { ProductSelectionStep2 } from './ProductSelectionStep2';
import { KitImagesStep3 } from './KitImagesStep3';
import { KitAvailabilityStep4 } from './KitAvailabilityStep4';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { KitModel } from '../models/KitModel';

interface Props {
  kitId: number;
  refetch: () => void;
  show: boolean;
  onHide: () => void;
}

export function KitEdit({ show, refetch, onHide, kitId }: Props) {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(createKitSchemas(intl)[0]);
  const [lastStep, setLastStep] = useState(false);
  const [kit, setKit] = useState<KitModel | null>(null);
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  useEffect(() => {
    setLoading(true);
    kitServices.getKit(kitId).then((kitData: AxiosResponse<KitModel>) => {
      const data = kitHelpers.initialValues(kitData.data);
      setKit(data);
      setLoading(false);
    });
  }, [kitId]);

  const prevStep = (values: CreateKitModel) => {
    if (!stepper.current) {
      return;
    }
    const currentIndex = stepper.current.currentStepIndex;
    const totatStepsNumber = stepper.current.totatStepsNumber;

    let backIndex = currentIndex - 1;
    setLastStep(backIndex === totatStepsNumber);
    setCurrentSchema(createKitSchemas(intl)[backIndex - 1]);
    stepper.current.goto(backIndex);
  };

  const handleSubmitClick = (
    type: 'close' | 'continue',
    formikProps: FormikProps<any>
  ) => {
    const { values, isValid } = formikProps;
    if (isValid) {
      submitStep(values, formikProps, type === 'close' ? true : false);
    }
  };
  const submitStep = (
    values: CreateKitModel,
    actions: FormikValues,
    closeModal: boolean
  ) => {
    if (!stepper.current) {
      return;
    }
    const currentIndex = stepper.current.currentStepIndex;
    const totatStepsNumber = stepper.current.totatStepsNumber;
    let nextIndex = currentIndex + 1;

    setLastStep(nextIndex === totatStepsNumber);

    setCurrentSchema(createKitSchemas(intl)[nextIndex - 1]);
    if (currentIndex === 1) {
      step1(nextIndex, values, actions, closeModal);
    }
    if (currentIndex === 2) {
      step2(nextIndex, values, actions, closeModal);
    }
    if (currentIndex === 3) {
      step3(nextIndex, values, actions, closeModal);
    }
    if (currentIndex === 4) {
      step4(nextIndex, values, actions, closeModal);
    }
  };

  const step1 = useCallback(
    (
      nextIndex: number,
      kit: CreateKitModel,
      actions: FormikValues,
      closeModal: boolean
    ) => {
      if (kit.id !== 0) {
        const body: KitInformationCreate = kitHelpers.createStep1(kit);

        kitServices
          .productInformationEdit(body)
          .then((values) => {
            if (closeModal) {
              refetch();
              onHide();
              toast.success('Kit successfully updated');
            } else if (stepper.current) {
              stepper.current.goto(nextIndex);
            }
          })
          .catch((error) => {
            setCurrentSchema(createKitSchemas(intl)[0]);
            if (error?.response?.status === 400) {
              handle400Error(error.response.data.errors, actions.setErrors);
            }
          });
      } else if (stepper.current) {
        stepper.current.goto(nextIndex);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const step2 = useCallback(
    (
      nextIndex: number,
      kit: CreateKitModel,
      actions: FormikValues,
      closeModal: boolean
    ) => {
      const kitProducts: KitProductModel[] = kitHelpers.createStep2(
        kit.products
      );
      kitServices
        .productSelection(kit.id, kitProducts)
        .then((values) => {
          if (closeModal) {
            refetch();
            onHide();
            toast.success('Kit successfully updated');
          } else if (stepper.current) {
            stepper.current.goto(nextIndex);
            kit.kitProducts = values.data.kitProducts;
          }
        })
        .catch((error) => {
          setCurrentSchema(createKitSchemas(intl)[1]);
          if (error?.response?.status === 400) {
            handle400Error(error.response.data.errors, actions.setErrors);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const step3 = useCallback(
    (
      nextIndex: number,
      kit: CreateKitModel,
      actions: FormikValues,
      closeModal: boolean
    ) => {
      const body: any = kitHelpers.createFormDataFiles(kit.files);

      kitServices
        .productImages(kit.id, body)
        .then((values) => {
          if (closeModal) {
            refetch();
            onHide();
            toast.success('Kit successfully updated');
          } else if (stepper.current) {
            stepper.current.goto(nextIndex);
            kit.files = kitHelpers.processFiles(values.data.files);
          }
        })
        .catch((error) => {
          setCurrentSchema(createKitSchemas(intl)[2]);
          if (error?.response?.status === 400) {
            handle400Error(error.response.data.errors, actions.setErrors);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kitId]
  );

  const step4 = useCallback(
    (
      nextIndex: number,
      kit: CreateKitModel,
      actions: FormikValues,
      closeModal: boolean
    ) => {
      if (kit.availabilities.length > 0) {
        kitServices
          .kitAvailabilities(kit.id, kit.availabilities)
          .then((values: any) => {
            if (stepper.current) {
              refetch();
              onHide();
              toast.success('Kit successfully updated');
            }
          })
          .catch((error: any) => {
            setCurrentSchema(createKitSchemas(intl)[3]);
            if (error?.response?.status === 400) {
              handle400Error(error.response.data.errors, actions.setErrors);
              if (stepper.current) {
                stepper.current.goto(4);
              }
            }
          });
      } else if (stepper.current) {
        stepper.current.goto(nextIndex);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kitId]
  );

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef, show, loading, kit]);

  const stepperClick = (step: number) => {
    if (!stepper.current) {
      return;
    }
    const totatStepsNumber = stepper.current.totatStepsNumber;
    setLastStep(step === totatStepsNumber);
    setCurrentSchema(createKitSchemas(intl)[step - 1]);
    stepper.current.goto(step);
  };

  return (
    <>
      {!loading && kit && (
        <Modal
          show={show}
          onHide={onHide}
          backdrop='static'
          keyboard={false}
          size='xl'
        >
          <Modal.Header className='px-9 pt-7 card-rounded'>
            <Modal.Title className='m-0 fw-bolder fs-3'>Edit Kit</Modal.Title>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={onHide}
            >
              <i className='las la-times fs-2'></i>
            </div>
          </Modal.Header>
          <Modal.Body className='mt-1 px-6 py-3 position-relative z-index-1'>
            <div
              ref={stepperRef}
              className='stepper stepper-links d-flex flex-column pt-5'
              id='kt_edit_account_stepper'
            >
              <div className='stepper-nav mb-5'>
                <div
                  className='stepper-item current'
                  data-kt-stepper-element='nav'
                  data-kt-stepper-action='step'
                  onClick={() => stepperClick(1)}
                >
                  <h3 className='stepper-title'>Kit Information</h3>
                </div>

                <div
                  className='stepper-item'
                  data-kt-stepper-element='nav'
                  data-kt-stepper-action='step'
                  onClick={() => stepperClick(2)}
                >
                  <h3 className='stepper-title'>Product Selection</h3>
                </div>

                <div
                  className='stepper-item'
                  data-kt-stepper-element='nav'
                  data-kt-stepper-action='step'
                  onClick={() => stepperClick(3)}
                >
                  <h3 className='stepper-title'>Kit Images</h3>
                </div>

                <div
                  className='stepper-item'
                  data-kt-stepper-element='nav'
                  data-kt-stepper-action='step'
                  onClick={() => stepperClick(4)}
                >
                  <h3 className='stepper-title'>Availability</h3>
                </div>
              </div>

              <Formik
                validationSchema={currentSchema}
                initialValues={kit}
                onSubmit={(values, actions) => {
                  //nothing to do
                }}
              >
                {(props) => (
                  <Form id='kt_edit_account_form' className='px-10 pt-4 pb-10'>
                    <div className='current' data-kt-stepper-element='content'>
                      <KitInformationStep1 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      {!!kitId && <ProductSelectionStep2 kitId={kitId} />}
                    </div>

                    <div data-kt-stepper-element='content'>
                      <KitImagesStep3 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <KitAvailabilityStep4 kit={props.values} />
                    </div>

                    <div className='d-flex flex-stack pt-15'>
                      <div className='mr-2'>
                        <button
                          onClick={() => prevStep(props.values)}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <i className='fas fa-arrow-left fs-5 me-3'></i>
                          <span>Back</span>
                        </button>
                      </div>

                      <div>
                        {!lastStep && (
                          <button
                            className='btn btn-lg btn-light-primary m-2'
                            type='submit'
                            name='continue'
                            onClick={() => {
                              handleSubmitClick('continue', props);
                            }}
                          >
                            <span className='indicator-label'>
                              Save and Continue
                            </span>
                          </button>
                        )}
                        <button
                          className='btn btn-lg btn-primary'
                          type='submit'
                          name='close'
                          onClick={() => {
                            handleSubmitClick('close', props);
                          }}
                        >
                          <span className='indicator-label'>
                            Save and Close
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
