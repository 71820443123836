import { useIntl } from 'react-intl';
import accountsServices from '../../services/AccountsServices';
import { accountSchemaValidation } from '../../utils/accountShemaValidations';
import { createAccountinitialValues } from '../../utils/utils';
import { CustomFormModal } from '../../../../components/CustomFormModal';
import { accountHelpers } from '../../helpers/account';
import { AccountForm } from './AccountForm';
interface Props {
  refetch: () => void;
}
const AddButton = (props: any) => {
  return (
    <button
      onClick={props.onClick}
      type='button'
      className='btn btn-primary align-self-center'
      data-kt-menu-trigger='click'
      data-kt-menu-placement='bottom-end'
    >
      <i className='bi bi-plus fs-2'></i>
      Add Account
    </button>
  );
};

export function AccountCreate({ refetch }: Props) {
  // i18n
  const intl = useIntl();

  const onSubmit = (values: any) => {
    return new Promise<any>((resolve, reject) => {
      accountsServices
        .createAccount(values)
        .then((val) => {
          values.id = val.data.id;
          if (values.branding.files) {
            uploadImage(values);
          }
          resolve(val);
          refetch();
        })
        .catch((err) => reject(err));
    });
  };
  const uploadImage = (values: any) => {
    const body: any = accountHelpers.createFormDataFiles(values.branding.files);
    accountsServices.accountImages(values.id, body).then().catch();
  };
  const onClose = () => {};

  return (
    <CustomFormModal
      initialValues={createAccountinitialValues}
      onSubmit={onSubmit}
      onClose={onClose}
      successMessage={intl.formatMessage({ id: 'ACCOUNT.CREATED.SUCCESS' })}
      title='Add Account'
      buttonLabel='Add'
      submitButtonLabel='Add'
      schemaValidations={accountSchemaValidation}
      buttonNode={<AddButton />}
    >
      <AccountForm />
    </CustomFormModal>
  );
}
