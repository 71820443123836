import { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import { CustomBadgeFilter } from '../../../components/CustomBadgeFilter';
import { CustomFilter } from '../../../components/CustomFilter';
import { FilterBadge } from '../../../models/FilterBadge';
import moment from 'moment';
import { EntityFiltersModel } from '../../../models/EntityFiltersModel';
import { FilterCascadeEntitySelections } from '../../../components/FilterCascadeEntitySelection';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { shallowEqual, useSelector } from 'react-redux';
import { initialFilter } from '../utils/utils';
import { ValueLabelPair } from '../../../models/ValueLabelPair';
import { auditServices } from '../services/AuditServices';
import ReactSelect from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';
import { FormattedMessage } from 'react-intl';
interface PropsFilter {
  queryParams: any;
  setQueryParams: any;
}
export function AuditTrailsFilter({
  setQueryParams,
  queryParams,
}: PropsFilter) {
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  // Filter States
  const [dateFrom, setDateFrom] = useState<string | null>();
  const dateFromRef = useRef<any>(null);
  const [eventType, setEventType] = useState<string | null>();
  const [logType, setLogType] = useState<string | null>();

  const [dateTo, setDateTo] = useState<string | null>();
  const dateToRef = useRef<any>(null);
  const eventTypeRef = useRef<null | Select>(null);
  const logTypeRef = useRef<null | Select>(null);

  const [reset, setReset] = useState<boolean>(false);

  // Default Filters:
  const [shouldApplyFilter, setShouldApplyFilter] = useState<boolean>(false);

  // List Event Types
  const [eventTypes, setEventTypes] = useState<ValueLabelPair[]>([]);

  // List Log Type
  const [logTypes, setLogTypes] = useState<ValueLabelPair[]>([]);

  //Entity
  const [entityFilters, setEntityFilters] = useState<EntityFiltersModel>({
    accountId: queryParams.accountId ? queryParams.accountId : undefined,
    clientId: queryParams.clientId ? queryParams.clientId : undefined,
    franchiseId: queryParams.franchiseId ? queryParams.franchiseId : undefined,
    locationId: queryParams.locationId ? queryParams.locationId : undefined,
  });

  const [filterBadges, setFilteBadges] = useState<FilterBadge[]>([]);
  const [entityFilterBadges, setEntityFilteBadges] = useState<FilterBadge[]>(
    []
  );

  // Cal EventTye Service
  const eventTypeList = useCallback(() => {
    auditServices.eventTypeList().then((response) => {
      const options: Array<ValueLabelPair> = response.data.map((x) => {
        return { value: x, label: x };
      });
      setEventTypes(options);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    eventTypeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Cal LogType Service
  const logTypeList = useCallback(() => {
    auditServices.logTypeList().then((response) => {
      const options: Array<ValueLabelPair> = response.data.map((x) => {
        return { value: x, label: x };
      });
      setLogTypes(options);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    logTypeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateFrom = (e: any) => {
    if (e.target.value) {
      setDateFrom(e.target.value);
    } else {
      setDateFrom(undefined);
    }
  };

  const handleDateTo = (e: any) => {
    if (e.target.value) {
      setDateTo(e.target.value);
    } else {
      setDateTo(undefined);
    }
  };
  const handleEventType = (params: any) => {
    if (params) {
      setEventType(params.value);
    } else {
      setEventType(undefined);
    }
  };
  const handleLogType = (params: any) => {
    if (params) {
      setLogType(params.value);
    } else {
      setLogType(undefined);
    }
  };

  const resetFilters = () => {
    setReset(true);
  };

  const applyFilter = () => {
    const newQueryParams = { ...queryParams };
    newQueryParams.dateFrom = dateFrom;
    newQueryParams.dateTo = dateTo;
    newQueryParams.eventType = eventType;
    newQueryParams.logType = logType;
    newQueryParams.pageNumber = 1;
    newQueryParams.accountId = entityFilters?.accountId;
    newQueryParams.clientId = entityFilters?.clientId;
    newQueryParams.franchiseId = entityFilters?.franchiseId;
    newQueryParams.locationId = entityFilters?.locationId;
    setQueryParams(newQueryParams);
  };

  useEffect(() => {
    if (reset) {
      setDateFrom(undefined);
      setDateTo(undefined);
      eventTypeRef.current?.clearValue();
      logTypeRef.current?.clearValue();
      let newQueryParams = {
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };
      setQueryParams({ ...initialFilter, ...newQueryParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  /** START Filter Badges */
  // Get initial values from the url
  useEffect(() => {
    let clearFilters = [];

    if (queryParams.eventType) {
      setEventType(queryParams.eventType);
      updateBadge('eventType', queryParams.eventType, eventTypeRef);
    } else {
      clearFilters.push('eventType');
    }

    if (queryParams.logType) {
      setLogType(queryParams.logType);
      updateBadge('logType', queryParams.logType, logTypeRef);
    } else {
      clearFilters.push('logType');
    }

    if (!!queryParams.dateFrom) {
      setDateFrom(queryParams.dateFrom);
      updateBadge(
        'dateFrom',
        'From: ' + moment(queryParams.dateFrom).format('MM/DD/YYYY'),
        dateFromRef
      );
    } else {
      clearFilters.push('dateFrom');
    }

    if (!!queryParams.dateTo) {
      setDateTo(queryParams.dateTo);
      updateBadge(
        'dateTo',
        'To: ' + moment(queryParams.dateTo).format('MM/DD/YYYY'),
        dateToRef
      );
    } else {
      clearFilters.push('dateTo');
    }
    if (entityFilterBadges.length > 0) {
      entityFilterBadges.forEach((item: FilterBadge) => {
        updateBadge(item.key, item.value, item.ref);
      });
    }
    if (!queryParams.accountId) clearFilters.push('accountId');
    if (!queryParams.clientId) clearFilters.push('clientId');
    if (!queryParams.franchiseId) clearFilters.push('franchiseId');
    if (!queryParams.locationId) clearFilters.push('locationId');

    if (clearFilters.length > 0) {
      clearMultipleFilter(clearFilters);
    }
    // Get initial values from the url
    const newQueryParams = {
      ...entityFilters,
      accountId: queryParams.accountId ? queryParams.accountId : undefined,
      clientId: queryParams.clientId ? queryParams.clientId : undefined,
      franchiseId: queryParams.franchiseId
        ? queryParams.franchiseId
        : undefined,
      locationId: queryParams.locationId ? queryParams.locationId : undefined,
    };
    setEntityFilters(newQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);
  // Get initial values from the url
  useEffect(() => {
    if (shouldApplyFilter) {
      let clearFilters = [];

      if (queryParams.eventType) {
        setEventType(queryParams.eventType);
        updateBadge('eventType', queryParams.eventType, eventTypeRef);
      } else {
        clearFilters.push('eventType');
      }

      if (queryParams.logType) {
        setLogType(queryParams.logType);
        updateBadge('logType', queryParams.logType, logTypeRef);
      } else {
        clearFilters.push('logType');
      }

      if (!!queryParams.dateFrom) {
        setDateFrom(queryParams.dateFrom);
        updateBadge(
          'dateFrom',
          'From: ' + moment(queryParams.dateFrom).format('MM/DD/YYYY'),
          dateFromRef
        );
      } else {
        clearFilters.push('dateFrom');
      }

      if (!!queryParams.dateTo) {
        setDateTo(queryParams.dateTo);
        updateBadge(
          'dateTo',
          'To: ' + moment(queryParams.dateTo).format('MM/DD/YYYY'),
          dateToRef
        );
      } else {
        clearFilters.push('dateTo');
      }
      if (entityFilterBadges.length > 0) {
        entityFilterBadges.forEach((item: FilterBadge) => {
          updateBadge(item.key, item.value, item.ref);
        });
      }
      if (!queryParams.accountId) clearFilters.push('accountId');
      if (!queryParams.clientId) clearFilters.push('clientId');
      if (!queryParams.franchiseId) clearFilters.push('franchiseId');
      if (!queryParams.locationId) clearFilters.push('locationId');

      if (clearFilters.length > 0) {
        clearMultipleFilter(clearFilters);
      }
      // Get initial values from the url
      const newQueryParams = {
        ...entityFilters,
        accountId: queryParams.accountId ? queryParams.accountId : undefined,
        clientId: queryParams.clientId ? queryParams.clientId : undefined,
        franchiseId: queryParams.franchiseId
          ? queryParams.franchiseId
          : undefined,
        locationId: queryParams.locationId ? queryParams.locationId : undefined,
      };
      setEntityFilters(newQueryParams);
      setShouldApplyFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldApplyFilter, entityFilterBadges]);

  const clearMultipleFilter = (filters: Array<string>) => {
    let badges = filterBadges;
    filters.forEach((element) => {
      badges = badges.filter((item: FilterBadge) => item.key !== element);
    });
    setFilteBadges(badges);
  };
  const clearFilter = (ref: any, keyParam: string) => {
    if (
      keyParam === 'accountId' ||
      keyParam === 'clientId' ||
      keyParam === 'franchiseId'
    ) {
      clearMultipleFilter([
        'accountId',
        'clientId',
        'franchiseId',
        'locationId',
      ]);
      setEntityFilteBadges([]);

      let params = { ...queryParams };
      params['accountId'] = userGroupRelations.accountId
        ? userGroupRelations.accountId
        : undefined;
      params['clientId'] = userGroupRelations.clientId
        ? userGroupRelations.clientId
        : undefined;
      params['franchiseId'] = userGroupRelations.franchiseId
        ? userGroupRelations.franchiseId
        : undefined;
      params['locationId'] = userGroupRelations.locationId
        ? userGroupRelations.locationId
        : undefined;
      // When removing a badge you should be redirected to page 1.
      params['pageNumber'] = 1;
      setQueryParams(params);

      filterBadges
        .find((element) => element.key === 'accountId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'clientId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'franchiseId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'locationId')
        ?.ref.current?.clearValue();
    } else if (ref) {
      ref.current?.clearValue();
      let params = { ...queryParams };
      params[keyParam] = undefined;
      // When removing a badge you should be redirected to page 1.
      params['pageNumber'] = 1;
      setQueryParams(params);
    }
    setFilteBadges(
      filterBadges.filter((item: FilterBadge) => item.key !== keyParam)
    );
  };
  const updateBadge = (key: string, value: string, ref: any) => {
    let badges = filterBadges;
    const finded = badges?.find((item: FilterBadge) => item.key === key);
    if (finded && finded !== undefined) {
      badges.splice(badges.indexOf(finded), 1, {
        key: key,
        value: value,
        ref: ref,
      });
    } else {
      badges.push({
        key: key,
        value: value,
        ref: ref,
      });
    }
    setFilteBadges(badges);
  };

  /** END Filter Badges */
  const showEntitiesFilter = !userGroupRelations.locationId;
  return (
    <>
      {filterBadges?.map((item: FilterBadge) => {
        if (item.value && item.value !== '')
          return (
            <CustomBadgeFilter
              text={item.value}
              badgeKey={item.key}
              key={item.key}
              clearFilter={() => {
                if (item.ref) clearFilter(item.ref, item.key);
              }}
            ></CustomBadgeFilter>
          );
        else return <></>;
      })}
      <CustomFilter
        applyFilters={applyFilter}
        resetFilters={resetFilters}
        size={showEntitiesFilter ? 'lg' : 'md'}
      >
        <div className='row'>
          <div className='col-md-6'>
            <FilterCascadeEntitySelections
              setReset={setReset}
              reset={reset}
              entityFilters={entityFilters}
              setEntityFilters={setEntityFilters}
              setEntityListBadge={setEntityFilteBadges}
              entityFilterBadges={entityFilterBadges}
              setShowFilterBadges={setShouldApplyFilter}
            />
          </div>
          <div className={showEntitiesFilter ? 'col-md-6' : 'col-md-12'}>
            <div className='mb-2' onClick={(e) => e.stopPropagation()}>
              <label className='pt-2 form-label fw-bold'>Date From</label>
              <input
                ref={dateFromRef}
                autoComplete='none'
                type='date'
                className='form-control form-control-solid w-250px ps-15'
                onChange={(e) => handleDateFrom(e)}
                defaultValue={dateFrom ? dateFrom : ''}
              />
              <label className='pt-2 form-label fw-bold'>Date To</label>
              <input
                ref={dateToRef}
                autoComplete='none'
                type='date'
                className='form-control form-control-solid w-250px ps-15'
                onChange={(e) => handleDateTo(e)}
                defaultValue={dateTo ? dateTo : ''}
              />
            </div>
            <div className='mb-2 pt-2' onClick={(e) => e.stopPropagation()}>
              <label className='form-label fw-bold'>Event Type</label>
              <ReactSelect
                placeholder={<FormattedMessage id='COMMON.ALL' />}
                isClearable
                name='eventType'
                id='eventType'
                onChange={handleEventType}
                options={eventTypes}
                value={
                  eventTypes
                    ? eventTypes.filter((x: any) => x.value === eventType)[0]
                    : null
                }
                ref={eventTypeRef}
              />
            </div>
            <div className='mb-2 pt-2' onClick={(e) => e.stopPropagation()}>
              <label className='form-label fw-bold'>Log Type</label>
              <ReactSelect
                placeholder={<FormattedMessage id='COMMON.ALL' />}
                isClearable
                name='logType'
                id='logType'
                onChange={handleLogType}
                options={logTypes}
                value={
                  logTypes
                    ? logTypes.filter((x: any) => x.value === logType)[0]
                    : null
                }
                ref={logTypeRef}
              />
            </div>
          </div>
        </div>
      </CustomFilter>
    </>
  );
}
