import { BooleanParam, NumberParam, StringParam } from 'use-query-params';
import { WarehouseAddInventory } from '../models/WarehouseAddInventory';
import { WarehouseModel } from '../models/WarehouseModel';

export const initialFilterWarehouses = {
  filter: undefined,
  sortDirection: 1, // asc => 1||desc => -1
  sortColumn: 'code',
  pageNumber: 1,
  pageSize: 10,
  active: 1,
};

export type WarehouseQueryFilterKeys = 'filter' | 'active' | 'state';

export const queryParamsWarehouseConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  filter: StringParam,
  active: BooleanParam,
  state: StringParam,
};

export const warehouseInitialValues: WarehouseModel = {
  id: 0,
  name: '',
  description: '',
  city: '',
  state: '',
  addressLine1: '',
  addressLine2: '',
  postalCode: '',
  isActive: true,
};

export const warehouseInventoryInitialValues: WarehouseAddInventory = {
  productId: 0,
  quantity: 0,
  startingCardNumber: '',
  shippingUnitSize: '',
  runPrefix: '',
  startingRunNumber: 1,
};

export const initialFilterInventories = {
  sortDirection: 1,
  sortColumn: 'productCode',
  pageNumber: 1,
  pageSize: 10,
  from: undefined,
  to: undefined,
  active: 1,
  filter: undefined,
  accountId: undefined,
  clientId: undefined,
  franchiseId: undefined,
  locationId: undefined,
  quantityDays: 90,
  productType: undefined,
  inventoryLevel: undefined,
};

// search params config
export const queryParamsConfigInventories = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  active: BooleanParam,
  from: StringParam,
  to: StringParam,
  filter: StringParam,
  accountId: NumberParam,
  clientId: NumberParam,
  franchiseId: NumberParam,
  locationId: NumberParam,
  quantityDays: NumberParam,
  productType: StringParam,
  inventoryLevel: StringParam,
};

export const initialFilterShippingUnits = {
  sortDirection: -1,
  sortColumn: 'id',
  pageNumber: 1,
  pageSize: 10,
};

// search params config
export const queryParamsConfigShippingUnits = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
};

export const initialFilterStockAdjustment = {
  sortDirection: 1,
  sortColumn: 'productCode',
  pageNumber: 1,
  pageSize: 10,
  filter: undefined,
};

// search params config
export const queryParamsConfigStockAdjustment = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  filter: StringParam,
};

export const warehouseAdjustStockInitialValues = {
  productId: 0,
  warehouseId: 0,
  currentLevel: 0,
  newLevel: 0,
  reason: '',
  removeAll: false,
  shippingUnitsToRemove: [] as number[],
  productType: '',
};
