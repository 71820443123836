import { Modal } from 'react-bootstrap-v5';
interface Props {
  show: boolean;
  id: number;
  newTrackingNumber: string;
  oldTrackingNumber: string;
  onHide: () => void;
  updateTrackingNumber: (id: number, trackingNumber: string) => any;
}

export function UpdateTrakingNbrModal({
  show,
  id,
  newTrackingNumber,
  oldTrackingNumber,
  onHide,
  updateTrackingNumber,
}: Props) {
  return (
    <Modal show={show} onHide={onHide} backdrop='static' keyboard={false}>
      <Modal.Body className='mt-1 px-8 py-8 position-relative z-index-1'>
        <div className='d-flex align-items-center'>
          <i className={`las la-exclamation-circle fs-5x text-warning`}></i>
          <div className='flex-grow-3 ms-4'>
            <div className='fw-bold text-gray-700 fs-6'>
              Are you sure to change the tracking number from{' '}
              <span className='fw-bolder'>{oldTrackingNumber}</span> to{' '}
              <span className='fw-bolder'>{newTrackingNumber}</span>?
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='border-0'>
        <div className='text-end'>
          <button
            type='button'
            id='kt_modal_new_card_cancel'
            className='btn btn-danger me-3'
            onClick={() => onHide()}
          >
            No
          </button>
          <button
            type='submit'
            id='kt_modal_new_card_submit'
            className='btn btn-primary'
            onClick={() => updateTrackingNumber(id, newTrackingNumber)}
          >
            Yes
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
