import { NumberParam, StringParam } from 'serialize-query-params';
import { GroupModel, GroupPermissionsFlagsDto } from '../models/GroupModel';
import { UserCreateModel } from '../models/UserCreateModel';
import { UserEditGroup } from '../models/UserEditGroup';
import { UserEditModel } from '../models/UserEditModel';
import { UserGroupsRelations } from '../models/UserGroupsRelations';

export const groupPermissionsInitialValues: GroupPermissionsFlagsDto = {
  create: false,
  update: false,
  view: false,
  list: false,
};
export const createGroupInitialValues: GroupModel = {
  id: 0,
  name: '',
  checkAll: false,
  accountPermission: groupPermissionsInitialValues,
  clientPermission: groupPermissionsInitialValues,
  franchisePermission: groupPermissionsInitialValues,
  locationPermission: groupPermissionsInitialValues,
  productPermission: groupPermissionsInitialValues,
  kitPermission: groupPermissionsInitialValues,
  orderPermission: groupPermissionsInitialValues,
  inventoryPermission: groupPermissionsInitialValues,
  userGroupsPermission: groupPermissionsInitialValues,
  warehousePermission: groupPermissionsInitialValues,
  warehouseInventoryPermission: groupPermissionsInitialValues,
  userGroupRelations: [],
};
export const editGroupInitialValues: GroupModel = {
  id: 0,
  name: '',
  checkAll: false,
  accountPermission: groupPermissionsInitialValues,
  clientPermission: groupPermissionsInitialValues,
  franchisePermission: groupPermissionsInitialValues,
  locationPermission: groupPermissionsInitialValues,
  productPermission: groupPermissionsInitialValues,
  kitPermission: groupPermissionsInitialValues,
  orderPermission: groupPermissionsInitialValues,
  inventoryPermission: groupPermissionsInitialValues,
  userGroupsPermission: groupPermissionsInitialValues,
  warehousePermission: groupPermissionsInitialValues,
  warehouseInventoryPermission: groupPermissionsInitialValues,
  reportPermission: groupPermissionsInitialValues,

  userGroupRelations: [],
};

// Initial Values
export const userInitialValues: UserEditModel = {
  name: '',
  email: '',
};

export const createUserinitialValues: UserCreateModel = {
  name: '',
  email: '',
  capID: '',
  capOrgID: '',
  capUserName: '',
};

export const userEditInitialValues: UserEditGroup = {
  id: 0,
  name: '',
  email: '',
  capID: 0,
  capOrgID: 0,
  capUserName: '',
  groups: [],
};
// Initial queryParams values
export const initialFilter = {
  filter: '',
  sortDirection: 1,
  sortColumn: 'name',
  pageNumber: 1,
  pageSize: 10,
};

// search params config
export const queryParamsConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  filter: StringParam,
};

export const initialFilterGroups = {
  filter: '',
  sortDirection: 1,
  sortColumn: 'name',
  pageNumber: 1,
  pageSize: 10,
};

export const queryParamsGroupConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  name: StringParam,
  // TODO: add extra filters
};

export type GroupQueryFilterKeys = 'name';
export type AccountQueryFilterKeys = 'filter';

export const userEditGroupInitialValues: UserGroupsRelations = {
  id: 0,
  groups: [],
};
