import { Fragment, useState } from 'react';
import { FormikErrors, useField, useFormikContext } from 'formik';
import Select from 'react-select';
import { ValueLabelPair } from '../models/ValueLabelPair';

const getFieldCSSClasses = (
  touched: boolean,
  errors:
    | string
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[]
    | undefined,
  showFeedback: boolean
) => {
  const classes = [];
  if (errors && !showFeedback && touched) {
    classes.push('is-invalid');
  }
  if (errors && showFeedback && touched) {
    classes.push('is-invalid');
  }
  if (!errors && showFeedback && touched) {
    classes.push('is-valid');
  }
  return classes.join(' ');
};

type Props = JSX.IntrinsicElements['select'] & {
  label?: string;
  name: string;
  isRequired?: boolean;
  errorServer?: string[];
  options?: Array<ValueLabelPair>;
  onChangeOption?: any;
  isDisabled?: boolean;
  isClearable?: boolean;
};
export const selectStyles = {
  menu: (styles: any) => ({ ...styles, zIndex: 999 }),
};
export const CustomSelect = ({
  label,
  name,
  isRequired,
  errorServer,
  options,
  placeholder,
  isDisabled,
  isClearable,
  ...props
}: Props): JSX.Element => {
  const [field, meta] = useField(name);
  const { errors } = useFormikContext<any>();
  const [didFocus, setDidFocus] = useState(false);
  const handleFocus = () => {
    setDidFocus(true);
  };
  const required = isRequired ? 'required' : '';
  const errorValue = errors[field.name] ? errors[field.name] : meta.error;

  const handleChange = (option: ValueLabelPair) => {
    const value = option ? option.value : null;
    const e: React.ChangeEvent<any> = {
      target: {
        id: field.name,
        name: field.name,
        value,
      },
    } as any;
    field.onChange(e);
    /*
      Handle change in X property of the form: Example,
      when the objectType changes, the service must be called to show this data in another select. 
      There is dependency between form properties
      ^ This only works if you pass the props.onChangeOption function. 
    */
    props.onChangeOption && props.onChangeOption(field.name, option);
  };

  const getValue = () => {
    if (!field.value) {
      return '' as any;
    } else if (options) {
      return options.find((option) => option.value === field.value);
    } else {
      return '' as any;
    }
  };

  return (
    <Fragment>
      {label && <label className={`${required} form-label`}>{label}</label>}
      <Select
        placeholder={placeholder || 'Select ...'}
        className={getFieldCSSClasses(meta.touched, errorValue, didFocus)}
        {...field}
        options={options ? options : []}
        onChange={handleChange}
        onBlur={field.onBlur}
        value={getValue()}
        isDisabled={isDisabled}
        styles={selectStyles}
        isClearable={isClearable}
        onFocus={handleFocus}
      />
      {meta.touched && (meta.error || errors[field.name]) && (
        <div className='invalid-feedback'>{errorValue}</div>
      )}
    </Fragment>
  );
};
