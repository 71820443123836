import { Fragment, useRef, useState } from 'react';
import { FormikErrors, useField, useFormikContext } from 'formik';

const getFieldCSSClasses = (
  touched: boolean,
  errors:
    | string
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[]
    | undefined,
  showFeedback: boolean
) => {
  const classes = ['form-control'];
  if (errors && !showFeedback && touched) {
    classes.push('is-invalid');
  }
  if (errors && showFeedback && touched) {
    classes.push('is-invalid');
  }
  if (!errors && showFeedback && touched) {
    classes.push('is-valid');
  }
  return classes.join(' ');
};

type Props = JSX.IntrinsicElements['textarea'] & {
  label: string;
  name: string;
  isRequired?: boolean;
  errorServer?: string[];
  autoFocus?: boolean;
};

export const CustomTextArea = ({
  label,
  name,
  isRequired,
  errorServer,
  autoFocus,
  ...props
}: Props): JSX.Element => {
  const [field, meta] = useField(name);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  if (field.value === null) field.value = '';
  const { errors } = useFormikContext<any>();
  const [didFocus, setDidFocus] = useState(false);
  const handleFocus = () => setDidFocus(true);
  const required = isRequired ? 'required' : '';
  const errorValue = errors[field.name] ? errors[field.name] : meta.error;

  setTimeout(() => {
    if (!!autoFocus && inputRef !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, 200);
  return (
    <Fragment>
      {label && (
        <label className={`${required} form-label fw-bolder`}>{label}</label>
      )}
      <textarea
        ref={inputRef}
        className={getFieldCSSClasses(meta.touched, errorValue, didFocus)}
        {...field}
        {...props}
        onFocus={handleFocus}
      ></textarea>
      {meta.touched && (meta.error || errors[field.name]) && (
        <div className='invalid-feedback'>{errorValue}</div>
      )}
    </Fragment>
  );
};
