import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { Routes } from './routing/Routes';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from 'oidc-react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { actions } from './modules/auth';
import { TemplateAccountColors } from './components/TemplateAccountColors';
import { BrandingModel } from './models/BrandingModel';
import { RootState } from './redux';

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  const dispatch = useDispatch();

  const oidcConfig = {
    onSignIn: async (user: any) => {
      dispatch(actions.login(user.access_token, user.profile.sub));
    },
    onSignOut: async () => {
      dispatch(actions.logout());
    },
    onBeforeSignIn: async () => {},
    autoSignIn: false,
    authority: process.env.REACT_APP_OID_AUTHORITY,
    clientId: process.env.REACT_APP_OID_CLIENTID,
    responseType: process.env.REACT_APP_OID_RESPONSETYPE,
    redirectUri: process.env.REACT_APP_OID_REDIRECTURI,
    automaticSilentRenew: true,
    silentRedirectUri: process.env.REACT_APP_OID_REDIRECTURI,
  };
  console.log(oidcConfig);

  const branding = useSelector<RootState, BrandingModel>(
    ({ auth }: any) => auth.branding,
    shallowEqual
  );

  useEffect(() => {
    console.log(branding);
  }, [branding]);

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <BrowserRouter basename={basename}>
          <I18nProvider>
            <LayoutProvider>
              <TemplateAccountColors branding={branding}>
                <AuthProvider {...oidcConfig}>
                  <Routes />
                </AuthProvider>
              </TemplateAccountColors>
            </LayoutProvider>
          </I18nProvider>
        </BrowserRouter>
      </Suspense>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
    </>
  );
};

export { App };
