import { ErrorMessage, useFormikContext } from 'formik';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { CreateKitModel } from '../models/CreateKitModel';
import { KitProductItem } from '../utils/utils';
import { KitCascadeProductSelections } from './KitCascadeProductSelections';

interface ProductSelectionStep2Props {
  kitId: number;
}

export function ProductSelectionStep2({ kitId }: ProductSelectionStep2Props) {
  const { setFieldValue, values } = useFormikContext<CreateKitModel>();
  const [idsCompatibles, setIdsCompatibles] = useState<number[]>([]);

  const addProduct = async (item: KitProductItem) => {
    const existProduct = values.products.find(
      (p: KitProductItem) => p.productId === item.productId
    );
    if (existProduct) {
      toast.warning('The selected product is already assigned to this Kit');
      return;
    }
    setFieldValue('products', [...values.products, item]);
    setIdsCompatibles([...idsCompatibles, item.productId]);
  };

  const handleRemoveItem = async (productId: number) => {
    if (values.products.length > 0) {
      setFieldValue(
        'products',
        values.products.filter((item) => item.productId !== productId)
      );
    }
    setIdsCompatibles([...idsCompatibles.filter((id) => id !== productId)]);
  };

  return (
    <div className='container-fluid mx-15'>
      <div className='card-body p-3'>
        <div className='row'>
          <div className='fw-bolder fs-2 mb-7'>Product Selection</div>
        </div>
        <div className='fv-row'>
          <div className='col-12'>
            <KitCascadeProductSelections
              addProduct={addProduct}
              kitId={kitId}
              idsCompatibles={idsCompatibles}
            />
          </div>
        </div>
        {values.products.length > 0 && (
          <div className='table-responsive mt-10'>
            <table className='fs-6 gy-5 bg-gray-50 w-100'>
              <thead className='bg-gray-50 w-100'>
                <tr>
                  <th>Product</th>
                  <th>Required</th>
                  <th>Primary</th>
                  <th>SU</th>
                  <th className='w-50px'></th>
                </tr>
              </thead>

              <tbody>
                {values.products.map((x: any, index: number) => {
                  return (
                    <tr key={`availabilities.${index}`}>
                      <td>{x.code ? x.code : '-'}</td>
                      <td>{x.isRequired ? 'Yes' : 'No'}</td>
                      <td>{x.isPrimary ? 'Yes' : 'No'}</td>
                      <td>{x.shippingUnitSize}</td>

                      <td>
                        <button
                          onClick={() => {
                            handleRemoveItem(x.productId);
                          }}
                          type='button'
                          id='buton-delete'
                          className='btn btn-active-danger p-3'
                        >
                          <i className='las la-times fs-2'></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        <div className='row mt-5 ms-0'>
          <div className='col-12'>
            <ErrorMessage name='products'>
              {(msg) => <div className='text-danger'>{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
      </div>
    </div>
  );
}
