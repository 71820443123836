/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AsideMenuItem } from './AsideMenuItem';
import { shallowEqual, useSelector } from 'react-redux';
import { GroupModel } from '../../../../app/modules/users/models/GroupModel';
import { RootState } from '../../../../app/redux';
import { UserGroupRelationModel } from '../../../../app/modules/auth/models/UserGroupRelationModel';
import { ActiveWarehouseModel } from '../../../../app/modules/warehouses/models/ActiveWarehouseModel';
import warehousesServices from '../../../../app/modules/warehouses/services/WarehousesServices';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';

const getMenuEntityValues = (
  ugr: UserGroupRelationModel,
  intl: any
): { title: string; to: string; level: number } | undefined => {
  if (!ugr) {
    return;
  }

  if (ugr.locationId) {
    return {
      to: `/accounts/${ugr.accountId}/clients/${ugr.clientId}/franchises/${ugr.franchiseId}/locations/${ugr.locationId}`,
      title: 'My Location Details',
      level: 0,
    };
  }
  if (ugr.franchiseId) {
    return {
      to: `/accounts/${ugr.accountId}/clients/${ugr.clientId}/franchises/${ugr.franchiseId}/locations`,
      title: 'My Franchise Details',
      level: 1,
    };
  }

  if (ugr.clientId) {
    return {
      to: `/accounts/${ugr.accountId}/clients/${ugr.clientId}/franchises`,
      title: 'My Client Details',
      level: 2,
    };
  }
  if (ugr.accountId) {
    return {
      to: `/accounts/${ugr.accountId}/clients`,
      title: 'My Account Details',
      level: 3,
    };
  }

  return {
    to: '/accounts',
    title: intl.formatMessage({ id: 'MENU.ENTITIES.ACCOUNTS' }),
    level: 4,
  };
};

export function AsideMenuMain() {
  const intl = useIntl();
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  const entityItemValues = getMenuEntityValues(userGroupRelations, intl);
  const [activeWarehouse, setActiveWarehouse] =
    useState<ActiveWarehouseModel | null>(null);

  useEffect(() => {
    if (group && group.warehousePermission?.view && !activeWarehouse) {
      findActiveWarehouse();
    }
  });

  const findActiveWarehouse = function () {
    warehousesServices.getActiveWarehouse().then((response) => {
      setActiveWarehouse(response.data);
    });
  };
  return (
    <>
      <AsideMenuItem
        to='/home'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({ id: 'MENU.HOME' })}
        fontIcon='fas fa-home'
        typeIcon='svg'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Modules
          </span>
        </div>
      </div>
      {entityItemValues && (
        <AsideMenuItemWithSub
          to='/entities'
          title='Client Management'
          fontIcon='fas fa-project-diagram'
          typeIcon='font'
        >
          {entityItemValues && (
            <AsideMenuItem
              to={entityItemValues.to}
              hasBullet={true}
              title={entityItemValues.title}
            ></AsideMenuItem>
          )}
          {entityItemValues && entityItemValues.level >= 3 && (
            <AsideMenuItem
              to='/entities/clients'
              title='Clients'
              hasBullet={true}
            ></AsideMenuItem>
          )}
          {entityItemValues && entityItemValues.level >= 2 && (
            <AsideMenuItem
              to='/entities/franchises'
              title='Franchises'
              hasBullet={true}
            ></AsideMenuItem>
          )}
          {entityItemValues && entityItemValues.level >= 1 && (
            <AsideMenuItem
              to='/entities/locations'
              title='Locations'
              hasBullet={true}
            ></AsideMenuItem>
          )}
        </AsideMenuItemWithSub>
      )}
      {group && group.orderPermission?.list && (
        <AsideMenuItem
          to='/orders/list'
          title='Orders'
          fontIcon='fas fa-shopping-cart'
          typeIcon='font'
        ></AsideMenuItem>
      )}
      {group && group.fulfillmentPermission?.list && (
        <AsideMenuItem
          to={`/fulfillments`}
          title='Fulfillment'
          fontIcon='fas fa-dolly-flatbed'
          typeIcon='font'
        ></AsideMenuItem>
      )}
      {group && group.productPermission?.list && (
        <AsideMenuItem
          to='/products'
          title='Products'
          fontIcon='fas fa-store'
          typeIcon='font'
        ></AsideMenuItem>
      )}
      {group && group.kitPermission?.list && (
        <AsideMenuItem
          to='/kits'
          title='Kits'
          fontIcon='fas fa-box-open'
          typeIcon='font'
        ></AsideMenuItem>
      )}
      <AsideMenuItemWithSub
        to='/warehouses'
        title='Inventory'
        fontIcon='fas fa-warehouse'
        typeIcon='font'
      >
        {group &&
          group.warehousePermission?.view &&
          activeWarehouse &&
          !!activeWarehouse.warehouseId && (
            <AsideMenuItem
              to={`/warehouses/${activeWarehouse.warehouseId}`}
              title={activeWarehouse.warehouseName}
              hasBullet={true}
            ></AsideMenuItem>
          )}
        {entityItemValues && (
          <AsideMenuItem
            to='/warehouses/inventory'
            title='Location Inventory'
            hasBullet={true}
          ></AsideMenuItem>
        )}
      </AsideMenuItemWithSub>
      {group &&
        group.warehousePermission?.list &&
        ((activeWarehouse && activeWarehouse.warehouseId === 0) ||
          !group.warehousePermission?.view) && (
          <AsideMenuItem
            to='/warehouses'
            title='Warehouses'
            fontIcon='fas fa-warehouse'
            typeIcon='font'
          ></AsideMenuItem>
        )}
      {group && group.warehouseInventoryPermission?.list && (
        <AsideMenuItem
          to={`/mfData`}
          title='MF Data'
          fontIcon='fas fa-file-invoice'
          typeIcon='font'
        ></AsideMenuItem>
      )}
      {group && group.userGroupsPermission?.list && (
        <AsideMenuItem
          to='/users/list'
          title={intl.formatMessage({ id: 'MENU.USERS' })}
          fontIcon='fas fa-users'
          icon='/media/icons/duotune/communication/com005.svg'
          typeIcon='font'
        ></AsideMenuItem>
      )}
      {group && group.userGroupsPermission?.list && (
        <AsideMenuItem
          to='/groups'
          title='Groups'
          fontIcon='fas fa-user-tag'
          typeIcon='font'
        ></AsideMenuItem>
      )}

      <AsideMenuItemWithSub
        to='/reports'
        title='Reports'
        fontIcon='fas fa-chart-line'
        typeIcon='font'
      >
        <AsideMenuItem
          to='/reports/low-level-product'
          hasBullet={true}
          title='Low Level Product'
        ></AsideMenuItem>

        <AsideMenuItem
          to='/reports/backordered-product'
          hasBullet={true}
          title='Backordered Product'
        ></AsideMenuItem>
        <AsideMenuItem
          hasBullet={true}
          to='/reports/standar-orders'
          title='Standard Orders'
        ></AsideMenuItem>
        <AsideMenuItem
          hasBullet={true}
          to='/reports/product-order-history'
          title='Product Order History'
        ></AsideMenuItem>
        <AsideMenuItem
          hasBullet={true}
          to='/reports/product-usage'
          title='Product Usage'
        ></AsideMenuItem>
      </AsideMenuItemWithSub>

      {group && (
        <AsideMenuItem
          to='/audit/list'
          title='Audit'
          fontIcon='fas fa-book'
          typeIcon='font'
        ></AsideMenuItem>
      )}
    </>
  );
}
