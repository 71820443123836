import warehouseServices from '../services/WarehousesServices';
import { WarehouseModel } from '../models/WarehouseModel';
//Modals imports
import { Formik, FormikHelpers, Form } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { ActionsComponent } from '../../../components/ActionsComponent';
import { toast } from 'react-toastify';
import { handle400Error } from '../../../utils/handle400Error';
import { useIntl } from 'react-intl';
import { warehouseInventoryInitialValues } from '../utils/utils';
import { WarehouseAddInventory } from '../models/WarehouseAddInventory';
import { warehouseInventorySchemaValidation } from '../utils/warehouseInventorySchemaValidation';
import { WarehouseInventoryForm } from './WarehouseInventoryForm';

interface Props {
  refetch: () => void;
  show: boolean;
  onHide: () => void;
  warehouse: WarehouseModel;
}

export function WarehouseCreateInventory({
  refetch,
  show,
  onHide,
  warehouse,
}: Props) {
  const intl = useIntl();
  const createWarehouseInventory = (
    values: WarehouseAddInventory,
    actions: FormikHelpers<WarehouseAddInventory>
  ) => {
    const params = {
      productId: values.productId,
      quantity: values.quantity,
      startingCardNumber:
        values.productType === 'Card' ? values.startingCardNumber : '',
      runPrefix: values.runPrefix,
      startingRunNumber: values.startingRunNumber,
    };
    warehouseServices
      .addWarehouseInventory(warehouse.id, params)
      .then(() => {
        toast.success('Warehouse Inventory added successfully.');
        onHide();
        refetch();
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          handle400Error(error.response.data.errors, actions.setErrors);
        }
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Formik
          enableReinitialize
          initialValues={warehouseInventoryInitialValues}
          validationSchema={warehouseInventorySchemaValidation(intl)}
          onSubmit={(values, actions) => {
            createWarehouseInventory(values, actions);
            actions.setSubmitting(false);
          }}
        >
          <Form>
            <Modal.Header className='px-9 pt-7 card-rounded'>
              <Modal.Title className='m-0 fw-bolder fs-3'>
                Add Warehouse Inventory
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='container-fluid mt-1 px-10 py-8 position-relative z-index-1'>
              <WarehouseInventoryForm />
            </Modal.Body>
            <Modal.Footer className='px-9 card-rounded  w-100'>
              <ActionsComponent
                labelSubmit='Add'
                showCancelButton={true}
                onCancel={onHide}
              />
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
