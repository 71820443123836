import locationServices from '../../services/LocationsServices';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { CustomInput } from '../../../../components/CustomInput';
import { CountryOptionsModel } from '../../../../models/CountryOptionsModel';
import { CustomSelect } from '../../../../components/CustomSelect';
import { useFormikContext } from 'formik';
import { LocationAddress } from '../../models/LocationModel';

export function AddressForm() {
  const [countries, setCountries] = useState<CountryOptionsModel[]>([]);
  const { setFieldValue, values } = useFormikContext<LocationAddress>();

  const countryList = useCallback(() => {
    locationServices.getCountries().then((values) => {
      const body = values.data;
      const countryOptions: CountryOptionsModel[] = body.map((x) => {
        return { value: x.countryName, label: x.countryName, ...x };
      });
      setCountries(countryOptions);
    });
  }, []);
  useEffect(() => {
    countryList();
  }, [countryList]);

  const handleCountryChange = (name: string, option: CountryOptionsModel) => {
    setFieldValue('addresses.countryCode', option.alpha3);
  };
  useEffect(() => {
    if (values.name) {
      setFieldValue('addresses.name', values.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  return (
    <Fragment>
      <div className='fv-row pt-4'>
        <label className='form-label mb-2'>Address Information</label>
      </div>
      <div className='row pt-4'>
        <div className='col-4'>
          <CustomInput
            isRequired={true}
            id='addresses.addressLine1'
            type='text'
            name='addresses.addressLine1'
            label='Address Line 1'
          />
        </div>
        <div className='col-4'>
          <CustomInput
            id='addresses.addressLine2'
            type='text'
            name='addresses.addressLine2'
            label='Address Line 2'
          />
        </div>
        <div className='col-4'>
          <CustomInput
            id='addresses.addressLine3'
            type='text'
            name='addresses.addressLine3'
            label='Address Line 3'
          />
        </div>
      </div>
      <div className='row pt-4'>
        <div className='col-4'>
          <CustomSelect
            isRequired={true}
            id='addresses.country'
            options={countries}
            name='addresses.country'
            label='Country'
            placeholder='Select Country'
            onChangeOption={handleCountryChange}
          />
        </div>
        <div className='col-4'>
          <CustomInput
            isRequired={true}
            id='addresses.city'
            type='text'
            name='addresses.city'
            label='City'
          />
        </div>
        <div className='col-4'>
          <CustomInput
            isRequired={true}
            id='addresses.state'
            type='text'
            name='addresses.state'
            label='State'
          />
        </div>
      </div>
      <div className='row pt-4'>
        <div className='col-4'>
          <CustomInput
            isRequired={true}
            id='addresses.postalCode'
            type='text'
            name='addresses.postalCode'
            label='Postal Code'
          />
        </div>
        <div className='col-4'>
          <CustomInput
            id='addresses.attention'
            type='text'
            name='addresses.attention'
            label='Attention'
          />
        </div>
        <div className='col-4'>
          <CustomInput
            id='addresses.phone'
            type='text'
            name='addresses.phone'
            label='Phone'
          />
        </div>
      </div>
    </Fragment>
  );
}
