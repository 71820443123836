import { Modal } from 'react-bootstrap-v5';
import { OrderViewModel } from '../../orders/models/OrderViewModel';
import { Formik, FormikHelpers, Form } from 'formik';
import { CustomInput } from '../../../components/CustomInput';
import { useIntl } from 'react-intl';
import { ActionsComponent } from '../../../components/ActionsComponent';
import { shippedSchemaValidation } from '../utils/shippedSchemaValidation';

interface Props {
  show: boolean;
  order: OrderViewModel;
  onHide: () => void;
  shipOrder: (
    id: number,
    trackingNumber: string,
    currentStatus: string
  ) => void;
}
interface PackOrderModel {
  trackingNumber: string;
}

export function ShippedOrderModal({ show, order, onHide, shipOrder }: Props) {
  const intl = useIntl();
  const save = (
    values: PackOrderModel,
    actions: FormikHelpers<PackOrderModel>
  ) => {
    shipOrder(order.id, values.trackingNumber, order.status);
  };
  return (
    <Modal show={show} onHide={onHide} backdrop='static' keyboard={false}>
      <Formik
        enableReinitialize
        initialValues={{ trackingNumber: order.shippingTrackingNbr }}
        validationSchema={shippedSchemaValidation(intl)}
        onSubmit={(values, actions) => {
          save(values, actions);
          actions.setSubmitting(false);
        }}
      >
        <Form>
          <Modal.Body className='mt-1 px-6 py-6 position-relative z-index-1'>
            <div className='d-flex align-items-center'>
              <i className={`las la-exclamation-circle fs-5x text-warning`}></i>
              <div className='flex-grow-3 ms-4'>
                <div className='fw-bold text-gray-700 fs-6'>
                  Are you sure you want to change the status of the order{' '}
                  <span className='fw-bolder'>#{order.id}</span> from{' '}
                  <span className='fw-bolder'>{order.status}</span> to{' '}
                  <span className='fw-bolder'>Shipped</span>?
                </div>
              </div>
            </div>
            <div className='row pt-5'>
              <div className='col-12'>
                <CustomInput
                  name='trackingNumber'
                  type='text'
                  label='Tracking Number'
                  isRequired={true}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className='border-0'>
            <ActionsComponent
              labelSubmit='Yes'
              labelDiscard='No'
              showCancelButton={true}
              onCancel={onHide}
            />
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
}
