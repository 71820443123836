import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const clientSchemaValidation = (intl: IntlShape) =>
  Yup.object().shape({
    name: Yup.string()
      .ensure()
      .trim()
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 256 })
      ),
    key: Yup.string()
      .ensure()
      .trim()
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 256 })
      ),
    autoReplenish: Yup.boolean(),
    canActiveCards: Yup.boolean(),
    allowThirdPartyShipping: Yup.boolean(),
    accountId: Yup.number().required(
      intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
    ),

    packingListMessage: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 256 })
      ),
    orderNote1: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 256 })
      ),
    orderNote2: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 256 })
      ),
    orderNote3: Yup.string()
      .ensure()
      .trim()
      .max(
        200,
        intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }, { max: 256 })
      ),
    shippingCompanies: Yup.array().min(
      1,
      'At least one shipping company is required'
    ),

    shippingMethod: Yup.string()
      .ensure()
      .trim()
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    shippingMethodAutoReplenishment: Yup.string()
      .ensure()
      .trim()
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
  });
