import { Field } from 'formik';
import { GroupPermissionsFlagsDto } from '../../models/GroupModel';

export interface PermissionItemModel {
  key: string;
  permission: GroupPermissionsFlagsDto;
  label: string;
}

interface Props {
  permission: PermissionItemModel;
}
export function PermissionItem({ permission }: Props) {
  return (
    <>
      <td className='text-gray-800'>{permission.label}</td>

      <td className='d-flex'>
        <label className='form-check form-check-sm form-check-custom  form-check-solid me-5 me-lg-20'>
          <Field
            className='form-check-input'
            type='checkbox'
            name={`${permission.key}.create`}
          />
          <span className='form-check-label'>Create</span>
        </label>

        <label className='form-check form-check-custom  form-check-solid me-5 me-lg-20'>
          <Field
            className='form-check-input'
            type='checkbox'
            name={`${permission.key}.update`}
          />
          <span className='form-check-label'>Update</span>
        </label>

        <label className='form-check form-check-custom  form-check-solid me-5 me-lg-20'>
          <Field
            className='form-check-input'
            type='checkbox'
            name={`${permission.key}.view`}
          />
          <span className='form-check-label'>View</span>
        </label>

        <label className='form-check form-check-custom  form-check-solid'>
          <Field
            className='form-check-input'
            type='checkbox'
            name={`${permission.key}.list`}
          />
          <span className='form-check-label'>List</span>
        </label>
      </td>
    </>
  );
}
