import { useEffect, useMemo, useState } from 'react';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { ShippingUnitModel } from '../models/InventoryModel';
import { ProductModel } from '../../products/models/ProductModel';
import { WarehouseModel } from '../models/WarehouseModel';
import warehouseServices from '../services/WarehousesServices';
import { PaginationWithoutSelectPage } from '../../../components/PaginationWithoutSelectPage';
import _, { isEqual } from 'lodash';
import React from 'react';
import { useField, useFormikContext } from 'formik';
import { ColumCheckbox } from '../../../components/ColumCheckbox';

const styles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontSize: '14px',
      color: '#a1a5b7',
    },
  },
  cells: {
    style: {},
  },
  pagination: {
    style: {
      justifyContent: 'flex-start',
    },
  },
};

interface ShippingUnitListSelectionProps {
  product: ProductModel;
  warehouse: WarehouseModel;
  fieldName?: string;
}

export function ShippingUnitListSelection({
  product,
  warehouse,
  fieldName = 'shippingUnitsToRemove',
}: ShippingUnitListSelectionProps) {
  const [field] = useField(fieldName);
  const [fieldRemoveAll] = useField('removeAll');
  const { setFieldValue } = useFormikContext();

  // Default Pagination
  const initSort = useMemo(() => {
    return {
      sortDirection: 1,
      sortColumn: 'number',
    };
  }, []);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState(initSort);
  const [entities, setEntities] = useState<Partial<ShippingUnitModel>[]>([]);

  const selectRemoveAll = () => {
    setFieldValue('removeAll', true);
  };

  const unselectRemoveAll = () => {
    setFieldValue('removeAll', false);
  };

  const selectAll = (checked: boolean) => {
    const rows = entities.map((e) => e.id);
    let newValues = [];
    let rowsWithoutEntities = field.value.filter(
      (r: number) => !entities.some((e) => e.id === r)
    );
    if (checked) {
      newValues = [...rowsWithoutEntities, ...rows];
    } else {
      newValues = rowsWithoutEntities;
    }

    setFieldValue(fieldName, newValues);
  };

  const selectRow = (checked: boolean, row: Partial<ShippingUnitModel>) => {
    let newValues = [];
    if (checked) {
      newValues = [...field.value, row.id];
    } else {
      newValues = field.value.filter(
        (r: number | undefined) => r && r !== row.id
      );
    }

    setFieldValue(fieldName, newValues);
  };

  const onRowClicked = (row: Partial<ShippingUnitModel>) => {
    if (fieldRemoveAll.value) return;
    const isSelected = _.find(field.value, (idRow: number) => idRow === row.id);
    selectRow(!isSelected, row);
  };

  const checkedAll =
    fieldRemoveAll.value ||
    entities.every((e) => field.value.some((r: number) => e.id === r));
  // useMemo will only be created once
  const columns: TableColumn<Partial<ShippingUnitModel>>[] = [
    {
      name: (
        <ColumCheckbox
          disabled={fieldRemoveAll.value}
          checked={checkedAll}
          onChecked={(e) => selectAll(e.target.checked)}
        />
      ),
      cell: (row) => (
        <ColumCheckbox
          disabled={fieldRemoveAll.value}
          checked={fieldRemoveAll.value || field.value.indexOf(row.id) > -1}
          onChecked={(e) => selectRow(e.target.checked, row)}
        />
      ),
      minWidth: '50px',
    },
    {
      name: 'SU #',
      selector: (row) => row.number || '-',
      sortable: true,
      sortField: 'number',
      minWidth: '100px',
    },
    {
      name: 'Starting card number',
      selector: (row) => row.startingNumber || '-',
      minWidth: '200px',
      sortable: true,
      sortField: 'startingNumber',
      wrap: true,
    },
    {
      name: 'Ending card number',
      selector: (row) => row.endingNumber || '-',
      minWidth: '200px',
      sortable: true,
      sortField: 'endingNumber',
      wrap: true,
    },
    {
      name: 'Quantity',
      selector: (row) => row.quantity || '-',
      sortable: true,
      sortField: 'quantity',
      minWidth: '120px',
    },
  ];

  //Initialization methods
  useEffect(() => {
    warehouseServices
      .findShippingUnits(
        warehouse.id,
        product.id,
        currentPage,
        currentSort,
        true
      )
      .then((response) => {
        setEntities(response.data.items || []);
        setTotalCount(response.data.totalCount || 0);
      });
  }, [currentPage, product, warehouse.id, currentSort]);

  useEffect(() => {
    setCurrentPage(1);
    setCurrentSort(initSort);
  }, [product, initSort]);

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSort = (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    const newSort = {
      sortDirection: sortDirection === 'asc' ? 1 : -1,
      sortColumn: selectedColumn.sortField
        ? selectedColumn.sortField
        : 'number',
    };
    if (!isEqual(currentSort, newSort)) {
      setCurrentSort(newSort);
    }
  };

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <label className={`required form-label fw-bolder`}>
          Select the Shipment Units you want to remove
        </label>

        <div className='card-body px-9 py-3'>
          {totalCount > 0 && (
            <div className='text-muted fw-bold text-center mb-5'>
              The {fieldRemoveAll.value ? totalCount : field.value.length} SU
              were selected.
              {!fieldRemoveAll.value && (
                <span
                  className='text-primary fw-bolder cursor-pointer ms-2'
                  onClick={selectRemoveAll}
                >
                  Select all SU({totalCount}) of this product.
                </span>
              )}
              {fieldRemoveAll.value && (
                <span
                  className='text-primary fw-bolder cursor-pointer ms-2'
                  onClick={unselectRemoveAll}
                >
                  Discard selection.
                </span>
              )}
            </div>
          )}

          <DataTable
            columns={columns}
            data={entities}
            pagination
            paginationComponent={PaginationWithoutSelectPage}
            paginationServer
            paginationTotalRows={totalCount}
            onChangePage={onChangePage}
            sortServer
            customStyles={{ ...styles }}
            paginationDefaultPage={1}
            paginationPerPage={8}
            paginationRowsPerPageOptions={[8]}
            onSort={handleSort}
            onRowClicked={onRowClicked}
          />
        </div>
      </div>
    </>
  );
}
