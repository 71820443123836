import { Fragment, useEffect } from 'react';
import { useState } from 'react';
//Modals imports
import { Modal } from 'react-bootstrap-v5';
import { AuditTrailModel } from '../models/AuditTrailModel';
import { auditServices } from '../services/AuditServices';
import moment from 'moment';
import ReactJson from 'react-json-view';
import { BasicTabModel, BasicTabs } from '../../../components/BasicTabs';
import { AvailabilityDetails } from './AvailabilityDetails';
interface Props {
  id: number;
  show: boolean;
  onHide: () => void;
}

export function AuditTrailDetail({ id, show, onHide }: Props) {
  // Init Values & State
  const [auditTrail, setAuditTrail] = useState<AuditTrailModel>();
  const [loading, setLoading] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState<string>('basic');
  const [showTabAvailability, setShowTabAvailability] =
    useState<boolean>(false);
  const [tabs, setTabs] = useState<BasicTabModel[]>([
    {
      title: 'Basic',
      id: 'basic',
    },
  ]);

  useEffect(() => {
    if (id && id !== 0 && show) {
      setLoading(true);
      auditServices.getAuditTrail(id).then((values) => {
        setAuditTrail(values.data);
        setLoading(false);
        if (!!values.data.data) {
          setShowTabAvailability(!!values.data.data);
          setTabs((a: BasicTabModel[]) => [
            ...a,
            {
              title: 'Additional Data',
              id: 'additional',
            },
          ]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, show]);

  return (
    <>
      {!loading && (
        <Modal
          onHide={onHide}
          show={show}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Modal.Header className='px-9 py-5 card-rounded'>
            <Modal.Title className='m-0 fw-bolder fs-3'>
              Audit Trail Detail
            </Modal.Title>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={onHide}
            >
              <i className='las la-times fs-2'></i>
            </div>
          </Modal.Header>
          <Modal.Body className='mt-1 pt-4 pb-1 position-relative z-index-1'>
            <BasicTabs
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              tabs={tabs}
            />
            {activeTab === 'basic' && (
              <Fragment>
                <div className='card-body px-10 pt-10'>
                  <div className='row mb-4'>
                    <div className='col-4'>
                      <div className='form-label'>Time Stamp</div>
                      <span className='text-muted'>
                        {moment
                          .utc(auditTrail?.timeStamp)
                          .local()
                          .format('MM-DD-YYYY HH:mm:ss')}
                      </span>
                    </div>
                    <div className='col-4'>
                      <div className='form-label'>User Email</div>
                      <span className='text-muted'>
                        {auditTrail?.userEmail}
                      </span>
                    </div>
                    <div className='col-4'>
                      <div className='form-label'>IP Address</div>
                      <span className='text-muted'>
                        {auditTrail?.remoteIPAddress}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-4'>
                    <div className='col-4'>
                      <div className='form-label'>Event Type</div>
                      <span className='text-muted'>
                        {auditTrail?.eventType}
                      </span>
                    </div>
                    {!auditTrail?.tableName &&
                      !auditTrail?.entityName &&
                      !!auditTrail?.primaryKey &&
                      auditTrail?.primaryKey > 0 && (
                        <div className='col-4'>
                          <div className='form-label'>Primary Key</div>
                          <span className='text-muted'>
                            {auditTrail?.primaryKey}
                          </span>
                        </div>
                      )}

                    {!!auditTrail?.tableName && (
                      <div className='col-4'>
                        <div className='form-label'>Table Name</div>
                        <span className='text-muted'>
                          {auditTrail?.tableName}
                        </span>
                      </div>
                    )}
                    {!!auditTrail?.entityName && (
                      <div className='col-4'>
                        <div className='form-label'>Entity Name</div>
                        <span className='text-muted'>
                          {auditTrail?.entityName}
                        </span>
                      </div>
                    )}
                  </div>
                  {!!auditTrail?.tableName &&
                    !!auditTrail?.entityName &&
                    !!auditTrail?.primaryKey && (
                      <div className='row'>
                        <div className='col-4'>
                          <div className='form-label'>Primary Key</div>
                          <span className='text-muted'>
                            {auditTrail?.primaryKey}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
                {!!auditTrail && <AvailabilityDetails audit={auditTrail} />}
              </Fragment>
            )}
            {showTabAvailability && activeTab === 'additional' && (
              <div className='card-body px-10 pt-10'>
                <div
                  className='scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-height='auto'
                  data-kt-scroll-wrappers='#kt_example_js_content'
                  data-kt-scroll-dependencies='#kt_example_js_header, #kt_example_js_footer, #kt_header'
                  data-kt-scroll-offset='100px'
                >
                  <div id='kt_example_js_content'>
                    {!!auditTrail?.data && (
                      <ReactJson
                        name='data'
                        src={auditTrail.data}
                        displayDataTypes={false}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className='p-2 card-rounded'>
            <button
              type='button'
              id='kt_modal_new_card_cancel'
              className='btn btn-light me-3'
              onClick={onHide}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
