import { Route, Switch } from 'react-router';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { AuditList } from './components/AuditList';

export function AuditPage() {
  const breadcrumbs: Array<PageLink> = [];

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Audit Trails List</PageTitle>
      <Switch>
        <Route path='/audit/list'>
          <AuditList />
        </Route>
      </Switch>
    </>
  );
}
