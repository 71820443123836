import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

const SUPPORTED_FORMATS = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
const FileSchema = Yup.mixed()
  .test('fileFormat', 'Invalid file type', (value) => {
    if (!value) {
      return true;
    }
    return SUPPORTED_FORMATS.includes(value.type);
  })
  .required('Required file');

export const importLocationsSchemaValidations = (intl: IntlShape) =>
  Yup.object().shape({
    clientId: Yup.number()
      .positive(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .min(1, intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .integer(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    file: FileSchema,
  });
