import { Field } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CustomInput } from '../../../../components/CustomInput';
import { EntityModel } from '../../../../models/EntityModel';
import { RootState } from '../../../../redux';
import { FranchiseModel } from '../../models/FranchiseModel';
import franchisesServices from '../../services/FranchiseServices';
import { franchiseSchemaValidation } from '../../utils/franchiseSchemeValidations';
import { editfranchiseInitialValues } from '../../utils/utils';
//Modals imports
import { Formik, FormikHelpers, Form } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { ActionsComponent } from '../../../../components/ActionsComponent';
import { toast } from 'react-toastify';
import { handle400Error } from '../../../../utils/handle400Error';
import { useIntl } from 'react-intl';

interface Props {
  refetch: () => void;
  id: number;
  show: boolean;
  onHide: () => void;
}
export function FranchiseEdit({ refetch, id, show, onHide }: Props) {
  const intl = useIntl();
  // Init Values & State
  const [franchise, setFranchise] = useState<FranchiseModel>(
    editfranchiseInitialValues
  );
  const [loading, setLoading] = useState<boolean>(false);
  // Obtain redux state values
  const [account, client] = useSelector<RootState, EntityModel[]>(
    ({ entities }: any) => [entities.account, entities.client],
    shallowEqual
  );

  const updateFranchise = (
    values: FranchiseModel,
    actions: FormikHelpers<FranchiseModel>
  ) => {
    franchisesServices
      .updateFranchise(id, values)
      .then(() => {
        toast.success('Franchise updated successfully.');
        onHide();
        refetch();
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          handle400Error(error.response.data.errors, actions.setErrors);
        }
      });
  };
  useEffect(() => {
    if (id && id !== 0 && show) {
      setLoading(true);
      franchisesServices.getFranchise(id).then((values) => {
        setFranchise(values.data);
        setLoading(false);
      });
    }
  }, [id, show]);

  if (client) {
    // Review in Backend: This field should not be sent
    franchise.clientId = client.id;
  }

  return (
    <>
      {!loading && (
        <Modal
          show={show}
          onHide={onHide}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Formik
            enableReinitialize
            initialValues={franchise}
            validationSchema={franchiseSchemaValidation(intl)}
            onSubmit={(values, actions) => {
              updateFranchise(values, actions);
              actions.setSubmitting(false);
            }}
          >
            <Form>
              <Modal.Header className='px-9 pt-7 card-rounded'>
                <Modal.Title className='m-0 fw-bolder fs-3'>
                  Edit Franchise
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='mt-1 px-6 py-3 position-relative z-index-1'>
                <div className='card-body p-3'>
                  <div className='row'>
                    {account && (
                      <div className='col-6'>
                        <div className='form-label'>Account</div>
                        <span className='text-muted'>{account.name}</span>
                      </div>
                    )}
                    {client && (
                      <div className='col-6'>
                        <div className='form-label'>Client</div>
                        <span className='text-muted'>{client.name}</span>
                      </div>
                    )}
                  </div>
                  <div className='row pt-4'>
                    <div className='col-6'>
                      <CustomInput
                        isRequired={true}
                        id='number'
                        name='number'
                        type='string'
                        label='Number'
                      />
                    </div>
                    <div className='col-6'>
                      <CustomInput
                        isRequired={true}
                        id='name'
                        type='text'
                        name='name'
                        label='Name'
                      />
                    </div>
                  </div>

                  <div className='row pt-4'>
                    <div className='form-check'>
                      <div className='p-3'>
                        <Field
                          type='checkbox'
                          className='form-check-input'
                          id='isCorporate'
                          name='isCorporate'
                        />
                        <label className='form-check-label ms-3'>
                          Corporate Franchise
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className='px-9 card-rounded  w-100'>
                <ActionsComponent
                  labelSubmit='Update'
                  showCancelButton={true}
                  onCancel={onHide}
                />
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      )}
    </>
  );
}
