import { NumberParam, StringParam } from 'serialize-query-params';
import moment from 'moment';
// Initial queryParams values
export const initialFilterLowLevelProduct = {
  accountId: undefined,
  clientId: undefined,
  sortColumn: 'client',
  sortDirection: 1,
  pageNumber: 1,
  pageSize: 10,
  lastXDays: 90,
};

// search params config
export const queryParamsLowLevelProductConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  accountId: NumberParam,
  clientId: NumberParam,
  lastXDays: NumberParam,
};

export const initialFilterBackorderedProduct = {
  sortColumn: 'client',
  sortDirection: 1,
  pageNumber: 1,
  pageSize: 10,
  accountId: undefined,
  clientId: undefined,
  productId: undefined,
};

// search params config
export const queryParamsBackorderedProductConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  accountId: NumberParam,
  clientId: NumberParam,
  productId: NumberParam,
};

export const initialFilterStandardOrder = {
  accountId: undefined,
  clientId: undefined,
  franchiseId: undefined,
  locationId: undefined,
  dateFrom: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  dateTo: moment().format('YYYY-MM-DD'),
  filterDateBy: 'orderDate',
  sortColumn: 'orderDate',
  sortDirection: -1,
  pageNumber: 1,
  pageSize: 10,
};

export const queryParamsStandardOrderConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  accountId: NumberParam,
  clientId: NumberParam,
  franchiseId: NumberParam,
  locationId: NumberParam,
  dateFrom: StringParam,
  dateTo: StringParam,
  filterDateBy: StringParam,
};

export const initialFilterProductOrderHistory = {
  accountId: undefined,
  clientId: undefined,
  franchiseId: undefined,
  locationId: undefined,
  dateFrom: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  dateTo: moment().format('YYYY-MM-DD'),
  filterDateBy: 'orderDate',
  sortColumn: 'orderDate',
  sortDirection: -1,
  pageNumber: 1,
  pageSize: 10,
};

export const queryParamsProductOrderHistoryConfig = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  accountId: NumberParam,
  clientId: NumberParam,
  franchiseId: NumberParam,
  locationId: NumberParam,
  dateFrom: StringParam,
  dateTo: StringParam,
  filterDateBy: StringParam,
};

export const initialFilterProductUsageForecasting = {
  accountId: undefined,
  clientId: undefined,
  franchiseId: undefined,
  locationId: undefined,
  dateFrom: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  dateTo: moment().format('YYYY-MM-DD'),
  productId: undefined,
  filterDateBy: 'orderDate',
  sortColumn: 'client',
  sortDirection: 1,
  pageNumber: 1,
  pageSize: 10,
};

export const queryParamsProductUsageForecasting = {
  sortDirection: NumberParam,
  sortColumn: StringParam,
  pageNumber: NumberParam,
  pageSize: NumberParam,
  accountId: NumberParam,
  clientId: NumberParam,
  franchiseId: NumberParam,
  locationId: NumberParam,
  dateFrom: StringParam,
  dateTo: StringParam,
  filterDateBy: StringParam,
  productId: NumberParam,
};
