import { ErrorMessage, useFormikContext } from 'formik';
import { Fragment, useEffect } from 'react';
import { CustomAllProductSelect } from '../../../components/CustomAllProductSelect';
import { CustomInput } from '../../../components/CustomInput';
import { WarehouseAddInventory } from '../models/WarehouseAddInventory';

export function WarehouseInventoryForm() {
  const { values, setFieldValue } = useFormikContext<WarehouseAddInventory>();
  useEffect(() => {
    setFieldValue('runPrefix', '');
    setFieldValue('startingRunNumber', 1);
    setFieldValue('quantity', 0);
    setFieldValue('startingCardNumber', '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.productId]);
  return (
    <Fragment>
      <div className='row mb-2'>
        <div className='col-6'>
          <CustomAllProductSelect
            isRequired={true}
            id='productId'
            name='productId'
            type='text'
            label='Product'
            addData={true}
          />
        </div>

        <div className='col-6'>
          <CustomInput
            id='shippingUnitSize'
            name='shippingUnitSize'
            label='Shipping Unit Size'
            type='text'
            isDisabled={true}
          />
        </div>

        <div className='col-12 my-2'>
          <ErrorMessage name='productId'>
            {(msg) => <div className='text-danger'>{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
      {values.productType === 'Card' && (
        <div className='row mb-2'>
          <div className='col-6'>
            <CustomInput
              id='runPrefix'
              name='runPrefix'
              label='Run Prefix'
              isRequired={true}
              type='text'
            />
          </div>
          <div className='col-6'>
            <CustomInput
              id='startingRunNumber'
              name='startingRunNumber'
              label='Starting Run Number'
              type='number'
            />
          </div>
        </div>
      )}

      <div className='row mb-2'>
        <div className='col-6'>
          <CustomInput
            id='quantity'
            name='quantity'
            label='Quantity'
            isRequired={true}
            type='number'
          />
        </div>
      </div>
      {values.productType === 'Card' && (
        <div className='row pt-4'>
          <div className='col-12'>
            <CustomInput
              isRequired={true}
              id='startingCardNumber'
              name='startingCardNumber'
              label='Starting Card Number'
              maxLength={50}
            />
          </div>
          <div className='fs-7 fw-bold text-muted my-2'>
            Masking Format: {values?.maskingFormat}
          </div>
        </div>
      )}
    </Fragment>
  );
}
