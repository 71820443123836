import { useIntl } from 'react-intl';
import accountsServices from '../../services/AccountsServices';
import { accountSchemaValidation } from '../../utils/accountShemaValidations';
import { useEffect, useState } from 'react';
import { editAccountinitialValues } from '../../utils/utils';
import { Formik, FormikHelpers, Form } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { ActionsComponent } from '../../../../components/ActionsComponent';
import { toast } from 'react-toastify';
import { handle400Error } from '../../../../utils/handle400Error';
import { AccountForm } from './AccountForm';
import { accountHelpers } from '../../helpers/account';
import { AccountEditModel } from '../../models/AccountEditModel';
import * as auth from '../../../auth/redux/AuthRedux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { UserGroupRelationModel } from '../../../auth/models/UserGroupRelationModel';
import { RootState } from '../../../../redux';

interface Props {
  refetch: () => void;
  id: number;
  show: boolean;
  onHide: () => void;
}

export function AccountEdit({ id, show, refetch, onHide }: Props) {
  // i18n
  const intl = useIntl();
  // Init Values & State
  const [account, setAccount] = useState<AccountEditModel>(
    editAccountinitialValues
  );
  const [loading, setLoading] = useState<boolean>(false);
  // onSubmit function
  useEffect(() => {
    if (id && id !== 0 && show) {
      setLoading(true);
      accountsServices.getAccount(Number(id)).then((response) => {
        const data = accountHelpers.initialValues(response.data);
        setAccount(data);
        setLoading(false);
      });
    }
  }, [id, show]);

  const dispatch = useDispatch();
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );

  const saveAccount = (
    values: AccountEditModel,
    actions: FormikHelpers<AccountEditModel>
  ) => {
    accountsServices
      .updateAccount(account.id, values)
      .then(() => {
        uploadImage(values);
        toast.success(intl.formatMessage({ id: 'ACCOUNT.UPDATED.SUCCESS' }));
        onHide();
        refetch();
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          handle400Error(error.response.data.errors, actions.setErrors);
        }
      });
  };
  const uploadImage = (values: any) => {
    const id = account?.branding?.files ? account?.branding?.files[0]?.id : 0;
    const body: any = accountHelpers.createFormDataFiles(
      values.branding.files,
      Number(id)
    );
    accountsServices
      .accountImages(values.id, body)
      .then((response) => {
        if (
          response.data.branding &&
          userGroupRelations &&
          userGroupRelations.accountId
        ) {
          dispatch(auth.actions.setBranding(response.data.branding));
        }
      })
      .catch();
  };
  return (
    <>
      {!loading && (
        <Modal
          show={show}
          onHide={onHide}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Formik
            enableReinitialize
            initialValues={account}
            validationSchema={accountSchemaValidation(intl)}
            onSubmit={(values, actions) => {
              saveAccount(values, actions);
              actions.setSubmitting(false);
            }}
          >
            <Form>
              <Modal.Header className='px-9 pt-7 card-rounded'>
                <Modal.Title className='m-0 fw-bolder fs-3'>
                  Edit Account
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='mt-1 px-6 py-3 position-relative z-index-1'>
                <AccountForm />
              </Modal.Body>
              <Modal.Footer className='px-9 card-rounded  w-100'>
                <ActionsComponent
                  labelSubmit='Update'
                  showCancelButton={true}
                  onCancel={onHide}
                />
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      )}
    </>
  );
}
