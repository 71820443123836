import { useFormikContext } from 'formik';
import { Fragment, useState } from 'react';
import CustomImageInput from '../../../../components/CustomImageInput';
import { CustomInput } from '../../../../components/CustomInput';
import { AccountEditModel } from '../../models/AccountEditModel';

export function AccountForm() {
  const [activeTab, setActiveTab] = useState<string>('tab1');
  const onChangeTab = (tabId: string) => {
    setActiveTab(tabId);
  };
  const { values, setFieldValue } = useFormikContext<AccountEditModel>();

  const handleChangeColorPicker = (value: string, field: string) => {
    setFieldValue(field, value);
  };
  return (
    <Fragment>
      <div className='d-flex overflow-auto h-55px'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li key='generalInformation' className='nav-item'>
            <div
              className={
                `nav-link text-active-primary me-6 ` +
                (activeTab === 'tab1' && 'active')
              }
              key='tab1'
              style={{ cursor: 'pointer' }}
              onClick={() => onChangeTab('tab1')}
            >
              General Information
            </div>
          </li>
          <li key='branding' className='nav-item'>
            <div
              className={
                `nav-link text-active-primary me-6 ` +
                (activeTab === 'tab2' && 'active')
              }
              style={{ cursor: 'pointer' }}
              key='tab2'
              onClick={() => onChangeTab('tab2')}
            >
              Branding
            </div>
          </li>
        </ul>
      </div>

      {activeTab === 'tab1' && (
        <div className='card-body p-9'>
          <div className='row'>
            <div className='col-4'>
              <CustomInput
                isRequired={true}
                id='accountKey'
                name='accountKey'
                type='text'
                label='Account KEY'
              />
            </div>
            <div className='col-8'>
              <CustomInput
                isRequired={true}
                id='accountName'
                type='text'
                name='accountName'
                label='Account Name'
              />
            </div>
          </div>

          <div className='row mt-5'>
            <div className='col-4'>
              <CustomInput
                id='city'
                name='city'
                label='City'
                isRequired={false}
                type='text'
              />
            </div>

            <div className='col-4'>
              <CustomInput
                id='state'
                name='state'
                label='State'
                isRequired={false}
                type='text'
              />
            </div>

            <div className='col-4'>
              <CustomInput
                id='postalCode'
                name='postalCode'
                label='Postal Code'
                isRequired={false}
                type='text'
              />
            </div>
          </div>

          <div className='row mt-5'>
            <div className='col-4'>
              <CustomInput
                id='contactName'
                name='contactName'
                label='Contact Name'
                isRequired={true}
                type='text'
              />
            </div>
            <div className='col-4'>
              <CustomInput
                id='addressLine1'
                name='addressLine1'
                label='Account Address 1'
                isRequired={false}
                type='text'
              />
            </div>

            <div className='col-4'>
              <CustomInput
                id='addressLine2'
                name='addressLine2'
                label='Account Address 2'
                type='text'
              />
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-4'>
              <CustomInput
                isRequired={false}
                id='accountNumber'
                type='text'
                name='accountNumber'
                label='Account Number'
              />
            </div>
          </div>
        </div>
      )}

      {activeTab === 'tab2' && (
        <div className='card-body p-9'>
          <div className='row mb-6'>
            <div className='col-lg-6'>
              <CustomImageInput
                title='Account Logo'
                name='branding.files[0]'
                accept='.png, .jpg'
                recommended='Recommended dimensions: 355 x 155. Recommended internal margins: left, right: 50, top: 37'
                width='350px'
                height='150px'
              ></CustomImageInput>
            </div>
          </div>
          <div className='row mt-6'>
            <div className='fw-bolder fs-4 mb-6'>Navigation Bar</div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <label className='form-label'>Background Color</label>

              <div className='d-flex align-items-center position-relative my-1'>
                <input
                  style={{ width: '4em' }}
                  type='color'
                  className='form-control form-control-color'
                  title='Choose Background Color'
                  value={values.branding?.navbarBackgroundColor}
                  onChange={(e) =>
                    handleChangeColorPicker(
                      e.target.value,
                      'branding.navbarBackgroundColor'
                    )
                  }
                ></input>{' '}
                <CustomInput
                  id='branding.navbarBackgroundColor'
                  name='branding.navbarBackgroundColor'
                  isRequired={false}
                />
              </div>
            </div>

            <div className='col-4'>
              <label className='form-label'>Icon Color</label>

              <div className='d-flex align-items-center position-relative my-1'>
                <input
                  style={{ width: '4em' }}
                  type='color'
                  className='form-control form-control-color'
                  title='Choose Icon Color'
                  value={values.branding?.navbarIconColor}
                  onChange={(e) =>
                    handleChangeColorPicker(
                      e.target.value,
                      'branding.navbarIconColor'
                    )
                  }
                ></input>{' '}
                <CustomInput
                  id='branding.navbarIconColor'
                  name='branding.navbarIconColor'
                  isRequired={false}
                />
              </div>
            </div>

            <div className='col-4'>
              <label className='form-label'>Text Color</label>

              <div className='d-flex align-items-center position-relative my-1'>
                <input
                  style={{ width: '4em' }}
                  type='color'
                  className='form-control form-control-color'
                  title='Choose Text Color'
                  value={values.branding?.navbarTextColor}
                  onChange={(e) =>
                    handleChangeColorPicker(
                      e.target.value,
                      'branding.navbarTextColor'
                    )
                  }
                ></input>{' '}
                <CustomInput
                  id='branding.navbarTextColor'
                  name='branding.navbarTextColor'
                  isRequired={false}
                />
              </div>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-4'>
              <label className='form-label'>Active Background Color</label>

              <div className='d-flex align-items-center position-relative my-1'>
                <input
                  style={{ width: '4em' }}
                  type='color'
                  className='form-control form-control-color'
                  title='Choose Active Background Color'
                  value={values.branding?.navbarBackgroundActiveColor}
                  onChange={(e) =>
                    handleChangeColorPicker(
                      e.target.value,
                      'branding.navbarBackgroundActiveColor'
                    )
                  }
                ></input>{' '}
                <CustomInput
                  id='branding.navbarBackgroundActiveColor'
                  name='branding.navbarBackgroundActiveColor'
                  isRequired={false}
                />
              </div>
            </div>

            <div className='col-4'>
              <label className='form-label'>Active Icon Color</label>

              <div className='d-flex align-items-center position-relative my-1'>
                <input
                  style={{ width: '4em' }}
                  type='color'
                  className='form-control form-control-color'
                  title='Choose Active Icon Color'
                  value={values.branding?.navbarIconActiveColor}
                  onChange={(e) =>
                    handleChangeColorPicker(
                      e.target.value,
                      'branding.navbarIconActiveColor'
                    )
                  }
                ></input>{' '}
                <CustomInput
                  id='branding.navbarIconActiveColor'
                  name='branding.navbarIconActiveColor'
                  isRequired={false}
                />
              </div>
            </div>

            <div className='col-4'>
              <label className='form-label'>Active Text Color</label>

              <div className='d-flex align-items-center position-relative my-1'>
                <input
                  style={{ width: '4em' }}
                  type='color'
                  className='form-control form-control-color'
                  title='Choose Active Text Color'
                  value={values.branding?.navbarTextActiveColor}
                  onChange={(e) =>
                    handleChangeColorPicker(
                      e.target.value,
                      'branding.navbarTextActiveColor'
                    )
                  }
                ></input>{' '}
                <CustomInput
                  id='branding.navbarTextActiveColor'
                  name='branding.navbarTextActiveColor'
                  isRequired={false}
                />
              </div>
            </div>
          </div>
          <div className='row mt-6'>
            <div className='fw-bolder fs-4 mb-6'>Primary Color</div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <div className='d-flex align-items-center position-relative my-1'>
                <input
                  style={{ width: '4em' }}
                  type='color'
                  className='form-control form-control-color'
                  title='Choose Primary color'
                  value={values.branding?.primaryColor}
                  onChange={(e) =>
                    handleChangeColorPicker(
                      e.target.value,
                      'branding.primaryColor'
                    )
                  }
                ></input>{' '}
                <CustomInput
                  id='branding.primaryColor'
                  name='branding.primaryColor'
                  isRequired={false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
