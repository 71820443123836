import axios from 'axios';
import { PagedResult } from '../../../models/pagedResult';
import { InventoryModel } from '..//models/InventoryModel';
import { ActiveWarehouseModel } from '../models/ActiveWarehouseModel';
import { AddStockAdjustmentModel } from '../models/AddStockAdjustmentModel';
import { WarehouseAddInventory } from '../models/WarehouseAddInventory';
import { WarehouseModel } from '../models/WarehouseModel';

export const WAREHOUSE_URL = `/api/v1/warehouses`;

const warehouseServices = {
  findWarehouses: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(WAREHOUSE_URL, { params: params });
  },
  getWarehouse: (id: number) => {
    return axios.get<WarehouseModel>(`${WAREHOUSE_URL}/${id}`);
  },
  desactivateWarehouse: (id: number, reason?: string) => {
    const body = {
      id,
      reason,
    };
    return axios.put(`${WAREHOUSE_URL}/deactivate/${id}`, body);
  },
  activateWarehouse: (id: number) => {
    return axios.put(`${WAREHOUSE_URL}/activate/${id}`);
  },
  createWareHouse: (warehouse: WarehouseModel) => {
    return axios.post(`${WAREHOUSE_URL}`, warehouse);
  },
  updateWareHouse: (warehouseId: number, warehouse: WarehouseModel) => {
    return axios.put(`${WAREHOUSE_URL}/${warehouseId}`, warehouse);
  },
  addWarehouseInventory: (
    warehouseId: number,
    warehouseInventory: WarehouseAddInventory
  ) => {
    return axios.put(
      `${WAREHOUSE_URL}/${warehouseId}/inventory`,
      warehouseInventory
    );
  },

  findInventories: (warehouseId: number, queryParams: any) => {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(`${WAREHOUSE_URL}/${warehouseId}/inventory`, {
      params: params,
    });
  },

  editWarehouseInventory: (
    warehouseId: number,
    warehouseInventory: InventoryModel
  ) => {
    return axios.put(
      `${WAREHOUSE_URL}/${warehouseId}/lowLevel`,
      warehouseInventory
    );
  },

  getWarehouseInventory: (productId: number, warehouseId: number) => {
    return axios.get<InventoryModel>(
      `${WAREHOUSE_URL}/${warehouseId}/${productId}/lowLevel`
    );
  },
  getInventoryDetails: (warehouseId: number, productId: number) => {
    return axios.get<InventoryModel>(
      `${WAREHOUSE_URL}/${warehouseId}/product/${productId}`
    );
  },

  getInventoryDetailsShippingUnits: (
    warehouseId: number,
    productId: number,
    queryParams: any
  ) => {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(
      `${WAREHOUSE_URL}/${warehouseId}/product/${productId}/shipping-units`,
      {
        params: params,
      }
    );
  },
  getActiveWarehouse: () => {
    return axios.get<ActiveWarehouseModel>(`${WAREHOUSE_URL}/active`);
  },

  findStockAdjustments: (warehouseId: number, queryParams: any) => {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(
      `${WAREHOUSE_URL}/${warehouseId}/stock-adjustments`,
      {
        params: params,
      }
    );
  },

  addWarehouseAdjustStock: (
    warehouseId: number,
    warehouseAdjustStock: AddStockAdjustmentModel
  ) => {
    return axios.post(
      `${WAREHOUSE_URL}/${warehouseId}/stock-adjustments`,
      warehouseAdjustStock
    );
  },

  getCurrentLevel: (warehouseId: number, productId: number) => {
    return axios.get(
      `${WAREHOUSE_URL}/${warehouseId}/${productId}/current-level`
    );
  },

  generateRunNumber: () => {
    return axios.post(`${WAREHOUSE_URL}/run-number`);
  },

  getRunNumber: () => {
    return axios.get(`${WAREHOUSE_URL}/run-number`);
  },

  findShippingUnits: (
    warehouseId: number,
    productId: number,
    page: number,
    sort: any,
    onlyWithQuantity?: boolean
  ) => {
    const params = {
      page: page,
      pageNumber: page,
      filter: undefined,
      pageSize: 8,
      onlyWithQuantity: !!onlyWithQuantity,
      ...sort,
    };
    return axios.get(
      `${WAREHOUSE_URL}/${warehouseId}/product/${productId}/shipping-units`,
      {
        params: params,
      }
    );
  },
};

export default warehouseServices;
