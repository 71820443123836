import { useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import ListComponent from '../../../components/ListComponent';
import moment from 'moment';
import { useQueryParamsReplace } from '../../../utils/useQueryParamsReplace';
import { ReportStandarOrderModel } from '../models/ReportStandarOrderModel';
import { reportsServices } from '../services/ReportsServices';
import {
  initialFilterStandardOrder,
  queryParamsStandardOrderConfig,
} from '../utils/utils';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { shallowEqual, useSelector } from 'react-redux';
import { StandardOrdersFilter } from './StandardOrdersFilter';
import { CustomBadge } from '../../../components/CustomBadge';
import { OriginBadge } from '../../../components/OriginBadge';
import { StatusBadge } from '../../../components/StatusBadge';

export function StandardOrders() {
  // Pagination, Filter and Query Params
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<ReportStandarOrderModel[]>([]);

  const [queryParams, setQueryParams] = useQueryParamsReplace(
    queryParamsStandardOrderConfig
  );

  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<ReportStandarOrderModel>[] = useMemo(
    () => [
      {
        name: 'Client',
        selector: (row) => row.client,
        sortable: true,
        sortField: 'client',
        wrap: true,
      },
      {
        name: 'Order Id',
        selector: (row) => row.orderId,
        sortable: true,
        sortField: 'orderId',
        width: '120px',
      },
      {
        name: 'Origin',
        cell: (row) => <OriginBadge origin={row.origin}></OriginBadge>,
        sortable: false,
        sortField: 'origin',
        width: '120px',
      },
      {
        name: 'Order Date',
        selector: (row) => moment(row.orderDate).format('MM-DD-YYYY HH:mm'),
        sortable: true,
        sortField: 'orderDate',
        width: '120px',

        wrap: true,
      },

      {
        name: 'Order Status',
        cell: (row) => <StatusBadge status={row.orderStatus}></StatusBadge>,
        sortable: false,
        sortField: 'status',
        width: '120px',
      },

      {
        name: 'Ship Date',
        selector: (row) =>
          row.shipDate ? moment(row.shipDate).format('MM-DD-YYYY') : '',
        sortable: true,
        sortField: 'shipDate',
        width: '120px',
      },
      {
        name: 'Tracking Number',
        selector: (row) => row.trackingNumber,
        sortable: false,
        sortField: 'trackingNumber',
        wrap: true,
        width: '120px',
      },
      {
        name: 'Carrier & Method',
        sortable: false,
        selector: (row) => `${row.shipCarrier}-${row.shipMethod}`,
        wrap: true,
        width: '120px',
      },
      {
        name: 'Activate',
        sortable: false,
        cell: (row) => (
          <CustomBadge
            status={!!row.activate}
            activeText='Active'
            inactiveText='Inactive'
          />
        ),
        width: '120px',
      },
      {
        name: 'Placed By',
        selector: (row) => row.placedBy,
        sortable: false,
        wrap: true,
        width: '200px',
      },
      {
        name: 'Location Number',
        selector: (row) => row.locationNumber,
        sortable: false,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Location Name, City & State',
        selector: (row) =>
          `${row.locationName}, ${row.locationCity}, ${row.locationState}`,
        sortable: false,
        wrap: true,
        width: '200px',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  //Initialization methods
  useEffect(() => {
    if (history.location.search === '') {
      const newQueryParams = {
        ...initialFilterStandardOrder,
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };
      setQueryParams(newQueryParams);
    }

    if (queryParams.pageNumber) find(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const find = function (queryParams: any) {
    reportsServices.standardOrders(queryParams).then((response) => {
      setEntities(response.data.items);
      setTotalCount(response.data.totalCount);
    });
  };
  const download = function () {
    reportsServices.standardOrdersDownload(queryParams).then((response) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'standar-order-report.csv');
      link.click();
    });
  };

  const customTableStyles = {
    rows: {
      style: {},
    },
    headCells: {
      style: {
        fontSize: '14px',
        color: '#a1a5b7',
      },
    },
    cells: {
      style: {},
    },
    pagination: {
      style: {
        justifyContent: 'flex-start',
      },
    },
  };

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header py-6'>
          <div className='card-title'></div>
          <div className='card-toolbar'>
            <StandardOrdersFilter
              queryParams={queryParams}
              setQueryParams={setQueryParams}
            />
            <button
              type='button'
              className='btn btn-primary align-self-center'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              onClick={(e) => {
                e.preventDefault();
                download();
              }}
            >
              <i className='fas fa-download fs-2'></i>
              Download
            </button>
          </div>
        </div>

        <div className='card-body px-9 py-3'>
          <ListComponent
            table={{
              columns,
              data: entities,
              paginationTotalRows: totalCount,
            }}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            customStyles={customTableStyles}
          />
        </div>
      </div>
    </>
  );
}
