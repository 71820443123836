import { CustomBadge } from '../../../components/CustomBadge';
import { KitProductModel } from '../models/CreateKitModel';
import { KitModel } from '../models/KitModel';

interface KitViewProductsProps {
  kit: KitModel;
}

export function KitViewProducts({ kit }: KitViewProductsProps) {
  return (
    <div className='p-5'>
      <div className='row'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle g-4'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Product</th>
                <th className='min-w-150px'>Required</th>
                <th className='min-w-150px'>Primary</th>
                <th className='min-w-150px'>SU</th>
              </tr>
            </thead>
            <tbody className='border-0'>
              {kit.kitProducts.map((p: KitProductModel) => {
                return (
                  <tr key={`products.${p.productId}`}>
                    <td>
                      {p.product && p.product.code ? p.product.code : '-'}
                    </td>

                    <td>
                      <CustomBadge
                        status={!!p.isRequired}
                        activeText='Yes'
                        inactiveText='No'
                      />
                    </td>

                    <td>
                      <CustomBadge
                        status={!!p.isPrimary}
                        activeText='Yes'
                        inactiveText='No'
                      />
                    </td>
                    <td> {p.product?.shippingUnitSize}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
