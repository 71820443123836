import { Field, FormikErrors, getIn, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { Fragment } from 'react';
import { CustomInput } from '../../../components/CustomInput';
import { OrderCreateModel } from '../models/OrderCreateModel';
import { OrderDetailsCreateModel } from '../models/OrderDetailsCreateModel';

interface Props {
  setProductsAdded: any;
  setOrder: any;
  order: OrderCreateModel;
}

export function PlaceOrderDetails({
  setProductsAdded,
  setOrder,
  order,
}: Props) {
  const { values, errors } = useFormikContext<OrderCreateModel>();
  /*
  This is very important: It reflects the changes between orders.details and values.details.
  It is a communication mechanism.
  Before, if the order changed, the values were reset and these values were the initial ones when inserting it, 
  and the edited values inside the form were lost.
*/
  useEffect(() => {
    setOrder((s: OrderCreateModel) => ({
      ...s,
      details: values.details,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.details]);
  const handleRemoveItem = async (customId: string) => {
    if (values.details.length > 0) {
      setOrder((s: OrderCreateModel) => ({
        ...s,
        details: s.details.filter((item) => item.customId !== customId),
      }));
      setProductsAdded((s: string[]) => s.filter((x) => x !== customId));
    }
  };

  const handleSelectQuantity = (
    e: any,
    detail: OrderDetailsCreateModel,
    index: number
  ) => {
    if (e.target.value === 'custom') {
      detail.customQuantity = true;
    } else {
      detail.quantity = Number(e.target.value);
    }
    // ?
    setOrder((s: OrderCreateModel) => ({
      ...s,
    }));
  };

  return (
    <Fragment>
      <div className='card card-body p-2' style={{ minHeight: '200px' }}>
        {values.details.length > 0 ? (
          <ul className='list-group list-group-flush'>
            {values.details.map((item, index) => {
              return (
                <li key={`details.${index}`} className='list-group-item pt-5'>
                  <div className='row'>
                    <div className='col-4'>
                      {!item.customQuantity && (
                        <select
                          style={{
                            backgroundColor: 'white',
                            borderColor: '#DDDDDD',
                          }}
                          className='form-select form-select-solid  px-2'
                          onChange={(e) => handleSelectQuantity(e, item, index)}
                          value={item.quantity}
                        >
                          {item.shippingUnitSize && item.shippingUnitSize > 0 && (
                            <>
                              <option value={item.shippingUnitSize * 1}>
                                {item.shippingUnitSize * 1}
                              </option>
                              <option value={item.shippingUnitSize * 2}>
                                {item.shippingUnitSize * 2}
                              </option>
                              <option value={item.shippingUnitSize * 3}>
                                {item.shippingUnitSize * 3}
                              </option>
                              <option value={item.shippingUnitSize * 4}>
                                {item.shippingUnitSize * 4}
                              </option>
                              <option value={item.shippingUnitSize * 5}>
                                {item.shippingUnitSize * 5}
                              </option>
                            </>
                          )}

                          <option value='custom'>Custom</option>
                        </select>
                      )}
                      {item.customQuantity && (
                        <input
                          autoComplete='none'
                          type='number'
                          className='form-control form-control-solid'
                          value={item.quantity}
                          placeholder=''
                          onChange={(e) => handleSelectQuantity(e, item, index)}
                          min='1'
                        />
                      )}
                    </div>

                    <div className='col-6 d-flex align-items-center'>
                      <label className='text-dark fw-bolder text-hover-primary mb-0 fs-6'>
                        {item.description}
                      </label>
                    </div>
                    <div className='col-2 d-flex align-items-center'>
                      <button
                        onClick={() => {
                          handleRemoveItem(item.customId);
                        }}
                        type='button'
                        id='buton-delete'
                        className='btn btn-icon btn-bg-light btn-active-danger btn-sm me-1'
                      >
                        <i className='las la-trash fs-2'></i>
                      </button>
                    </div>
                  </div>
                  <div className='row d-flex align-items-center'>
                    {values.details[index].canActivate && (
                      <div className='col-12 my-3'>
                        <div className='d-block'>
                          <div className='form-check form-check-custom'>
                            <Field
                              type='checkbox'
                              className={`form-check-input ${
                                item.activeCards ? 'bg-primary' : ''
                              }`}
                              name={`details.${index}.activeCards`}
                              id={`details.${index}.activeCards`}
                            />

                            <label className='form-check-label'>
                              Activate en route
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                    {values.details[index].activeCards && (
                      <div className='col-4'>
                        <CustomInput
                          name={`details.${index}.cardValue`}
                          id={`details.${index}.cardValue`}
                          type='number'
                          isDisabled={item.cardAttributesType === 'Fixed'}
                          showError={false}
                          focus={true}
                          min={
                            item.cardAttributesType === 'Fixed'
                              ? item.value
                              : item.minValue
                          }
                          max={
                            item.cardAttributesType === 'Fixed'
                              ? item.value
                              : item.maxValue
                          }
                        />
                      </div>
                    )}
                    {values.details[index].activeCards &&
                      item.cardAttributesType === 'Range' && (
                        <div className='col-4'>
                          {processError(errors.details, index) ? (
                            <ErrorMessageActiveCards
                              name={`details.${index}.cardValue`}
                              min={item.minValue}
                              max={item.maxValue}
                            />
                          ) : (
                            <div className='text-muted  fs-6'>
                              {`$${item.minValue} - $${item.maxValue}`}
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className='p-0 h-100px'>
            <h5 className='text-center p-12 fw-bold text-gray-400'>
              No items added
            </h5>
          </div>
        )}
      </div>
    </Fragment>
  );
}
const processError = (
  error:
    | string
    | string[]
    | FormikErrors<OrderDetailsCreateModel>[]
    | undefined,
  index: number
) => {
  if (error && error[index]) {
    return true;
  }
  return false;
};

// Custom Error Message
const ErrorMessageActiveCards = ({ name, min, max }: any) => (
  <Field
    name={name}
    render={({ form }: any) => {
      const error = getIn(form.errors, name);
      return error ? (
        <div className='text-danger'>{message(error, min, max)}</div>
      ) : null;
    }}
  />
);
// View: CreateOrdersSchema.details
const message = (msg: string, min: number, max: number) => {
  if (msg === 'Required') {
    return msg;
  }
  if (msg === 'Out of range') {
    return `($${min} - $${max})`;
  }
  return msg;
};
