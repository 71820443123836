import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const CreateOrdersSchema = (intl: IntlShape) =>
  Yup.object().shape({
    details: Yup.array()
      .of(
        Yup.object().shape({
          entityId: Yup.number()
            .positive(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
            .min(1, intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
            .integer(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
          quantity: Yup.number()
            .positive(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
            .min(1, intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
            .integer(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
          activeCards: Yup.boolean().required(
            intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
          ),
          isKitEntity: Yup.boolean().required(
            intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
          ),
          cardValue: Yup.number().when(['cardAttributesType', 'activeCards'], {
            is: (valueType: string, activeCards: boolean) =>
              valueType === 'Range' && activeCards,
            then: Yup.number()
              .positive(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
              .min(Yup.ref('minValue'), 'Out of range')
              .max(Yup.ref('maxValue'), 'Out of range')
              .required('Invalid'),
          }),
        })
      )
      .min(1),
    locationId: Yup.number()
      .positive(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .min(1, intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .integer(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
    orderOrigin: Yup.string().required(
      intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
    ),
    customerOrderId: Yup.string(),
    customerMiscData: Yup.string(),
  });
