import { Formik, FormikHelpers, Form } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { ActionsComponent } from '../../../../components/ActionsComponent';
import { toast } from 'react-toastify';
import { handle400Error } from '../../../../utils/handle400Error';
import { useIntl } from 'react-intl';
import { LocationInventoryForm } from './LocationInventoryForm';
import { InventoryModel } from '../../models/InventoryModel';
import inventoryServices from '../../services/InventoryServices';
import { locationInventorySchemaValidation } from '../../utils/locationInventorySchemaValidation';
import { useEffect, useState } from 'react';

interface Props {
  refetch: () => void;
  show: boolean;
  onHide: () => void;
  inventoryId: number;
  locationId: number;
}

export function LocationEditInventory({
  refetch,
  show,
  onHide,
  inventoryId,
  locationId,
}: Props) {
  const intl = useIntl();
  const [inventory, setInventory] = useState<InventoryModel | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const updateLocationInventory = (
    values: InventoryModel,
    actions: FormikHelpers<InventoryModel>
  ) => {
    inventoryServices
      .editInvetory(values)
      .then(() => {
        toast.success('Location Inventory edited successfully.');
        onHide();
        refetch();
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          handle400Error(error.response.data.errors, actions.setErrors);
        }
      });
  };

  // Call the backend when there is a valid id and the modal has to be displayed.
  useEffect(() => {
    if (
      locationId &&
      inventoryId &&
      locationId !== 0 &&
      inventoryId !== 0 &&
      show
    ) {
      setLoading(true);
      inventoryServices.getInventory(inventoryId, locationId).then((values) => {
        setInventory(values.data);
        setLoading(false);
      });
    }
  }, [locationId, inventoryId, show]);

  return (
    <>
      {!loading && inventory && (
        <Modal
          show={show}
          onHide={onHide}
          backdrop='static'
          keyboard={false}
          dialogClassName='modal-90w'
          aria-labelledby='example-custom-modal-styling-title'
        >
          <Formik
            enableReinitialize
            initialValues={inventory}
            validationSchema={locationInventorySchemaValidation(intl)}
            onSubmit={(values, actions) => {
              updateLocationInventory(values, actions);
              actions.setSubmitting(false);
            }}
          >
            <Form>
              <Modal.Header className='px-9 pt-7 card-rounded'>
                <Modal.Title className='m-0 fw-bolder fs-3'>
                  Edit Inventory
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='container-fluid mt-1 px-10 py-8 position-relative z-index-1'>
                <LocationInventoryForm />
              </Modal.Body>
              <Modal.Footer className='px-9 card-rounded  w-100'>
                <ActionsComponent
                  labelSubmit='Update'
                  showCancelButton={true}
                  onCancel={onHide}
                />
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      )}
    </>
  );
}
