import { Route, Switch } from 'react-router';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { LocationsMasterList } from './components/locations/LocationsMasterList';
import { ClientsMasterList } from './components/clients/ClientsMasterList';
import { FranchisesMasterList } from './components/franchises/FranchisesMasterList';

export function EntitiesPage() {
  const breadcrumbs: Array<PageLink> = [];

  return (
    <>
      <Switch>
        <Route exact path='/entities/clients'>
          <PageTitle breadcrumbs={breadcrumbs}>Clients List</PageTitle>
          <ClientsMasterList />
        </Route>
        <Route exact path='/entities/franchises'>
          <PageTitle breadcrumbs={breadcrumbs}>Franchises List</PageTitle>
          <FranchisesMasterList />
        </Route>
        <Route exact path='/entities/locations'>
          <PageTitle breadcrumbs={breadcrumbs}>Locations List</PageTitle>
          <LocationsMasterList />
        </Route>
      </Switch>
    </>
  );
}
