import clsx from 'clsx';
import React, { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { LoginUserModel } from '../../../../app/modules/auth/models/LoginUserModel';
import { UserGroupModel } from '../../../../app/modules/auth/models/UserGroupModel';
import { UserGroupRelationModel } from '../../../../app/modules/auth/models/UserGroupRelationModel';
import { RootState } from '../../../../app/redux';
import { toAbsoluteUrl, toAbsoluteUrlAvatar } from '../../../helpers';
import { HeaderUserMenu } from '../../../partials';
import { useLayout } from '../../core';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-auto h-40px w-md-auto h-md-40px py-3';
const getUserGroupRelations = (
  ugr: UserGroupRelationModel,
  group: UserGroupModel
) => {
  if (!ugr) {
    return 'Admin';
  }

  if (ugr.franchiseId) {
    return ugr.franchiseName;
  }
  if (ugr.locationId) {
    return ugr.locationName;
  }
  if (ugr.clientId) {
    return ugr.clientName;
  }
  if (ugr.accountId) {
    return ugr.accountName;
  }
  if (ugr.accountId === null) {
    return group.name;
  }
};
const Topbar: FC = () => {
  const user: LoginUserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as LoginUserModel;
  const group: UserGroupModel = useSelector<RootState>(
    ({ auth }) => auth.group,
    shallowEqual
  ) as UserGroupModel;
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );

  const { config } = useLayout();
  const { header } = config;
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}

        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative ',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <span className='text-muted font-weight-bold font-size-base d-none d-md-inline m-1'>
            Hi,
          </span>
          <span className='text-dark-50 font-weight-bolder font-size-base d-none d-md-inline m-3'>
            {user.name}
          </span>
          {group && (
            <>
              <span className='h-20px border-gray-500 border-start mx-4'></span>
              <span className='text-muted font-weight-bold font-size-base d-none d-md-inline m-1'>
                {group.userGroupRelations.length === 0
                  ? group.name
                  : getUserGroupRelations(userGroupRelations, group)}
              </span>
            </>
          )}
          {header.menuIcon === 'svg' && (
            <img
              className='m-3 h-40px'
              src={
                user.gravatarHash && user.gravatarHash !== ''
                  ? toAbsoluteUrlAvatar(user.gravatarHash)
                  : toAbsoluteUrl('/media/svg/avatars/001-boy.svg')
              }
              alt='metronic'
            />
          )}{' '}
          {header.menuIcon === 'font' && (
            <i className='fs-1 bi bi-person-bounding-box'></i>
          )}
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
    </div>
  );
};

export { Topbar };
