import { useFormikContext, Field } from 'formik';

export const SellectAllCheck = ({
  permissionKeys,
}: {
  permissionKeys: string[];
}) => {
  const { setFieldValue } = useFormikContext();

  const selectAll = (e: any) => {
    const checked = e.target.checked;
    setFieldValue('checkAll', checked);
    permissionKeys.forEach((pk) => {
      setFieldValue(`${pk}.create`, checked);
      setFieldValue(`${pk}.update`, checked);
      setFieldValue(`${pk}.list`, checked);
      setFieldValue(`${pk}.view`, checked);
    });
  };
  return (
    <tr>
      <td className='text-gray-800'>Administrator Access</td>
      <td>
        <label className='form-check form-check-sm form-check-custom form-check-solid me-9'>
          <Field
            className='form-check-input'
            name='checkAll'
            type='checkbox'
            id='kt_roles_select_all'
            onChange={selectAll}
          />
          <span className='form-check-label'>Select all</span>
        </label>
      </td>
    </tr>
  );
};
