import axios from 'axios';
import { PagedResult } from '../../../models/pagedResult';

export const REPORT_URL = `/api/v1/reports`;

export const reportsServices = {
  lowLevelProduct: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(`${REPORT_URL}/low-level-product`, {
      params: params,
    });
  },

  lowLevelProductDownload: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get(`${REPORT_URL}/low-level-product/download`, {
      params: params,
    });
  },

  backorderedProduct: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(`${REPORT_URL}/backordered-product`, {
      params: params,
    });
  },

  backorderedProductDownload: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get(`${REPORT_URL}/backordered-product/download`, {
      params: params,
    });
  },

  productUsageForecasting: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(`${REPORT_URL}/forecasting-usage-product`, {
      params: params,
    });
  },
  productUsageForecastingDownload: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get(`${REPORT_URL}/forecasting-usage-product/download`, {
      params: params,
    });
  },
  standardOrders: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(`${REPORT_URL}/standard-order`, {
      params: params,
    });
  },
  standardOrdersDownload: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get(`${REPORT_URL}/standard-order/download`, {
      params: params,
    });
  },

  productOrderHistory: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(`${REPORT_URL}/product-order-history`, {
      params: params,
    });
  },
  productOrderHistoryDownload: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get(`${REPORT_URL}/product-order-history/download`, {
      params: params,
    });
  },
};
