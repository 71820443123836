import {
  CreateKitModel,
  KitFile,
  KitProductModel,
} from '../models/CreateKitModel';
import { KitInformationCreate } from '../models/KitInformationCreate';
import { KitModel } from '../models/KitModel';
import { KitProductItem } from '../utils/utils';

const processFiles = (files: KitFile[]) => {
  const data = [...files];
  if (data.length === 1) {
    if (data[0].id) {
      data[0].type = 'image/' + data[0].ext.replace('.', '');
    }
  }
  if (data.length === 2) {
    data[0].type = 'image/' + data[0].ext.replace('.', '');
    data[1].type = 'image/' + data[1].ext.replace('.', '');
  }
  return data;
};

const createStep1 = (kit: CreateKitModel): KitInformationCreate => {
  return {
    id: kit.id || 0,
    code: kit.code,
    description: kit.description,
    premium: kit.premium,
    webOrderable: kit.webOrderable,
    maxOrderQuantity: kit.maxOrderQuantity,
    shippingUnitSize: kit.shippingUnitSize,
  };
};

const createStep2 = (producItems: KitProductItem[]): KitProductModel[] => {
  return producItems.map((pi: KitProductItem) => {
    return {
      kitId: pi.kitId,
      productId: pi.productId,
      isRequired: pi.isRequired,
      isPrimary: pi.isPrimary,
      product: null,
    } as KitProductModel;
  });
};

const createFormDataFiles = (kitImages: any) => {
  const nonDeleteFileIds: string[] = [];

  kitImages.forEach((x: any) => {
    if (x && x?.id) {
      nonDeleteFileIds.push(x.id);
    }
  });
  const formData = new FormData();
  kitImages.forEach((x: any) => {
    if (x && !x.id) formData.append('files', x);
  });

  formData.append('nonDeleteFileIds', nonDeleteFileIds.join(','));
  return formData;
};

const getProductIds = (kitProducts: KitProductModel[]) => {
  const ids = kitProducts.map(
    (kitProduct: KitProductModel) => kitProduct.productId
  );
  return ids;
};
const initialValues = (kit: KitModel) => {
  const data = { ...kit };

  data.maxOrderQuantity = data.maxOrderQuantity || 0;
  data.shippingUnitSize = data.shippingUnitSize || 0;

  data.products = data.kitProducts.map(
    (kp: KitProductModel): KitProductItem => {
      return {
        productId: kp.productId,
        isRequired: kp.isRequired,
        isPrimary: kp.isPrimary,
        type: kp.product?.productType || '',
        code: kp.product?.code || '',
        kitId: data.id,
        shippingUnitSize: kp.product?.shippingUnitSize,
      };
    }
  );

  data.files = processFiles(data.files);

  return data;
};

export const kitHelpers = {
  createStep1,
  initialValues,
  createStep2,
  createFormDataFiles,
  processFiles,
  getProductIds,
};
