import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { OrderCreateModel } from '../models/OrderCreateModel';
import { shallowEqual, useSelector } from 'react-redux';
import { CascadeEntitySelections } from './CascadeEntitySelection';

import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { ProductOrderModel } from '../models/ProductOrderModel';
import orderServices from '../services/OrdersServices';
import { ProductCard } from './ProductCard';
import { PlaceOrder } from './PlaceOrder';
import { initialValuesCreateOrder } from '../utils/utils';
import { OrderDetailsCreateModel } from '../models/OrderDetailsCreateModel';
import clientsServices from '../../entities/services/ClientsServices';
import { AddressThirdShipToModal } from './AddressThirdShipToModal';
import { productsAndKitsHelpers } from '../helpers/productAndKits';
import { EntityFiltersModel } from '../../../models/EntityFiltersModel';
import { useParams } from 'react-router-dom';

interface Params {
  accountId: string;
  clientId: string;
  franchiseId: string;
  locationId: string;
}
export function OrdersCreate() {
  const { accountId, clientId, franchiseId, locationId } = useParams<Params>();

  const [order, setOrder] = useState<OrderCreateModel>(
    initialValuesCreateOrder
  );
  const [filterProduct, setFilterProduct] = useState('');
  const [orderProductsBy, setOrderProductsBy] = useState('ProductCode');
  const [productsAdded, setProductsAdded] = useState<string[]>([]);
  const [products, setProducts] = useState<ProductOrderModel[]>();
  const [clientCanActiveCards, setClientCanActiveCards] =
    useState<boolean>(false);
  const [showChangeButton, setShowChangeButton] = useState<boolean>(false);
  const [isThirdPartyShipTo, setIsThirdPartyShipTo] = useState<boolean>(false);
  const [flow, setFlow] = useState<string>('');

  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  const [entityFilters, setEntityFilters] = useState<EntityFiltersModel>({
    accountId: userGroupRelations.accountId
      ? userGroupRelations.accountId
      : accountId
      ? Number(accountId)
      : undefined,
    clientId: userGroupRelations.clientId
      ? userGroupRelations.clientId
      : clientId
      ? Number(clientId)
      : undefined,
    franchiseId: userGroupRelations.franchiseId
      ? userGroupRelations.franchiseId
      : franchiseId
      ? Number(franchiseId)
      : undefined,
    locationId: userGroupRelations.locationId
      ? userGroupRelations.locationId
      : locationId
      ? Number(locationId)
      : undefined,
  });
  useEffect(() => {
    if (
      entityFilters.accountId &&
      entityFilters.clientId &&
      !entityFilters.franchiseId &&
      entityFilters.locationId
    ) {
      return setFlow('Secondary');
    }
    if (
      entityFilters.accountId &&
      entityFilters.clientId &&
      entityFilters.franchiseId &&
      entityFilters.locationId
    ) {
      return setFlow('Basic');
    }
    setFlow('');
  }, [entityFilters]);
  // Allow a user to place a 3rd party location order
  const canCreateThirdPartyOrders = useCallback(() => {
    orderServices.canCreateThirdPartyOrders().then((respose) => {
      setIsThirdPartyShipTo(respose.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getProductsAndKitsForThirdParty = () => {
    orderServices
      .getProductsAndKitsForThirdParty(filterProduct, orderProductsBy)
      .then((response) => {
        const items = productsAndKitsHelpers.processingItems(
          userGroupRelations,
          response.data
        );
        items.forEach((element) => {
          if (
            productsAdded.find(
              (customId: string) => customId === element.customId
            )
          ) {
            element.selected = true;
          }
        });
        setProducts(items);
      });
  };

  const findProducts = () => {
    if (entityFilters.locationId) {
      orderServices
        .findProducts(entityFilters.locationId, filterProduct, orderProductsBy)
        .then((response) => {
          const items = productsAndKitsHelpers.processingItems(
            userGroupRelations,
            response.data
          );
          items.forEach((element) => {
            if (
              productsAdded.find(
                (customId: string) => customId === element.customId
              )
            ) {
              element.selected = true;
            }
          });
          setProducts(items);
        });
    }
  };
  useEffect(() => {
    canCreateThirdPartyOrders();
  }, [canCreateThirdPartyOrders]);
  useEffect(() => {
    if (flow === 'Basic') {
      // Review this
      setOrder((s: OrderCreateModel) => ({
        ...s,
        clientId: entityFilters.clientId ? entityFilters.clientId : 0,
        locationId: entityFilters.locationId ? entityFilters.locationId : 0,
        details: [],
      }));
      // Get Products & Kits for order detail: Lcoation
      findProducts();
      entityFilters.clientId && clientDetails(entityFilters.clientId);
    } else if (flow === 'Secondary') {
      // Temp: Clear Products list:
      getProductsAndKitsForThirdParty();
      // Get Products & Kits for order detail: Client
      setOrder((s: OrderCreateModel) => ({
        ...s,
        clientId: entityFilters.clientId ? entityFilters.clientId : 0,
        locationId: entityFilters.locationId ? entityFilters.locationId : 0,
        details: [],
      }));
      //Find Products  by
      entityFilters.clientId && clientDetails(entityFilters.clientId);
    } else {
      setProducts([]);
      setProductsAdded([]);
      setOrder(initialValuesCreateOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow]);

  useEffect(() => {
    if (flow === 'Basic') {
      findProducts();
    }
    if (flow === 'Secondary') {
      getProductsAndKitsForThirdParty();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderProductsBy]);

  const onFilterChange = (e: any) => {
    setFilterProduct(e.target.value);
    debounced({ value: e.target.value });
  };
  const debounced = useDebouncedCallback((params: any) => {
    if (flow === 'Basic') {
      findProducts();
    }
    if (flow === 'Secondary') {
      getProductsAndKitsForThirdParty();
    }
  }, 800);

  const handleAddProduct = (product: ProductOrderModel, quantity: number) => {
    const newOrder = { ...order };

    var detail = newOrder.details.find(
      (item) => item.customId === product.customId
    );

    if (!detail) {
      const orderDetail: OrderDetailsCreateModel = {
        entityId: product.entityId,
        activeCards: false,
        isKitEntity: product.isKitEntity,
        quantity: quantity,
        description: product.name,
        canActivate: product.canActivate && clientCanActiveCards,
        shippingUnitSize: product.shippingUnitSize,
        customQuantity: product.customQuantity ? product.customQuantity : false,
        customId: product.customId,
        // Route
        cardAttributesType: product.cardAttributesType,
        value: product.value,
        minValue: product.minValue,
        maxValue: product.maxValue,
      };
      if (
        orderDetail.cardAttributesType === 'Fixed' &&
        orderDetail.canActivate
      ) {
        orderDetail.cardValue = orderDetail.value;
      }
      if (
        orderDetail.cardAttributesType === 'Range' &&
        orderDetail.canActivate
      ) {
        orderDetail.cardValue = orderDetail.minValue;
      }
      setOrder((s: OrderCreateModel) => ({
        ...s,
        details: [...s.details, orderDetail],
      }));

      setProductsAdded([...productsAdded, product.customId]);
    } else {
      detail.quantity += quantity;
      if (detail.quantity % product.shippingUnitSize !== 0) {
        detail.customQuantity = true;
      } else if (detail.quantity / product.shippingUnitSize > 5) {
        detail.customQuantity = true;
      }
      setOrder(newOrder);
    }
  };

  // Get Client Information: CanActiveCards (true, false)
  const clientDetails = useCallback((clientId: number) => {
    clientsServices.getClient(clientId).then((response) => {
      setClientCanActiveCards(response.data.canActiveCards);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Clear or set border green
  useEffect(() => {
    if (products && products.length > 0) {
      const updatedProducts = [...(products ? products : [])];
      updatedProducts?.forEach((element) => {
        if (
          productsAdded.find(
            (customId: string) => customId === element.customId
          )
        ) {
          element.selected = true;
        } else {
          element.selected = false;
        }
      });
      setProducts([...updatedProducts]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAdded]);

  //  Modal: Select or add 3rd party ship-to this order
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  // Change the address (to a non-3rd party or to a different 3rd party):
  const handleChangeButton = () => {
    setEntityFilters((s: EntityFiltersModel) => ({
      ...s,
      locationId: undefined,
    }));
    setShowChangeButton(false);
  };

  return (
    <Fragment>
      <div className='row'>
        <div className='col-8 px-5'>
          <div className='card row mb-10'>
            {userGroupRelations &&
              !showChangeButton &&
              !userGroupRelations.locationId && (
                <div className='card-title p-9 m-0'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bold m-0'>
                      Select the location for this order to see available
                      products
                    </h3>
                  </div>
                </div>
              )}
            {showChangeButton && (
              <div className='card-title p-9 m-0'>
                <div className='card-title m-0'>
                  <div className='row'>
                    <div className='col pt-3'>
                      <h3 className='fw-bold m-0'>
                        Shipping to 3rd party address
                      </h3>
                    </div>
                    <div className='col'>
                      <button
                        id='open-modal'
                        className='btn btn-light b-0'
                        onClick={handleChangeButton}
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={`card-body px-9 py-0`}>
              {!showChangeButton && (
                <div className='row'>
                  <CascadeEntitySelections
                    entityFilters={entityFilters}
                    setEntityFilters={setEntityFilters}
                  />
                  {!!isThirdPartyShipTo && (
                    <div className='col-1 pt-3'>
                      <span className='fw-bolder'>or:</span>
                    </div>
                  )}
                  {!!isThirdPartyShipTo && (
                    <div className='col-5'>
                      <button
                        id='open-modal'
                        className='btn btn-light b-0'
                        onClick={handleShowModal}
                      >
                        <span className='indicator-label'>
                          Ship-to a 3rd party
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div
                style={{ paddingTop: '3em', paddingBottom: '1em' }}
                className='row '
              >
                {entityFilters.locationId && (
                  <Fragment>
                    <div className='col-md-6 d-flex align-items-center position-relative my-1'>
                      <i className='las la-search position-absolute ms-6 fs-2'></i>
                      <input
                        autoComplete='none'
                        type='search'
                        className='form-control form-control-solid w-300px ps-15'
                        placeholder='Search by product ID or name'
                        onChange={(e) => {
                          onFilterChange(e);
                        }}
                      />
                    </div>
                    <div className='col-md-6 d-flex flex-stack'>
                      <div className='me-5'>
                        <label className='fs-5 fw-bold'>Order by</label>
                      </div>

                      <div className='form-check form-check-sm form-check-custom'>
                        <label className='form-check-label fw-bold text-muted'>
                          <input
                            onChange={() => setOrderProductsBy('ProductCode')}
                            name='order'
                            className={`form-check-input mx-2 ${
                              orderProductsBy === 'ProductCode'
                                ? 'bg-primary'
                                : ''
                            }`}
                            id='flexCheckboxSm'
                            type='radio'
                            value='productCode'
                            checked={orderProductsBy === 'ProductCode'}
                          />
                          Product Code
                        </label>
                      </div>

                      <div className='form-check form-check-sm form-check-custom'>
                        <label className='form-check-label fw-bold text-muted'>
                          <input
                            onChange={() =>
                              setOrderProductsBy('ProductDescription')
                            }
                            name='order'
                            className={`form-check-input mx-2 ${
                              orderProductsBy === 'ProductDescription'
                                ? 'bg-primary'
                                : ''
                            }`}
                            id='flexCheckboxSm'
                            type='radio'
                            value='description'
                            checked={orderProductsBy === 'ProductDescription'}
                          />
                          Description
                        </label>
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>

          <div style={{ backgroundColor: 'transparent' }} className='card'>
            <div className='row'>
              {products &&
                products.map((product, index) => {
                  return (
                    <div
                      key={`${product.type}.${product.entityId}`}
                      className='col-md-4'
                    >
                      <ProductCard
                        product={product}
                        handleAddProduct={handleAddProduct}
                      />
                    </div>
                  );
                })}
              {!products && (
                <>
                  {entityFilters.locationId && (
                    <h5 className='text-center text-muted'>
                      There are no Products available to order for this Location
                    </h5>
                  )}
                  {!entityFilters.locationId && (
                    <h5 className='text-center text-muted'>
                      There are no products to display
                    </h5>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className='col-4 px-5'>
          <PlaceOrder
            order={order}
            setProductsAdded={setProductsAdded}
            setOrder={setOrder}
          />
        </div>
      </div>
      {showModal && (
        <AddressThirdShipToModal
          show={showModal}
          onHide={handleCloseModal}
          setEntityFilters={setEntityFilters}
          setShowChangeButton={setShowChangeButton}
          setProductsAdded={setProductsAdded}
        />
      )}
    </Fragment>
  );
}
