import { UrlUpdateType, useQueryParams } from 'use-query-params';

export const useQueryParamsReplace = (
  config: any
): [any, (newQuery: any, updateType?: UrlUpdateType) => void] => {
  const [query, setQuery] = useQueryParams(config);

  const setQueryReplace = (
    newQuery: any,
    updateType: UrlUpdateType = 'replace'
  ) => setQuery(newQuery, updateType);

  return [query, setQueryReplace];
};
