import axios from 'axios';
import { PagedResult } from '../../../models/pagedResult';
import { InventoryModel } from '../models/InventoryModel';

export const INVENTORY_URL = `/api/v1/inventory`;

const inventoryServices = {
  findInventory: (locationId: number, queryParams: any) => {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(`${INVENTORY_URL}/${locationId}`, {
      params: params,
    });
  },
  findAllInventory: (queryParams: any) => {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(INVENTORY_URL, {
      params: params,
    });
  },
  getInventory: (inventoryId: number, locationId: number) => {
    return axios.get<InventoryModel>(
      `${INVENTORY_URL}/${locationId}/get/${inventoryId}`
    );
  },
  editInvetory: (inventory: InventoryModel) => {
    return axios.put(`${INVENTORY_URL}`, inventory);
  },
};

export default inventoryServices;
