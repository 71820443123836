import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { Formik, Form, FormikHelpers } from 'formik';

import { CustomInput } from '../../../../components/CustomInput';
import { userSchemaValidation } from '../../utils/userShemaValidations';
import { userEditInitialValues } from '../../utils/utils';
import { NavTabs } from '../../../../components/NavTabs';
import { PageLink } from '../../../../../_metronic/layout/core';
import { usersServices } from '../../services/UsersServices';
import { SelectSetGroups } from './SelectSetGroups';
import { UserEditGroup } from '../../models/UserEditGroup';
interface Params {
  id: string;
}

export function UsersEdit() {
  const { id } = useParams<Params>();
  const intl = useIntl();
  const history = useHistory();
  const [user, setUser] = useState<UserEditGroup>(userEditInitialValues);
  const [loading, setLoading] = useState(false);

  const tabs: Array<PageLink> = [
    {
      title: 'Groups',
      path: `/users/edit/${id}`,
      isActive: false,
    },
  ];

  // Back to /users
  const goBack = () => {
    history.goBack();
  };
  useEffect(() => {
    setLoading(true);
    // server call for getting user by id
    usersServices.getUser(Number(id)).then((response) => {
      setUser(response.data);
      setLoading(false);
    });
  }, [id]);

  const updateUser = (
    values: UserEditGroup,
    actions: FormikHelpers<UserEditGroup>
  ) => {};

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{user.name}</h3>
          </div>
          <button
            onClick={goBack}
            className='btn  btn-light btn-active-primary align-self-center'
          >
            <i className='fas fa-arrow-left'></i>
            {intl.formatMessage({ id: 'ACTION.BACK' })}
          </button>
        </div>

        <div className='card-body p-9'>
          {!loading && (
            <Formik
              initialValues={user}
              validationSchema={userSchemaValidation(intl)}
              onSubmit={(values, actions) => {
                updateUser(values, actions);
              }}
            >
              <Form>
                <div className='row'>
                  <div className='col-6'>
                    <CustomInput
                      disabled={true}
                      isRequired={true}
                      id='name'
                      name='name'
                      type='text'
                      label={intl.formatMessage({ id: 'USER.NAME' })}
                    />
                  </div>
                  <div className='col-6'>
                    <CustomInput
                      disabled={true}
                      isRequired={true}
                      id='email'
                      name='email'
                      label={intl.formatMessage({ id: 'USER.EMAIL' })}
                    />
                  </div>
                </div>
                <div className='row py-10'>
                  <div className='col-6'>
                    <CustomInput
                      id='capUserName'
                      type='text'
                      disabled={true}
                      name='capUserName'
                      isRequired={true}
                      label={intl.formatMessage({ id: 'USER.CAP_USERNAME' })}
                    />
                  </div>
                </div>
                <div className='d-flex overflow-auto h-55px'>
                  <NavTabs submenu={tabs} />
                </div>

                <div className='row mt-5'>
                  <div className='col-6'>
                    <div className='row'>
                      <SelectSetGroups user={user} />
                    </div>
                    <div className='row mt-8'>
                      <div className='text-start '>
                        <button
                          type='submit'
                          id='kt_modal_new_card_submit'
                          className='btn btn-primary'
                        >
                          <span className='indicator-label'>Add Groups</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          )}
        </div>
      </div>
    </>
  );
}
