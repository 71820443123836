import axios from 'axios';
import { PagedResult } from '../../../models/pagedResult';
import { ClientCreateModel } from '../models/ClientCreateModel';
import { ClientEditModel } from '../models/ClientEditModel';
import { ClientModel } from '../models/ClientModel';

export const CLIENTS_URL = `/api/v1/clients`;

const clientsServices = {
  findClients: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(CLIENTS_URL, { params: params });
  },

  getClient: (id: number) => {
    return axios.get<ClientModel>(`${CLIENTS_URL}/${id}`);
  },
  getAll: function () {
    return axios.get<ClientModel[]>(`${CLIENTS_URL}/all`);
  },

  deleteClient: (id: number) => {
    return axios.delete(`${CLIENTS_URL}/${id}`);
  },
  createClient: (client: ClientCreateModel) => {
    return axios.post(CLIENTS_URL, client);
  },
  updateClient: (id: number, client: ClientEditModel) => {
    return axios.put(`${CLIENTS_URL}/${id}`, client);
  },
  desactivateClient: (id: number, reason?: string) => {
    const body = {
      id,
      reason,
    };
    return axios.put(`${CLIENTS_URL}/deactivate/${id}`, body);
  },
  activateClient: (id: number) => {
    return axios.put(`${CLIENTS_URL}/activate/${id}`);
  },
};

export default clientsServices;
