import { Modal } from 'react-bootstrap-v5';
import { useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { OrderViewModel } from '../models/OrderViewModel';
import { CustomSelect } from '../../../components/CustomSelect';
import { ActionsComponent } from '../../../components/ActionsComponent';
import { CustomInput } from '../../../components/CustomInput';

const warehouseInventoryActionOptions = [
  { value: 'return', label: 'Return inventory' },
  { value: 'notReturn', label: 'Do not return inventory' },
];

export const newOrderSchema = (intl: IntlShape) =>
  Yup.object().shape({
    warehouseInventoryAction: Yup.string().required(
      intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
    ),
    cancelReason: Yup.string().when('status', {
      is: 'BackOrdered',
      then: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
      ),
    }),
  });

interface CancelFormValues {
  warehouseInventoryAction?: string;
  cancelReason?: string;
  status: string;
}

interface Props {
  cancel: (params: any) => void;
  show: boolean;
  order: OrderViewModel;
  onHide: () => void;
}

export function CancelOrder({ cancel, show, order, onHide }: Props) {
  const intl = useIntl();

  const save = (values: CancelFormValues) => {
    cancel({ ...values, order });
  };
  return (
    <Modal show={show} onHide={onHide} backdrop='static' keyboard={false}>
      <Formik
        initialValues={{
          warehouseInventoryAction: 'return',
          status: order.status,
          cancelReason: '',
        }}
        validationSchema={newOrderSchema(intl)}
        onSubmit={(values, actions) => {
          save(values);
          actions.setSubmitting(false);
        }}
      >
        <Form>
          <Modal.Body className='mt-1 px-6 py-6 position-relative z-index-1'>
            <div className='d-flex align-items-center mb-4'>
              <i className={`las la-exclamation-circle fs-5x text-warning`}></i>
              <div className='flex-grow-3 ms-4'>
                <div className='fw-bold text-gray-700 fs-6'>
                  Are you sure you want to cancel this order?
                </div>
                <div className='fw-bold text-gray-1000 fs-6'>
                  This operation cannot be undone.
                </div>
              </div>
            </div>
            <div>
              <CustomSelect
                isRequired={true}
                id='warehouseInventoryAction'
                options={warehouseInventoryActionOptions}
                name='warehouseInventoryAction'
                isDisabled={false}
                defaultValue={warehouseInventoryActionOptions[0].value}
              />
              {order.status === 'BackOrdered' && (
                <div className='row pt-5'>
                  <div className='col-12'>
                    <CustomInput
                      name='cancelReason'
                      type='text'
                      label='Cancel Reason'
                      isRequired={true}
                    />
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className=' border-0'>
            <ActionsComponent
              labelSubmit='OK'
              labelDiscard='Cancel'
              showCancelButton={true}
              onCancel={onHide}
            />
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
}
